import { Injectable } from '@angular/core';
import {HelperMethods} from "../helper_methods";

@Injectable({
  providedIn: 'root'
})
export class UserSessionProviderService {
  userSessionName: string = 'sp-user-session';
  constructor() { }


  setSession(value: string) {
    HelperMethods.setCookie(this.userSessionName, value);
  }

  getSession(): string {
    let item = HelperMethods.getCookie(this.userSessionName);
    if (item != null)
      return item;
    else
      return null
  }

  logoutUser() {
    HelperMethods.deleteCookie(this.userSessionName);
  }

  isUserLogged(): boolean {
    let employeeId = this.getSession();
    if (!employeeId || employeeId == '')
      return false;
    else
      return true;
  }
}
