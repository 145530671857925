import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";
import {EmployeeQuizAnswerMessageData} from "../message_data/employee_quiz_answer_message_data";
import {EmployeeQuizAttemptMessageData} from "../message_data/employee_quiz_attempt_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class EmployeeQuizAnswersRequestMessage extends RequestMessage {
    attempts: Array<EmployeeQuizAttemptMessageData> = new Array<EmployeeQuizAttemptMessageData>();
    answers: Array<EmployeeQuizAnswerMessageData> = new Array<EmployeeQuizAnswerMessageData>();
    employeeId: number;
    preferredLang: number;

    constructor() {
        super()
        this.type = MessageTypes.EmployeeQuizAnswersRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.attempts = deserializeArrayOfObject(jsonObject.attempts, EmployeeQuizAttemptMessageData);
        this.answers = deserializeArrayOfObject(jsonObject.answers, EmployeeQuizAnswerMessageData);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}