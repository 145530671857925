import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../app";


@Component({
    selector: 'sp-main',
    templateUrl: '../../templates/spmain.component.html'

})

export class SpMainComponent {
    private router: Router;
    app: App = App.instance;


    constructor(router: Router) {

    }
}
