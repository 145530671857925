import {Component, OnInit, ViewChild} from '@angular/core';
import {App} from "../../app";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {ReferenceListComponent} from "./reference_list.component";
import {CourseFilterData} from "../common/course_filter_data";
import {CoursesFilterComponent} from "../common/courses_filter.component";
import {BrandExtendedMessageData} from "../../communication/message_data/brand_extended_message_data";
import {CategoryMessageData} from "../../communication/message_data/category_message_data";

@Component({
    selector: 'sp-reference',
    templateUrl: '../../../templates/reference/reference.component.html'

})
export class ReferenceComponent implements OnInit {
    @ViewChild('cfilter', { static: true }) filter: CoursesFilterComponent;
    @ViewChild('crLibrary', { static: true }) coursesLibraryComponent: ReferenceListComponent;
    @ViewChild('crArchive', { static: true }) coursesArchiveComponent: ReferenceListComponent;

    isFilterOpen: boolean = false;
    isFilterSet: boolean = false;

    app: App = App.instance;
    referenceLibrary: Array<CourseMessageData>;
    referenceArchive: Array<CourseMessageData>;

    activeTab: HTMLElement;
    activePage: HTMLElement;

    ngOnInit(): any {
        this.initTabs();
        this.showLibraryPage();
    }

    constructor() {

    }

    initTabs() {
        this.referenceLibrary = this.app.data.getRetailerReferenceLibrary();
        this.referenceArchive = this.app.data.getRetailerArchive();
    }

    onTabClick(index: number) {
        switch (index) {
            case 0:
                this.showLibraryPage();
                break;
            case 1:
                this.showArchivePage();
                break;
        }
    }

    showLibraryPage() {
        this.updateActive('tab-library', 'library');
        this.isFilterSet = this.coursesLibraryComponent.filterData.isSet();
    }

    showArchivePage() {
        this.updateActive('tab-archive', 'archive');
        this.isFilterSet = this.coursesArchiveComponent.filterData.isSet();
    }

    private updateActive(tabId: string, pageId: string) {
        let tab = document.getElementById(tabId);
        let page = document.getElementById(pageId);

        if (this.activeTab) {
            this.activeTab.classList.remove("active");
        }

        if (this.activePage)
            this.activePage.classList.remove("active");

        this.activeTab = tab;
        this.activePage = page;

        this.activeTab.classList.add("active");
        this.activePage.classList.add("active");
        this.isFilterOpen = false;
    }

    public onFilterButtonClick() {
        if (this.isFilterOpen) {
            this.isFilterOpen = false;
            return;
        }

        let fd: CourseFilterData;
        let ab: Array<BrandExtendedMessageData>;
        let ac: Array<CategoryMessageData>;

        switch (this.activeTab.id) {
            case "tab-library":
                fd = this.coursesLibraryComponent.filterData;
                ab = this.coursesLibraryComponent.getAllBrands();
                ac = this.coursesLibraryComponent.getAllCategories();
                break;
            case "tab-archive":
                fd = this.coursesArchiveComponent.filterData;
                ab = this.coursesArchiveComponent.getAllBrands();
                ac = this.coursesArchiveComponent.getAllCategories();
                break;
        }

        this.filter.filterData = fd;
        this.filter.allBrands = ab;
        this.filter.allCategories = ac;

        this.filter.init();
        this.isFilterOpen = true;
    }

    onFilterChanged(fd: CourseFilterData) {
        switch (this.activeTab.id) {
            case "tab-library":
                this.coursesLibraryComponent.filterList(fd);
                break;
            case "tab-archive":
                this.coursesArchiveComponent.filterList(fd);
                break;
        }

        this.isFilterSet = fd.isSet();
    }
}

