import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class StoreForBrandLeaderboardMessageData implements IJsonDeserializable {
    version: number = 0;

    id: number;
    name: string;
    address: string;
    description: string;
    city: string;
    zip: string;
    retailerName: string;
    retailerId: number;
    countryId: number;
    countryName: string;
    stateId: number;
    stateName: string;
    stateAbrv: string;
    storeZipRequirement: boolean;
    storeDescriptionRequirement: boolean;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.address = jsonObject.address;
        this.city = jsonObject.city;
        this.zip = jsonObject.zip;
        this.retailerName = jsonObject.retailerName;
        this.retailerId = jsonObject.retailerId;
        this.countryId = jsonObject.countryId;
        this.countryName = jsonObject.countryName;
        this.stateId = jsonObject.stateId;
        this.stateName = jsonObject.stateName;
        this.stateAbrv = jsonObject.stateAbrv;

        if (this.version > 0) {
            this.description = jsonObject.description;
            this.storeZipRequirement = jsonObject.storeZipRequirement;
            this.storeDescriptionRequirement = jsonObject.storeDescriptionRequirement;
        }
    }
}
