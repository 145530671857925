import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class EmployeeEventAttendanceConfirmationRequestMessage extends RequestMessage {
    employeeId: number;
    eventInvitationId: number;
    attendanceCode: string;
    deviceUUID: string;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.EmployeeEventAttendanceConfirmationRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.eventInvitationId = jsonObject.eventInvitationId;
        this.attendanceCode = jsonObject.attendanceCode;
        this.deviceUUID = jsonObject.deviceUUID;
        this.preferredLang = jsonObject.preferredLang;
    }
}
