import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class AwardQuizQuestionMessageData implements IJsonDeserializable {

    version: number = 0;

    id: number;
    awardQuizId: number;
    text: string;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.awardQuizId = jsonObject.awardQuizId;
        this.text = jsonObject.text;
    }
}
