import {deserializeDateTimeUtc, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {SurveyQuestionMessageData} from "./survey_question_message_data";
import {EmployeeSurveyStatus} from "../../ui/surveys/helpers/employee_survey_status";

export class SurveyMessageData implements IJsonDeserializable, Iid {

    version: number = 0;
    id: number;
    name: string;
    description: string;
    fromDateUtc: Date;
    toDateUtc: Date;
    count: number;
    employeeStatus: EmployeeSurveyStatus;
    employeeSurveyId: number;
    forEmployeeDeviceUUID: string;
    brandId: number;
    timeZone: number;

    //private local
    brandImage: String;
    questions: Array<SurveyQuestionMessageData> = [];

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.description = jsonObject.description;
        this.fromDateUtc = deserializeDateTimeUtc(jsonObject.fromDateUtc);
        this.toDateUtc = deserializeDateTimeUtc(jsonObject.toDateUtc);
        this.count = jsonObject.count;
        this.employeeStatus = jsonObject.employeeStatus;
        this.employeeSurveyId = jsonObject.employeeSurveyId;
        this.forEmployeeDeviceUUID = jsonObject.forEmployeeDeviceUUID;
        this.brandId = jsonObject.brandId;
        this.timeZone = jsonObject.timeZone;
    }

    get isPassed() {
        return this.employeeStatus != null && this.employeeStatus == EmployeeSurveyStatus.Finished && this.employeeSurveyId != null && this.forEmployeeDeviceUUID.length > 0 ? true : false;
    }
}
