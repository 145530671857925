import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {RegisterComponent} from "./register.component";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'sp-register-preferences',
    templateUrl: '../../../templates/register/preferences.component.html'

})

export class PreferencesComponent {
    public registerData: RegisterData;
    public app: App = App.instance;

    constructor(private registerComponent: RegisterComponent, private router: Router, private http: HttpClient) {
        this.registerData = registerComponent.RegisterData;
    }

    ActionButtonTapped() {
        this.router.navigate(['register', 'terms_and_conditions'])
    }

    BackButtonTapped() {
        this.router.navigate(['register', 'profile'])
    }

}
