import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class LeaderBoardInfoMessageData implements IJsonDeserializable {
    version: number;

    employeeId: number;
    employeeName: string;
    employeeScreenName: string;

    sellPoints: number;
    countCertificates: number;
    dateTime: Date;
    retailerId: number;
    retailerName: string;

    storeId: number;
    storeName: string;
    storeCity: string;
    storeAddress: string;

    stateId: number;
    stateName: string;
    stateAbrv: string;

    position: number;
    my: boolean;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.employeeId = jsonObject.employeeId;
        this.employeeName = jsonObject.employeeName;
        this.employeeScreenName = jsonObject.employeeScreenName;

        this.sellPoints = jsonObject.sellPoints;
        this.countCertificates = jsonObject.countCertificates;
        this.dateTime = deserializeDateTime(jsonObject.dateTime);
        this.retailerId = jsonObject.retailerId;
        this.retailerName = jsonObject.retailerName;

        this.storeId = jsonObject.storeId;
        this.storeName = jsonObject.storeName;
        this.storeCity = jsonObject.storeCity;
        this.storeAddress = jsonObject.storeAddress;

        this.stateId = jsonObject.stateId;
        this.stateName = jsonObject.stateName;
        this.stateAbrv = jsonObject.stateAbrv;

        this.position = jsonObject.position;
        this.my = jsonObject.my;
    }
}

