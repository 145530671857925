import {Component, OnInit} from '@angular/core';
import {App} from "../../app";
import {LanguageMessageData} from "../../communication/message_data/language_message_data";

@Component({
    selector: 'language-dropdown',
    templateUrl: "../../../templates/common/language_dropdown.component.html"
})
export class LanguageDropdownComponent implements OnInit {
    selected: string;
    visibility = false;
    public app: App;

    constructor() {
        this.app = App.instance;
    }

    ngOnInit() {
    }

    onLanguageChange(item: LanguageMessageData) {
        this.app.language.changeLanguage(item);
        this.visibility = !this.visibility;
    }

    onButtonLanguageClick(event: Event) {
        this.visibility = !this.visibility;
        return false;
    }

}
