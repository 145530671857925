import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EventAttachmentMessageData implements IJsonDeserializable {
    version: number = 0;

    id: number;
    eventId: number;
    name: string;
    fileDescriptor: string;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.eventId = jsonObject.eventId;
        this.name = jsonObject.name;
        this.fileDescriptor = jsonObject.fileDescriptor;
    }
}
