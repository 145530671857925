import {ResponseMessage} from "../response_message";
import {EmployeeAwardStateMessageData} from "../message_data/employee_award_state_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class CheckEmployeeAwardStatesResponseMessage extends ResponseMessage {
    records: Array<EmployeeAwardStateMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.CheckEmployeeAwardStatesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.records = deserializeArrayOfObject(jsonObject.records, EmployeeAwardStateMessageData);
    }
}
