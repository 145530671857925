import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class EmployeePointsSyncResponseMessage extends ResponseMessage {
    server: number;
    local: number;
    serverBalance: number;

    constructor() {
        super()
        this.type = MessageTypes.EmployeePointsSyncResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.server = jsonObject.server;
        this.local = jsonObject.local;
        this.serverBalance = jsonObject.serverBalance;
    }
}
