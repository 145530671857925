import {ResponseMessage} from "../response_message";
import {JobCategoryMessageData} from "../message_data/job_category_message_data";

import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class JobCategoriesResponseMessage extends ResponseMessage {
    jobCategories: Array<JobCategoryMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.JobCategoriesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.jobCategories = deserializeArrayOfObject(jsonObject.jobCategories, JobCategoryMessageData);
    }
}
