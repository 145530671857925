import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class ClaimAwardRequestMessage extends RequestMessage {
    employeeId: number;
    awardId: number;
    email: string;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.ClaimAwardRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.awardId = jsonObject.awardId;
        this.email = jsonObject.email;
        this.preferredLang = jsonObject.preferredLang;
    }
}
