import {ContentStatisticMessageData} from "../communication/message_data/content_statistic_message_data";

export class ContentStatistic {
    guid: string;
    courseId: number;
    employeeId: number;
    topicId: number;
    teaserId: number;
    quizId: number;
    certificateId: number;
    dateOpen: Date;
    dateClose: Date;
    totalTime: number;
    type: number;
    device: string;
    appVersion: string;

    performRelated: number;

    closePeriod() {
        this.dateClose = new Date();
        this.totalTime = Math.ceil(Math.abs((this.dateClose.getTime() - this.dateOpen.getTime()) / 1000));
    }

    private toMessageData(): ContentStatisticMessageData {
        let messageData: ContentStatisticMessageData = new ContentStatisticMessageData();
        messageData.guid = this.guid;
        messageData.courseId = this.courseId;
        messageData.employeeId = this.employeeId;
        messageData.topicId = this.topicId;
        messageData.teaserId = this.teaserId;
        messageData.quizId = this.quizId;
        messageData.certificateId = this.certificateId;
        messageData.dateOpen = this.dateOpen;
        messageData.dateClose = this.dateClose;
        messageData.totalTime = this.totalTime;
        messageData.type = this.type;
        messageData.device = this.device;
        messageData.appVersion = this.appVersion;
        messageData.performRelated = this.performRelated;

        return messageData;
    }

    public static toMessageData(records: Array<ContentStatistic>): ContentStatisticMessageData[] {
        let results: Array<ContentStatisticMessageData> = [];

        for (let record of records) {
            results.push(record.toMessageData());
        }

        return results;
    }
}