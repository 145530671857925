import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterComponent} from "./register.component";
import {RegisterData} from "./register.data";
import {App} from "../../app";

@Component({
    selector: 'sp-register-retailer-employer',
    templateUrl: '../../../templates/register/retailer_employer.component.html'

})

export class RetailerEmployerComponent {
    public registerData: RegisterData;
    public app: App = App.instance;

    constructor(private registerComponent: RegisterComponent, private router: Router) {
        this.registerData = registerComponent.RegisterData;
    }

    RetailerButtonTapped() {
        this.router.navigate(['register', 'retailers_list']);
    }

    StoreButtonTapped() {

        this.router.navigate(['register', 'stores_list']);
    }

    JobCategoryButtonTapped() {
        this.router.navigate(['register', 'job_categories_list']);
    }

    ActionButtonTapped() {
        this.router.navigate(['register', 'profile'])
    }

    BackButtonTapped() {
        let button = this.router.navigate(['register', 'country']);

    }
}

