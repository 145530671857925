import {IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {BrandCategoryMessageData} from "./brand_category_message_data";

export class CategoryMessageData implements IJsonDeserializable, Iid {
    version: number = 0;
    id: number;
    name: string;
    imageFileDescriptor: string;
    isActive: boolean;

    brandCategories: Array<BrandCategoryMessageData> = [];

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.imageFileDescriptor = jsonObject.imageFileDescriptor;
        this.isActive = jsonObject.isActive;
    }
}
