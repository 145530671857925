import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {App} from "../../app";
import {EmailValidator} from "../common/email_validator";
import {ChangeEmailAfterActivationResponseMessage} from "../../communication/messages/change_email_after_activation_response_message";
import {SpModalComponent} from "../common/sp_modal.component";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-profile-change-email',
    templateUrl: '../../../templates/profile/profile_change_email.component.html'
})
export class ProfileChangeEmailComponent implements OnInit {
    app: App = App.instance;
    newEmail: string;
    confirmEmail: string;

    form: FormGroup;
    areEmailsEquals: boolean = false;
    isFirstTimeOpen: boolean = true;
    isEmailValid: boolean = false;

    constructor(private modal:ModalService) {
    }

    ngOnInit() {

    }

    onConfirmEmailKeyPressed() {
        this.isFirstTimeOpen = false;
        this.validateEmails(this.newEmail, this.confirmEmail);

    }

    onNewEmailKeyPressed(args: any) {
        this.isFirstTimeOpen = false;
        let email = args.target.value;
        if (!EmailValidator.isEmailValid(email))
            this.isEmailValid = false;
        else
            this.isEmailValid = true;
    }

    validateEmails(newEmail: string, confirmEmail: string) {
        if (newEmail === confirmEmail) {
            this.areEmailsEquals = true;

        } else {
            this.areEmailsEquals = false;
        }
    }

    onChangeButtonClick() {
        if (!this.isFormValid())
            return;

        this.app.driver.changeEmailAfterActivation(this.app.data.employee.id, this.newEmail, this.app.data.employee.email, true)
            .onSuccess((rsp: ChangeEmailAfterActivationResponseMessage) => {
                if (rsp.success) {
                    if ((!rsp.isUsed) || (this.app.data.employee.isDemo && rsp.isDemo)) {
                        this.modal.show('CHANGE_EMAIL_AFTER_ACTIVATION.SUCCESS', "CHANGE_EMAIL_AFTER_ACTIVATION.EMAIL_SUCCESSFULLY_CHANGED");
                    } else {
                        if(rsp.isUsed){
                            this.modal.show('CHANGE_EMAIL_AFTER_ACTIVATION.ERROR', "CHANGE_EMAIL_AFTER_ACTIVATION.EMAIL_IS_ALREADY_IN_USE");
                        }else {
                            this.modal.show('CHANGE_EMAIL_AFTER_ACTIVATION.ERROR', "CHANGE_EMAIL_AFTER_ACTIVATION.EMAIL_IS_NOT_ALLOWED");
                        }
                    }
                    this.resetForm();
                }else {
                    this.modal.show('CHANGE_EMAIL_AFTER_ACTIVATION.ERROR', "CHANGE_EMAIL_AFTER_ACTIVATION.EMAIL_IS_NOT_ALLOWED");
                }
            })
            .onError((e, ec, em) => {
                this.resetForm();
                this.modal.show('CHANGE_EMAIL_AFTER_ACTIVATION.ERROR', "CHANGE_EMAIL_AFTER_ACTIVATION.SERVER_DID_NOT_RESPOND");
            })
            .send();
    }

    resetForm(){
        this.isFirstTimeOpen = true;
        this.newEmail = '';
        this.confirmEmail = '';
    }

    isFormValid(): boolean {
        if (this.areEmailsEquals && this.isEmailValid)
            return true;

        return false;
    }

}
