import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../app";

@Component({
    selector: 'sp-retailer',
    templateUrl: '../../../templates/home/home.component.html'

})
export class HomeComponent implements OnInit {

    public app: App = App.instance;
    @Input() isRetailer: boolean = false;

    @Input() tiles: any;


    constructor(private router: Router) {

    }

    ngOnInit(): any {
        this.isRetailer = this.app.data.employee.isRetailer();
        this.app.driver.getTermsAndConditionsId().onSuccess(rsp => {
            if (this.app.getEmployee().termsAndConditionsId == null || this.app.getEmployee().termsAndConditionsId != rsp.termsAndConditionsId) {
                this.router.navigate(['updated_terms_and_conditions']);
            }
        }).onError(console.log).send();
    }


}
