import {ResponseMessage} from "../response_message";
import {CountryMessageData} from "../message_data/country_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class CountriesResponseMessage extends ResponseMessage {
    countries: Array<CountryMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.CountriesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.countries = deserializeArrayOfObject(jsonObject.countries, CountryMessageData);
    }
}
