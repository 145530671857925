import {Component, Input} from '@angular/core';
import {LeaderBoardInfoMessageData} from "../../../../communication/message_data/leaderboard_info_message_data";
import {LeaderBoardType} from "../../../../data/leader_board_types";
import {App} from "../../../../app";

@Component({
    selector: 'sp-retailer-leaderboard-list',
    templateUrl: '../../../../../templates/leaderboard/retailer/retailer_leaderboard_list.component.html'

})
export class RetailerLeaderboardListComponent {
    app: App = App.instance;
    @Input() records: Array<LeaderBoardInfoMessageData>;
    @Input() leaderBoardType: LeaderBoardType;
    LeaderBoardTypes = LeaderBoardType;

    constructor() {
    }

    get recordsForDisplay(){
        return this.records.slice(0,10);
    }

    get myScore(){
        const record = this.records.find(p => p.my);
        return record != null ? record.position : ' N/A';
    }
}

