import {App} from "../app";
import {ContentStatisticType} from "./content_statistic_type";
import {ContentStatistic} from "./content_statistic";
import {CommonStatistic} from "./common_statistic";
import {ActionStatistic} from "./action_statistic";
import {ActionStatisticType} from "./action_statistic_type";
import {SellPointsSource} from "./sell_points_source";
import {UUID} from "../common/uuid";
import {isDevMode} from "@angular/core";

export class Statistic {
    debug: boolean = isDevMode();

    public app: App;
    private activeContentStatisticType: ContentStatisticType = ContentStatisticType.unknown;
    private activeContentId: number = -1;
    private activeContentRecord: ContentStatistic;
    private activeContentRecordBeforeSuspend: ContentStatistic;
    private appOnFocusCommonStatistic: CommonStatistic;
    private awardQuizCommonStatistic: CommonStatistic;

    public constructor(app: App) {
        this.app = app;
    }

    public closeCurrentContentStatistic() {
        if ((this.activeContentRecord != null)) {
            this.activeContentRecord.closePeriod();
            this.sendContentStatistic(this.activeContentRecord);
            this.activeContentRecord = null;
            this.activeContentId = -1;
            if (this.debug)
                console.log("close current content statistic");
        }
    }

    public createWithDefaultContentStatistic(): ContentStatistic {
        let record: ContentStatistic = new ContentStatistic();
        record.guid = UUID.create();
        record.appVersion = this.app.appVersion;
        record.device = this.app.deviceUUID;
        record.dateOpen = new Date();
        record.employeeId = this.app.getEmployeeId();
        record.performRelated = 0;
        return record;
    }

    public createWithDefaultCommonStatistic(): CommonStatistic {
        let record: CommonStatistic = new CommonStatistic();
        record.guid = UUID.create();
        record.appVersion = this.app.appVersion;
        record.device = this.app.deviceUUID;
        record.dateOpen = new Date();
        record.employeeId = this.app.getEmployeeId();
        return record;
    }

    public createWithDefaultActionStatistic(action: ActionStatisticType): ActionStatistic {
        let record: ActionStatistic = new ActionStatistic();
        record.guid = UUID.create();
        record.appVersion = this.app.appVersion;
        record.device = this.app.deviceUUID;
        record.dateTime = new Date();
        record.employeeId = this.app.getEmployeeId();
        record.action = action;
        record.performRelated = 0;
        return record;
    }

    public createAndSaveWithDefaultActionStatistic(action: ActionStatisticType, send: boolean): ActionStatistic {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(action);
        if (send)
            this.sendActionStatistic(record);
        return record;
    }

    public contentStatisticCommonOpen(type: ContentStatisticType, id: number): ContentStatistic {
        if (((this.activeContentStatisticType == type) && (this.activeContentId == id)))
            return null;

        this.closeCurrentContentStatistic();
        this.activeContentId = id;
        this.activeContentStatisticType = type;
        let record: ContentStatistic = this.createWithDefaultContentStatistic();
        record.type = type;
        if (this.debug)
            console.log("new content statistic");

        return record;
    }

    public setPerformRelatedOnActive(operations: number): boolean {
        if (this.activeContentRecord != null) {
            if (this.activeContentRecord.performRelated > 0)
                return false;

            this.activeContentRecord.performRelated = operations;
            return true;
        }

        return false;
    }

    public courseOpen(id: number, type: ContentStatisticType) {
        let record: ContentStatistic = this.contentStatisticCommonOpen(type, id);
        if ((record != null)) {
            record.courseId = id;
            record.type = type;
            this.activeContentRecord = record;
            if (this.debug)
                console.log("course open");
        }
    }

    public courseClosed(id: number, type: ContentStatisticType) {
        this.courseOpen(id, type);
        this.closeCurrentContentStatistic();
        if (this.debug)
            console.log("course closed");
    }

    public topicOpen(courseId: number, id: number, type: ContentStatisticType) {
        let record: ContentStatistic = this.contentStatisticCommonOpen(type, id);
        if ((record != null)) {
            record.courseId = courseId;
            record.topicId = id;
            record.type = type;
            this.activeContentRecord = record;
            if (this.debug)
                console.log("topic open");
        }
    }

    public topicClosed(courseId: number, id: number, type: ContentStatisticType) {
        this.topicOpen(courseId, id, type);
        this.closeCurrentContentStatistic();
        if (this.debug)
            console.log("topic closed");
    }

    public teaserOpen(courseId: number, topicId: number, id: number) {
        let record: ContentStatistic = this.contentStatisticCommonOpen(ContentStatisticType.teaser, id);
        if ((record != null)) {
            record.courseId = courseId;
            record.topicId = topicId;
            record.teaserId = id;
            this.activeContentRecord = record;
            if (this.debug)
                console.log("teser open");
        }
    }

    public teaserClosed(courseId: number, topicId: number, id: number) {
        this.teaserOpen(courseId, topicId, id);
        this.closeCurrentContentStatistic();
        if (this.debug)
            console.log("teser closed");
    }

    public courseQuizOpen(courseId: number, id: number) {
        let record: ContentStatistic = this.contentStatisticCommonOpen(ContentStatisticType.quiz, id);
        if ((record != null)) {
            record.courseId = courseId;
            record.quizId = id;
            this.activeContentRecord = record;
            if (this.debug)
                console.log("course quiz open");
        }
    }

    public topicQuizOpen(courseId: number, topicId: number) {
        let record: ContentStatistic = this.contentStatisticCommonOpen(ContentStatisticType.topicQuiz, topicId);
        if ((record != null)) {
            record.courseId = courseId;
            record.topicId = topicId;
            this.activeContentRecord = record;
            if (this.debug)
                console.log("topic quiz open");
        }
    }

    public employeeCertified(courseId: number, passed: number, points: number) {
        if (passed > 0 && points > 0)
            this.courseCertificationPointsAction(courseId, points);

        if (passed > 0)
            this.employeeCertifiedAction(courseId, false);

        if (this.debug)
            console.log("employee certified");
    }

    public employeeRecertified(courseId: number, passed: number, points: number) {
        if (passed > 0 && points > 0)
            this.courseRecertificationPointsAction(courseId, points);

        if (passed > 0)
            this.employeeCertifiedAction(courseId, true);

        if (this.debug)
            console.log("employee recertified");
    }

    public courseQuizClosed(courseId: number, id: number, score: number, passed: number) {
        this.courseQuizOpen(courseId, id);
        this.closeCurrentContentStatistic();
        if (this.debug)
            console.log("course quiz closed");
    }

    public topicQuizClosed(courseId: number, topicId: number, score: number, passed: number) {
        this.topicQuizOpen(courseId, topicId);
        this.closeCurrentContentStatistic();
        this.topicQuizAction(topicId, score, passed);
        if (this.debug)
            console.log("course quiz closed");
    }

    public openCourseCertificate(courseId: number, id: number) {
        let record: ContentStatistic = this.contentStatisticCommonOpen(ContentStatisticType.certificate, id);
        if (record != null) {
            record.courseId = courseId;
            record.certificateId = id;
            this.activeContentRecord = record;
            if (this.debug)
                console.log("certificate open");
        }
    }

    public closeCourseCertificate(courseId: number, id: number) {
        this.openCourseCertificate(courseId, id);
        this.closeCurrentContentStatistic();
        if (this.debug)
            console.log("certificate closed");
    }

    public loginAction() {
        let as: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.login);
        as.additional1int = 4;
        as.additional1str = this.app.appVersion;
        this.sendActionStatistic(as);
        if (this.debug)
            console.log("loginAction");
    }

    public logoutAction() {
        let as: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.logout);
        as.additional1int = 4;
        as.additional1str = this.app.appVersion;
        this.sendActionStatistic(as);
        if (this.debug)
            console.log("logoutAction");
    }

    public newContentAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.newContent, true);
        if (this.debug)
            console.log("newContentAction");
    }

    public emailCertificateAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.emailCertificate, true);
        if (this.debug)
            console.log("emailCertificateAction");
    }

    public emailAwardAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.emailCertificate, true);
        if (this.debug)
            console.log("emailAwardAction");
    }

    public facebookShareInviteAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.facebookShareInvite, true);
        if (this.debug)
            console.log("facebookShareInviteAction");
    }

    public facebookShareCertificateAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.facebookShareCertificate, true);
        if (this.debug)
            console.log("facebookShareCertificateAction");
    }

    public facebookShareAwardAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.facebookShareAward, true);
        if (this.debug)
            console.log("facebookShareAwardAction");
    }

    public appStartAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.appStart, true);
        if (this.debug)
            console.log("appStartAction");
    }

    public courseQuizAction(courseQuizId: number, scope: number, passed: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.courseQuiz);
        record.additional1int = courseQuizId;
        record.additional2int = scope;
        record.additional3int = passed;
        this.sendActionStatistic(record);
        if (this.debug) {
            console.log("courseQuizAction");
        }

    }

    public topicQuizAction(topicId: number, scope: number, passed: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.courseQuiz);
        record.additional1int = topicId;
        record.additional2int = scope;
        record.additional3int = passed;
        this.sendActionStatistic(record);
        if (this.debug) {
            console.log("courseQuizAction");
        }

    }

    public eventSelfRegistrationAction(eventId: number, employeeId: number, personalCode: string) {
        var record = this.createWithDefaultActionStatistic(ActionStatisticType.eventRepRegistration);
        record.additional1int = eventId;
        this.sendActionStatistic(record);
        if (this.debug) {
            console.log("eventSelfRegistrationAction");
        }
    }


    public topicAddedToClipboardAction(topicId: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.topicAddedToClipboard);
        record.additional1int = topicId;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("topicAddedToClipboardAction");
    }

    public topicRemovedFromClipboardAction(topicId: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.topicRemovedFromClipboard);
        record.additional1int = topicId;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("topicRemovedFromClipboardAction");
    }

    public rateInMarketAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.rateInMarket, true);
        if (this.debug)
            console.log("rateInMarketAction");
    }

    public rateFormOpenAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.rateFormOpen, true);
        if (this.debug)
            console.log("rateFormOpenAction");
    }

    public rateFormSubmitAction() {
        this.createAndSaveWithDefaultActionStatistic(ActionStatisticType.rateFormSubmit, true);
        if (this.debug)
            console.log("rateFormSubmitAction");
    }

    public courseCertificationPointsAction(courseId: number, points: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.sellPoint);
        record.performRelated = 2;
        record.additional1int = points;
        record.additional2int = SellPointsSource.courseCertification;
        record.additional3int = courseId;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("courseCertificationPointsAction");
    }

    public courseRecertificationPointsAction(courseId: number, points: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.sellPoint);
        record.performRelated = 2;
        record.additional1int = points;
        record.additional2int = SellPointsSource.courseRecertification;
        record.additional3int = courseId;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("courseCertificationPointsAction");
    }

    public employeeCertifiedAction(courseId: number, recertification: boolean) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.employeeCertified);
        record.additional1int = courseId;
        record.performRelated = 1;
        if (recertification) {
            record.additional2int = 1;
        }
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("employeeCertifiedAction");
    }

    public dailyLoginPointsAction(points: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.sellPoint);
        record.additional1int = points;
        record.additional2int = SellPointsSource.dailyLogin;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("dailyLoginPointsAction");
    }

    public socialMediaSharePointsAction(points: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.sellPoint);
        record.additional1int = points;
        record.additional2int = SellPointsSource.socialMediaShare;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("socialMediaSharePointsAction");
    }

    public referralInvitedPointsAction(points: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.sellPoint);
        record.additional1int = points;
        record.additional2int = SellPointsSource.referralInvited;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("referralInvitedPointsAction");
    }

    public referralInvitingPointsAction(points: number) {
        let record: ActionStatistic = this.createWithDefaultActionStatistic(ActionStatisticType.sellPoint);
        record.additional1int = points;
        record.additional2int = SellPointsSource.referralInviting;
        this.sendActionStatistic(record);
        if (this.debug)
            console.log("referralInvitingPointsAction");
    }

    private sendActionStatistic(record: ActionStatistic, successListener: ISuccessListenerWithArg = null) {
        let records: Array<ActionStatistic> = [];
        records.push(record);
        this.app.driver.actionStatistic(records).send();
    }

    private sendContentStatistic(record: ContentStatistic) {
        let records: Array<ContentStatistic> = [];
        records.push(record);
        this.app.driver.contentStatistic(records).send();
    }

    // 
    private sendCommonStatistic(record: CommonStatistic) {
        let records: Array<CommonStatistic> = [];
        records.push(record);
        this.app.driver.commonStatistic(records).send();
    }

}

export interface ISuccessListenerWithArg {
    onSuccess(success: boolean): boolean;
}
