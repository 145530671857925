import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";
import {deserializeDateTime} from "../json_deserialization/json_deserialization";

export class CheckEmployeeAwardStatesRequestMessage extends RequestMessage {
    employeeId: number;
    afterDate: Date;
    ids: number[];
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.CheckEmployeeAwardStatesRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.afterDate = deserializeDateTime(jsonObject.afterDate);
        this.ids = jsonObject.ids;
        this.preferredLang = jsonObject.preferredLang;
    }
}
