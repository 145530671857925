import {ResponseMessage} from "../response_message";
import {EmployeeCourseStateMessageData} from "../message_data/employee_course_state_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class CheckEmployeeCourseStatesResponseMessage extends ResponseMessage {
    records: Array<EmployeeCourseStateMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.CheckEmployeeCourseStatesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.records = deserializeArrayOfObject(jsonObject.records, EmployeeCourseStateMessageData);
    }
}
