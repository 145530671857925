import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class ForgottenPasswordResponseMessage extends ResponseMessage {
    email: string;
    success: boolean;

    constructor() {
        super();
        this.type = MessageTypes.ForgottenPasswordResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.email = jsonObject.email;
        this.success = jsonObject.success;
    }
}
