import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class StoreForBrandLeaderboardRequestMessage extends RequestMessage {
    searchQuery: string;
    radius: number;
    brandId: number;
    employeeId: number;
    preferredLang: number;
    countryId:number;
    latitude:number;
    longitude:number;

    constructor() {
        super();
        this.type = MessageTypes.StoreForBrandLeaderboardRequest;
    }
}
