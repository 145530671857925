import {ResponseMessage} from "../response_message";
import {ContentChangedDateMessageData} from "../message_data/content_changed_date_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class GetContentChangesDatesResponseMessage extends ResponseMessage {
    changes: Array<ContentChangedDateMessageData>;

    constructor() {
        super();
        this.type = MessageTypes.GetContentChangesDatesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.changes = deserializeArrayOfObject(jsonObject.changes, ContentChangedDateMessageData);
    }
}
