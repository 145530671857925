import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class ThemeMessageData implements IJsonDeserializable, Iid {

    version: number = 0;

    id: number;
    foreColorText: number;
    foreColorIcon: number;
    backgroundColorUsedTile: number;
    backgroundColorFreeTile: number;

    gradientFirstColor: number;
    gradientSecondColor: number;
    gradientAngle: number;

    playzoneGradientFirstColor: number;
    playzoneGradientSecondColor: number;
    playzoneGradientAngle: number;

    overWhiteForeColor: number;
    overWhiteSolidColor: number;
    topicHeaderColor: number;
    barTextColor: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version
        this.id = jsonObject.id
        this.foreColorText = jsonObject.foreColorText
        this.foreColorIcon = jsonObject.foreColorIcon
        this.backgroundColorUsedTile = jsonObject.backgroundColorUsedTile
        this.backgroundColorFreeTile = jsonObject.backgroundColorFreeTile
        this.gradientFirstColor = jsonObject.gradientFirstColor
        this.gradientSecondColor = jsonObject.gradientSecondColor
        this.gradientAngle = jsonObject.gradientAngle
        this.playzoneGradientFirstColor = jsonObject.playzoneGradientFirstColor
        this.playzoneGradientSecondColor = jsonObject.playzoneGradientSecondColor
        this.playzoneGradientAngle = jsonObject.playzoneGradientAngle
        this.overWhiteForeColor = jsonObject.overWhiteForeColor
        this.overWhiteSolidColor = jsonObject.overWhiteSolidColor
        this.topicHeaderColor = jsonObject.topicHeaderColor
        this.barTextColor = jsonObject.barTextColor
    }

    private static _default: ThemeMessageData = new ThemeMessageData();

    static get Default(): ThemeMessageData {

        this._default.id = 0,
            this._default.foreColorText = 16777215,
            this._default.foreColorIcon = 10157516,
            this._default.backgroundColorUsedTile = 13386,
            this._default.backgroundColorFreeTile = 2118507,
            this._default.gradientFirstColor = 4900088,
            this._default.gradientSecondColor = 13386,
            this._default.gradientAngle = 1,
            this._default.topicHeaderColor = 16064000,
            this._default.playzoneGradientFirstColor = 16064000,
            this._default.playzoneGradientSecondColor = 13386,
            this._default.playzoneGradientAngle = 1,
            this._default.overWhiteForeColor = 6455706,
            this._default.overWhiteSolidColor = 16064000,
            this._default.barTextColor = 16777215
        return this._default;
    }
}
