import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class ContentStatisticMessageData implements IJsonDeserializable {
    version: number = 0;

    guid: string;
    employeeId: number;
    courseId: number;
    topicId: number;
    teaserId: number;
    quizId: number;
    certificateId: number;
    dateOpen: Date;
    dateClose: Date;
    totalTime: number;
    type: number;
    device: string;
    appVersion: string;
    performRelated: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.guid = jsonObject.guid;
        this.employeeId = jsonObject.employeeId;
        this.courseId = jsonObject.courseId;
        this.topicId = jsonObject.topicId;
        this.teaserId = jsonObject.teaserId;
        this.quizId = jsonObject.quizId;
        this.certificateId = jsonObject.certificateId;
        this.dateOpen = jsonObject.dateOpen;
        this.dateClose = jsonObject.dateClose;
        this.totalTime = jsonObject.totalTime;
        this.type = jsonObject.type;
        this.device = jsonObject.device;
        this.appVersion = jsonObject.appVersion;
        this.performRelated = jsonObject.performRelated;
    }
}