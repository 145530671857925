import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class ActivateAndLoginEmployeeRequestMessage extends RequestMessage {
    activationCode: string;
    userId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.ActivateAndLoginEmployeeRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.activationCode = jsonObject.activationCode;
        this.userId = jsonObject.userId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
