export enum AppCampaignTaskTargetType {
    Unknown = 0,
    CertifiedForCourse = 10,
    CertifiedForAnyCourse = 11,
    AttendEvent = 20,//not used
    AttendAnyEvent = 21,
    CompleteTheSurvey = 30,//not used
    CompleteAnySurvey = 31,
    AttendReferenceLibrary = 40,
    AttendAnyReferenceLibrary = 41,
    AttendToolbox = 50,//not used
    AttendAnyToolbox = 51,//not used
    ShareLink = 60
}