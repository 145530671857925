import {ItemMessageData} from "./item_message_data";

export class LanguageMessageData extends ItemMessageData {
    languageMessageDataVersion: number = 1;
    code: string;

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.languageMessageDataVersion = jsonObject.languageMessageDataVersion;
        if (this.languageMessageDataVersion > 0) {
            this.code = jsonObject.code;
        }

    }
}
