import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class BrandCategoryMessageData implements IJsonDeserializable, Iid {
    version: number = 0;
    id: number;
    brandId: number;
    categoryId: number;
    imageFileDescriptor: string;
    textOverImageColor: number;
    gradientFirstColor: number;
    gradientSecondColor: number;
    gradientAngle: number;
    tileTextColor: number;
    color1: number;
    color2: number;

    updatedAt: Date;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.brandId = jsonObject.brandId;
        this.categoryId = jsonObject.categoryId;
        this.imageFileDescriptor = jsonObject.imageFileDescriptor;
        this.textOverImageColor = jsonObject.textOverImageColor;
        this.gradientFirstColor = jsonObject.gradientFirstColor;
        this.gradientSecondColor = jsonObject.gradientSecondColor;
        this.gradientAngle = jsonObject.gradientAngle;
        this.tileTextColor = jsonObject.tileTextColor;
        this.color1 = jsonObject.color1;
        this.color2 = jsonObject.color2;

        this.updatedAt = jsonObject.updatedAt;
    }
}
