import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UIEventData} from "../../data/ui_event_data";
import {EventDetailDataService} from "./event_detail_data.service";

@Component({
    selector: 'sp-events-list',
    templateUrl: '../../../templates/events/events_list.component.html',
})
export class EventsListComponent implements OnInit, OnDestroy {
    @Input() items: Array<UIEventData>;
    orderedItems: Array<UIEventData> = [];
    constructor(private router: Router, private eventSearchedDataService: EventDetailDataService) {
    }

    ngOnInit(): void {
        this.orderedItems = this.items.sort((a: any, b: any) =>
            new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
        );
        this.orderedItems.forEach(i => i.loadData());
    }

    onListItemClicked(item: UIEventData) {
        if (item.event.status == 6) {
            return;
        }

        this.eventSearchedDataService.setEventData(item);

        this.router.navigate(['main', 'event_detail', item.event.id]);
    }

    ngOnDestroy(): void {
        this.orderedItems.forEach(i => i.stopTimers());
    }

}
