import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {App} from "../../app";
import {ActivatedRoute, Router} from "@angular/router";
import {EventAttachmentMessageData} from "../../communication/message_data/event_attachment_message_data";
import {HelperMethods} from "../../common/helper_methods";
import {EventDetailDataService} from "./event_detail_data.service";

@Component({
    selector: 'sp-event-attachment',
    templateUrl: "../../../templates/events/event_attachment.component.html"
})
export class EventAttachmentComponent implements OnInit, OnDestroy {

    @ViewChild('wrapper', { static: false }) imageWrapper: ElementRef;

    app: App = App.instance;
    private subscriber: any;
    public eventAttachment: EventAttachmentMessageData;
    private eventAttachmentId: number;
    private eventId: number;
    attachmentType: string;
    public imageWidth: string = "";
    public imageHeight: string = "";

    constructor(private route: ActivatedRoute, private router: Router, private eventSearchedDataService: EventDetailDataService) {
    }


    ngOnInit() {
        this.subscriber = this.route.params.subscribe(params => {
            this.eventAttachmentId = +params['id'];
            this.loadData();
        });
    }

    private loadData() {
        this.eventAttachment = this.app.data.getEventAttachmentById(this.eventAttachmentId);
        if (typeof this.eventAttachment === "undefined") {
            this.eventAttachment = this.eventSearchedDataService.getEventAttachmentById(this.eventAttachmentId);
        }
        this.eventId = this.eventAttachment.eventId;
        this.attachmentType = HelperMethods.getFileExtension(this.eventAttachment.fileDescriptor);
    }

    imageLoad() {
        let newImg = new Image();
        newImg.onload = () => {
            let imageWidth = +newImg.width;
            let imageHeight = +newImg.height;
            let clientWidth = +this.imageWrapper.nativeElement.clientWidth;
            let clientHeight = +this.imageWrapper.nativeElement.clientHeight;
            let xRatio = imageWidth / clientWidth;
            let yRatio = imageHeight / clientHeight;
            let ratio = Math.max(xRatio, yRatio);
            if (ratio > 1) {
                imageWidth = imageWidth / ratio;
                imageHeight = imageHeight / ratio;
            }
            this.imageWidth = "" + imageWidth;
            this.imageHeight = "" + imageHeight;

        };
        newImg.src = this.eventAttachment.fileDescriptor;
    }

    onBackPressed() {
        this.router.navigate(['main', 'event_detail', this.eventId]);
    }

    ngOnDestroy(): void {
        this.subscriber.unsubscribe();
    }
}
