import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class EmployeeFeedbackResponseMessage extends ResponseMessage {
    success: boolean;

    constructor() {
        super()
        this.type = MessageTypes.EmployeeFeedbackResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.success = jsonObject.success;
    }
}
