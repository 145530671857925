import {EmployeeSurveyAnswerMessageData} from "../../../communication/message_data/employee_survey_answer_message_data";
import {UUID} from "../../../common/uuid";
import {SurveyQuestionMessageData} from "../../../communication/message_data/survey_question_message_data";
import {QuizQuestionType} from "../../../data/quiz_question_type";
import {SurveyQuestionAnswerMessageData} from "../../../communication/message_data/survey_question_answer_message_data";

export class BaseAnswer {

    questionTypes = QuizQuestionType;

    createEmployeeSurveyAnswerMessage(question: SurveyQuestionMessageData, employeeSurveyId: number, callBack: any, imageData?: any): void {
        let message = new EmployeeSurveyAnswerMessageData();
        message.guid = UUID.create();
        message.employeeSurveyId = employeeSurveyId;
        message.questionId = question.id;
        message.answeredAt = new Date();
        message.type = question.type;
        message.correct = true;

        switch (question.type) {
            case QuizQuestionType.SingleSelect:
                message.questionAnswerId = question.selectedAnswers[0].id;
                message.correct = this.isSelectAnswerCorrect(question);
                message.questionAnswerIds = "";
                message.questionAnswerIds = question.selectedAnswers[0].id.toString();
                break;
            case QuizQuestionType.MultiSelectAny:
            case QuizQuestionType.MultiSelectAll:
                message.questionAnswerId = question.selectedAnswers[0].id;
                message.questionAnswerIds = "";
                question.selectedAnswers.forEach((answer, index, array) => {
                    message.questionAnswerIds += answer.id.toString()
                    if (array.length - 1 > index) {
                        message.questionAnswerIds += ",";
                    }
                });
                message.correct = this.isSelectAnswerCorrect(question);
                break;
            case QuizQuestionType.ImageLibrary:
            case QuizQuestionType.ImageCamera:
                message.fileName = imageData.name;
                message.bytesContent = imageData.base64DataServer;
                break;
            case QuizQuestionType.OpenTextSingleLine:
            case QuizQuestionType.OpenTextSingleLineMaxChars:
            case QuizQuestionType.OpenTextSingleLineRegex:
            case QuizQuestionType.OpenTextMultiLine:
            case QuizQuestionType.OpenTextMultiLineMaxChars:
            case QuizQuestionType.Numbers:
            case QuizQuestionType.DateTime:
            case QuizQuestionType.Rate:
                message.stringContent = question.answerText.toString();
                break;
            default:
                break;
        }

        question.employeeAnswerData = message;
        callBack();
    }


    isSelectAnswerCorrect(question: SurveyQuestionMessageData): boolean {
        let selectedQuestionAnswers: Array<SurveyQuestionAnswerMessageData> = question.selectedAnswers;
        let questionCorrectAnswersCount: number = question.answers.filter(ans => ans.correct).length
        let correctSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => ans.correct).length;
        let incorrectSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => !ans.correct).length;

        if (question.type == QuizQuestionType.SingleSelect) {
            return selectedQuestionAnswers[0].correct;
        }

        if (question.type == QuizQuestionType.MultiSelectAny) {
            return correctSelectedAnswersCount > 0 && incorrectSelectedAnswersCount == 0;
        }

        if (question.type == QuizQuestionType.MultiSelectAll) {
            return correctSelectedAnswersCount == questionCorrectAnswersCount && incorrectSelectedAnswersCount == 0;
        }
    }

}