import {IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {TeaserAnswerMessageData} from "./teaser_answer_message_data";
import {QuizQuestionType} from "../../data/quiz_question_type";

export class TeaserMessageData implements IJsonDeserializable, Iid {
    version: number = 1;

    id: number;
    topicId: number;
    name: string;
    text: string;
    question: string;
    successMessage: string;
    failureMessage: string;
    questionType: QuizQuestionType = QuizQuestionType.SingleSelect;

    answers: Array<TeaserAnswerMessageData> = [];

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.topicId = jsonObject.topicId;
        this.name = jsonObject.name;
        this.text = jsonObject.text;
        this.question = jsonObject.question;
        this.successMessage = jsonObject.successMessage;
        this.failureMessage = jsonObject.failureMessage;
        if (this.version > 0) {
            this.questionType = jsonObject.questionType;
        }
    }
}
