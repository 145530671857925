import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class RetailerCategoriesRequestMessage extends RequestMessage {
    retailerId: number;
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.RetailerCategoriesRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.retailerId = jsonObject.retailerId;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;

    }
}
