import {ResponseMessage} from "../response_message";
import {StoreResponseMessageData} from "../message_data/store_response_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class StoresResponseMessage extends ResponseMessage {
    stores: Array<StoreResponseMessageData> = [];
    orderedByZip: boolean = false;

    constructor() {
        super();
        this.type = MessageTypes.StoresResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.stores = deserializeArrayOfObject(jsonObject.stores, StoreResponseMessageData);
        this.orderedByZip = jsonObject.orderedByZip;
    }
}
