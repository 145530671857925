import {RequestMessage} from "../request_message";
import {RegisterRetailerData} from "../message_data/register_retailer_data";
import {MessageTypes} from "../message_types";
import {deserializeObject} from "../json_deserialization/json_deserialization";

export class RegisterRetailerRequestMessage extends RequestMessage {
    data: RegisterRetailerData;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.RegisterRetailerRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.preferredLang = jsonObject.preferredLang;
        this.data = deserializeObject(jsonObject.data, RegisterRetailerData);
    }
}
