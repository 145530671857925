import {ResponseMessage} from "../response_message";
import {EmployeeTopicStateMessageData} from "../message_data/employee_topic_state_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class CheckEmployeeTopicStatesResponseMessage extends ResponseMessage {
    records: Array<EmployeeTopicStateMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.CheckEmployeeTopicStatesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.records = deserializeArrayOfObject(jsonObject.records, EmployeeTopicStateMessageData);
    }
}