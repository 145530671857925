import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class AwardQuizMessageData implements IJsonDeserializable {

    version: number = 0;
    id: number;
    awardId: number;
    name: string;
    countDown: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.awardId = jsonObject.awardId;
        this.name = jsonObject.name;
        this.countDown = jsonObject.countDown;
    }
}
