import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {App} from "../../app";

export class EmployeeCertificateMessageData implements IJsonDeserializable {
    version: number = 0;

    id: number;
    employeeId: number;
    courseId: number;
    courseName: string;
    retailerId: number;
    retailerName: string;
    brandId: number;
    brandName: string;
    isDownloaded: boolean;
    imageFileDescriptor: string;
    dateCreated: Date;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.employeeId = jsonObject.employeeId;
        this.courseId = jsonObject.courseId;
        this.courseName = jsonObject.courseName;
        this.retailerId = jsonObject.retailerId;
        this.retailerName = jsonObject.retailerName;
        this.brandId = jsonObject.brandId;
        this.brandName = jsonObject.brandName;
        this.isDownloaded = jsonObject.isDownloaded;
        this.imageFileDescriptor = jsonObject.imageFileDescriptor;
        this.dateCreated = deserializeDateTime(jsonObject.dateCreated);
    }

    getBrandImage() {
        let brand = App.instance.data.brands.getValue(this.brandId);
        if (brand)
            return brand.imageFileDescriptor;

        return null;
    }
}
