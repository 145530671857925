import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {App} from "../../app";
import {QuizMessageData} from "../../communication/message_data/quiz_message_data";
import {QuizQuestionAnswerMessageData} from "../../communication/message_data/quiz_question_answer_message_data";
import {QuizQuestionMessageData} from "../../communication/message_data/quiz_question_message_data";
import {BrandCategoryMessageData} from "../../communication/message_data/brand_category_message_data";
import {CategoryMessageData} from "../../communication/message_data/category_message_data";
import {BrandExtendedMessageData} from "../../communication/message_data/brand_extended_message_data";
import {EmployeeCourseState} from "../../data/employee_course_state";
import {HelperMethods} from "../../common/helper_methods";
import {UUID} from "../../common/uuid";
import {CertificateForCourseResponseMessage} from "../../communication/messages/certificate_for_course_response_message";
import {EmployeeQuizAttemptMessageData} from "../../communication/message_data/employee_quiz_attempt_message_data";
import {EmployeeQuizAnswerMessageData} from "../../communication/message_data/employee_quiz_answer_message_data";
import {QuizType} from "../../data/quiz_type";
import {EmployeePointsReasonMessageData} from "../../communication/message_data/employee_points_reason_message_data";
import {EmployeePointsReasonType} from "../../data/employee_points_reason_type";
import {EmployeePointsSyncWithCheckResponseMessage} from "../../communication/messages/employee_points_sync_with_check_response_message";
import {QuizQuestionType} from "../../data/quiz_question_type";
import {ModalService} from "../../common/services/modal.service";
import {AppCampaignsProcessorService} from "../../common/services/app_campaigns_processor.service";

@Component({
    selector: 'sp-course-quiz',
    templateUrl: '../../../templates/courses/course_quiz.component.html'
})
export class CourseQuizComponent implements OnInit {
    private sub: any;
    app: App = App.instance;

    questionTypes = QuizQuestionType;

    course: CourseMessageData;
    quiz: QuizMessageData;

    currentIndex: number;
    currentQuestion: QuizQuestionMessageData;

    selectedAnswers: Array<QuizQuestionAnswerMessageData>;
    quizQuestions: Array<QuizQuestionMessageData>;

    leftActionButtonState: CQActionButtonState;
    leftActionButtonText: string;
    brand: BrandExtendedMessageData;


    rightActionButtonState: CQActionButtonState;
    rightActionButtonText: string;

    isFinished: boolean = false;
    brandCategory: BrandCategoryMessageData;
    category: CategoryMessageData;

    resultRatio: string;
    resultText: string;
    private certificateId: number;
    private quizAttempt: EmployeeQuizAttemptMessageData;
    private quizAnswers: Array<EmployeeQuizAnswerMessageData> = new Array<EmployeeQuizAnswerMessageData>();

    constructor(private route: ActivatedRoute, private router: Router, private modal: ModalService, private appCampaignProcessor: AppCampaignsProcessorService) {
    }

    ngOnInit(): any {
        let that = this;
        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // (+) converts string 'id' to a number
            that.course = that.app.data.courses.getValue(id);
            that.brand = that.app.data.brands.getValue(that.course.brandId);
            this.brandCategory = this.app.data.getBrandCategory(this.course.brandId, this.course.categoryId);
            that.loadData();
        });
    }

    private loadData() {
        this.isFinished = false;
        this.quiz = this.course.quiz;
        this.quizQuestions = this.quiz.questions.filter((q: QuizQuestionMessageData) => q.forCourseQuiz == true);
        if (this.quizQuestions.length == 0) {
            this.quizQuestions = this.quiz.questions;
        }
        this.quizQuestions = HelperMethods.shuffle(this.quizQuestions);
        if (this.quiz.maxQuestionsToShow > 0 && this.quiz.maxQuestionsToShow < this.quizQuestions.length) {
            this.quizQuestions = this.quizQuestions.slice(0, this.quiz.maxQuestionsToShow);
        }

        this.currentIndex = 0;
        this.currentQuestion = this.quizQuestions[0];
        this.selectedAnswers = new Array<QuizQuestionAnswerMessageData>();

        this.updateActionButtonState();
        this.app.statistic.courseQuizOpen(this.course.id, this.quiz.id);
        this.startQuizStatisticDataCollector();
    }

    private updateActionButtonState() {
        if (this.currentIndex == 0) {
            this.leftActionButtonState = CQActionButtonState.None;
            this.leftActionButtonText = "";
        } else {
            this.leftActionButtonState = CQActionButtonState.Previous;
            this.leftActionButtonText = "COURSE_QUIZ.PREVIOUS";
        }

        if (this.currentIndex == this.quizQuestions.length - 1) {
            this.rightActionButtonState = CQActionButtonState.Finish;
            this.rightActionButtonText = "COURSE_QUIZ.FINISH";
        } else {
            this.rightActionButtonState = CQActionButtonState.Next;
            this.rightActionButtonText = "COURSE_QUIZ.NEXT";
        }
    }

    onLeftActionButtonClick() {
        if (this.leftActionButtonState == CQActionButtonState.None)
            return;

        if (this.leftActionButtonState == CQActionButtonState.Back)
            this.router.navigate(['main', 'course_detail', this.course.id]);

        this.currentIndex--;
        this.currentQuestion = this.quizQuestions[this.currentIndex];
        this.updateActionButtonState();
    }

    async onRightActionButtonClick() {
        if (this.rightActionButtonState == CQActionButtonState.Next)
            this.tryProcessForward();
        else if (this.rightActionButtonState == CQActionButtonState.Finish)
            await this.tryFinishQuiz();
        else if (this.rightActionButtonState == CQActionButtonState.TryAgain)
            this.tryAgain();
        else if (this.rightActionButtonState == CQActionButtonState.Certificate)
            this.showCertificate();
    }

    tryProcessForward() {
        if (!this.anyAnswerSelected())
            return;

        this.currentIndex++;
        this.currentQuestion = this.quizQuestions[this.currentIndex];
        this.updateActionButtonState();
    }

    anyAnswerSelected() {
        let questionAnswersCount = this.selectedAnswers.filter(ans => ans.questionId == this.currentQuestion.id).length;
        return questionAnswersCount > 0;
    }

    async tryFinishQuiz() {
        if (!this.anyAnswerSelected())
            return;

        this.isFinished = true;
        let correct: number = 0;
        let all: number = this.quizQuestions.length;
        this.quizQuestions.forEach(qq => correct += (this.isAnswerCorrect(qq) ? 1 : 0));
        this.category = this.course.category;

        this.leftActionButtonState = CQActionButtonState.Back;
        this.leftActionButtonText = "COURSE_QUIZ.BACK";

        this.resultRatio = correct + "/" + all;
        if ((all == correct && this.course.forEmployeeState < EmployeeCourseState.quizCompleted) || (all == correct && this.course.allowRecertification)) {
            let that = this;

            let byCertification = this.course.forEmployeeState < EmployeeCourseState.quizCompleted;
            let points = this.course.bonusPoints;

            await this.sendCertificateRequest(points, async (rsp: CertificateForCourseResponseMessage) => {
                if (rsp.success) {
                    this.course.forEmployeeState = EmployeeCourseState.quizCompleted;
                    this.certificateId = rsp.certificate.id;
                    this.app.data.certificates.setValue(rsp.certificate.id, rsp.certificate);

                    this.rightActionButtonState = CQActionButtonState.Certificate;
                    this.rightActionButtonText = "COURSE_QUIZ.CERTIFICATE";

                    this.resultText = "COURSE_QUIZ.CERTIFIED";
                    this.course.employeeLastCertificationDate = new Date();
                    this.course.employeeCertificationExpiryDate = this.course.employeeLastCertificationDate.plusDays(this.course.recertificationDays);
                    let reason = EmployeePointsReasonType.Unknown;
                    if (byCertification) {
                        reason = EmployeePointsReasonType.CourseCertificate;
                        this.app.statistic.employeeCertified(this.course.id, 1, points);
                        this.app.statistic.setPerformRelatedOnActive(1);
                    } else {
                        reason = EmployeePointsReasonType.CourseReCertificate;
                        this.app.statistic.employeeRecertified(this.course.id, 1, points);
                    }
                    let pr = new EmployeePointsReasonMessageData();
                    pr.guid = UUID.create();
                    pr.employeeId = this.app.getEmployee().id;
                    pr.dateTime = new Date();
                    pr.reason = reason;
                    pr.points = points;
                    pr.reasonArgument = this.course.id;
                    pr.device = this.app.deviceUUID;
                    pr.appVersion = this.app.appVersion;
                    this.app.driver.syncEmployeePointsWithCheck(this.app.getEmployee().id, 0, 0, new Array(pr)).onSuccess((rsp: EmployeePointsSyncWithCheckResponseMessage) => {
                        if (rsp.error == null) {
                            let ep = this.app.data.getEmployeePoints();
                            ep.server = rsp.server;
                            ep.local = rsp.local;
                        }
                    }).onError(e => {
                        console.log("error");
                    }).send();

                    await this.appCampaignProcessor.coursePassed(this.app, this.course.brandId, this.course.id, this.course.name);
                }
            });

        } else {
            this.rightActionButtonState = CQActionButtonState.TryAgain;
            this.rightActionButtonText = "COURSE_QUIZ.TRY_AGAIN";
            this.leftActionButtonState = CQActionButtonState.Back;
            this.leftActionButtonText = "COURSE_QUIZ.BACK";
            this.resultText = "COURSE_QUIZ.NOT_CERTIFIED";
        }

        let finalScore = Math.round((correct * 100) / all);
        this.endQuizStatisticDataCollector(finalScore, all == correct);
        this.app.statistic.courseQuizClosed(this.course.id, this.quiz.id, finalScore, all == correct ? 1 : 0);
    }

    onAnswerClick(answer: QuizQuestionAnswerMessageData) {
        answer.answeredAt = new Date();
        if (this.currentQuestion.questionType == QuizQuestionType.SingleSelect) {
            this.selectedAnswers = this.selectedAnswers.filter(a => a.questionId != this.currentQuestion.id);
            this.selectedAnswers.push(answer);
        }

        if (this.currentQuestion.questionType == QuizQuestionType.MultiSelectAny || this.currentQuestion.questionType == QuizQuestionType.MultiSelectAll) {
            let answerAllreadySelected = this.selectedAnswers.some(a => a.id == answer.id);
            if (answerAllreadySelected) {
                this.selectedAnswers = this.selectedAnswers.filter(a => a.id != answer.id);
            } else {
                this.selectedAnswers.push(answer);
            }
        }
    }

    isAnswerSelected(answer: QuizQuestionAnswerMessageData) {
        return this.selectedAnswers.some(item => item.id == answer.id);
    }

    isAnswerCorrect(question: QuizQuestionMessageData): boolean {
        let selectedQuestionAnswers: Array<QuizQuestionAnswerMessageData> = this.selectedAnswers.filter(item => item.questionId == question.id);
        let questionCorrectAnswersCount: number = question.answers.filter(ans => ans.isCorrect).length
        let correctSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => ans.isCorrect).length;
        let incorrectSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => !ans.isCorrect).length;

        if (question.questionType == QuizQuestionType.SingleSelect) {
            return selectedQuestionAnswers[0].isCorrect;
        }

        if (question.questionType == QuizQuestionType.MultiSelectAny) {
            return correctSelectedAnswersCount > 0 && incorrectSelectedAnswersCount == 0;
        }

        if (question.questionType == QuizQuestionType.MultiSelectAll) {
            return correctSelectedAnswersCount == questionCorrectAnswersCount && incorrectSelectedAnswersCount == 0;
        }
    }

    private tryAgain() {
        this.loadData();
    }

    private showCertificate() {
        if (!this.certificateId && this.certificateId <= 0)
            return;

        this.router.navigate(['main', 'certificate_detail', this.certificateId]);

    }

    private sendCertificateRequest(points: number, callback: any) {
        let guid: string = UUID.create();
        let dateTime = new Date();
        let isRecertification = this.course.isAllowedRecertification();
        this.app.driver.certificateForCourse(this.app.data.employee.id, this.course.id, isRecertification, guid, dateTime, points)
            .onSuccess((rsp: CertificateForCourseResponseMessage) => callback(rsp)).onError(e => {
            this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
        }).send();
    }

    get imageFileDescriptor(): string {
        if (this.brandCategory && this.brandCategory.imageFileDescriptor) {
            return this.brandCategory.imageFileDescriptor;
        } else {
            return '/assets/images/default_brand_category_image.jpg';
        }
    }

    private startQuizStatisticDataCollector() {
        this.quizAttempt = new EmployeeQuizAttemptMessageData();
        this.quizAttempt.guid = UUID.create();
        this.quizAttempt.employeeId = this.app.getEmployee().id;
        this.quizAttempt.passed = false;
        this.quizAttempt.score = 0;
        this.quizAttempt.quizType = QuizType.Course;
        this.quizAttempt.topicId = null;
        this.quizAttempt.awardId = null;
        this.quizAttempt.startAt = new Date();
    }

    private endQuizStatisticDataCollector(score: number, passed: boolean) {
        this.quizAttempt.passed = passed;
        this.quizAttempt.score = score;
        this.quizAttempt.quizId = this.quiz.id;
        this.quizAttempt.courseId = this.course.id;
        this.quizAttempt.endAt = new Date();
        this.quizAnswers = this.app.quizStatisticProcessor.quizAnswersToMessageData(this.selectedAnswers, this.quizAttempt.guid, this.quizQuestions);
        this.app.quizStatisticProcessor.trySendData(this.quizAttempt, this.quizAnswers);
    }

    get questionHint() {
        let questionHint = "";
        switch (this.currentQuestion.questionType) {
            case QuizQuestionType.SingleSelect:
                questionHint = 'COMMON.QUESTION_HINTS.SINGLE_SELECT';
                break;
            case QuizQuestionType.MultiSelectAny:
            case QuizQuestionType.MultiSelectAll:
                questionHint = 'COMMON.QUESTION_HINTS.MULTI_SELECT_ALL';
                break;
            default:
                questionHint = ''
                break;
        }
        return questionHint;
    }

}

enum CQActionButtonState {
    None,
    Back,
    Previous,
    Next,
    Finish,
    TryAgain,
    Certificate
}