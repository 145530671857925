import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {SurveyQuestionMessageData} from "../../../communication/message_data/survey_question_message_data";
import {BaseAnswer} from "./base_answer";

@Component({
    selector: 'sp-date-time-answer',
    templateUrl: '../../../../templates/surveys/answer_types/date-time-answer.component.html'
})
export class DateTimeAnswerComponent extends BaseAnswer implements OnInit, OnDestroy {
    //TODO:Date time picker when implement new version of surveys
    @Input() question: SurveyQuestionMessageData;
    @Input() surveyId: number;
    @Input() employeeSurveyId: number;
    @Output() answerSelect = new EventEmitter();

    date: Date;
    // settings = {
    //     bigBanner: true,
    //     timePicker: true,
    //     format: 'yyyy-MM-dd hh:mm:ss a',
    //     defaultOpen: false,
    //     closeOnSelect: false
    // }

    constructor() {
        super();
    }


    ngOnInit(): void {//TODO:Date time picker when implement new version of surveys
        // if (this.question.employeeAnswerData != null) {
        //     this.date = new Date(this.question.employeeAnswerData.stringContent);
        // } else {
        //     this.date = new Date();
        // }
    }

    initDate() {

    }

    ngOnDestroy(): void {
    }

    onDataSelected(date: any) {
        this.question.answerText = date.toISOString().toString().split(".")[0];
        this.createEmployeeSurveyAnswerMessage(this.question, this.employeeSurveyId, () => {
            this.answerSelect.emit();
        });
    }
}