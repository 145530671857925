import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";
import {QueryGoogleLocationBySearchRequestMessage} from "./query_google_location_by_search_request_message";
import {deserializeObject} from "../json_deserialization/json_deserialization";
import {UserMessageData} from "../message_data/user_message_data";
import {LocationObtainedBy} from "../../data/location_obtained_by";

export class QueryGoogleLocationBySearchResponseMessage extends ResponseMessage {
    latitude: number;
    longitude: number;
    googlePlaceId:string;
    locationObtainedBy: LocationObtainedBy;

    constructor() {
        super();
        this.type = MessageTypes.QueryGoogleLocationBySearchResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.latitude = jsonObject.latitude;
        this.longitude = jsonObject.longitude;
        this.googlePlaceId = jsonObject.googlePlaceId;
        this.locationObtainedBy = jsonObject.locationObtainedBy;
    }
}
