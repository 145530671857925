import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'sp-modal',
    templateUrl: '../../../templates/common/sp_modal.component.html'
})

export class SpModalComponent implements OnInit {

    public message: string;
    public title: string;
    public onSubmit: () => void;
    public onCancel: () => void;
    public hidden: boolean = true;
    public okButtonText = 'COMMON.OK';
    public cancelButtonText = 'COMMON.CANCEL';

    ngOnInit(): any {
        return undefined;
    }

    constructor() {

    }

    show(title: string, message: string, okButtonText?: string, cancelButtonText?: string, onSubmit?: () => void, onCancel?: () => void) {
        this.title = title;
        this.message = message;
        this.hidden = false;
        if (okButtonText) {
            this.okButtonText = okButtonText;
        }
        if (cancelButtonText) {
            this.cancelButtonText = cancelButtonText;
        }
        if (onSubmit) {
            this.onSubmit = onSubmit;
        }
        if (onCancel) {
            this.onCancel = onCancel;
        }
    }

    onButtonSubmit() {
        if (this.onSubmit) {
            this.onSubmit();
        }
        this.hide();
    }

    onButtonCancel() {
        if (this.onCancel) {
            this.onCancel();
        }
        this.hide();
    }

    hide() {
        this.hidden = true;
        this.onSubmit = null;
        this.onCancel = null;
    }

}