import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {App} from "../../app";

export class GetTermsAndConditionsIdRequestMessage extends RequestMessage {
    employeeId: number;
    preferredLang: number;
    osType: number;
    brandId: number;
    retailerId: number;
    countryId: number;

    constructor() {
        super();
        this.type = MessageTypes.GetTermsAndConditionsDateRequest;
        this.osType = App.instance.appType;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
        this.osType = jsonObject.osType;
        this.brandId = jsonObject.brandId;
        this.retailerId = jsonObject.retailerId;
        this.countryId = jsonObject.countryId;
    }
}
