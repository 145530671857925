import {Component, Input, OnDestroy, OnInit,} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TopicMessageData} from "../../communication/message_data/topic_message_data";
import {App} from "../../app";


@Component({
    selector: 'sp-common-pdf',
    templateUrl: '../../../templates/common_topics/common_topic_pdf.component.html'
})

export class CommonTopicPdfComponent implements OnInit, OnDestroy {
    @Input() topic: TopicMessageData;

    private sub: any;
    public app: App = App.instance;
    private courseId: any;


    ngOnInit(): any {
        let that = this;
        that.sub = that.route.params.subscribe(params => {
            let id = +params['id']; // (+) converts string 'id' to a number
            that.topic = that.app.data.topics.getValue(id);
            that.courseId = id;
            //that.courseId = this.app.data.courses.getValue(id);
            let cst = that.topic.getStatisticsTypeByCourse(that.app.data.courses.getValue(that.topic.courseId));
            that.app.statistic.topicOpen(that.topic.courseId, that.topic.id, cst)
        });

    }

    ngOnDestroy(): any {
        let cst = this.topic.getStatisticsTypeByCourse(this.app.data.courses.getValue(this.topic.courseId));
        this.app.statistic.topicClosed(this.topic.courseId, this.topic.id, cst)
    }

    constructor(private route: ActivatedRoute, private router: Router) {


    }

    onMainToolBoxScreen() {
        this.router.navigate(['main', 'reference_detail', this.topic.courseId]);
    }


}
