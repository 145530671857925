import {Message} from "./message";
import {ErrorMessageData} from "./message_data/error_message_data";
export class ResponseMessage extends Message {
    error:ErrorMessageData;
    messageId:string;

    deserialize(jsonObject:any):void {
        super.deserialize(jsonObject);

        this.error = jsonObject.error;
        this.messageId = jsonObject.messageId;
    }
}
