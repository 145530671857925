import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {App} from "../../app";
import {StringExtensions} from "../../common/extensions";
import {SpModalComponent} from "../common/sp_modal.component";
import {LoginResponseMessage} from "../../communication/messages/login_response_message";
import {ForgottenPasswordResponseMessage} from "../../communication/messages/forgotten_password_response_message";
import {LanguageDropdownComponent} from "../common/language_dropdown.component";
import {TranslateService} from "@ngx-translate/core";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {ModalService} from "../../common/services/modal.service";
import {UserSessionProviderService} from "../../common/services/user-session-provider.service";


@Component({
    selector: 'ng-login',
    templateUrl: '../../../templates/login/login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {

    @ViewChild('dropdown', { static: false }) dropdown: LanguageDropdownComponent;
    @ViewChild('loader', { static: false }) loader: SpLoaderComponent;
    app: App;
    router: Router;
    loginData: LoginData;
    blink = false;
    inProcessing = false;

    private sub: any;

    constructor(router: Router, private route: ActivatedRoute, private translate: TranslateService, private changeDetector: ChangeDetectorRef, private modal:ModalService, private userSessionProvider: UserSessionProviderService) {
        this.app = App.instance;
        this.router = router;
        this.loginData = new LoginData();
    }

    OnLoginFormSubmit() {
        this.inProcessing = true;
        this.changeDetector.detectChanges();
        this.loader.start();
        this.app.driver.login(this.loginData.email, this.loginData.password)
            .onSuccess((rsp: LoginResponseMessage) => {
                this.userSessionProvider.setSession(rsp.employee.id.toString());
                this.router.navigate(["loading"]);
            })
            .onError((e, ec, em, response) => {
                if (response != null) {
                    this.inProcessing = false;
                    this.loader.stopAndHide();
                    if (response.employee != null) {
                        switch (response.employee.status) {
                            case EmployeeStatus.Inactive:
                                this.modal.show("LOGIN.ERROR", "LOGIN.YOUR_ACCOUNT_IS_NOT_ACTIVE_YET");
                                return;
                            case EmployeeStatus.Suspended:
                                this.modal.show("LOGIN.ERROR", "LOGIN.YOUR_ACCOUNT_IS_SUSPENDED");
                                return;
                        }
                    }
                    this.modal.show("LOGIN.ERROR", "LOGIN.ERROR_WRONG_EMAIL_OR_PASSWORD");
                    return
                }

                this.modal.show("LOGIN.ERROR", "LOGIN.ERROR_NO_CONNECTION");
            })
            .send();
    }

    onForgotPasswordSubmit() {
        if (!StringExtensions.isNullorEmpty(this.loginData.email) && StringExtensions.IsEmail(this.loginData.email)) {
            this.inProcessing = true;
            this.changeDetector.detectChanges();
            this.loader.start();
            this.app.driver.forgottenPassword(this.loginData.email)
                .onSuccess((rsp: ForgottenPasswordResponseMessage) => {
                    this.inProcessing = false;
                    this.loader.stopAndHide();
                    if (rsp.success) {
                        this.modal.show('LOGIN.SUCCESS', "LOGIN.SUCCESS_RESET_PASSWORD_SENT");
                        this.blink = false;
                    } else {
                        this.modal.show("LOGIN.ERROR", "LOGIN.ERROR_EMAIL_NOT_FOUND");
                        this.blink = false;
                    }
                })
                .onError((e, ec, em) => {
                    this.inProcessing = false;
                    this.modal.show("LOGIN.ERROR", "LOGIN.ERROR_NO_CONNECTION");
                })
                .send();
        } else {
            let that = this;
            this.blink = true;
            setTimeout(function () {
                that.blink = false;
            }, 1500)
        }
        return false;
    }

    ngOnInit(): any {
    }

    ngOnDestroy(): any {
    }
}

export class LoginData {


    private _email: string;
    private _password: string;

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get isValid() {
        return this.validateForm();
    }

    validateForm(): boolean {

        if (!StringExtensions.isNullorEmpty(this.email) && !StringExtensions.isNullorEmpty(this.password) && StringExtensions.IsEmail(this.email)) {
            return true;
        }

        return false;
    }
}

export enum EmployeeStatus {
    Inactive = 0,
    Active = 1,
    Suspended = 2
}