import {Component, OnInit} from '@angular/core';
import {App} from "../../app";

import {StringExtensions} from "../../common/extensions";
import {Router} from "@angular/router";
import {ModalService} from "../../common/services/modal.service";


@Component({
    selector: 'sp-settings-feedback',
    templateUrl: '../../../templates/settings/settings_feedback.component.html',
})
export class SettingsFeedbackComponent implements OnInit {
    app: App = App.instance;
    syncing: CheckboxWithLabel;
    crashing: CheckboxWithLabel;
    quizzes: CheckboxWithLabel;
    printing: CheckboxWithLabel;
    courses: CheckboxWithLabel;
    checkboxesList: Array<CheckboxWithLabel> = [];
    comment: string = "";
    router: Router;
    submiting = false;

    constructor(router: Router, private modal: ModalService) {
        this.router = router;


    }

    ngOnInit() {
        this.syncing = new CheckboxWithLabel('Syncing');
        this.crashing = new CheckboxWithLabel('Crashing');
        this.quizzes = new CheckboxWithLabel('Quizzes');
        this.printing = new CheckboxWithLabel('Printing');
        this.courses = new CheckboxWithLabel('Courses');
        this.checkboxesList.push(this.syncing, this.crashing, this.quizzes, this.printing, this.courses);
        this.app.statistic.rateFormOpenAction();
    }

    onCheckboxChange() {

    }

    onSubmitButtonClick() {
        if (!this.isValidData())
            return;

        this.submiting = true;
        this.app.driver.employeeFeedback(this.app.getEmployeeId(), this.app.deviceUUID, this.app.appVersion, this.syncing.isChecked, false, this.quizzes.isChecked, this.courses.isChecked,
            this.crashing.isChecked, false, this.printing.isChecked, false, this.comment).onSuccess(rsp => {
            console.log("loadData Response succes ---->");
            this.app.statistic.rateFormSubmitAction();
            this.router.navigate(['main']);
        })
            .onError((error, ec, em) => {
                this.submiting = false;
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
            }).send();
    }

    isValidData(): boolean {
        return this.checkboxesList.some((ch) => ch.isChecked) || !StringExtensions.isNullorEmpty(this.comment);
    }
}

export class CheckboxWithLabel {
    label: string;
    isChecked: boolean;

    constructor(label: string, isChecked: boolean = false) {
        this.label = label;
        this.isChecked = isChecked;
    }
}
