import {Component, OnInit, ViewChild} from '@angular/core';
import {App} from "../../../app";
import {ActivatedRoute, Router} from "@angular/router";
import {RetailersResponseMessage} from "../../../communication/messages/retailers_response_message";
import {RetailerMessageData} from "../../../communication/message_data/retailer_message_data";
import {StoreResponseMessageData} from "../../../communication/message_data/store_response_message_data";
import {RetailerCategoryMessageData} from "../../../communication/message_data/retailer_category_message_data";
import {ChangeEmployeeRetailerStoreCategoriesResponseMessage} from "../../../communication/messages/change_employee_retailer_store_categories_response_message";
import {SpLoaderComponent} from "../../common/sp_loader.component";
import {CountriesResponseMessage} from "../../../communication/messages/countries_response_message";
import {CountryMessageData} from "../../../communication/message_data/country_message_data";
import {LanguageMessageData} from "../../../communication/message_data/language_message_data";
import {LanguagesResponseMessage} from "../../../communication/messages/languages_response_message";
import {ModalService} from "../../../common/services/modal.service";
import {AddStoraData} from "../../../data/add.store.data";

@Component({
    selector: 'sp-update-profile-data',
    templateUrl: '../../../../templates/profile/retailer/update_profile_data.component.html'
})
export class UpdateProfileDataComponent implements OnInit {
    app: App = App.instance;
    @ViewChild('sploader', { static: false }) spLoader: SpLoaderComponent;
    @ViewChild('sploader2', { static: false }) spLoader2: SpLoaderComponent;
    isCountryStep: boolean = false;
    isLanguageStep: boolean = false;
    isRetailerStep: boolean = false;
    isStoreStep: boolean = false;


    currentCountryId: number;
    currentLanguageId: number;
    currentCountry: CountryMessageData;
    currentRetailer: RetailerMessageData;
    currentStoreId: number;

    term: string[];

    sub: any;
    private step: number;
    countriesList: Array<CountryMessageData>;
    languagesList: Array<LanguageMessageData>;
    retailersList: Array<RetailerMessageData>;

    public zipCode: string = "";
    storesList: Array<StoreResponseMessageData>;

    store: AddStoraData;

    constructor(private route: ActivatedRoute, private router: Router, private modal: ModalService) {
    }

    async ngOnInit() {
        let data = this.app.data;
        this.currentCountryId = data.employee.countryId;
        this.currentLanguageId = data.employee.languageId;
        await this.loadCountries();
        await this.loadRetailers();
        this.currentCountry = this.countriesList.find(p => p.id == this.currentCountryId);
        this.currentRetailer = this.retailersList.find(p => p.id == data.retailer.id);
        this.currentStoreId = data.store.id;

        this.sub = this.route.params.subscribe(params => {
            this.step = +params['ist'];
            switch (this.step) {
                case 1:
                    this.showCountryStep();
                    break;
                case 2:
                    this.showLanguageStep();
                    break;
                case 3:
                    this.showRetailerStep();
                    break;
                case 4:
                    this.showStoreStep();
                    break;
            }
        });
    }

    updateUI(isCountryStep: boolean, isLanguageStep: boolean, isRetailerStep: boolean, isStoreStep: boolean) {
        this.isCountryStep = isCountryStep;
        this.isLanguageStep = isLanguageStep;
        this.isRetailerStep = isRetailerStep;
        this.isStoreStep = isStoreStep;
    }

    private showCountryStep() {
        this.updateUI(true, false, false, false);
    }

    private loadCountries() {
        return new Promise<void>(resolve => {
            this.app.driver.getCountries(this.app.data.employee.isDemo).onSuccess((rsp: CountriesResponseMessage) => {
                if (rsp.countries) {
                    this.countriesList = rsp.countries;
                }
                resolve();
            }).onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                resolve();
            })
                .send();
        });
    }

    private showLanguageStep() {
        this.app.driver.getLanguages(this.currentCountryId).onSuccess((rsp: LanguagesResponseMessage) => {
            if (rsp.languages) {
                this.languagesList = rsp.languages;
                this.updateUI(false, true, false, false);
            }
        })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
            })
            .send();
    }

    showRetailerStep() {
        this.updateUI(false, false, true, false);
    }

    loadRetailers() {
        return new Promise<void>(resolve => {
            this.app.driver.getRetailers(this.currentCountryId)
                .onSuccess((rsp: RetailersResponseMessage) => {
                    if (rsp.retailers) {
                        this.retailersList = rsp.retailers;
                    }
                    resolve();
                })
                .onError((e, ec, em) => {
                    this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                    resolve();
                })
                .send();
        });
    }

    onCountryItemSelected(record: CountryMessageData) {
        this.currentCountryId = record.id;
        this.currentCountry = record;
        this.showLanguageStep();
    }

    onLanguageItemSelected(record: LanguageMessageData) {
        this.currentLanguageId = record.id;
        this.showRetailerStep();
    }

    onRetailerItemSelected(record: RetailerMessageData) {
        this.currentRetailer = record;
        this.showStoreStep();
    }


    private showStoreStep() {
        this.updateUI(false, false, false, true);
    }

    OnSelectedStore(record: StoreResponseMessageData) {
        this.currentStoreId = record.id;
        this.store = null;
        this.onDoneButtonClick();
    }

    OnSelectStoreCancel() {
        this.router.navigate(['main', 'profile_menu']);
    }

    OnPickStoreOrCreate(record: AddStoraData) {
        this.currentStoreId = this.app.data.store.id;
        this.store = record;
        this.onDoneButtonClick();
    }


    onDoneButtonClick() {
        let haveChanges = this.app.data.retailer.id != this.currentRetailer.id || (this.app.data.store.id != this.currentStoreId || this.store != null) || this.app.data.employee.languageId != this.currentLanguageId;

        if (!haveChanges) {
            this.router.navigate(['loading']);
            return;
        }


        let languageId = this.app.data.employee.languageId != this.currentLanguageId ? this.currentLanguageId : null;
        let retailerId = this.app.data.retailer.id != this.currentRetailer.id ? this.currentRetailer.id : null;
        let storeId = null;
        let store = null;
        if (this.app.data.store.id != this.currentStoreId || this.store != null) {
            if (this.app.data.store.id != this.currentStoreId && this.store == null) {
                storeId = this.currentStoreId;
            } else {
                store = this.store;
            }
        }

        this.app.driver.changeEmployeeRetailerStoreCategories(this.app.getEmployeeId(), languageId, retailerId, storeId, store)
            .onSuccess((rsp: ChangeEmployeeRetailerStoreCategoriesResponseMessage) => {
                this.router.navigate(['loading']);
                this.app.tryLoginUser();
            })
            .onError(((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
            }))
            .send();
    }
}

export class RetailerCategoryWrapper {
    retailerCategory: RetailerCategoryMessageData;
    isChecked: boolean = false;


    constructor(retailerCategory: RetailerCategoryMessageData, isChecked: boolean) {
        this.retailerCategory = retailerCategory;
        this.isChecked = isChecked;

    }
}