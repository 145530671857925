export enum SurveyResponseError {
    AlreadyStartedOnOtherDevice = 1,
    SurveyDoesNotExist = 2,
    SurveyMaxInActionCountReached = 3,
    SurveyExpired = 4,
    SurveyAlreadyFinished = 5,
    SurveyDoesNotContainQuestion = 6
}

export class EmployeeSurveyErrorHelper {
    public static getErrorMessage(code: number) {
        let errorMessage: string;
        switch (code) {
            case SurveyResponseError.AlreadyStartedOnOtherDevice:
                errorMessage = "SURVEYS.ERROR_MESSAGES.ALREADY_STARTED_ON_OTHERDEVICE";
                break;
            case SurveyResponseError.SurveyDoesNotExist:
            case SurveyResponseError.SurveyDoesNotContainQuestion:
                errorMessage = "SURVEYS.ERROR_MESSAGES.SURVEY_DOES_NOT_EXIST";
                break;
            case SurveyResponseError.SurveyMaxInActionCountReached:
                errorMessage = "SURVEYS.ERROR_MESSAGES.SURVEY_MAXIMUM_AVAILABLE_QUOTA_REACHED";
                break;
            case SurveyResponseError.SurveyExpired:
                errorMessage = "SURVEYS.ERROR_MESSAGES.SURVEY_EXPIRED";
                break;
            case SurveyResponseError.SurveyAlreadyFinished:
                errorMessage = "SURVEYS.ERROR_MESSAGES.SURVEY_ALREADY_FINISHED";
                break;
            default:
                errorMessage = "SURVEYS.ERROR_MESSAGES.DEFAULT";
        }
        return errorMessage;
    }
}