import {IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {SurveyQuestionAnswerMessageData} from "./survey_question_answer_message_data";
import {QuizQuestionType} from "../../data/quiz_question_type";
import {EmployeeSurveyAnswerMessageData} from "./employee_survey_answer_message_data";

export class SurveyQuestionMessageData implements IJsonDeserializable, Iid {
    version: number = 0;

    id: number;
    surveyId: number;
    type: number;
    sortOrder: number;
    question: string;
    filter: string;
    nodeType: number

    //private
    answers: Array<SurveyQuestionAnswerMessageData> = [];
    selectedAnswers: Array<SurveyQuestionAnswerMessageData> = [];
    answerText: string = "";
    imageBase64Data: string;

    employeeAnswerData: EmployeeSurveyAnswerMessageData = null;


    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.surveyId = jsonObject.surveyId;
        this.type = jsonObject.type;
        this.sortOrder = jsonObject.sortOrder;
        this.question = jsonObject.question;
        this.filter = jsonObject.filter;
        this.nodeType = jsonObject.nodeType;
    }


    isSelectQuestionType() {
        if (
            this.type == QuizQuestionType.SingleSelect ||
            this.type == QuizQuestionType.MultiSelectAny ||
            this.type == QuizQuestionType.MultiSelectAll) {
            return true
        }
        return false
    }

    isImageLibraryQuestionType() {
        if (this.type == QuizQuestionType.ImageLibrary || this.type == QuizQuestionType.ImageCamera) {
            return true
        }
        return false
    }

    isOpenTextQuestionType() {
        if (this.type == QuizQuestionType.OpenTextSingleLine ||
            this.type == QuizQuestionType.OpenTextSingleLineMaxChars ||
            this.type == QuizQuestionType.OpenTextSingleLineRegex ||
            this.type == QuizQuestionType.OpenTextMultiLine ||
            this.type == QuizQuestionType.OpenTextMultiLineMaxChars) {
            return true
        }
        return false
    }

    isNumberQuestionType() {
        if (this.type == QuizQuestionType.Numbers) {
            return true
        }
        return false
    }

    isRateQuestionType() {
        if (this.type == QuizQuestionType.Rate) {
            return true
        }
        return false
    }

    isDateTimeQuestionType() {
        if (this.type == QuizQuestionType.DateTime) {
            return true
        }
        return false
    }

    anyAnswerSelected() {
        return this.employeeAnswerData != null;
    }
}