import {App} from "./app";
import {StringExtensions} from "./common/extensions";
import {LanguagesResponseMessage} from "./communication/messages/languages_response_message";
import {LanguageMessageData} from "./communication/message_data/language_message_data";
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "./common/services/modal.service";
import {HelperMethods} from "./common/helper_methods";
import {UserSessionProviderService} from "./common/services/user-session-provider.service";

export class Language {

    private languages: Array<LanguageMessageData> = new Array<LanguageMessageData>();
    defaultLanguage: string = "en";
    private languageSessionName = "locale";
    private translate: TranslateService;
    selectedLanguage: LanguageMessageData = new LanguageMessageData();
    public app: App;

    constructor(translate: TranslateService, app: App, private modal: ModalService, private userSessionProvider: UserSessionProviderService) {
        this.translate = translate;
        this.app = app;
        this.setDefault();
        app.driver.getLanguages(0).onSuccess((rsp: LanguagesResponseMessage) => {
            if (rsp.languages) {
                rsp.languages.forEach((p) => {
                    this.languages.push(p);
                });

                translate.addLangs(this.languages.map((v) => v.code));
                this.createSessionIfNeeded();

            } else {
                this.modal.show("COMMON.ERROR", "COMMON.SERVER_DID_NOT_RESPOND");
            }
        }).onError((e, ec, em, responce) => {
            this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
        }).send();

    }

    public getLanguages(): Array<LanguageMessageData> {
        return this.languages;
    }


    public changeLanguage(language: LanguageMessageData) {
        if (this.languages.indexOf(language)) {
            this.translate.use(language.code);
        } else {
            this.translate.use(this.defaultLanguage);
        }
        HelperMethods.setCookie(this.languageSessionName, language.code);
        this.selectedLanguage = language;
    }

    private setDefault() {
        this.translate.addLangs(this.languages.map((v) => v.code));
        this.translate.setDefaultLang(this.defaultLanguage);
        this.translate.use(this.defaultLanguage);
    }

    private createSessionIfNeeded() {
        if (this.userSessionProvider.isUserLogged()) {
            if (App.instance.data.employee) {
                if (!StringExtensions.isNullorEmpty(HelperMethods.getCookie(this.languageSessionName)))
                    this.selectedLanguage = this.languages.filter((p) => p.code == HelperMethods.getCookie(this.languageSessionName))[0];
                else
                    this.selectedLanguage = this.languages.filter((p) => p.id == App.instance.data.employee.languageId)[0];
            } else {
                this.selectedLanguage = this.languages.filter((p) => p.code == this.defaultLanguage)[0];
            }
        } else {
            if (!StringExtensions.isNullorEmpty(HelperMethods.getCookie(this.languageSessionName))) {
                this.selectedLanguage = this.languages.filter((p) => p.code == HelperMethods.getCookie(this.languageSessionName))[0];
            } else {
                this.selectedLanguage = this.languages.filter((p) => p.code == this.defaultLanguage)[0];
            }
        }
        HelperMethods.setCookie(this.languageSessionName, this.selectedLanguage.code, 366);
        this.translate.use(this.selectedLanguage.code);
    }
}
