import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class RetailerEventsFreeSearchRequestMessage extends RequestMessage {
    employeeId: number;
    preferredLang: number;
    retailerId: number;
    storeId: number;

    zipCode: string;
    radius: number;
    eventCode: string;

    constructor() {
        super();
        this.type = MessageTypes.RetailerEventsFreeSearchRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
        this.retailerId = jsonObject.retailerId;
        this.storeId = jsonObject.storeId;

        this.zipCode = jsonObject.zipCode;
        this.radius = jsonObject.radius;
        this.eventCode = jsonObject.eventCode;
    }
}
