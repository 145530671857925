import {Injectable} from '@angular/core';
import {UIEventData} from "../../data/ui_event_data";
import {EventAttachmentMessageData} from "../../communication/message_data/event_attachment_message_data";

@Injectable()
export class EventDetailDataService {

    private _eventData: UIEventData = null;

    constructor() {
    }

    getEventData(): UIEventData {
        return this._eventData;
    }

    setEventData(event: UIEventData): void {
        this._eventData = event;
    }

    getEventAttachmentById(attachmentId: number): EventAttachmentMessageData {
        return this._eventData.attachments.filter((attachment) => attachment.id === attachmentId)[0];
    }

}
