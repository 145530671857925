import {MessageTypes} from "../message_types";
import {ResponseMessage} from "../response_message";

export class EmployeeCheckInForEventResponseMessage extends ResponseMessage {
    isAlreadyJoinedOrAttend: boolean;
    success: boolean;
    isAlreadyJoined: boolean;
    attend: boolean;
    joined: boolean;

    constructor() {
        super();
        this.type = MessageTypes.EmployeeCheckInEventResponse;

    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.isAlreadyJoinedOrAttend = jsonObject.isAlreadyJoinedOrAttend;
        this.success = jsonObject.success;
        this.isAlreadyJoined = jsonObject.isAlreadyJoined;
        this.attend = jsonObject.attend;
        this.joined = jsonObject.joined;
    }
}
