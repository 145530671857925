/**
 * Created by User on 1/12/2017.
 */
import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class CountriesByBrandRequestMessage extends RequestMessage {

    brandId: number;
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.CountriesByBrandRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.brandId = jsonObject.brandId;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
