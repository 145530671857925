import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {FormsModule} from "@angular/forms";
import {appRoutingProviders, routing} from "./router";
import {SafeHtml, SafeStyle, SafeTrustedUrl, SearchByMultiple, SearchByName} from "./ui/common/pipes";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {LoginComponent} from "./ui/login/login.component";
import {RegisterComponent} from "./ui/register/register.component";
import {LoadingComponent} from "./ui/loading.component";
import {SettingsFeedbackComponent} from "./ui/settings/settings_feedback.component";
import {CertificateDetailCompoent} from "./ui/profile/certificate_detail.component";
import {PreviousCertificatesListComponent} from "./ui/profile/retailer/previous_certificates_list.component";
import {BrandCertificatesComponent} from "./ui/profile/brand/brand_certificates.component";
import {JobCategoriesComponent} from "./ui/profile/retailer/job_categories.component";
import {RetailerCertificatesComponent} from "./ui/profile/retailer/retailer_certificates.component";
import {ProfileChangeEmailComponent} from "./ui/profile/profile_change_email.component";
import {UpdateProfileDataComponent} from "./ui/profile/retailer/update_profile_data.component";
import {ProfileMenuComponent} from "./ui/profile/profile.component";
import {DownloadComponent} from "./ui/common/download.component";
import {SettingsPreferencesComponent} from "./ui/settings/settings_preferences.component";
import {RetailerEmployerComponent} from "./ui/register/retailer_employer.component";
import {RetailersListComponent} from "./ui/register/retailers_list.component";
import {StoresListComponent} from "./ui/register/stores_list.component";
import {JobCategoriesListComponent} from "./ui/register/job_categories_list.component";
import {ProfileComponent} from "./ui/register/profile.component";
import {PreferencesComponent} from "./ui/register/preferences.component";
import {TermsAndConditionsComponent} from "./ui/register/terms_and_conditions.component";
import {EmailVerificationComponent} from "./ui/register/email_verification.component";
import {ChangeEmailComponent} from "./ui/register/change_email.component";
import {SpMainComponent} from "./ui/spmain.component";
import {CoursesComponent} from "./ui/courses/courses.component";
import {CoursesDetailComponent} from "./ui/courses/courses_detail.component";
import {CourseTopicsComponent} from "./ui/courses/course_topics.component";
import {CourseQuizComponent} from "./ui/courses/course_quiz.component";
import {ReferenceComponent} from "./ui/reference/reference.component";
import {ReferenceDetailComponent} from "./ui/reference/reference_detail.component";
import {CommonTopicVideoComponent} from "./ui/common_topics/common_topic_video.component";
import {CommonTopicUrlComponent} from "./ui/common_topics/common_topic_url.component";
import {CommonTopicPdfComponent} from "./ui/common_topics/common_topic_pdf.component";
import {CommonTopicImageComponent} from "./ui/common_topics/common_topic_image.component";
import {CommonTopicHtmlComponent} from "./ui/common_topics/common_topic_html.comoponent";
import {ToolboxComponent} from "./ui/reference/toolbox.component";
import {BrandLeaderboardComponent} from "./ui/leaderboard/brand/brand_leaderboard.component";
import {BrandLeaderboardFilterComponent} from "./ui/leaderboard/brand/internal/brand_leaderboard_filter.component";
import {RetailerLeaderboardComponent} from "./ui/leaderboard/retailer/retailer_leaderboard.component";
import {SettingsComponent} from "./ui/settings/settings.component";
import {SettingsTermsAndConditionsComponent} from "./ui/settings/settings_terms_and_conditions.component";
import {ProfileChangePasswordComponent} from "./ui/profile/profile_change_password.component";
import {CountryComponent} from "./ui/register/country.component";
import {LanguagesListComponent} from "./ui/register/languages_list.component";
import {CountriesListComponent} from "./ui/register/countries_list.component";
import {HomeComponent} from "./ui/home/home.component";
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {MenuComponent} from "./ui/common/menu";
import {SpModalComponent} from "./ui/common/sp_modal.component";
import {RetailerComponent} from "./ui/home/retailer/home_retailer.component";
import {CoursesListComponent} from "./ui/courses/courses_list.component";
import {BrandComponent} from "./ui/home/brand/home_brand.component";
import {CoursesFilterComponent} from "./ui/common/courses_filter.component";
import {ReferenceListComponent} from "./ui/reference/reference_list.component";
import {SpLoaderComponent} from "./ui/common/sp_loader.component";
import {BrandLeaderboardListComponent} from "./ui/leaderboard/brand/internal/brand_leaderboard_list.component";
import {BrandProfileComponent} from "./ui/profile/brand/profile_brand.component";
import {RetailerProfileComponent} from "./ui/profile/retailer/profile_retailer.component";
import {CertificatesListComponent} from "./ui/profile/retailer/certificates_list.component";
import {RetailerLeaderboardListComponent} from "./ui/leaderboard/retailer/internal/retailer_leaderboard_list.component";
import {PreviousRetailersListComponent} from "./ui/profile/retailer/previous_retailers_list.component";
import {EmployerComponent} from "./ui/register/employer.component";
import {LanguageDropdownComponent} from "./ui/common/language_dropdown.component";
import {BrandChangeLanguage} from "./ui/profile/brand/brand_change_language.component";
import {EventsComponent} from "./ui/events/events.component";
import {UpComingEventsComponent} from "./ui/events/events_upcoming.component";
import {MyEventsComponent} from "./ui/events/events_my.component";
import {SearchEventsComponent} from "./ui/events/events_search.component";
import {EventDetailComponent} from "./ui/events/event_detail.component";
import {EventAttachmentComponent} from "./ui/events/event_attachment.component";
import {EventsListComponent} from "./ui/events/events_list.component";
import {QRCodeComponent} from "./ui/common/qr_code";
import {TopicQuizComponent} from "./ui/courses/topic_quiz.component";
import {EventDetailDataService} from "./ui/events/event_detail_data.service";
import {SurveysListComponent} from "./ui/surveys/surveys_list.component";
import {SurveyDetailComponent} from "./ui/surveys/survey_detail.component";
import {SurveyDetailService} from "./ui/surveys/helpers/survey_detail.service";
import {ImageLibraryAnswerComponent} from "./ui/surveys/answer_types/image_library_answer.component";
import {NumberAnswerComponent} from "./ui/surveys/answer_types/number_answer.component";
import {OpenTextAnswerComponent} from "./ui/surveys/answer_types/open_text_answer.component";
import {RateAnswerComponent} from "./ui/surveys/answer_types/rate_answer.component";
import {SelectAnswerComponent} from "./ui/surveys/answer_types/select_answer.component";
import {DateTimeAnswerComponent} from "./ui/surveys/answer_types/date_time_answer.component";
import {SurveyFileUploadComponent} from "./ui/surveys/helpers/survey_file_upload.component";
import {UpdatedTermsAndConditionsComponent} from "./ui/settings/updated_terms_and_conditions.component";
import {EmployeeStatesListComponent} from "./ui/register/employee_states_list.component";
import {DownloadButtonsComponent} from "./ui/common/download_buttons.component";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CommonTopicHtmlWithZendeskChatComponent} from './ui/common_topics/common_topic_html_with_zendesk_chat.comoponent';
import {AppCampaignsProcessorService} from "./common/services/app_campaigns_processor.service";
import {PickStoreFromGoogleMapsComponent} from "./common/components/pick_store_from_google_maps.component";
import {AgmCoreModule} from '@agm/core';
import {AddStoreInternalComponent} from "./common/components/add_store.internal.component";
import {StoresListInternalComponent} from "./common/components/stores_list.internal.component";
import { SelectStoreStatesListInternalComponent } from './common/components/select_store_states_list.internal.component';
import {UserSessionProviderService} from "./common/services/user-session-provider.service";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [BrowserModule, FormsModule, HttpClientModule, routing,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: Window['googleApiKey']
        })
    ],
    declarations: [AppComponent, HomeComponent, SafeStyle, SafeHtml, SafeTrustedUrl, LoginComponent, RegisterComponent, RetailerEmployerComponent, RetailersListComponent, StoresListComponent, JobCategoriesListComponent, ProfileComponent, PreferencesComponent,
        TermsAndConditionsComponent, EmailVerificationComponent, ChangeEmailComponent, CertificateDetailCompoent, SpMainComponent, CoursesComponent, CoursesDetailComponent,
        CourseTopicsComponent, CourseQuizComponent, TopicQuizComponent, ReferenceComponent, ReferenceDetailComponent, CommonTopicVideoComponent, CommonTopicUrlComponent, CommonTopicPdfComponent,
        CommonTopicImageComponent, CommonTopicHtmlComponent, CommonTopicHtmlWithZendeskChatComponent, ToolboxComponent, BrandLeaderboardComponent, BrandLeaderboardFilterComponent, RetailerLeaderboardComponent, SettingsComponent,
        SettingsTermsAndConditionsComponent, SettingsPreferencesComponent, DownloadComponent, ProfileMenuComponent, UpdateProfileDataComponent, ProfileChangeEmailComponent, ProfileChangePasswordComponent,
        RetailerCertificatesComponent, JobCategoriesComponent, BrandCertificatesComponent, PreviousCertificatesListComponent, SettingsFeedbackComponent, LoadingComponent,
        CountryComponent, CountriesListComponent, LanguagesListComponent, MenuComponent, SpModalComponent, BrandComponent, CoursesListComponent, RetailerComponent, CoursesFilterComponent,
        ReferenceListComponent, SpLoaderComponent, BrandLeaderboardListComponent, BrandProfileComponent, RetailerProfileComponent, SearchByName, SearchByMultiple, CertificatesListComponent, RetailerLeaderboardListComponent,
        PreviousRetailersListComponent, EmployerComponent, LanguageDropdownComponent, BrandChangeLanguage, EventsComponent, UpComingEventsComponent, MyEventsComponent, SearchEventsComponent, EventDetailComponent, EventAttachmentComponent, EventsListComponent, QRCodeComponent,
        SurveysListComponent, SurveyDetailComponent, SelectAnswerComponent, RateAnswerComponent, OpenTextAnswerComponent, NumberAnswerComponent, ImageLibraryAnswerComponent, SurveyFileUploadComponent, DateTimeAnswerComponent, UpdatedTermsAndConditionsComponent, EmployeeStatesListComponent, DownloadButtonsComponent, PickStoreFromGoogleMapsComponent,
        AddStoreInternalComponent, SelectStoreStatesListInternalComponent, StoresListInternalComponent],
    bootstrap: [AppComponent],
    providers: [
        appRoutingProviders, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }, EventDetailDataService, SurveyDetailService, AppCampaignsProcessorService, UserSessionProviderService
    ],
})
export class AppModule {
}


