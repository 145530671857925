import {Component, OnInit, ViewChild} from "@angular/core";
import {App} from "../../app";
import {HelperMethods} from "../../common/helper_methods";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {Router} from "@angular/router";


@Component({
    selector: 'sp-settings-terms-and-conditions',
    templateUrl: '../../../templates/settings/settings_terms_and_conditions.component.html',
})

export class SettingsTermsAndConditionsComponent implements OnInit {
    url: string = null;
    termsId: number;
    isLoadingInProcess: boolean = true;
    public app: App = App.instance;
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;

    constructor(protected router: Router) {
    }

    hideLoader() {
        this.spLoader.stopAndHide();
    }

    ngOnInit() {
        this.spLoader.start();
        this.isLoadingInProcess = true;
        this.init();
    }

    init() {
        if (App.instance.getEmployee().termsAndConditionsId != null) {
            this.url = HelperMethods.getUrlForTearmsAndConditions(App.instance.getEmployee().termsAndConditionsId);
            this.isLoadingInProcess = false;
        } else {
            this.url = HelperMethods.getUrlForTearmsAndConditions(null, App.instance.getEmployee());
            this.isLoadingInProcess = false;
        }
    }

    get isUpdatedTermsAndConditionsPreview(): boolean {
        return false;
    }

    get headerText() {
        return "TERMS_AND_CONDITIONS.TITLE";
    }
}

