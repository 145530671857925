import {Component, OnInit} from '@angular/core';
import {App} from "../../../app";
import {LanguagesResponseMessage} from "../../../communication/messages/languages_response_message";
import {LanguageMessageData} from "../../../communication/message_data/language_message_data";
import {ModalService} from "../../../common/services/modal.service";

@Component({
    selector: 'sp-brand-change-language',
    templateUrl: '../../../../templates/profile/brand/brand_change_language.component.html'
})
export class BrandChangeLanguage implements OnInit {
    app: App = App.instance;
    public languagesList: Array<LanguageMessageData>;

    constructor(private modal: ModalService) {
    }

    ngOnInit() {
        this.app.driver.getLanguages(this.app.data.employee.countryId)
            .onSuccess((rsp: LanguagesResponseMessage) => {
                if (rsp.languages) {
                    this.languagesList = rsp.languages;
                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
            })
            .send();
    }

    onLanguageItemSelected(record: LanguageMessageData) {
        this.app.language.changeLanguage(record);
        window.location.reload();
    }
}
