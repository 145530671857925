import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {ActionStatisticMessageData} from "../message_data/action_statistic_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class QueryGoogleLocationBySearchRequestMessage extends RequestMessage {
    searchQuery: string;
    countryId: number;
    stateId: number;

    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.QueryGoogleLocationBySearchRequest;
    }
}
