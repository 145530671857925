import {IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {BrandCategoryMessageData} from "./brand_category_message_data";
import {ThemeMessageData} from "./theme_message_data";

export class BrandExtendedMessageData implements IJsonDeserializable, Iid {
    version: number = 3;
    id: number;
    name: string;
    imageFileDescriptor: string;
    landscapeImageFileDescriptor: string;
    categoryLogoImageFileDescriptor: string;
    themeId: number;

    brandCategories: Array<BrandCategoryMessageData> = [];
    theme: ThemeMessageData;

    withoutStorefront: boolean = true;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.imageFileDescriptor = jsonObject.imageFileDescriptor;
        this.landscapeImageFileDescriptor = jsonObject.landscapeImageFileDescriptor;
        this.categoryLogoImageFileDescriptor = jsonObject.categoryLogoImageFileDescriptor;
        this.themeId = jsonObject.themeId;

            if (this.version > 2) {
            this.withoutStorefront = jsonObject.withoutStorefront;
        }
    }
}
