import {Component, OnInit, ViewChild} from "@angular/core";
import {SettingsTermsAndConditionsComponent} from "./settings_terms_and_conditions.component";
import {App} from "../../app";
import {Router} from "@angular/router";
import {SpModalComponent} from "../common/sp_modal.component";
import {HelperMethods} from "../../common/helper_methods";
import {ModalService} from "../../common/services/modal.service";
import {UserSessionProviderService} from "../../common/services/user-session-provider.service";


@Component({
    selector: 'sp-updated-terms-and-conditions',
    templateUrl: '../../../templates/settings/settings_terms_and_conditions.component.html',
})
export class UpdatedTermsAndConditionsComponent extends SettingsTermsAndConditionsComponent implements OnInit {

    constructor(protected router: Router, private modal: ModalService, private userSessionProviderService:UserSessionProviderService) {
        super(router);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    init() {
        this.app.driver.getTermsAndConditionsId().onSuccess(rsp => {
            if (rsp.termsAndConditionsId) {
                this.termsId = rsp.termsAndConditionsId;
                this.url = HelperMethods.getUrlForTearmsAndConditions(rsp.termsAndConditionsId);
                this.isLoadingInProcess = false;
            } else {
                this.url = HelperMethods.getUrlForTearmsAndConditions(null, this.app.getEmployee());
                this.isLoadingInProcess = false;
            }
        }).onError(() => {
            this.modal.show
        }).send();
    }

    get isUpdatedTermsAndConditionsPreview(): boolean {
        return true;
    }

    get headerText() {
        return "TERMS_AND_CONDITIONS.UPDATED_TITLE";
    }

    onDisagreeButtonClick() {
        this.modal.show("TERMS_AND_CONDITIONS.MODAL_DISAGREE_HEADER",
            "TERMS_AND_CONDITIONS.MODAL_DISAGREEING_MESSAGE",
            "TERMS_AND_CONDITIONS.DISAGREE_BUTTON_TEXT",
            "TERMS_AND_CONDITIONS.CANCEL_BUTTON_TEXT", () => {
                this.userSessionProviderService.logoutUser()
                this.router.navigate(['login']);
            }, () => {
                return;
            });
    }

    onAgreeButtonClick() {
        this.spLoader.start();
        this.isLoadingInProcess = true;
        this.app.driver.updateTermsAndConditionsEmployeeId(this.termsId).onSuccess(rsp => {
            this.app.getEmployee().termsAndConditionsId = rsp.termsAndConditionsId;
            this.router.navigate(['main']);
        }).onError(() => {
            this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
        }).send();
    }
}

