import {ItemMessageData} from "./item_message_data";

export class QueryGooglePlaceMessageData extends ItemMessageData {
    placeId: string;
    name: string;
    address: string;
    latitude: number;
    longitude: number;

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.placeId = jsonObject.placeId;
        this.name = jsonObject.name;
        this.address = jsonObject.address;
        this.latitude = jsonObject.latitude;
        this.longitude = jsonObject.longitude;
    }
}
