import {ResponseMessage} from "../response_message";
import {RecentlyAwardedMessageData} from "../message_data/recently_awarded_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class RecentlyAwardedResponseMessage extends ResponseMessage {
    data: Array<RecentlyAwardedMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.RecentlyAwardedResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.data = deserializeArrayOfObject(jsonObject.data, RecentlyAwardedMessageData);
    }
}
