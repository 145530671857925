import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeeQuizAnswerMessageData implements IJsonDeserializable {
    public version: number = 1;
    public guid: string;
    public attemptGuid: string;
    public questionId: number;
    public questionAnswerId: number;
    public questionType: number;
    public stringContent: string;
    public correct: boolean;
    public answeredAt: Date;
    public questionAnswerIds: string = "";

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.attemptGuid = jsonObject.attemptGuid;
        this.questionId = jsonObject.questionId;
        this.questionAnswerId = jsonObject.questionAnswerId;
        this.questionType = jsonObject.questionType;
        this.stringContent = jsonObject.stringContent;
        this.correct = jsonObject.correct;
        this.answeredAt = jsonObject.answeredAt;

        if (this.version > 0) {
            this.questionAnswerIds = jsonObject.questionAnswerIds;
        }
    }
}