export enum TopicType {
    Unknown = 0,
    Html = 1,
    Pdf = 2,
    Video = 3,
    Image = 4,
    Url = 5,
    DiscusForumUrl = 6,
    ExternalUrl = 7,
    HtmlJson = 8,
    HtmlJsonWithZendeskChat = 9
}
