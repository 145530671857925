import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {TeaserMessageData} from "./teaser_message_data";
import {CourseMessageData} from "./course_message_data";
import {EmployeeCourseState} from "../../data/employee_course_state";
import {App} from "../../app";
import {ContentStatisticType} from "../../data/content_statistic_type";
import {CourseState} from "../../data/course_state";
import {CourseType} from "../../data/course_type";
import {TopicType} from "../../data/topic_type";

export class TopicMessageData implements IJsonDeserializable {
    version: number = 0;

    id: number;
    courseId: number;
    name: string;
    shortName: string;
    body: string;
    topicType: number;
    order: number;
    minStayTime: number;
    lastChanged: Date;

    employeeRecordStatus: boolean;
    employeeTeaserStatus: boolean;
    employeeUpdatedAt: Date;
    employeeClipboardStatus: boolean;

    fileDescriptor: string;
    supportingFilesDescriptor = new Array<string>();

    previewFileDescriptor: string;

    //private
    teaser: TeaserMessageData;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.courseId = jsonObject.courseId;
        this.name = jsonObject.name;
        this.shortName = jsonObject.shortName;
        this.body = jsonObject.body || "";
        this.topicType = jsonObject.topicType;
        this.order = jsonObject.order;
        this.minStayTime = jsonObject.minStayTime;
        this.lastChanged = deserializeDateTime(jsonObject.lastChanged);
        this.employeeRecordStatus = jsonObject.employeeRecordStatus || false;
        this.employeeTeaserStatus = jsonObject.employeeTeaserStatus || false;
        this.employeeUpdatedAt = jsonObject.employeeUpdatedAt;
        this.employeeClipboardStatus = jsonObject.employeeClipboardStatus;
        this.fileDescriptor = jsonObject.fileDescriptor;
        this.supportingFilesDescriptor = jsonObject.supportingFilesDescriptor;
        this.previewFileDescriptor = jsonObject.previewFileDescriptor;
    }

    //local
    public updateStateUp(course: CourseMessageData): [boolean, boolean, EmployeeCourseState] {
        if (this.employeeRecordStatus) {
            return [false, false, course.forEmployeeState];
        }

        this.employeeRecordStatus = true;
        var courseChanged = course.updateStateUp();
        var result: [boolean, boolean, EmployeeCourseState] = [true, courseChanged[0], courseChanged[1]];

        var operation = 1;
        if (result[1]) {
            operation = 10 * result[2] + operation;
        }

        App.instance.statistic.setPerformRelatedOnActive(operation);
        return result;
    }

    public getStatisticsTypeByCourse(course: CourseMessageData): ContentStatisticType {
        if (course.recordStatus == CourseState.bounced)
            return ContentStatisticType.archiveTopic;

        switch (course.userType) {
            case CourseType.course:
                return ContentStatisticType.topic;
            case CourseType.trainingLibrary:
                return ContentStatisticType.trainingTopic;
            case CourseType.referenceLibrary:
                return ContentStatisticType.referenceTopic;
        }

        return ContentStatisticType.unknown;
    }

    getTopicIconByType(): string {
        switch (this.topicType) {
            case TopicType.Html:
            case TopicType.HtmlJson:
            case TopicType.HtmlJsonWithZendeskChat:
                return "sp-courses";
            case TopicType.Image:
                return "sp-image";
            case TopicType.Pdf:
                return "sp-pdf";
            case TopicType.Url:
            case TopicType.ExternalUrl:
                return "sp-link";
            case TopicType.Video:
                return "sp-video";
            default:
                return '';
        }
    }

    getCourseTopicControl(): string {
        switch (this.topicType) {
            case TopicType.Html:
            case TopicType.HtmlJson:
                return 'common_topic_html';
            case TopicType.HtmlJsonWithZendeskChat:
                return 'common_topic_html_with_zendesk_chat';
            case TopicType.Image:
                return 'common_topic_image';
            case TopicType.Pdf:
                return 'common_topic_pdf';
            case TopicType.Url:
            case TopicType.ExternalUrl:
                return 'common_topic_url';
            case TopicType.Video:
                return 'common_topic_video';
            default:
                return "";
        }
    }
}





