import {Component, OnInit} from '@angular/core';
import {RegisterComponent} from "./register.component";
import {Router} from "@angular/router";
import {StateMessageData} from "../../communication/message_data/state_message_data";
import {ModalService} from "../../common/services/modal.service";
import {RegisterData} from './register.data';

@Component({
    selector: 'sp-employee-states-list',
    templateUrl: '../../../templates/register/states_list.component.html'

})
export class EmployeeStatesListComponent {
    registerData: RegisterData;

    constructor(registerComponent: RegisterComponent, private router: Router, protected modal: ModalService) {
        this.registerData = registerComponent.RegisterData;
    }

    Selected(record: StateMessageData) {
        this.registerData.State = record;
        this.router.navigate(['register', 'country'])
    }

    BackButtonTapped() {
        this.router.navigate(['register', 'country'])
    }

    getCountryId(): number {
        return this.registerData.Country.id;
    }
}