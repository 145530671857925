import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";
import {UserMessageData} from "../message_data/user_message_data";
import {deserializeObject} from "../json_deserialization/json_deserialization";

export class ActivateAndLoginEmployeeResponseMessage extends ResponseMessage {
    isAlreadyActivated: boolean;
    success: boolean;
    user: UserMessageData;

    constructor() {
        super();
        this.type = MessageTypes.ActivateAndLoginEmployeeResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.isAlreadyActivated = jsonObject.isAlreadyActivated;
        this.success = jsonObject.success;
        this.user = deserializeObject(jsonObject.user, UserMessageData);
    }
}
