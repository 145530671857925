import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {App} from "../../app";
import {ActivatedRoute, Router} from "@angular/router";
import {EventAttachmentMessageData} from "../../communication/message_data/event_attachment_message_data";
import {UIEventData} from "../../data/ui_event_data";
import {EmployeeRegisterForEventResponseMessage} from "../../communication/messages/employee_register_for_event_response_message";
import {EventDetailDataService} from "./event_detail_data.service";
import {EventsMessageData} from "../../communication/message_data/events_message_data";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {EmployeeCheckInForEventResponseMessage} from "../../communication/messages/employee_check_in_for_event_response_message";
import {SpModalComponent} from "../common/sp_modal.component";
import {ModalService} from "../../common/services/modal.service";
import {AppCampaignsProcessorService} from "../../common/services/app_campaigns_processor.service";
import {EmployeeEventAttendanceConfirmationResponseMessage} from "../../communication/messages/employee_event_attendance_confirmation_response_message";

@Component({
    selector: 'sp-events-details',
    templateUrl: "../../../templates/events/event_detail.component.html",
})

export class EventDetailComponent implements OnInit, OnDestroy {
    data: UIEventData;
    public app: App = App.instance;
    public isRegisterInProcess: boolean = false;
    attendanceCode: string = "";
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;

    constructor(private route: ActivatedRoute, private router: Router,
                private eventSearchedDataService: EventDetailDataService,
                private modal: ModalService,
                private appCampaignsProcessor: AppCampaignsProcessorService
    ) {
    }

    ngOnInit(): any {
        this.loadData();
    }

    private loadData() {
        this.data = this.eventSearchedDataService.getEventData();
        this.data.loadData();
        let brand = this.app.data.brands.values().find((p) => p.id == this.data.event.brandId);//todo: get from event brand, no needed
        if (brand) {
            this.data.imageUrl = brand.imageFileDescriptor;//todo: get from event brand
        }
    }

    onRegisterClicked() {
        this.isRegisterInProcess = true;
        this.spLoader.start();
        this.app.driver.registerEmployeeForEvent(this.app.data.employee.id,
            this.data.event.id,
            this.data.event.employeeInvitationId
        ).onSuccess((rsp: EmployeeRegisterForEventResponseMessage) => {
            if (rsp != null && rsp.error == null) {
                this.data.event.employeeIsRegistered = rsp.success;
                this.data.event.employeeInvitationId = rsp.employeeEventInvitationId;
                this.data.event.employeeInvitationPersonalCode = rsp.employeeInvitationPersonalCode;
                this.data.loadData();

                let event = this.app.data.events.getValue(this.data.event.id);
                if (event == null) {
                    let eventList: Array<EventsMessageData> = [this.data.event];
                    let attachmentList: Array<EventAttachmentMessageData> = this.data.attachments;
                    this.app.data.populateDataEvents(eventList, attachmentList);
                }
                this.app.data.events.setValue(this.data.event.id, this.data.event);
                this.app.statistic.eventSelfRegistrationAction(this.data.event.id, this.app.data.employee.id, this.data.event.employeeInvitationPersonalCode);
                this.spLoader.stopAndHide();
                this.isRegisterInProcess = false;
            }
        }).onError((error, code, message) => {
            if(message != undefined && message.length > 0){
                this.modal.show("COMMON.ERROR", message);
            }else{
                this.modal.show("COMMON.ERROR", "COMMON.UNIDENTIFIED_ERROR");
            }
            this.spLoader.stopAndHide();
            this.isRegisterInProcess = false;
        }).send();
    }

    onPrintClicked() {
        window.print();
    }

    onBackPressed() {
        if (this.data.event.employeeIsRegistered) {
            this.router.navigate(['main', 'events', 2]);
        } else {
            this.router.navigate(['main', 'events', 1]);
        }
    }

    onEventAttachmentClick(attachment: EventAttachmentMessageData) {
        this.router.navigate(['main', 'event_attachment', attachment.id]);
    }

    ngOnDestroy() {
        this.data.stopTimers();
    }

    onOnlineCheckInForEvent(data: UIEventData) {
        this.isRegisterInProcess = true;
        this.spLoader.start();
        this.app.driver.EmployeeCheckInForEvent(this.app.data.employee.id,
            data.event.employeeInvitationId
        ).onSuccess(async (rsp: EmployeeCheckInForEventResponseMessage) => {
            data.event.employeeIsAttend = (rsp.success && rsp.attend) || rsp.isAlreadyJoinedOrAttend;
            data.event.employeeIsJoined = (rsp.success && rsp.joined) || rsp.isAlreadyJoined;
            data.loadData();
            if(data.event.employeeIsAttend){
                await this.appCampaignsProcessor.eventAttended(this.app, data.event.brandId, data.event.id, data.event.name);
            }
            window.open(data.addressDetail);
            this.isRegisterInProcess = false;
            this.spLoader.stopAndHide();
        }).onError((error, code, message) => {
            if(message != undefined && message.length > 0){
                this.modal.show("COMMON.ERROR", message);
            }else{
                this.modal.show("COMMON.ERROR", "COMMON.UNIDENTIFIED_ERROR");
            }
            this.spLoader.stopAndHide();
            this.isRegisterInProcess = false;
        }).send();
    }

    onOnlineEventJoinClick(url: string) {
        if (this.data.onlineEventCheckInAvailable)
            window.open(url);
        if (this.data.onlineEventCheckInExpired)
            this.modal.show("EVENT.MODAL_TITLE", "EVENT.MODAL_DESCRIPTION");
    }

    sendAttendanceCode(){
        if(this.attendanceCode.length == 0){
            return;
        }
        this.isRegisterInProcess = true;
        this.spLoader.start();
        this.app.driver.EmployeeEventAttendanceConfirmation(this.app.data.employee.id, this.data.event.employeeInvitationId, this.attendanceCode)
        .onSuccess(async (rsp: EmployeeEventAttendanceConfirmationResponseMessage) => {
            this.data.event.employeeIsAttend = rsp.success || rsp.isAlreadyAttend;
            if(rsp.success){
                await this.appCampaignsProcessor.eventAttended(this.app, this.data.event.brandId, this.data.event.id, this.data.event.name);
                this.data.isAttendanceCodeFormVisible = false;
                this.modal.show("", "EVENTS.ATTENDANCE_CODE_SUCCESS", "COMMON.OK");
            }else {
                this.modal.show("COMMON.ERROR", "EVENTS.ERROR_SUBMITTING_CODE");
            }
            this.spLoader.stopAndHide();
            this.isRegisterInProcess = false;
            this.data.loadData();
        }).onError((error) => {
            this.modal.show("", "EVENTS.ERROR_SUBMITTING_CODE");
            this.spLoader.stopAndHide();
            this.isRegisterInProcess = false;
        }).send();
    }

}
