import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";
import {deserializeDateTime} from "../json_deserialization/json_deserialization";

export class CertificateForCourseRequestMessage extends RequestMessage {

    employeeId: number;
    courseId: number;
    recertification: boolean;
    guid: string;
    dateTime: Date;
    points: number;
    preferredLang: number;


    constructor() {
        super();
        this.type = MessageTypes.CertificateForCourseRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.courseId = jsonObject.courseId;
        this.recertification = jsonObject.recertification;
        this.guid = jsonObject.guid;
        this.dateTime = deserializeDateTime(jsonObject.dateTime);
        this.points = jsonObject.points;
        this.preferredLang = jsonObject.preferredLang;
    }
}
