import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterComponent} from "./register.component";
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {CountryMessageData} from "../../communication/message_data/country_message_data";
import {CountriesResponseMessage} from "../../communication/messages/countries_response_message";
import {LanguagesResponseMessage} from "../../communication/messages/languages_response_message";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-register-country',
    templateUrl: '../../../templates/register/country.component.html'

})


export class CountryComponent implements OnInit {
    public registerData: RegisterData;
    public app: App = App.instance;

    constructor(private registerComponent: RegisterComponent, private router: Router, private http: HttpClient, private translate: TranslateService, private modal: ModalService) {
        this.registerData = registerComponent.RegisterData;

    }

    ngOnInit() {
        this.app.driver.getCountries(false)
            .onSuccess((rsp: CountriesResponseMessage) => {
                if (rsp.countries) {
                    this.registerData.countriesList = rsp.countries;
                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
            })
            .send();


    }


    CountryButtonClicked() {
        this.router.navigate(['register', 'countries_list']);
    }

    LanguageButtonClicked() {
        this.router.navigate(['register', 'languages_list']);
    }

    StateButtonClicked() {
        this.router.navigate(['register', 'states_list']);
    }

    ActionButtonTapped() {
        this.router.navigate(['register', 'retailer_employer']);
    }

    BackButtonTapped() {
        this.router.navigate(['/']);
    }
}
