import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class EmployeeRegisterForEventResponseMessage extends ResponseMessage {
    isAlreadyExists: boolean;
    success: boolean;

    employeeInvitationPersonalCode: string;
    employeeEventInvitationId: number;

    constructor() {
        super();
        this.type = MessageTypes.EmployeeRegisterForEventResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.isAlreadyExists = jsonObject.isAlreadyExists;
        this.success = jsonObject.success;

        this.employeeInvitationPersonalCode = jsonObject.employeeInvitationPersonalCode;
        this.employeeEventInvitationId = jsonObject.employeeEventInvitationId;
    }
}
