import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class RegisterRetailerResponseMessage extends ResponseMessage {
    id: number;
    registered: boolean;

    constructor() {
        super()
        this.type = MessageTypes.RegisterRetailerResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.id = jsonObject.id;
        this.registered = jsonObject.registered;
    }
}
