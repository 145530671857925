import {Component, OnInit} from "@angular/core";
import {App} from "../app";


@Component({
    selector: 'sp-loading',
    templateUrl: '../../templates/loading.component.html',
})

export class LoadingComponent implements OnInit {
    public email: string;
    public password: string;

    ngOnInit(): void {
        var app = App.instance;
        app.tryLoginUser();

    }

    constructor() {
    }
}
