import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class LanguagesRequestMessage extends RequestMessage {

    employeeId: number;
    preferredLang: number;
    countryId: number;
    osVersion: number;

    constructor() {
        super();
        this.type = MessageTypes.LanguagesRequest;
        this.osVersion = 4;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
        this.countryId = jsonObject.countryId;
        this.osVersion = jsonObject.osVersion;

    }
}
