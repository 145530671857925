import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";
import {RetailerMessageData} from "../message_data/retailer_message_data";


export class RetailersByBrandResponseMessage extends ResponseMessage {
    retailers: Array<RetailerMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.RetailersByBrandResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.retailers = deserializeArrayOfObject(jsonObject.retailers, RetailerMessageData);
    }
}
