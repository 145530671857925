import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {SurveyQuestionMessageData} from "../../../communication/message_data/survey_question_message_data";
import {BaseAnswer} from "./base_answer";

@Component({
    selector: 'sp-rate-answer',
    templateUrl: '../../../../templates/surveys/answer_types/rate-answer.component.html'
})
export class RateAnswerComponent extends BaseAnswer implements OnInit, OnDestroy {

    @Input() question: SurveyQuestionMessageData;
    @Input() surveyId: number;
    @Input() employeeSurveyId: number;
    @Output() answerSelect = new EventEmitter();
    @ViewChild('rateControl', { static: false }) rateControl: ElementRef;

    rates: Array<any> = [
        {
            value: 1,
            selected: false
        },
        {
            value: 2,
            selected: false
        },
        {
            value: 3,
            selected: false
        },
        {
            value: 4,
            selected: false
        },
        {
            value: 5,
            selected: false
        },
    ];

    constructor() {
        super();
    }


    ngOnInit(): void {
        if (this.question.answerText != null) {
            this.selectRate(this.question.answerText);
        }
    }

    ngOnDestroy(): void {
    }

    onClick(rate: any): void {
        for (let i = 0; i < this.rates.length; i++) {
            this.rates[i].selected = false
        }
        this.selectRate(rate.value);
        this.question.answerText = rate.value;
        this.createEmployeeSurveyAnswerMessage(this.question, this.employeeSurveyId, () => {
            this.answerSelect.emit();
        });
    }

    selectRate(rate: any) {
        let index = Number(rate) - 1;
        for (let i = 0; i <= index; i++) {
            this.rates[i].selected = true;
        }
    }
}