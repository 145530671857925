import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {ActionStatisticMessageData} from "../message_data/action_statistic_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class QueryGooglePlacesByLatLongApiRequestMessage extends RequestMessage {
    latitude: number;
    longitude: number;
    retailerName: string;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.QueryGooglePlacesByLatLongApiRequest;
    }
}
