import {ItemMessageData} from "./item_message_data";

export class StoreResponseMessageData extends ItemMessageData {
    storeResponseMessageDataVersion: number = 0;

    storeNumber: string;
    address: string;
    city: string;
    stateId: number;
    zip: string;
    abrv: string;
    latitude: number;
    longitude: number;
    isDefaultForRetailer: boolean;
    itemMessageDataVersion: number

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.storeResponseMessageDataVersion = jsonObject.storeResponseMessageDataVersion;
        this.itemMessageDataVersion = jsonObject.itemMessageDataVersion;
        this.isDefaultForRetailer = jsonObject.isDefaultForRetailer;
        this.storeNumber = jsonObject.storeNumber;
        this.address = jsonObject.address;
        this.city = jsonObject.city;
        this.stateId = jsonObject.stateID;
        this.zip = jsonObject.zip;
        this.abrv = jsonObject.abrv;
        this.latitude = jsonObject.latitude;
        this.longitude = jsonObject.longitude;
    }
}
