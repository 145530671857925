import {
    Component,
    QueryList,
    ViewChildren,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../app";
import {AgmMarker} from "@agm/core";
import {QueryGooglePlaceMessageData} from "../../communication/message_data/query_google_place_message_data";
import { LocationCordinates } from 'app/ts/common/components/stores_list.internal.component';

@Component({
    selector: 'sp-pick-store-from-google-maps',
    templateUrl: '../../../templates/register/pick_store_from_google_maps.component.html',
    styleUrls: ['../../../styles/components/pick_store_from_google_maps.component.css']
})

export class PickStoreFromGoogleMapsComponent implements OnInit {
    @Input() currentZipCordinates: LocationCordinates;
    @Input() stores: Array<QueryGooglePlaceMessageData>;
    @Output() onPickStore: EventEmitter<IMarker> = new EventEmitter<IMarker>();
    @ViewChildren(AgmMarker) agmMarkers: QueryList<AgmMarker>;
    public app: App = App.instance;
    loaded = false;
    zoom: number = 8;
    markers: IMarker[] = [];

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.markers = this.stores.map(s => {
            return Object.assign({}, s);
        });
        this.loaded = true;
    }

    clickedMarker(label: string, index: number) {
        this.closeAllMarkers();
    }

    closeAllMarkers() {
        this.agmMarkers.forEach(p => p.infoWindow.forEach(l => l.close()));
    }

    OnMarkerClose() {
        this.closeAllMarkers();
    }

    OnMarkerSelect(marker: IMarker) {
        this.onPickStore.emit(marker);
    }
}

export interface IMarker {
    placeId: string;
    name: string;
    address: string;
    latitude: number;
    longitude: number;
}
