import {ItemMessageData} from "./item_message_data";

export class StateMessageData extends ItemMessageData {
    stateMessageDataVersion: number = 0;

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.stateMessageDataVersion = jsonObject.stateMessageDataVersion;
    }
}
