import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class ChangeEmailAfterActivationResponseMessage extends ResponseMessage {
    success: boolean;
    isUsed: boolean;
    isAllowedForBrand: boolean;
    isDemo: boolean;
    oldEmailDoesNotMatch: boolean;

    constructor() {
        super();
        this.type = MessageTypes.ChangeEmailAfterActivationResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.success = jsonObject.success;
        this.isUsed = jsonObject.isUsed;
        this.isAllowedForBrand = jsonObject.isAllowedForBrand;
        this.isDemo = jsonObject.isDemo;
        this.oldEmailDoesNotMatch = jsonObject.oldEmailDoesNotMatch;
    }
}
