import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {App} from "../../app";
import {ChangePasswordResponseMessage} from "../../communication/messages/change_password_response_message";
import {SpModalComponent} from "../common/sp_modal.component";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-profile-change_password',
    templateUrl: '../../../templates/profile/profile_change_password.component.html'
})

export class ProfileChangePasswordComponent implements OnInit {


    public app: App = App.instance;
    @ViewChild('new_password', { static: true }) newPassword: ElementRef;
    @ViewChild('confirm_password', { static: true }) confirmPassword: ElementRef;
    @ViewChild('old_password', { static: true }) oldPassword: ElementRef;
    isFirstTimeOpenPassword: boolean = true;
    isPasswordNew: boolean = false;
    arePasswordsEquals: boolean = false;
    isPasswordOld: boolean = false;


    constructor(private modal:ModalService) {

    }

    ngOnInit() {

    }

    onOldPasswordKeyPressed() {
        this.isFirstTimeOpenPassword = false;
        this.oldPasswordWrite(this.oldPassword.nativeElement.value);

    }

    oldPasswordWrite(oldPassword: string) {
        if (oldPassword === null || oldPassword === "") {
            this.isPasswordOld = false;
        } else {
            this.isPasswordOld = true;
        }
    }

    onNewPasswordKeyPressed() {
        this.isFirstTimeOpenPassword = false;
        this.newPasswordWrite(this.newPassword.nativeElement.value);

    }

    newPasswordWrite(newPassword: string) {
        if (newPassword === null || newPassword === "") {

            this.isPasswordNew = false;
        } else {
            this.isPasswordNew = true;
        }

    }

    onConfirmPasswordPressed() {
        this.isFirstTimeOpenPassword = false;
        this.validatePassword(this.newPassword.nativeElement.value, this.confirmPassword.nativeElement.value);

    }

    validatePassword(newPassword: string, confirmPassword: string) {
        if (newPassword === confirmPassword) {
            this.arePasswordsEquals = true;

        } else {
            this.arePasswordsEquals = false;
        }

    }

    onChangeButtonClick() {
        this.app.driver.changePassword(this.app.data.employee.id, this.newPassword.nativeElement.value, this.oldPassword.nativeElement.value)
            .onSuccess((rsp: ChangePasswordResponseMessage) => {
                if (rsp.success) {
                    this.modal.show('RETAILER_PASSWORD_CHANGE_PASSWORD.SUCCESS', "RETAILER_PASSWORD_CHANGE_PASSWORD.SUCCESS_MESSAGE");
                } else {
                    this.modal.show('RETAILER_PASSWORD_CHANGE_PASSWORD.ERROR', "RETAILER_PASSWORD_CHANGE_PASSWORD.ERROR_MESSAGE");
                }
                this.resetForm();
            })
            .onError((e, ec, em) => {
                this.resetForm();
                this.modal.show('RETAILER_PASSWORD_CHANGE_PASSWORD.ERROR', "RETAILER_PASSWORD_CHANGE_PASSWORD.ERROR_MESSAGE_SERVER");
            }).send();
    }

    resetForm(){
        this.oldPassword.nativeElement.value = '';
        this.newPassword.nativeElement.value = '';
        this.confirmPassword.nativeElement.value = '';
    }

}
