import {ResponseMessage} from "../response_message";
import {RetailerCategoryMessageData} from "../message_data/retailer_category_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class RetailerCategoriesResponseMessage extends ResponseMessage {
    retailerCategories: Array<RetailerCategoryMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.RetailerCategoriesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.retailerCategories = deserializeArrayOfObject(jsonObject.retailerCategories, RetailerCategoryMessageData);
    }
}
