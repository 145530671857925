import {ResponseMessage} from "../response_message";
import {StateMessageData} from "../message_data/state_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class StatesResponseMessage extends ResponseMessage {
    states: Array<StateMessageData>;

    constructor() {
        super();
        this.type = MessageTypes.StatesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.states = deserializeArrayOfObject(jsonObject.states, StateMessageData);
    }
}
