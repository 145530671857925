import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {EmployeeCertificateMessageData} from "../../../communication/message_data/eployee_certificate_message_data";
import {App} from "../../../app";

@Component({
    selector: 'sp-brand-certificates',
    templateUrl: '../../../../templates/profile/brand/brand_certificates.component.html'
})
export class BrandCertificatesComponent implements OnInit {
    app: App = App.instance;
    certificates: Array<EmployeeCertificateMessageData> = [];

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.certificates = this.app.data.getCertificates();
    }


}

