import {IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {ThemeMessageData} from "./theme_message_data";

export class RetailerExtendedMessageData implements IJsonDeserializable {
    version: number = 2;

    id: number;
    name: string;
    imageFileDescriptor: string;
    themeId: number;
    withoutPlayzone: boolean;

    theme: ThemeMessageData;

    withoutStorefront: boolean;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.imageFileDescriptor = jsonObject.imageFileDescriptor;
        this.themeId = jsonObject.themeId;
        this.withoutPlayzone = jsonObject.withoutPlayzone;

        if (this.version > 1) {
            this.withoutStorefront = jsonObject.withoutStorefront;
        }
    }
}
