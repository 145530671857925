import {App} from "../app";
import {StringExtensions} from "./extensions";
import {EmployeeMessageData} from "../communication/message_data/employee_message_data";

export class HelperMethods {
    static getUrlForTearmsAndConditions(termsId: number, employee?: EmployeeMessageData, country_id?: number) {
        let url = 'https://secure.sellpro.net/public/terms_and_conditions';
        if (termsId == null) {
            url = url.concat('?os_type=' + App.instance.appType);
            let countryId = null;
            if (employee != null) {
                if (employee.isRetailer()) {
                    url = url.concat("&retailer_id=" + employee.retailerId);
                } else {
                    url = url.concat("&brand_id=" + employee.brandId);
                }
                countryId = employee.countryId.toString();
            } else if (country_id) {
                countryId = country_id.toString();
            }
            if (countryId != null) {
                url = url.concat("&country_id=");
                url = url.concat(countryId.toString());
            }
        } else {
            url = url.concat("?id=" + termsId);
        }
        return url;
    }

    static getUrlForTheMall(employeeId: number) {
        let urlString = "https://secure.sellpro.net/public/store_front?employee_id={EmployeeId}";
        urlString = urlString.replace("{EmployeeId}", employeeId.toString());
        return urlString;
    }

    static getFileExtension(value: string): string {

        if (StringExtensions.isNullorEmpty(value)) {
            return;
        } else {
            let typeTemp = value.split(".");
            let extension = typeTemp[typeTemp.length - 1];
            return extension;
        }
    }

    static todayIsAfterDelayDays(pastDay: Date, delayDays: number): boolean {
        let one_day_milliseconds = 1000 * 60 * 60 * 24;
        let now = new Date();

        let days = (now.getTime() - pastDay.getTime()) / (one_day_milliseconds);
        let theDays = days - delayDays;
        if (theDays > 1 || theDays === 1) {
            return true;
        } else {
            return false;
        }
    }

    static isBeforeNow(pastDay: Date): boolean {
        let one_day_milliseconds = 1000 * 60 * 60 * 24;
        let now = new Date();

        let days = (now.getTime() - pastDay.getTime()) / (one_day_milliseconds);
        if (days > 1 || days === 1) {
            return true;
        } else {
            return false;
        }
    }


    static shuffle<T>(array: T[]) {
        let counter = array.length;

        while (counter > 0) {
            let index = Math.floor(Math.random() * counter);
            counter--;
            let temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }
        return array;
    }

    static setCookie(key:string, value:string, exdays:number = 1) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = key + "=" + value + ";" + expires + ";path=/;Secure;";
    }

    static getCookie(cname:string, defaultValue:any = null) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return defaultValue;
    }

    static deleteCookie(key:string) {
        HelperMethods.setCookie(key, "", -1);
    }
}

// EXTENDING DATE OBJECT

declare global {
    interface Date {
        plusDays(plusDays: number): Date;
    }
}


Date.prototype.plusDays = function (days: number): Date {
    let one_day_milliseconds = 1000 * 60 * 60 * 24;
    if (!days) return this;

    let dateInMilliseconds = this.getTime();
    let daysInMilliseconds = one_day_milliseconds * days;

    let date = new Date(dateInMilliseconds + daysInMilliseconds);
    return date;
};
