import {RequestMessage} from "../request_message";
import {CommonStatisticMessageData} from "../message_data/common_statistic_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class CommonStatisticRequestMessage extends RequestMessage {
    records: Array<CommonStatisticMessageData> = [];
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.CommonStatisticRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.records = deserializeArrayOfObject(jsonObject.records, CommonStatisticMessageData);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
