import {Component, OnInit, ViewChild} from '@angular/core';
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {RegisterComponent} from "./register.component";
import {Router} from "@angular/router";
import {RetailerMessageData} from "../../communication/message_data/retailer_message_data";
import {RetailersResponseMessage} from "../../communication/messages/retailers_response_message";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-retailers-list',
    templateUrl: '../../../templates/register/retailers_list.component.html'
})
export class RetailersListComponent implements OnInit {
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;
    public registerData: RegisterData;
    public app: App = App.instance;
    public term: string = "";


    constructor(private registerComponent: RegisterComponent, private router: Router, private modal: ModalService) {
        this.registerData = registerComponent.RegisterData;
    }

    ngOnInit() {
        if (this.registerData.retailersList.length > 0)
            return;

        this.spLoader.start();
        this.app.driver.getRetailers(this.registerData.Country.id)
            .onSuccess((rsp: RetailersResponseMessage) => {
                if (rsp.retailers) {
                    this.spLoader.stopAndHide();
                    this.registerData.retailersList = rsp.retailers;

                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                this.spLoader.stopAndHide();
            })
            .send();
    }

    Selected(record: RetailerMessageData) {
        this.registerData.Retailer = record;
        this.router.navigate(['register', 'retailer_employer'])
    }

}
