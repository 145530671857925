import {ResponseMessage} from "../response_message";
import {LanguageMessageData} from "../message_data/language_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class LanguagesResponseMessage extends ResponseMessage {
    languages: Array<LanguageMessageData>;

    constructor() {
        super()
        this.type = MessageTypes.LanguagesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.languages = deserializeArrayOfObject(jsonObject.languages, LanguageMessageData);
    }
}
