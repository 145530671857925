import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EmployeeCertificateMessageData} from "../../../communication/message_data/eployee_certificate_message_data";
import {App} from "../../../app";

@Component({
    selector: 'sp-previous-retailer-certificates-list',
    templateUrl: '../../../../templates/profile/retailer/previous_certificates_list.component.html'
})
export class PreviousCertificatesListComponent implements OnInit {
    public app: App = App.instance;
    public sub: any;

    private retailerId: number;
    certificates: Array<EmployeeCertificateMessageData>;

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.retailerId = +params['id']; // (+) converts string 'id' to a number
            this.loadData();
        });
    }

    onListItemSelected(cert: EmployeeCertificateMessageData) {
    }

    onBackButtonClick() {
        if (this.app.isRetailer) {
            this.router.navigate(['main', 'retailer_certificates', true]);
        } else {
            this.router.navigate(['main', 'brand_certificates']);
        }
    }


    private loadData() {
        this.certificates = this.app.data.getCertificatesByRetailer(this.retailerId);
    }
}

export class CertificatesWithRetailerName {
    retailerId: number;
    retailerName: string;
    certificates: Array<EmployeeCertificateMessageData> = [];

    constructor(retailerId: number, retailerName: string) {
        this.retailerId = retailerId;
        this.retailerName = retailerName;
    }
}
