import {HttpClient} from "@angular/common/http";
import {LoginRequestMessage} from "./messages/login_request_message";
import {ResponseMessage} from "./response_message";
import {RequestMessage} from "./request_message";
import {LoginResponseMessage} from "./messages/login_response_message";
import {CountriesResponseMessage} from "./messages/countries_response_message";
import {CountriesRequestMessage} from "./messages/countries_request_message";
import {LanguagesResponseMessage} from "./messages/languages_response_message";
import {LanguagesRequestMessage} from "./messages/languages_request_message";
import {RetailersResponseMessage} from "./messages/retailers_response_message";
import {RetailersRequestMessage} from "./messages/retailers_request_message";
import {StoresResponseMessage} from "./messages/stores_response_message";
import {StoresRequestMessage} from "./messages/stores_request_message";
import {JobCategoriesResponseMessage} from "./messages/job_categories_response_message";
import {JobCategoriesRequestMessage} from "./messages/job_categories_request_message";
import {RetailerCategoriesResponseMessage} from "./messages/retailer_categories_response_message";
import {RetailerCategoriesRequestMessage} from "./messages/retailer_categories_request_message";
import {BrandsRequestMessage} from "./messages/brands_request_message";
import {BrandsResponseMessage} from "./messages/brands_response_message";
import {StatesResponseMessage} from "./messages/states_response_message";
import {StatesRequestMessage} from "./messages/states_request_message";
import {CheckRegistrationEmailResponseMessage} from "./messages/check_registration_email_response_message";
import {CheckRegistrationEmailRequestMessage} from "./messages/check_registration_email_request_message";
import {CheckRegistrationUsernameResponseMessage} from "./messages/check_registration_username_response_message";
import {CheckRegistrationUsernameRequestMessage} from "./messages/check_registration_username_request_message";
import {EmployeeFeedbackMessageData} from "./message_data/employee_feedback_message_data";
import {EmployeeFeedbackResponseMessage} from "./messages/employee_feedback_response_message";
import {EmployeeFeedbackRequestMessage} from "./messages/employee_feedback_request_message";
import {ChangeEmailRequestMessage} from "./messages/change_email_request_message";
import {ChangeEmailResponseMessage} from "./messages/change_email_response_message";
import {ChangeEmailAfterActivationResponseMessage} from "./messages/change_email_after_activation_response_message";
import {ChangeEmailAfterActivationRequestMessage} from "./messages/change_email_after_activation_request_message";
import {ChangePasswordResponseMessage} from "./messages/change_password_response_message";
import {ChangePasswordRequestMessage} from "./messages/change_password_request_message";
import {ActivateAndLoginEmployeeResponseMessage} from "./messages/activate_and_login_employee_response_message";
import {ActivateAndLoginEmployeeRequestMessage} from "./messages/activate_and_login_employee_request_message";
import {CoursesAndRelatedResponseMessage} from "./messages/courses_and_related_response_message";
import {CoursesAndRelatedRequestMessage} from "./messages/courses_and_related_request_message";
import {EmployeePointsSyncRequestMessage} from "./messages/employee_points_sync_request_message";
import {ClaimAwardRequestMessage} from "./messages/claim_award_request_message";
import {ClaimAwardResponseMessage} from "./messages/claim_award_response_message";
import {ChangeEmployeeRetailerStoreCategoriesResponseMessage} from "./messages/change_employee_retailer_store_categories_response_message";
import {ChangeEmployeeRetailerStoreCategoriesRequestMessage} from "./messages/change_employee_retailer_store_categories_request_message";
import {ChangeEmployeePreferencesResponseMessage} from "./messages/change_employee_preferences_response_message";
import {ChangeEmployeePreferencesRequestMessage} from "./messages/change_employee_preferences_request_message";
import {RecentlyAwardedRequestMessage} from "./messages/recently_awarded_request_message";
import {RecentlyAwardedResponseMessage} from "./messages/recently_awarded_response_message";
import {GetContentChangesDatesRequestMessage} from "./messages/get_content_changes_dates_request_message";
import {GetContentChangesDatesResponseMessage} from "./messages/get_content_changes_dates_response_message";
import {GetContentStateChangesDatesResponseMessage} from "./messages/get_content_state_changes_dates_response_message";
import {GetContentStateChangesDatesRequestMessage} from "./messages/get_content_state_changes_dates_request_message";
import {CheckEmployeeCourseStatesResponseMessage} from "./messages/check_employee_course_states_response_message";
import {CheckEmployeeCourseStatesRequestMessage} from "./messages/check_employee_course_states_request_message";
import {CheckEmployeeTopicStatesResponseMessage} from "./messages/check_employee_topic_states_response_message";
import {CheckEmployeeTopicStatesRequestMessage} from "./messages/check_employee_topic_states_request_message";
import {CheckEmployeeAwardStatesResponseMessage} from "./messages/check_employee_award_states_response_message";
import {CheckEmployeeAwardStatesRequestMessage} from "./messages/check_employee_award_states_request_message";
import {StoreForBrandLeaderboardResponseMessage} from "./messages/store_for_brand_leaderboard_response_message";
import {StoreForBrandLeaderboardRequestMessage} from "./messages/store_for_brand_leaderboard_request_message";
import {ForgottenPasswordResponseMessage} from "./messages/forgotten_password_response_message";
import {ForgottenPasswordRequestMessage} from "./messages/forgotten_password_request_message";
import {ActionStatisticRequestMessage} from "./messages/action_statistic_request_message";
import {ContentStatistic} from "../data/content_statistic";
import {ContentStatisticRequestMessage} from "./messages/content_statistic_request_message";
import {CommonStatistic} from "../data/common_statistic";
import {CommonStatisticRequestMessage} from "./messages/common_statistic_request_message";
import {ActionStatistic} from "../data/action_statistic";
import {ActionStatisticResponseMessage} from "./messages/action_statistic_response_message";
import {ContentStatisticResponseMessage} from "./messages/content_statistic_response_message";
import {CommonStatisticResponseMessage} from "./messages/common_statistic_response_message";
import {SuccessHandler} from "./success_handler";
import {ErrorHandler} from "./error_handler";
import {EmployeeLeaderBoardByBrandRequestMessage} from "./messages/employee_leaderboard_by_brand_request_message";
import {EmployeeLeaderBoardRequestMessage} from "./messages/employee_leaderboard_request_message";
import {EmployeeLeaderBoardResponseMessage} from "./messages/employee_leaderboard_response_message";
import {EmployeeLeaderBoardByBrandResponseMessage} from "./messages/employee_leaderboard_by_brand_response_message";
import {RegisterBrandData} from "./message_data/register_brand_data";
import {RegisterBrandRequestMessage} from "./messages/register_brand_request_message";
import {RegisterData} from "../ui/register/register.data";
import {RegisterBrandResponseMessage} from "./messages/register_brand_response_message";
import {RegisterRetailerRequestMessage} from "./messages/register_retailer_request_message";
import {RegisterRetailerResponseMessage} from "./messages/register_retailer_response_message";
import {RegisterRetailerData} from "./message_data/register_retailer_data";
import {RegisterStoreMessageData} from "./message_data/register_store_message_data";
import {ChangeEmployeeJobCategoryRequestMessage} from "./messages/change_employee_job_category_request_message";
import {ChangeEmployeeJobCategoryResponseMessage} from "./messages/change_employee_job_category_response_message";
import {ICommonEmployeeData} from "../data/common_employee_data";
import {CountriesByBrandRequestMessage} from "./messages/countries_by_brand_request_message";
import {CountriesByBrandResponseMessage} from "./messages/countries_by_brand_response_message";
import {RetailersByBrandRequestMessage} from "./messages/retailer_by_brand_request_message";
import {RetailersByBrandResponseMessage} from "./messages/retailer_by_brand_response_message";
import {RetailerEventsRequestMessage} from "./messages/retailer_events_request_message";
import {RetailerEventsResponseMessage} from "./messages/retailer_events_response_message";
import {RetailerEventsFreeSearchRequestMessage} from "./messages/retailer_events_free_search_request_message";
import {RetailerEventsFreeSearchResponseMessage} from "./messages/retailer_events_free_search_response_message";
import {EmployeeRegisterForEventRequestMessage} from "./messages/employee_register_for_event_request_message";
import {EmployeeRegisterForEventResponseMessage} from "./messages/employee_register_for_event_response_message";
import {CertificateForCourseRequestMessage} from "./messages/certificate_for_course_request_message";
import {CertificateForCourseResponseMessage} from "./messages/certificate_for_course_response_message";
import {EmployeeQuizAnswerMessageData} from "./message_data/employee_quiz_answer_message_data";
import {EmployeeQuizAttemptMessageData} from "./message_data/employee_quiz_attempt_message_data";
import {EmployeeQuizAnswersResponseMessage} from "./messages/employee_quiz_answers_response_message";
import {EmployeeQuizAnswersRequestMessage} from "./messages/employee_quiz_answers_request_message";
import {EmployeeCheckInForEventRequestMessage} from "./messages/employee_check_in_for_event_request_message";
import {EmployeeCheckInForEventResponseMessage} from "./messages/employee_check_in_for_event_response_message";
import {EmployeePointsReasonMessageData} from "./message_data/employee_points_reason_message_data";
import {EmployeePointsSyncWithCheckRequestMessage} from "./messages/employee_points_sync_with_check_request_message";
import {EmployeePointsSyncResponseMessage} from "./messages/employee_points_sync_response_message";
import {EmployeePointsSyncWithCheckResponseMessage} from "./messages/employee_points_sync_with_check_response_message";
import {GetSurveysRequestMessage} from "./messages/get_surveys_request_message";
import {GetSurveysResponseMessage} from "./messages/get_surveys_response_message";
import {TryStartSurveyRequestMessage} from "./messages/try_start_survey_request_message";
import {TryStartSurveyResponseMessage} from "./messages/try_start_survey_response_message";
import {ReportSurveyResponseMessage} from "./messages/report_survey_response_message";
import {ReportSurveyRequestMessage} from "./messages/report_survey_request_message";
import {UiSurveyData} from "../data/ui_survey_data";
import {EmployeeSurveyAnswerMessageData} from "./message_data/employee_survey_answer_message_data";
import {GrsBalanceRequestMessage} from "./messages/grs_balance_request_message";
import {GrsBalanceResponseMessage} from "./messages/grs_balance_response_message";
import {UpdateTermsAndConditionsEmployeeIdRequestMessage} from "./messages/update_terms_and_conditions_employee_id_request_message";
import {UpdateTermsAndConditionsEmployeeIdResponseMessage} from "./messages/update_terms_and_conditions_employee_id_response_message";
import {App} from "../app";
import {GetTermsAndConditionsIdRequestMessage} from "./messages/get_terms_and_conditions_id_request_message";
import {GetTermsAndConditionsIdResponseMessage} from "./messages/get_terms_and_conditions_id_response_message";
import {isDevMode} from '@angular/core';
import {MessageTypes} from "./message_types";
import {EmployeeMessageData} from "./message_data/employee_message_data";
import {PreferencesData} from "../ui/settings/settings_preferences.component";
import {ReportAppCampaignTaskRequestMessage} from "./messages/report_app_campaign_task_request_message";
import {ReportAppCampaignTaskResponseMessage} from "./messages/report_app_campaign_task_response_message";
import {QueryGoogleLocationBySearchRequestMessage} from "./messages/query_google_location_by_search_request_message";
import {QueryGoogleLocationBySearchResponseMessage} from "./messages/query_google_location_by_search_response_message";
import {environment} from 'environments/environment';
import {QueryGooglePlacesByLatLongApiRequestMessage} from "./messages/query_google_places_by_lat_long_api_request_message";
import {QueryGooglePlacesByLatLongApiResponseMessage} from "./messages/query_google_places_by_lat_long_api_response_message";
import {AddStoraData} from "../data/add.store.data";
import {EmployeeLeaderBoard2RequestMessage} from "./messages/employee_leaderboard_2_request_message";
import {EmployeeLeaderBoard2ResponseMessage} from "./messages/employee_leaderboard_2_response_message";
import {EmployeeLeaderBoardByBrand2RequestMessage} from "./messages/employee_leaderboard_by_brand_2_request_message";
import {EmployeeLeaderBoardByBrand2ResponseMessage} from "./messages/employee_leaderboard_by_brand_2_response_message";
import {EmployeeEventAttendanceConfirmationRequestMessage} from "./messages/employee_event_attendance_confirmation_request_message";
import {EmployeeEventAttendanceConfirmationResponseMessage} from "./messages/employee_event_attendance_confirmation_response_message";

export class MessageRoutine<TS extends RequestMessage, RT extends ResponseMessage> {
    request: TS;
    responseConstructor: { new(): RT };
    driver: DriverWithMessages;

    os: SuccessHandler<RT>;
    oe: ErrorHandler<RT>;

    constructor(driver: DriverWithMessages, request: TS, responseConstructor: { new(): RT }) {
        this.driver = driver;
        this.request = request;
        this.responseConstructor = responseConstructor;
    }

    public onSuccess(os: SuccessHandler<RT>): MessageRoutine<TS, RT> {
        this.os = os;
        return this;
    }

    public onError(oe: ErrorHandler<RT>): MessageRoutine<TS, RT> {
        this.oe = oe;
        return this;
    }

    public send(): MessageRoutine<TS, RT> {
        this.driver.sendMessage(this.request, this.responseConstructor, this.os, this.oe);
        return this;
    }
}

export class DriverWithMessages {
    http: HttpClient;
    commonEmployeeData: ICommonEmployeeData;

    constructor(http: HttpClient, commonEmployeeData: ICommonEmployeeData) {
        this.http = http;
        this.commonEmployeeData = commonEmployeeData;
    }

    getCountries(demo: boolean): MessageRoutine<CountriesRequestMessage, CountriesResponseMessage> {
        let message: CountriesRequestMessage = new CountriesRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.demo = false;
        return new MessageRoutine<CountriesRequestMessage, CountriesResponseMessage>(this, message, CountriesResponseMessage);
    }

    getLanguages(countryId: number): MessageRoutine<LanguagesRequestMessage, LanguagesResponseMessage> {
        let message: LanguagesRequestMessage = new LanguagesRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.countryId = countryId;
        return new MessageRoutine<LanguagesRequestMessage, LanguagesResponseMessage>(this, message, LanguagesResponseMessage);
    }

    getRetailers(countryId: number): MessageRoutine<RetailersRequestMessage, RetailersResponseMessage> {
        let message: RetailersRequestMessage = new RetailersRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.countryId = countryId;

        return new MessageRoutine<RetailersRequestMessage, RetailersResponseMessage>(this, message, RetailersResponseMessage);
    }

    getStores(retailerId: number, longitude: number, latitude: number): MessageRoutine<StoresRequestMessage, StoresResponseMessage> {
        let message: StoresRequestMessage = new StoresRequestMessage();
        message.longitude = longitude;
        message.latitude = latitude;
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.retailerId = retailerId;

        return new MessageRoutine<StoresRequestMessage, StoresResponseMessage>(this, message, StoresResponseMessage);
    }

    getJobCategories(userType: number, employeeId: number, preferredLang: number): MessageRoutine<JobCategoriesRequestMessage, JobCategoriesResponseMessage> {
        let message: JobCategoriesRequestMessage = new JobCategoriesRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = preferredLang;
        message.userType = userType;

        return new MessageRoutine<JobCategoriesRequestMessage, JobCategoriesResponseMessage>(this, message, JobCategoriesResponseMessage);
    }

    getRetailersCategories(retailerId: number, employeeId: number, preferredLang: number): MessageRoutine<RetailerCategoriesRequestMessage, RetailerCategoriesResponseMessage> {
        let message: RetailerCategoriesRequestMessage = new RetailerCategoriesRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = preferredLang;
        message.retailerId = retailerId;

        return new MessageRoutine<RetailerCategoriesRequestMessage, RetailerCategoriesResponseMessage>(this, message, RetailerCategoriesResponseMessage);
    }

    getBrands(): MessageRoutine<BrandsRequestMessage, BrandsResponseMessage> {
        let message: BrandsRequestMessage = new BrandsRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        return new MessageRoutine<BrandsRequestMessage, BrandsResponseMessage>(this, message, BrandsResponseMessage);
    }

    getStates(countryId: number): MessageRoutine<StatesRequestMessage, StatesResponseMessage> {
        let message: StatesRequestMessage = new StatesRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.countryId = countryId;

        return new MessageRoutine<StatesRequestMessage, StatesResponseMessage>(this, message, StatesResponseMessage);
    }

    checkRegistrationEmail(email: string): MessageRoutine<CheckRegistrationEmailRequestMessage, CheckRegistrationEmailResponseMessage> {
        let message: CheckRegistrationEmailRequestMessage = new CheckRegistrationEmailRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.email = email;

        return new MessageRoutine<CheckRegistrationEmailRequestMessage, CheckRegistrationEmailResponseMessage>(this, message, CheckRegistrationEmailResponseMessage);
    }

    checkRegistrationScreenName(name: string, employerType: number): MessageRoutine<CheckRegistrationUsernameRequestMessage, CheckRegistrationUsernameResponseMessage> {
        let message: CheckRegistrationUsernameRequestMessage = new CheckRegistrationUsernameRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.userName = name;
        message.userType = employerType;

        return new MessageRoutine<CheckRegistrationUsernameRequestMessage, CheckRegistrationUsernameResponseMessage>(this, message, CheckRegistrationUsernameResponseMessage);
    }

    submitFeedback(employeeId: number, appVersion: string, deviceUUID: string, messageData: EmployeeFeedbackMessageData): MessageRoutine<EmployeeFeedbackRequestMessage, EmployeeFeedbackResponseMessage> {
        let message: EmployeeFeedbackRequestMessage = new EmployeeFeedbackRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.appVersion = appVersion;
        message.deviceUUID = deviceUUID;

        message.data = messageData;

        return new MessageRoutine<EmployeeFeedbackRequestMessage, EmployeeFeedbackResponseMessage>(this, message, EmployeeFeedbackResponseMessage);
    }

    registerRetailerByUserInfo(userInfo: RegisterData): MessageRoutine<RegisterRetailerRequestMessage, RegisterRetailerResponseMessage> {
        let data: RegisterRetailerData = new RegisterRetailerData();
        data.userType = userInfo.UserType;
        data.countryId = userInfo.Country.id;
        data.languageId = userInfo.Language.id;
        data.retailerId = userInfo.Retailer.id;
        data.stateId = userInfo.State.id;
        data.termsAndConditionsId = userInfo.TermsAndConditionsId;
        if (userInfo.Store != null && userInfo.Store.id > 0) {
            data.storeId = userInfo.Store.id;
        } else {
            let storeData: RegisterStoreMessageData = new RegisterStoreMessageData();
            if (userInfo.CreatedStore != null) {
                if (userInfo.CreatedStore.GooglePlaceId != null) {
                    storeData.storeNumber = userInfo.CreatedStore.StoreNumber;
                    storeData.address = userInfo.CreatedStore.Address;
                    storeData.googlePlaceId = userInfo.CreatedStore.GooglePlaceId;
                    storeData.lat = userInfo.CreatedStore.Lat;
                    storeData.lng = userInfo.CreatedStore.Lng;
                } else {
                    storeData.storeNumber = userInfo.CreatedStore.StoreNumber;
                    storeData.address = userInfo.CreatedStore.Address;
                    storeData.city = userInfo.CreatedStore.City;
                    if (userInfo.CreatedStore.State && userInfo.CreatedStore.State.id != null) {
                        storeData.stateId = userInfo.CreatedStore.State.id;
                    }
                    storeData.zip = userInfo.CreatedStore.Zip;
                    storeData.description = userInfo.CreatedStore.Description;
                }
                storeData.retailerId = userInfo.Retailer.id;
                data.store = storeData;
            }
        }
        data.firstName = userInfo.FirstName;
        data.lastName = userInfo.LastName;
        data.password = userInfo.Password;
        data.sellProEmailNotifications = userInfo.SellProEmailNotifications;
        data.miscEmailNotifications = userInfo.MiscEmailNotifications;
        data.fullSyncOnCellularData = true;
        data.jobCategory = userInfo.JobCategory.id;
        data.email = userInfo.Email;
        data.userName = null;
        data.zipCode = userInfo.Zip;
        data.demo = false;
        let message: RegisterRetailerRequestMessage = new RegisterRetailerRequestMessage();
        message.preferredLang = data.languageId;
        message.data = data;

        return new MessageRoutine<RegisterRetailerRequestMessage, RegisterRetailerResponseMessage>(this, message, RegisterRetailerResponseMessage);
    }

    changeEmail(employeeId: number, newEmail: string, oldEmal: string, sendConfirmEmail: boolean): MessageRoutine<ChangeEmailRequestMessage, ChangeEmailResponseMessage> {
        let message: ChangeEmailRequestMessage = new ChangeEmailRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.newEmail = newEmail;
        message.oldEmail = oldEmal;
        message.sentEmail = sendConfirmEmail;

        return new MessageRoutine<ChangeEmailRequestMessage, ChangeEmailResponseMessage>(this, message, ChangeEmailResponseMessage);
    }

    changeEmailAfterActivation(employeeId: number, newEmail: string, oldEmal: string, sendConfirmEmail: boolean): MessageRoutine<ChangeEmailAfterActivationRequestMessage, ChangeEmailAfterActivationResponseMessage> {
        let message: ChangeEmailAfterActivationRequestMessage = new ChangeEmailAfterActivationRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.newEmail = newEmail;
        message.oldEmail = oldEmal;
        message.sentEmail = sendConfirmEmail;

        return new MessageRoutine<ChangeEmailAfterActivationRequestMessage, ChangeEmailAfterActivationResponseMessage>(this, message, ChangeEmailAfterActivationResponseMessage);
    }

    changePassword(employeeId: number, newPassword: string, oldPassword: string): MessageRoutine<ChangePasswordRequestMessage, ChangePasswordResponseMessage> {
        let message: ChangePasswordRequestMessage = new ChangePasswordRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.oldPassword = oldPassword;
        message.password = newPassword;

        return new MessageRoutine<ChangePasswordRequestMessage, ChangePasswordResponseMessage>(this, message, ChangePasswordResponseMessage);
    }

    login(email: string, password: string): MessageRoutine<LoginRequestMessage, LoginResponseMessage> {
        let message: LoginRequestMessage = new LoginRequestMessage();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.email = email;
        message.password = password;

        return new MessageRoutine<LoginRequestMessage, LoginResponseMessage>(this, message, LoginResponseMessage);
    }

    actvateAndlogin(employeeId: number, confirmCode: string): MessageRoutine<ActivateAndLoginEmployeeRequestMessage, ActivateAndLoginEmployeeResponseMessage> {
        let message: ActivateAndLoginEmployeeRequestMessage = new ActivateAndLoginEmployeeRequestMessage();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.userId = employeeId;
        message.activationCode = confirmCode;

        return new MessageRoutine<ActivateAndLoginEmployeeRequestMessage, ActivateAndLoginEmployeeResponseMessage>(this, message, ActivateAndLoginEmployeeResponseMessage);
    }


    getCoursesAndRelated(employeeId: number): MessageRoutine<CoursesAndRelatedRequestMessage, CoursesAndRelatedResponseMessage> {
        let message: CoursesAndRelatedRequestMessage = new CoursesAndRelatedRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        return new MessageRoutine<CoursesAndRelatedRequestMessage, CoursesAndRelatedResponseMessage>(this, message, CoursesAndRelatedResponseMessage);
    }


    getRetailerEvents(employeeId: number, storeId: number, retailerId: number): MessageRoutine<RetailerEventsRequestMessage, RetailerEventsResponseMessage> {
        let message: RetailerEventsRequestMessage = new RetailerEventsRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.retailerId = retailerId;
        message.storeId = storeId;
        return new MessageRoutine<RetailerEventsRequestMessage, RetailerEventsResponseMessage>(this, message, RetailerEventsResponseMessage);
    }

    getRetailerFreeSearchEvents(employeeId: number, retailerId: number, zipCode: string, radius: number, eventCode: string, storeId: number): MessageRoutine<RetailerEventsFreeSearchRequestMessage, RetailerEventsFreeSearchResponseMessage> {
        let message = new RetailerEventsFreeSearchRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.retailerId = retailerId;
        message.zipCode = zipCode;
        message.radius = radius;
        message.eventCode = eventCode;
        message.storeId = storeId;

        return new MessageRoutine<RetailerEventsFreeSearchRequestMessage, RetailerEventsFreeSearchResponseMessage>(this, message, RetailerEventsFreeSearchResponseMessage);
    }

    registerEmployeeForEvent(employeeId: number, eventId: number, employeeEventInvitationId: number): MessageRoutine<EmployeeRegisterForEventRequestMessage, EmployeeRegisterForEventResponseMessage> {
        let message = new EmployeeRegisterForEventRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.eventId = eventId;
        message.employeeEventInvitationId = employeeEventInvitationId ? employeeEventInvitationId : 0;


        return new MessageRoutine<EmployeeRegisterForEventRequestMessage, EmployeeRegisterForEventResponseMessage>(this, message, EmployeeRegisterForEventResponseMessage);
    }

    syncEmployeePoints(employeeId: number, server: number, local: number): MessageRoutine<EmployeePointsSyncRequestMessage, EmployeePointsSyncResponseMessage> {
        let message: EmployeePointsSyncRequestMessage = new EmployeePointsSyncRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.server = server;
        message.local = local;

        return new MessageRoutine<EmployeePointsSyncRequestMessage, EmployeePointsSyncResponseMessage>(this, message, EmployeePointsSyncResponseMessage);
    }

    syncEmployeePointsWithCheck(employeeId: number, server: number, local: number, pointsReason: Array<EmployeePointsReasonMessageData>): MessageRoutine<EmployeePointsSyncWithCheckRequestMessage, EmployeePointsSyncWithCheckResponseMessage> {
        let message: EmployeePointsSyncWithCheckRequestMessage = new EmployeePointsSyncWithCheckRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.server = server;
        message.local = local;
        message.pointsReason = pointsReason;

        return new MessageRoutine<EmployeePointsSyncWithCheckRequestMessage, EmployeePointsSyncWithCheckResponseMessage>(this, message, EmployeePointsSyncWithCheckResponseMessage);
    }

    claimAward(employeeId: number, awardId: number, email: string): MessageRoutine<ClaimAwardRequestMessage, ClaimAwardResponseMessage> {
        let message: ClaimAwardRequestMessage = new ClaimAwardRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.awardId = awardId;
        message.email = email;

        return new MessageRoutine<ClaimAwardRequestMessage, ClaimAwardResponseMessage>(this, message, ClaimAwardResponseMessage);
    }

    changeEmployeeRetailerStoreCategories(employeeId: number, languageId: number, retailerId: number, storeId: number, store: AddStoraData): MessageRoutine<ChangeEmployeeRetailerStoreCategoriesRequestMessage, ChangeEmployeeRetailerStoreCategoriesResponseMessage> {
        let message: ChangeEmployeeRetailerStoreCategoriesRequestMessage = new ChangeEmployeeRetailerStoreCategoriesRequestMessage();
        message.employeeId = employeeId;
        if ((languageId != null) && (languageId > 0)) {
            message.changeLanguage = true;
            message.languageId = languageId;
        } else

            message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        if ((retailerId != null) && (retailerId > 0)) {
            message.changeRetailer = true;
            message.retailerId = retailerId;
        }

        if ((storeId != null && storeId > 0) || store != null) {
            message.changeStoreId = true;
            if (storeId != null && storeId > 0) {
                message.storeId = storeId;
            } else {
                let storeData: RegisterStoreMessageData = new RegisterStoreMessageData();
                storeData.retailerId = store.RetailerId;
                if (store.GooglePlaceId != null) {
                    storeData.storeNumber = store.StoreNumber;
                    storeData.address = store.Address;
                    storeData.googlePlaceId = store.GooglePlaceId;
                    storeData.lat = store.Lat;
                    storeData.lng = store.Lng;
                } else {
                    storeData.storeNumber = store.StoreNumber;
                    storeData.address = store.Address;
                    storeData.city = store.City;
                    if (store.State && store.State.id != null) {
                        storeData.stateId = store.State.id;
                    }
                    storeData.zip = store.Zip;
                    storeData.description = store.Description;
                }
                message.store = storeData;
            }
        }

        return new MessageRoutine<ChangeEmployeeRetailerStoreCategoriesRequestMessage, ChangeEmployeeRetailerStoreCategoriesResponseMessage>(this, message, ChangeEmployeeRetailerStoreCategoriesResponseMessage);
    }

    changeEmployeePreferences(employee: EmployeeMessageData, data: PreferencesData): MessageRoutine<ChangeEmployeePreferencesRequestMessage, ChangeEmployeePreferencesResponseMessage> {
        let message: ChangeEmployeePreferencesRequestMessage = new ChangeEmployeePreferencesRequestMessage();
        message.employeeId = employee.id;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.sellProEmails = data.eventNotificationsEmails;
        message.miscMarketingEmails = data.miscMarketingEmails;
        message.fullSyncOnCellularData = employee.fullSyncOnCellularData;
        message.nfContent = data.nfContent;
        message.nfAwards = data.nfAwards;
        message.nfEvents = data.nfEvents;
        message.nfContests = data.nfContests;
        message.nfTipsAndTricks = data.nfTipsAndTricks;
        message.nfLastUpdate = data.nfLastUpdate;

        return new MessageRoutine<ChangeEmployeePreferencesRequestMessage, ChangeEmployeePreferencesResponseMessage>(this, message, ChangeEmployeePreferencesResponseMessage);
    }

    employeeFeedback(employeeId: number, deviceUUID: string, appVersion: string, syncingImprove: boolean, playzoneImprove: boolean, quzzesImprove: boolean, coursesImprove: boolean, crashingImprove: boolean, awardsImprove: boolean, printingImprove: boolean, memoryUsageImprove: boolean, comment: string): MessageRoutine<EmployeeFeedbackRequestMessage, EmployeeFeedbackResponseMessage> {
        let message: EmployeeFeedbackRequestMessage = new EmployeeFeedbackRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.deviceUUID = deviceUUID;
        message.appVersion = appVersion;

        let data: EmployeeFeedbackMessageData = new EmployeeFeedbackMessageData();
        data.syncingImprove = syncingImprove;
        data.playzoneImprove = playzoneImprove;
        data.quizzesImprove = quzzesImprove;
        data.coursesImprove = coursesImprove;
        data.crashingImprove = crashingImprove;
        data.awardsImprove = awardsImprove;
        data.printingImprove = printingImprove;
        data.memoryUsageImprove = memoryUsageImprove;
        data.comment = comment;
        message.data = data;

        return new MessageRoutine<EmployeeFeedbackRequestMessage, EmployeeFeedbackResponseMessage>(this, message, EmployeeFeedbackResponseMessage);
    }

    getRecentlyAwarded(employeeId: number, count: number): MessageRoutine<RecentlyAwardedRequestMessage, RecentlyAwardedResponseMessage> {
        let message: RecentlyAwardedRequestMessage = new RecentlyAwardedRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.count = count;

        return new MessageRoutine<RecentlyAwardedRequestMessage, RecentlyAwardedResponseMessage>(this, message, RecentlyAwardedResponseMessage);
    }

    getEmployeeLeaderBoard(employee: EmployeeMessageData, count: number, requestType: number): MessageRoutine<EmployeeLeaderBoard2RequestMessage, EmployeeLeaderBoard2ResponseMessage> {
        let message: EmployeeLeaderBoard2RequestMessage = new EmployeeLeaderBoard2RequestMessage();
        message.employeeId = employee.id;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.count = count;
        message.filterType = requestType;
        message.countryId = employee.countryId;
        message.retailerId = employee.retailerId;
        message.storeId = employee.storeId;

        return new MessageRoutine<EmployeeLeaderBoard2RequestMessage, EmployeeLeaderBoard2ResponseMessage>(this, message, EmployeeLeaderBoard2ResponseMessage);
    }

    getBrandLeaderboard(employeeId: number,employeeBrandId: number,filterType: number, filterSubType: number, retailerId: number, storeId:number, countryId:number, count: number): MessageRoutine<EmployeeLeaderBoardByBrand2RequestMessage, EmployeeLeaderBoardByBrand2ResponseMessage> {
        let message: EmployeeLeaderBoardByBrand2RequestMessage = new EmployeeLeaderBoardByBrand2RequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.filterType = filterType;
        message.filterSubType = filterSubType;
        message.brandId = employeeBrandId;
        message.retailerId = retailerId;
        message.storeId = storeId;
        message.countryId = countryId;
        message.count = count;
        return new MessageRoutine<EmployeeLeaderBoardByBrand2RequestMessage, EmployeeLeaderBoardByBrand2ResponseMessage>(this, message, EmployeeLeaderBoardByBrand2ResponseMessage);
    }


    certificateForCourse(employeeId: number, courseId: number, recertification: boolean, guid: string, datetime: Date, points: number): MessageRoutine<CertificateForCourseRequestMessage, CertificateForCourseResponseMessage> {
        let message: CertificateForCourseRequestMessage = new CertificateForCourseRequestMessage();
        message.employeeId = employeeId;
        message.courseId = courseId;
        message.recertification = recertification;
        message.guid = guid;
        message.dateTime = datetime;
        message.points = points;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        return new MessageRoutine(this, message, CertificateForCourseResponseMessage);
    }

    //
    //  actionStatistic(records:Array<ActionStatistic>) : MessageRoutine<, >{
    //     let message:ActionStatisticRequestMessage = new ActionStatisticRequestMessage();
    //     message.records = ActionStatistic.toMessageData(records);
    //
    //     this.send(message, messageReceiver);
    // }
    //
    //  contentStatistic(records:Array<ContentStatistic>) : MessageRoutine<, >{
    //     let message:ContentStatisticRequestMessage = new ContentStatisticRequestMessage();
    //     message.records = ContentStatistic.toMessageData(records);
    //
    //     this.send(message, messageReceiver);
    // }
    //
    //  commonStatistic(records:Array<CommonStatistic>) : MessageRoutine<, >{
    //     let message:CommonStatisticRequestMessage = new CommonStatisticRequestMessage();
    //     message.records = CommonStatistic.toMessageData(records);
    //
    //     this.send(message, messageReceiver);
    // }

    getContentChangesDates(employeeId: number, deviceId: string): MessageRoutine<GetContentChangesDatesRequestMessage, GetContentChangesDatesResponseMessage> {
        let message: GetContentChangesDatesRequestMessage = new GetContentChangesDatesRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.deviceId = deviceId;

        return new MessageRoutine<GetContentChangesDatesRequestMessage, GetContentChangesDatesResponseMessage>(this, message, GetContentChangesDatesResponseMessage);
    }

    getContentStateChangesDates(employeeId: number, deviceId: string): MessageRoutine<GetContentStateChangesDatesRequestMessage, GetContentStateChangesDatesResponseMessage> {
        let message: GetContentStateChangesDatesRequestMessage = new GetContentStateChangesDatesRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.deviceId = deviceId;

        return new MessageRoutine<GetContentStateChangesDatesRequestMessage, GetContentStateChangesDatesResponseMessage>(this, message, GetContentStateChangesDatesResponseMessage);
    }


    checkEmployeeCourseStates(employeeId: number, courseIds: number[], afterDate: Date): MessageRoutine<CheckEmployeeCourseStatesRequestMessage, CheckEmployeeCourseStatesResponseMessage> {
        let message: CheckEmployeeCourseStatesRequestMessage = new CheckEmployeeCourseStatesRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.ids = courseIds;
        message.afterDate = afterDate;

        return new MessageRoutine<CheckEmployeeCourseStatesRequestMessage, CheckEmployeeCourseStatesResponseMessage>(this, message, CheckEmployeeCourseStatesResponseMessage);
    }

    checkEmployeeTopicStates(employeeId: number, topicIds: number[], afterDate: Date): MessageRoutine<CheckEmployeeTopicStatesRequestMessage, CheckEmployeeTopicStatesResponseMessage> {
        let message: CheckEmployeeTopicStatesRequestMessage = new CheckEmployeeTopicStatesRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.ids = topicIds;
        message.afterDate = afterDate;

        return new MessageRoutine<CheckEmployeeTopicStatesRequestMessage, CheckEmployeeTopicStatesResponseMessage>(this, message, CheckEmployeeTopicStatesResponseMessage);
    }

    checkEmployeeAwardStates(employeeId: number, awardIds: number[], afterDate: Date): MessageRoutine<CheckEmployeeAwardStatesRequestMessage, CheckEmployeeAwardStatesResponseMessage> {
        let message: CheckEmployeeAwardStatesRequestMessage = new CheckEmployeeAwardStatesRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.ids = awardIds;
        message.afterDate = afterDate;

        return new MessageRoutine<CheckEmployeeAwardStatesRequestMessage, CheckEmployeeAwardStatesResponseMessage>(this, message, CheckEmployeeAwardStatesResponseMessage);
    }

    storesForBrandLeaderboard(brandId: number, countryId: number, searchQuery: string, radius: number): MessageRoutine<StoreForBrandLeaderboardRequestMessage, StoreForBrandLeaderboardResponseMessage> {
        let message: StoreForBrandLeaderboardRequestMessage = new StoreForBrandLeaderboardRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.radius = radius;
        message.searchQuery = searchQuery;
        message.brandId = brandId;
        message.countryId = countryId;

        return new MessageRoutine<StoreForBrandLeaderboardRequestMessage, StoreForBrandLeaderboardResponseMessage>(this, message, StoreForBrandLeaderboardResponseMessage);
    }

    getCountriesByBrand(brandId: number): MessageRoutine<CountriesByBrandRequestMessage, CountriesByBrandResponseMessage> {
        let message: CountriesByBrandRequestMessage = new CountriesByBrandRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.brandId = brandId;

        return new MessageRoutine<CountriesByBrandRequestMessage, CountriesByBrandResponseMessage>(this, message, CountriesByBrandResponseMessage);
    }

    getRetailersByBrand(brandId: number, countryId: number): MessageRoutine<RetailersByBrandRequestMessage, RetailersByBrandResponseMessage> {
        let message: RetailersByBrandRequestMessage = new RetailersByBrandRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.brandId = brandId;
        message.countryId = countryId;

        return new MessageRoutine<RetailersByBrandRequestMessage, RetailersByBrandResponseMessage>(this, message, RetailersByBrandResponseMessage);
    }

    changeEmployeeJobCategory(employeeId: number, jobCategoryId: number): MessageRoutine<ChangeEmployeeJobCategoryRequestMessage, ChangeEmployeeJobCategoryResponseMessage> {
        let message: ChangeEmployeeJobCategoryRequestMessage = new ChangeEmployeeJobCategoryRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.jobCategoryId = jobCategoryId;

        return new MessageRoutine<ChangeEmployeeJobCategoryRequestMessage, ChangeEmployeeJobCategoryResponseMessage>(this, message, ChangeEmployeeJobCategoryResponseMessage);
    }

    forgottenPassword(email: string): MessageRoutine<ForgottenPasswordRequestMessage, ForgottenPasswordResponseMessage> {
        let message: ForgottenPasswordRequestMessage = new ForgottenPasswordRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.email = email;

        return new MessageRoutine<ForgottenPasswordRequestMessage, ForgottenPasswordResponseMessage>(this, message, ForgottenPasswordResponseMessage);
    }

    actionStatistic(records: Array<ActionStatistic>): MessageRoutine<ActionStatisticRequestMessage, ActionStatisticResponseMessage> {
        let message: ActionStatisticRequestMessage = new ActionStatisticRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.records = ActionStatistic.toMessageData(records);
        return new MessageRoutine<ActionStatisticRequestMessage, ActionStatisticResponseMessage>(this, message, ActionStatisticResponseMessage);
    }

    contentStatistic(records: Array<ContentStatistic>): MessageRoutine<ContentStatisticRequestMessage, ContentStatisticResponseMessage> {
        let message: ContentStatisticRequestMessage = new ContentStatisticRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.records = ContentStatistic.toMessageData(records);

        return new MessageRoutine<ContentStatisticRequestMessage, ContentStatisticResponseMessage>(this, message, ContentStatisticResponseMessage);
    }

    commonStatistic(records: Array<CommonStatistic>): MessageRoutine<CommonStatisticRequestMessage, CommonStatisticResponseMessage> {
        let message: CommonStatisticRequestMessage = new CommonStatisticRequestMessage();
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.records = CommonStatistic.toMessageData(records);

        return new MessageRoutine<CommonStatisticRequestMessage, CommonStatisticResponseMessage>(this, message, CommonStatisticResponseMessage);
    }

    reportQuizAnswers(attempts: Array<EmployeeQuizAttemptMessageData>, answers: Array<EmployeeQuizAnswerMessageData>): MessageRoutine<EmployeeQuizAnswersRequestMessage, EmployeeQuizAnswersResponseMessage> {
        let message: EmployeeQuizAnswersRequestMessage = new EmployeeQuizAnswersRequestMessage();
        message.attempts = attempts;
        message.answers = answers;
        message.employeeId = this.commonEmployeeData.getId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();

        return new MessageRoutine<EmployeeQuizAnswersRequestMessage, EmployeeQuizAnswersResponseMessage>(this, message, EmployeeQuizAnswersResponseMessage);
    }

    EmployeeCheckInForEvent(employeeId: number, eventInvitationId: number): MessageRoutine<EmployeeCheckInForEventRequestMessage, EmployeeCheckInForEventResponseMessage> {
        let message: EmployeeCheckInForEventRequestMessage = new EmployeeCheckInForEventRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.eventInvitationId = eventInvitationId;

        return new MessageRoutine<EmployeeCheckInForEventRequestMessage, EmployeeCheckInForEventResponseMessage>(this, message, EmployeeCheckInForEventResponseMessage);
    }

    EmployeeEventAttendanceConfirmation(employeeId: number, eventInvitationId: number, attendanceCode: string):MessageRoutine<EmployeeEventAttendanceConfirmationRequestMessage, EmployeeEventAttendanceConfirmationResponseMessage>{
        let message: EmployeeEventAttendanceConfirmationRequestMessage = new EmployeeEventAttendanceConfirmationRequestMessage();
        message.employeeId = employeeId;
        message.eventInvitationId = eventInvitationId;
        message.attendanceCode = attendanceCode;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        return new MessageRoutine<EmployeeEventAttendanceConfirmationRequestMessage, EmployeeEventAttendanceConfirmationResponseMessage>(this, message, EmployeeEventAttendanceConfirmationResponseMessage);
    }

    getSurveys(employeeId: number): MessageRoutine<GetSurveysRequestMessage, GetSurveysResponseMessage> {
        let message: GetSurveysRequestMessage = new GetSurveysRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();

        return new MessageRoutine<GetSurveysRequestMessage, GetSurveysResponseMessage>(this, message, GetSurveysResponseMessage);
    }

    tryStartSurvey(employeeId: number, surveyId: number, deviceUUID: string): MessageRoutine<TryStartSurveyRequestMessage, TryStartSurveyResponseMessage> {
        let message: TryStartSurveyRequestMessage = new TryStartSurveyRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.deviceUUID = deviceUUID;
        message.surveyId = surveyId;

        return new MessageRoutine<TryStartSurveyRequestMessage, TryStartSurveyResponseMessage>(this, message, TryStartSurveyResponseMessage);
    }

    tryFinishReportSurvey(employeeId: number, surveyData: UiSurveyData, surveyEmployeeAnswers: Array<EmployeeSurveyAnswerMessageData>): MessageRoutine<ReportSurveyRequestMessage, ReportSurveyResponseMessage> {
        let message: ReportSurveyRequestMessage = new ReportSurveyRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.surveyId = surveyData.surveyId;
        message.employeeSurveyId = surveyData.employeeSurveyId;
        message.answers = surveyEmployeeAnswers;

        return new MessageRoutine<ReportSurveyRequestMessage, ReportSurveyResponseMessage>(this, message, ReportSurveyResponseMessage);
    }

    getGrsBalance(employeeId: number): MessageRoutine<GrsBalanceRequestMessage, GrsBalanceResponseMessage> {
        let message: GrsBalanceRequestMessage = new GrsBalanceRequestMessage();
        message.employeeId = employeeId;
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        return new MessageRoutine<GrsBalanceRequestMessage, GrsBalanceResponseMessage>(this, message, GrsBalanceResponseMessage);
    }

    updateTermsAndConditionsEmployeeId(termsAndConditionsId: number): MessageRoutine<UpdateTermsAndConditionsEmployeeIdRequestMessage, UpdateTermsAndConditionsEmployeeIdResponseMessage> {
        let message: UpdateTermsAndConditionsEmployeeIdRequestMessage = new UpdateTermsAndConditionsEmployeeIdRequestMessage();
        message.employeeId = App.instance.getEmployeeId();
        message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
        message.termsAndConditionsId = termsAndConditionsId;
        return new MessageRoutine<UpdateTermsAndConditionsEmployeeIdRequestMessage, UpdateTermsAndConditionsEmployeeIdResponseMessage>(this, message, UpdateTermsAndConditionsEmployeeIdResponseMessage);
    }

    getTermsAndConditionsId(registerData?: RegisterData): MessageRoutine<GetTermsAndConditionsIdRequestMessage, GetTermsAndConditionsIdResponseMessage> {
        let message: GetTermsAndConditionsIdRequestMessage = new GetTermsAndConditionsIdRequestMessage();
        if (registerData != null) {
            message.preferredLang = registerData.Language.id;
            message.brandId = registerData.Brand.id;
            message.retailerId = registerData.Retailer.id;
            message.countryId = registerData.Country.id;
        } else {
            message.employeeId = App.instance.getEmployeeId();
            message.preferredLang = this.commonEmployeeData.getEmployeeLanguage();
            message.brandId = App.instance.getEmployee().brandId;
            message.retailerId = App.instance.getEmployee().retailerId;
            message.countryId = App.instance.getEmployee().countryId;
        }
        return new MessageRoutine<GetTermsAndConditionsIdRequestMessage, GetTermsAndConditionsIdResponseMessage>(this, message, GetTermsAndConditionsIdResponseMessage);
    }


    markAppCampaignTask(message: ReportAppCampaignTaskRequestMessage): MessageRoutine<ReportAppCampaignTaskRequestMessage, ReportAppCampaignTaskResponseMessage> {
        return new MessageRoutine<ReportAppCampaignTaskRequestMessage, ReportAppCampaignTaskResponseMessage>(this, message, ReportAppCampaignTaskResponseMessage);
    }

    getGoogleLocationBySearchQuery(searchQuery: string, countryId: number, stateId: number, preferredLang: number): MessageRoutine<QueryGoogleLocationBySearchRequestMessage, QueryGoogleLocationBySearchResponseMessage> {
        let message: QueryGoogleLocationBySearchRequestMessage = new QueryGoogleLocationBySearchRequestMessage();
        message.searchQuery = searchQuery.toUpperCase();
        message.countryId = countryId;
        message.stateId = stateId;
        message.preferredLang = preferredLang;
        return new MessageRoutine<QueryGoogleLocationBySearchRequestMessage, QueryGoogleLocationBySearchResponseMessage>(this, message, QueryGoogleLocationBySearchResponseMessage);
    }

    getGooglePlacesByCordinatesAndRetailerName(retailerName: string, latitude: number, longitude: number, preferredLang: number): MessageRoutine<QueryGooglePlacesByLatLongApiRequestMessage, QueryGooglePlacesByLatLongApiResponseMessage> {
        let message: QueryGooglePlacesByLatLongApiRequestMessage = new QueryGooglePlacesByLatLongApiRequestMessage();
        message.retailerName = retailerName;
        message.latitude = latitude;
        message.longitude = longitude;
        message.preferredLang = preferredLang;
        return new MessageRoutine<QueryGooglePlacesByLatLongApiRequestMessage, QueryGooglePlacesByLatLongApiResponseMessage>(this, message, QueryGooglePlacesByLatLongApiResponseMessage);
    }

    sendMessage<T extends ResponseMessage>(requestMessage: RequestMessage, responseConstructor: { new(): T }, sh: SuccessHandler<T>, eh: ErrorHandler<T>): void {
        let jsonString = JSON.stringify(requestMessage);
        this.http.post("/messages", jsonString)
            .subscribe(
                data => {
                    let response = new responseConstructor();
                    response.deserialize(data);
                    if (isDevMode()) {
                        console.log("message received: " + MessageTypes[response.type]);
                    }
                    if (response.error) {
                        if (eh)
                            eh(null, response.error.code, response.error.message, response);

                        return;
                    }

                    if (sh)
                        sh(response);
                },
                error => {
                    if (eh)
                        eh(error);
                });

    }
}
