import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../../app";
import {MenuComponent, MenuItemName} from "../../common/menu";
import {EmployeeType} from "../../../data/employee_type";

@Component({
    selector: 'sp-brand-home',
    templateUrl: '../../../../templates/home/brand/home_brand.component.html'
})
export class BrandComponent implements OnInit {

    public app: App = App.instance;
    @Input() countTools: number;
    @Input() countProgress: number;
    @Input() image: string;
    @Input() fullName: string;
    @Input() roleString: string;
    @Input() certificates: string;
    empPoints: string;

    withoutStoreFront: boolean;


    constructor(private router: Router) {
    }

    ngOnInit(): any {
        this.countTools = this.app.data.getCountNewTools();
        this.countProgress = this.app.data.getCountInProgressCourses();
        this.image = this.app.data.employee.brand.landscapeImageFileDescriptor;
        this.fullName = this.app.data.employee.firstName + " " + this.app.data.employee.lastName;
        switch (this.app.data.employee.role) {
            case this.app.data.employee.role = EmployeeType.brandAdmin :
                this.roleString = "Brand admin";
                break;
            case this.app.data.employee.role = EmployeeType.superAdmin :
                this.roleString = "Super admin";
                break;
            case this.app.data.employee.role = EmployeeType.brand :
                this.roleString = "Brand rep";
                break;
            case this.app.data.employee.role = EmployeeType.retailer :
                this.roleString = "Retailer";
                break;
        }
        this.certificates = this.app.data.getCertificateCount() + "";
        this.empPoints = this.app.getEmployeePoints().toString();
        this.withoutStoreFront = this.app.data.employee.brand.withoutStorefront;
    }

    OnToolboxTileClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Toolbox);
        this.router.navigate(['main', 'toolbox']);
    }

    onDownloadShareTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Share);
        this.router.navigate(['main', 'download', 2]);
    }

    OnCourseBrandTileClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Courses);
        this.router.navigate(['main', 'courses', 2]);
    }

    onDowlonadBrandNotificationTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Notifications);
        this.router.navigate(['main', 'download', 1]);
    }

    OnDownloadBrandEventsTileClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Events);
        this.router.navigate(['main', 'download', 5]);
    }

    onSurveyTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Survey)
        this.router.navigate(['main', 'surveys']);
    }

    onDownloadProShopTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.ProShop);
        this.router.navigate(['main', 'download', 6]);
    }

    OnTheMallTileClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.TheMall);
        this.router.navigate(['main', 'download', 7]);
    }
}

