import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {BrandExtendedMessageData} from "./brand_extended_message_data";
import {StoreResponseMessageData} from "./store_response_message_data";
import {RetailerExtendedMessageData} from "./retailer_extended_message_data";
import {EmployeeType} from "../../data/employee_type";

export class EmployeeMessageData implements IJsonDeserializable {
    version: number = 0;

    id: number;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    retailerId: number;
    retailerName: string;
    storeZip: string;
    storeId: number;
    role: number;
    brandId: number;
    isRated: boolean;
    dateRated: Date;
    storeName: string;
    storeCity: string;
    isDemo: boolean;
    countryId: number;
    countryName: string;
    fullSyncOnCellularData: boolean;
    miscMarketingEmails: boolean;
    eventEmailNotifications: boolean;
    updatedAt: Date;
    createdAt: Date;
    isEmailVerified: boolean;
    jobCategoryId: number;
    jobCategoryName: string;
    languageId: number;
    languageName: string;
    languageCode: string;
    storeSearchByAddress: boolean;
    status: number;
    termsAndConditionsId: number;
    nfContent: boolean;
    nfAwards: boolean;
    nfEvents: boolean;
    nfContests: boolean;
    nfTipsAndTricks: boolean;
    nfLastUpdate: Date;


    //local
    brand: BrandExtendedMessageData;
    retailer: RetailerExtendedMessageData;
    store: StoreResponseMessageData;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.email = jsonObject.email;
        this.username = jsonObject.username;
        this.firstName = jsonObject.firstName;
        this.lastName = jsonObject.lastName;
        this.retailerId = jsonObject.retailerId;
        this.retailerName = jsonObject.retailerName;
        this.storeZip = jsonObject.storeZip;
        this.storeId = jsonObject.storeId;
        this.role = jsonObject.role;
        this.brandId = jsonObject.brandId;
        this.isRated = jsonObject.isRated;
        this.dateRated = deserializeDateTime(jsonObject.dateRated);
        this.storeName = jsonObject.storeName;
        this.storeCity = jsonObject.storeCity;
        this.isDemo = jsonObject.isDemo;
        this.countryId = jsonObject.countryId;
        this.countryName = jsonObject.countryName;
        this.fullSyncOnCellularData = jsonObject.fullSyncOnCellularData;
        this.miscMarketingEmails = jsonObject.miscMarketingEmails;
        this.eventEmailNotifications = jsonObject.eventEmailNotifications;
        this.updatedAt = deserializeDateTime(jsonObject.updatedAt);
        this.createdAt = deserializeDateTime(jsonObject.createdAt);
        this.isEmailVerified = jsonObject.isEmailVerified;
        this.jobCategoryId = jsonObject.jobCategoryId;
        this.jobCategoryName = jsonObject.jobCategoryName;
        this.languageId = jsonObject.languageId;
        this.languageName = jsonObject.languageName;
        this.languageCode = jsonObject.languageCode;
        this.storeSearchByAddress = jsonObject.storeSearchByAddress;


        if (this.version > 4) {
            this.status = jsonObject.status;
        }

        if (this.version > 5) {
            this.termsAndConditionsId = jsonObject.termsAndConditionsId;
        }

        if (this.version > 6) {
            this.nfContent = jsonObject.nfContent;
            this.nfAwards = jsonObject.nfAwards;
            this.nfEvents = jsonObject.nfEvents;
            this.nfContests = jsonObject.nfContests;
            this.nfTipsAndTricks = jsonObject.nfTipsAndTricks;
            this.nfLastUpdate = deserializeDateTime(jsonObject.nfLastUpdate);
        }
    }

    //local
    isRetailer(): boolean {
        if (this.role == EmployeeType.retailer)
            return true;

        return false;
    }
}
