import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class ClaimAwardResponseMessage extends ResponseMessage {
    success: boolean;

    constructor() {
        super()
        this.type = MessageTypes.ClaimAwardResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.success = jsonObject.success;
    }
}
