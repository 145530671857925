import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class CountriesRequestMessage extends RequestMessage {
    demo: boolean;
    employeeId: number;
    preferredLang: number;
    osVersion: number;


    constructor() {
        super()
        this.type = MessageTypes.CountriesRequest;
        this.osVersion = 4;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.demo = jsonObject.demo;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
        this.osVersion = jsonObject.osVersion;
    }
}
