import {ItemMessageData} from "./item_message_data";
import {StoreFieldsRequirementValues} from "../../data/store_fields_requirement_values";

export class CountryMessageData extends ItemMessageData {
    countryMessageDataVersion: number = 2;
    storeSearchByAddress: boolean;
    storeZipRequirement: StoreFieldsRequirementValues;
    storeDescriptionRequirement: StoreFieldsRequirementValues;

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.countryMessageDataVersion = jsonObject.countryMessageDataVersion;
        this.storeSearchByAddress = jsonObject.storeSearchByAddress;
        this.storeZipRequirement = jsonObject.storeZipRequirement;
        this.storeDescriptionRequirement = jsonObject.storeDescriptionRequirement;
    }

    get showStoreZip(): boolean {
        return this.storeZipRequirement != StoreFieldsRequirementValues.No;
    }

    get showStoreDescription(): boolean {
        return this.storeDescriptionRequirement != StoreFieldsRequirementValues.No;
    }
}
