import {RequestMessage} from "../request_message";
import {ContentStatisticMessageData} from "../message_data/content_statistic_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class ContentStatisticRequestMessage extends RequestMessage {
    records: Array<ContentStatisticMessageData> = [];
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.ContentStatisticRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.records = deserializeArrayOfObject(jsonObject.records, ContentStatisticMessageData);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
