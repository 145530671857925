export {}

export class StringExtensions {
    static isNullorEmpty(input: string): boolean {
        return !input || !input.trim();
    }

    static getLeaderboardDateText(date: Date): string {
        let month = date.getMonth();
        let year = date.getFullYear();

        let monthText: string;
        switch (month) {
            case 0:
                monthText = "Jan";
                break;
            case 1:
                monthText = "Feb";
                break;
            case 2:
                monthText = "Mar";
                break;
            case 3:
                monthText = "Apr";
                break;
            case 4:
                monthText = "May";
                break;
            case 5:
                monthText = "Jun";
                break;
            case 6:
                monthText = "Jul";
                break;
            case 7:
                monthText = "Aug";
                break;
            case 8:
                monthText = "Sep";
                break;
            case 9:
                monthText = "Oct";
                break;
            case 10:
                monthText = "Nov";
                break;
            case 11:
                monthText = "Dec";
                break;
        }

        return monthText + " " + year;
    }


    static IsZipValidForCountry(zip: string, country: string): boolean {


        switch (country.toLowerCase()) {
            case "united states":
            case "usa":
                return StringExtensions.IsZipValidForUnitedStates(zip);

            case "canada":
                return StringExtensions.IsZipValidForCanada(zip);

            default:
                return true;
        }
    }

    static IsEmail(string: string) {
        if (this.isNullorEmpty(string))
            return false;
        let rgx = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}"\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/.test(string)
        return rgx;

    }


    static IsZipValidForUnitedStates(zip: string): boolean {

        return /^\d{5}$/.test(zip);
    }

    static IsZipValidForCanada(zip: string): boolean {
        return /^(\w{3})(\s)?(\w{3})$/.test(zip);
    }
}
