import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CourseFilterData} from "./course_filter_data";
import {BrandExtendedMessageData} from "../../communication/message_data/brand_extended_message_data";
import {CategoryMessageData} from "../../communication/message_data/category_message_data";

@Component({
    selector: "sp-cfilter",
    templateUrl: "../../../templates/common/courses_filter.component.html"
})
export class CoursesFilterComponent {
    opened: boolean = false;

    @Input() filterData: CourseFilterData;

    @Input() allBrands: Array<BrandExtendedMessageData>;
    @Input() allCategories: Array<CategoryMessageData>;

    @Output() onFilterChanged = new EventEmitter<CourseFilterData>();

    isBrandGroupVisible: boolean = false;
    isCategoryGroupVisible: boolean = false;
    isSortGroupVisible: boolean = false;

    brandData: Array<BrandListItem> = [];
    categoryData: Array<CategoryListItem> = [];
    sortData: Array<SortListItem> = [];

    init() {
        this.brandData = [];
        this.categoryData = [];
        this.sortData = [];

        for (let b of this.allBrands) {
            let li = new BrandListItem();
            li.data = b;
            li.isSelected = this.filterData.brandIds.some(p => p == b.id);
            this.brandData.push(li);
        }

        for (let c of this.allCategories) {
            let li = new CategoryListItem();
            li.data = c;
            li.isSelected = this.filterData.categoryIds.some(p => p == c.id);
            this.categoryData.push(li);
        }

        let byLastUpdated = new SortListItem();
        byLastUpdated.data = CourseFilterData.ByLastUpdated;
        byLastUpdated.name = "COURSE_FILTER.LAST_UPDATE";
        byLastUpdated.isSelected = this.filterData.sortType == CourseFilterData.ByLastUpdated;
        this.sortData.push(byLastUpdated);

        let byTitle = new SortListItem();
        byTitle.data = CourseFilterData.ByTitle;
        byTitle.name = "COURSE_FILTER.BY_TITLE";
        byTitle.isSelected = this.filterData.sortType == CourseFilterData.ByTitle;
        this.sortData.push(byTitle);

        let byBrand = new SortListItem();
        byBrand.data = CourseFilterData.ByBrand;
        byBrand.name = "COURSE_FILTER.BY_BRAND";
        byBrand.isSelected = this.filterData.sortType == CourseFilterData.ByBrand;
        this.sortData.push(byBrand);
    }

    public onCategoryItemClick(cat: CategoryListItem) {
        cat.isSelected = !cat.isSelected;

        this.applyFilter();
    }

    public onBrandItemClick(brand: BrandListItem) {
        brand.isSelected = !brand.isSelected;

        this.applyFilter();
    }

    public onSortItemClick(sort: SortListItem) {
        this.sortData[0].isSelected = false;
        this.sortData[1].isSelected = false;
        this.sortData[2].isSelected = false;

        sort.isSelected = true;

        this.applyFilter();
    }

    public applyFilter() {
        this.filterData.reset();
        for (let b of this.brandData) {
            if (b.isSelected)
                this.filterData.brandIds.push(b.data.id);
        }

        for (let c of this.categoryData) {
            if (c.isSelected)
                this.filterData.categoryIds.push(c.data.id);
        }

        if (this.sortData[0].isSelected)
            this.filterData.sortType = this.sortData[0].data;
        else if (this.sortData[1].isSelected)
            this.filterData.sortType = this.sortData[1].data;
        else
            this.filterData.sortType = this.sortData[2].data;

        this.onFilterChanged.emit(this.filterData);
    }

    searchHandler(args: any): any {
        this.filterData.filterText = args.target.value.toLowerCase();
        this.applyFilter();
    }

    onClearAllClick() {
        this.brandData.forEach(p => p.isSelected = false);
        this.categoryData.forEach(p => p.isSelected = false);
        this.sortData.forEach(p => {
            p.isSelected = p.data == CourseFilterData.ByLastUpdated;
        });

        this.applyFilter();
    }
}

export class BrandListItem {
    data: BrandExtendedMessageData;
    isSelected: boolean;
}

export class CategoryListItem {
    data: CategoryMessageData;
    isSelected: boolean;
}

export class SortListItem {
    data: number;
    name: string;
    isSelected: boolean;
}
