import {App} from "../../app";
import {CheckRegistrationEmailResponseMessage} from "../../communication/messages/check_registration_email_response_message";
import {CheckRegistrationUsernameResponseMessage} from "../../communication/messages/check_registration_username_response_message";
import {SuccessHandler} from "../../communication/success_handler";
import {ErrorHandler} from "../../communication/error_handler";

export class RegistrationHelper {

    static IsEmailAvailable(inputEmail: string, os: SuccessHandler<CheckRegistrationEmailResponseMessage>, oe: ErrorHandler<CheckRegistrationEmailResponseMessage>) {
        let app = App.instance;
        var result = app.driver.checkRegistrationEmail(inputEmail).onSuccess(os).onError(oe).send();
    }


    public static IsUsernameAvailable(username: string, userType: number, os: SuccessHandler<CheckRegistrationUsernameResponseMessage>, oe: ErrorHandler<CheckRegistrationUsernameResponseMessage>) {
        let app = App.instance;
        var result = app.driver.checkRegistrationScreenName(username, userType)
            .onSuccess(os).onError(oe).send();
    }
}