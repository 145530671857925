import {IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {QuizQuestionAnswerMessageData} from "./quiz_question_answer_message_data";
import {QuizQuestionType} from "../../data/quiz_question_type";

export class QuizQuestionMessageData implements IJsonDeserializable, Iid {
    version: number = 3;
    id: number;
    quizId: number;
    text: string;

    topicId: number;
    success: string;
    failure: string;
    forAwardQuiz: boolean;
    forCourseQuiz: boolean = true;
    awardQuizTime: number;
    questionType: QuizQuestionType = QuizQuestionType.SingleSelect;

    answers: Array<QuizQuestionAnswerMessageData> = [];

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.quizId = jsonObject.quizId;
        this.text = jsonObject.text;

        if (this.version > 0) {
            this.topicId = jsonObject.topicId;
            this.success = jsonObject.success;
            this.failure = jsonObject.failure;
            this.forAwardQuiz = jsonObject.forAwardQuiz;
            this.awardQuizTime = jsonObject.awardQuizTime;
        }
        if (this.version > 1) {
            this.forCourseQuiz = jsonObject.forCourseQuiz;
        }

        if (this.version > 2) {
            this.questionType = jsonObject.questionType;
        }
    }
}
