import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";
import {EmployeeFeedbackMessageData} from "../message_data/employee_feedback_message_data";
import {deserializeObject} from "../json_deserialization/json_deserialization";

export class EmployeeFeedbackRequestMessage extends RequestMessage {
    employeeId: number;
    deviceUUID: string;
    appVersion: string;

    data: EmployeeFeedbackMessageData;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.EmployeeFeedbackRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.deviceUUID = jsonObject.deviceUUID;
        this.appVersion = jsonObject.appVersion;
        this.preferredLang = jsonObject.preferredLang;
        this.data = deserializeObject(jsonObject.data, EmployeeFeedbackMessageData);
    }
}
