import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class RetailersByBrandRequestMessage extends RequestMessage {
    countryId: number;
    brandId: number;
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.RetailersByBrandRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.countryId = jsonObject.countryId;
        this.brandId = jsonObject.brandId;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
