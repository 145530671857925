import {CountryMessageData} from "../../communication/message_data/country_message_data";
import {LanguageMessageData} from "../../communication/message_data/language_message_data";
import {RetailerMessageData} from "../../communication/message_data/retailer_message_data";
import {JobCategoryMessageData} from "../../communication/message_data/job_category_message_data";
import {StoreResponseMessageData} from "../../communication/message_data/store_response_message_data";
import {RetailerCategoryMessageData} from "../../communication/message_data/retailer_category_message_data";
import {BrandMessageData} from "../../communication/message_data/brand_message_data";
import {StateMessageData} from "../../communication/message_data/state_message_data";
import {Injectable, OnChanges, SimpleChange} from "@angular/core";
import {StringExtensions} from "../../common/extensions";
import {RegistrationHelper} from "./RegistrationHelper";
import {CheckRegistrationEmailResponseMessage} from "../../communication/messages/check_registration_email_response_message";
import {StoreFieldsRequirementValues} from "../../data/store_fields_requirement_values";
import {AddStoraData} from "app/ts/data/add.store.data";

@Injectable()
export class RegisterData implements OnChanges {

    private _Country: CountryMessageData = new CountryMessageData();
    private _Language: LanguageMessageData = new LanguageMessageData();
    private _UserType: number;
    private _Retailer: RetailerMessageData = new RetailerMessageData();
    private _JobCategory: JobCategoryMessageData = new JobCategoryMessageData();
    private _Store: StoreResponseMessageData = new StoreResponseMessageData();
    private _CreatedStore: AddStoraData;
    private _State: StateMessageData = new StateMessageData();
    private _TermsAndConditionsId: number;

    private _FirstName: string;
    private _LastName: string;
    private _Email: string;
    private _ConfirmEmail: string;
    private _Password: string;
    private _ConfirmPassword: string;
    private _SellProEmailNotifications: boolean = true;
    private _MiscEmailNotifications: boolean = true;
    private _Zip: string;
    private _RegistrationId: number;
    private _DisableValidation: boolean = true;
    private _Brand: BrandMessageData = new BrandMessageData();
    private _IsEmailValidOnServer: boolean = false;
    private _IsUsernameValidOnServer: boolean = false;
    private _UserId: number;
    public countriesList = new Array<CountryMessageData>();
    public languagesList = new Array<LanguageMessageData>();
    public retailersList = new Array<RetailerMessageData>();
    public jobCategoriesList = new Array<JobCategoryMessageData>();
    public brandsList = new Array<BrandMessageData>();


    private initAddStoreData() {
        this.CreatedStore = new AddStoraData(this._Country.storeZipRequirement, this._Country.storeDescriptionRequirement, this._Country.name);
    }

    get UserId(): number {
        return this._UserId;
    }

    set UserId(value: number) {
        this._UserId = value;
    }

    get Country(): CountryMessageData {
        return this._Country;
    }

    set Country(value: CountryMessageData) {
        if (value == this.Country) return;

        this._Country = value;
        this.languagesList = new Array<LanguageMessageData>();
        this.retailersList = new Array<RetailerMessageData>();
        this.brandsList = new Array<BrandMessageData>();
        this.retailersList = new Array<RetailerMessageData>();
        this.jobCategoriesList = new Array<JobCategoryMessageData>();

        this.State = new StateMessageData();
        this.Language = new LanguageMessageData();
        this.Retailer = new RetailerMessageData();
        this.Brand = new BrandMessageData();
    }


    get State(): StateMessageData {
        return this._State;
    }

    set State(value: StateMessageData) {
        this._State = value;
    }

    get Language(): LanguageMessageData {
        return this._Language;
    }

    set Language(value: LanguageMessageData) {
        this._Language = value;
    }

    get UserType(): number {
        return this._UserType;
    }

    set UserType(value: number) {
        if (value == this._UserType) return;
        this._UserType = value;

        this.retailersList = new Array<RetailerMessageData>();
        this.brandsList = new Array<BrandMessageData>();
        this.retailersList = new Array<RetailerMessageData>();
        this.jobCategoriesList = new Array<JobCategoryMessageData>();
        this.countriesList = new Array<CountryMessageData>();

        this.Retailer = new RetailerMessageData();
        this.Brand = new BrandMessageData();


    }

    get Retailer(): RetailerMessageData {
        return this._Retailer;
    }

    set Retailer(value: RetailerMessageData) {
        if (value == this._Retailer) return;
        this._Retailer = value;

        this.Store = new StoreResponseMessageData();
        this.JobCategory = new JobCategoryMessageData();
        this.initAddStoreData();
    }

    get JobCategory(): JobCategoryMessageData {
        return this._JobCategory;
    }

    set JobCategory(value: JobCategoryMessageData) {
        this._JobCategory = value;
    }

    get Store(): StoreResponseMessageData {
        return this._Store;
    }

    set Store(value: StoreResponseMessageData) {
        if (value == this._Store) return;

        this._Store = value;
        this.initAddStoreData()
    }

    get CreatedStore(): AddStoraData {
        return this._CreatedStore;
    }

    set CreatedStore(value: AddStoraData) {
        this._CreatedStore = value;
    }

    get FirstName(): string {
        return this._FirstName;
    }

    set FirstName(value: string) {
        this._FirstName = value;
    }

    get LastName(): string {
        return this._LastName;
    }

    set LastName(value: string) {
        this._LastName = value;
    }

    get Email(): string {
        return this._Email;
    }

    set Email(value: string) {
        this._Email = value;
    }

    get ConfirmEmail(): string {
        return this._ConfirmEmail;
    }

    set ConfirmEmail(value: string) {
        this._ConfirmEmail = value;
    }

    get Password(): string {
        return this._Password;
    }

    set Password(value: string) {
        this._Password = value;
    }

    get ConfirmPassword(): string {
        return this._ConfirmPassword;
    }

    set ConfirmPassword(value: string) {
        this._ConfirmPassword = value;
    }

    get SellProEmailNotifications(): boolean {
        return this._SellProEmailNotifications;
    }

    set SellProEmailNotifications(value: boolean) {
        this._SellProEmailNotifications = value;
    }

    get MiscEmailNotifications(): boolean {
        return this._MiscEmailNotifications;
    }

    set MiscEmailNotifications(value: boolean) {
        this._MiscEmailNotifications = value;
    }

    get Zip(): string {
        return this._Zip;
    }

    set Zip(value: string) {
        this._Zip = value;
    }

    get RegistrationId(): number {
        return this._RegistrationId;
    }

    set RegistrationId(value: number) {
        this._RegistrationId = value;
    }

    get DisableValidation(): boolean {
        return this._DisableValidation;
    }

    set DisableValidation(value: boolean) {

        this._DisableValidation = value;

    }

    get Brand(): BrandMessageData {
        return this._Brand;
    }

    set Brand(value: BrandMessageData) {
        if (value == this._Brand) return;
        this._Brand = value;
        this.JobCategory = new JobCategoryMessageData();
    }


    get TermsAndConditionsId(): number {
        return this._TermsAndConditionsId;
    }

    set TermsAndConditionsId(value: number) {
        this._TermsAndConditionsId = value;
    }

    get IsEmailValidOnServer(): boolean {
        return this._IsEmailValidOnServer;
    }

    set IsEmailValidOnServer(value: boolean) {
        this._IsEmailValidOnServer = value;
    }

    get IsUsernameValidOnServer(): boolean {
        return this._IsUsernameValidOnServer;
    }

    set IsUsernameValidOnServer(value: boolean) {
        this._IsUsernameValidOnServer = value;
    }

    get IsFirstStepValid(): boolean {
        if (this.Country != null && this.Country.id > 0 && this.Language != null && this.Language.id > 0 && this.State.id != null && this.State.id > 0) {
            return true;
        }
        return false;
    }

    get IsSecondStepValid(): boolean {
        if (this.Retailer != null && this.Retailer.id > 0 && this.Store != null && this.Store.id != 0 && this.JobCategory.id > 0) {
            return true;
        }
        return false;
    }

    get StoreName(): string {
        if (this.CreatedStore != null && this.CreatedStore.StoreNumber)
            return this.CreatedStore.StoreNumber;

        if (this.Store != null && this.Store.id > 0)
            return this.Store.name;

        return null;
    }

    get IsProfileStepValid() {
        if (this.firstNameError == '' &&
            this.lastNameError == '' &&
            this.emailError == '' &&
            this.confirmEmailError == '' &&
            this.passwordError == '' &&
            this.confirmPasswordError == ''
        ) {
            return true;
        }
        return false;
    }

    private _firstNameError: string = '';
    private _lastNameError: string = '';
    private _emailError: string = '';
    private _confirmEmailError: string = '';
    private _passwordError: string = '';
    private _confirmPasswordError: string = '';

    get confirmPasswordError(): string {
        return this._confirmPasswordError;
    }

    set confirmPasswordError(value: string) {
        this._confirmPasswordError = value;
    }

    get passwordError(): string {
        return this._passwordError;
    }

    set passwordError(value: string) {
        this._passwordError = value;
    }

    get confirmEmailError(): string {
        return this._confirmEmailError;
    }

    set confirmEmailError(value: string) {
        this._confirmEmailError = value;
    }

    get emailError(): string {
        return this._emailError;
    }

    set emailError(value: string) {
        this._emailError = value;
    }

    get lastNameError(): string {
        return this._lastNameError;
    }

    set lastNameError(value: string) {
        this._lastNameError = value;
    }

    get firstNameError(): string {
        return this._firstNameError;
    }

    set firstNameError(value: string) {
        this._firstNameError = value;
    }

    public ValidateProfile() {
        if (StringExtensions.isNullorEmpty(this.FirstName)) {
            this.firstNameError = "PROFILE.FIRST_NAME_REQUIRED";
        } else {
            this.firstNameError = '';
        }

        if (StringExtensions.isNullorEmpty(this.LastName)) {
            this.lastNameError = "PROFILE.LAST_NAME_REQUIRED";
        } else {
            this.lastNameError = '';
        }

        if (StringExtensions.isNullorEmpty(this.Email)) {
            this.emailError = "PROFILE.EMAIL_REQUIRED";
        } else {
            if (!StringExtensions.isNullorEmpty(this.Email) && !StringExtensions.IsEmail(this.Email)) {
                this.emailError = "PROFILE.EMAIL_IS_NOT_VALID";
            } else {

                this.IsEmailValidOnServer = false;
                let that = this;
                RegistrationHelper.IsEmailAvailable(this.Email, function (rsp: CheckRegistrationEmailResponseMessage) {
                    if (rsp != null) {
                        if (rsp.isValidForRetailer) {
                            that.emailError = '';
                            that.IsEmailValidOnServer = true;
                        } else {
                            that.emailError = "PROFILE.EMAIL_IS_ALREADY_IN_USE";
                        }

                    }
                }, function (er) {
                    that.emailError = "PROFILE.SERVER_DID_NOT_RESPOND";
                });
            }
        }

        if (StringExtensions.isNullorEmpty(this.Password)) {
            this.passwordError = "PROFILE.PASSWORD_REQUIRED";
        } else {
            this.passwordError = '';
        }

        if (StringExtensions.isNullorEmpty(this.ConfirmEmail)) {
            this.confirmEmailError = "PROFILE.CONFIRM_EMAIL_REQUIRED";
        } else {
            if (!StringExtensions.isNullorEmpty(this.Email) && this.Email != this.ConfirmEmail) {
                this.confirmEmailError = "PROFILE.EMAIL_NOT_MATCH";
            } else {
                this.confirmEmailError = '';
            }
        }

        if (StringExtensions.isNullorEmpty(this.ConfirmPassword)) {
            this.confirmPasswordError = "PROFILE.CONFIRM_PASSWORD_REQUIRED";
        } else {
            if (!StringExtensions.isNullorEmpty(this.Password) && this.Password != this.ConfirmPassword) {
                this.confirmPasswordError = "PROFILE.PASSWORD_NOT_MATCH";
            } else {
                this.confirmPasswordError = '';
            }
        }
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        for (let propName in changes) {
            let chng = changes[propName];
            let cur = JSON.stringify(chng.currentValue);
            let prev = JSON.stringify(chng.previousValue);

        }
    }

}


export class RetailerCategoryMessageDataExt {
    public selected: boolean;
    public item: RetailerCategoryMessageData

    constructor(selected: boolean, item: RetailerCategoryMessageData) {
        this.selected = selected;
        this.item = item;
    }
}
