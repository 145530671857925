import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {TopicMessageData} from "./topic_message_data";
import {EmployeeCourseState} from "../../data/employee_course_state";
import {CategoryMessageData} from "./category_message_data";
import {QuizMessageData} from "./quiz_message_data";
import {BrandExtendedMessageData} from "./brand_extended_message_data";
import {CourseType} from "../../data/course_type";
import {CourseState} from "../../data/course_state";
import {ContentStatisticType} from "../../data/content_statistic_type";
import {HelperMethods} from "../../common/helper_methods";
import {App} from "../../app";

export class CourseMessageData implements IJsonDeserializable {
    version: number = 2;

    id: number;
    name: string;
    subName: string;
    body: string;
    brandId: number;
    categoryId: number;
    recordStatus: number;
    type: number;
    userType: number;
    retailerOrientation: boolean;
    brandOrientation: boolean;
    updatedAt: Date;
    activatedAt: Date;
    bonusPoints: number;
    explicitTopicOrder: boolean;
    forEmployeeState: number;
    forEmployeeLocked: boolean;
    forEmployeeUpdatedAt: Date;
    forEmployeeBonusStatus: number;
    spotlightPosition: number;
    spotlightIconType: number;
    spotlightLineOne: string;
    spotlightLineTwo: string;
    allowRecertification: boolean;
    recertificationDays: number;
    employeeLastCertificationDate: Date = null;
    employeeCertificationExpiryDate: Date = null;

    //private
    topics: TopicMessageData[] = new Array<TopicMessageData>();
    brand: BrandExtendedMessageData;
    category: CategoryMessageData;
    quiz: QuizMessageData;


    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.subName = jsonObject.subName;
        this.body = jsonObject.body;
        this.brandId = jsonObject.brandId;
        this.categoryId = jsonObject.categoryId;
        this.recordStatus = jsonObject.recordStatus;
        this.type = jsonObject.type;
        this.userType = jsonObject.userType;
        this.retailerOrientation = jsonObject.retailerOrientation;
        this.brandOrientation = jsonObject.brandOrientation;
        this.updatedAt = deserializeDateTime(jsonObject.updatedAt);
        this.activatedAt = deserializeDateTime(jsonObject.activatedAt);
        this.bonusPoints = jsonObject.bonusPoints;
        this.explicitTopicOrder = jsonObject.explicitTopicOrder;
        this.forEmployeeState = jsonObject.forEmployeeState || EmployeeCourseState.noAnyTopicOpen;
        this.forEmployeeLocked = jsonObject.forEmployeeLocked;
        this.forEmployeeUpdatedAt = jsonObject.forEmployeeUpdatedAt;
        this.forEmployeeBonusStatus = jsonObject.forEmployeeBonusStatus || 0;
        this.spotlightPosition = jsonObject.spotlightPosition;
        this.spotlightIconType = jsonObject.spotlightIconType;
        this.spotlightLineOne = jsonObject.spotlightLineOne;
        this.spotlightLineTwo = jsonObject.spotlightLineTwo;
        this.allowRecertification = jsonObject.allowRecertification;
        this.recertificationDays = jsonObject.recertificationDays;
        if (this.allowRecertification && this.recertificationDays > 0) {
            this.employeeLastCertificationDate = deserializeDateTime(jsonObject.lastCertificationDate);
            this.employeeCertificationExpiryDate = deserializeDateTime(jsonObject.employeeCertificationExpiryDate);
        }


    }


    //local
    countOfNotPassesTopics(): number {
        var result = 0;
        this.topics.forEach((t) => {
            if (t.employeeRecordStatus == false) {
                result++;
            }
        });

        return result;
    }

    countOfPassedTopics(): number {
        return this.topics.length - this.countOfNotPassesTopics();
    }

    public updateStateUp(): [boolean, EmployeeCourseState] {
        var hasChanges = false;

        if (this.forEmployeeState == EmployeeCourseState.noAnyTopicOpen || this.forEmployeeState == EmployeeCourseState.unknown) {
            this.forEmployeeState = EmployeeCourseState.inProgress;
            hasChanges = true;
        }

        if (this.forEmployeeState == EmployeeCourseState.inProgress) {
            if (this.userType != CourseType.course) {

                if (this.countOfNotPassesTopics() == 0) {
                    this.forEmployeeState = EmployeeCourseState.allTopicsPassed;
                    hasChanges = true;
                }
            }
        }
        return [hasChanges, this.forEmployeeState];
    }

    public getStatisticsType(): ContentStatisticType {
        if (this.recordStatus == CourseState.bounced) {
            return ContentStatisticType.archiveCourse;
        }

        switch (this.userType) {
            case CourseType.course:
                return ContentStatisticType.course;

            case CourseType.referenceLibrary:
                return ContentStatisticType.referenceCourse;

            case CourseType.trainingLibrary:
                return ContentStatisticType.trainingCourse;
        }

        return ContentStatisticType.unknown;
    }

    allTopicsPassed(): boolean {
        for (let topic of this.topics) {
            if (!topic.employeeRecordStatus)
                return false;
        }

        return true;
    }

    public getEffectiveEmployeeState() {
        if (this.forEmployeeState != EmployeeCourseState.quizCompleted) {
            return this.forEmployeeState;
        }
        if (this.employeeCertificationExpiryDate != null && HelperMethods.isBeforeNow(this.employeeCertificationExpiryDate)) {
            return EmployeeCourseState.inProgress;
        }
        return this.forEmployeeState;
    }

    public isAllowedRecertification(): boolean {
        if ((!this.allowRecertification || this.recertificationDays < 1 || this.employeeLastCertificationDate == null) && this.getEffectiveEmployeeState() != EmployeeCourseState.inProgress) {
            return false;
        }
        if (this.employeeCertificationExpiryDate != null) {
            return HelperMethods.isBeforeNow(this.employeeCertificationExpiryDate);
        }
        if (this.employeeLastCertificationDate) {
            let lastCertificationPlusDays: Date = this.employeeLastCertificationDate.plusDays(this.recertificationDays);
            return HelperMethods.isBeforeNow(lastCertificationPlusDays);
        }
        return false;
    }

    public decoratedNameByState(): String {
        if (this.recordStatus == CourseState.inAppPreview || this.recordStatus == CourseState.pendingQa)
            return "* " + this.name;

        return this.name;
    }

    public getMinutesToComplete() {
        let courseSecounds = 0;
        const quizQuestionsCount = this.quiz.questions.length > this.quiz.maxQuestionsToShow ? this.quiz.maxQuestionsToShow : this.quiz.questions.length;
        const teasersCount = this.topics.filter(p => p.teaser != null).length;
        const topicQuizCount = this.topics.filter(p => App.instance.data.hasTopicQuiz(p.id)).length;
        const topicsMinStateTimeSeconds = this.topics.map(p => p.minStayTime).reduce((p, n) => p + n);
        courseSecounds = topicsMinStateTimeSeconds + (15 * (quizQuestionsCount + teasersCount + topicQuizCount));
        courseSecounds = Math.ceil(courseSecounds/5) * 5
        let totalMinutes = Math.floor(courseSecounds / 60);
        const totalSeconds = courseSecounds % 60;
        if(totalMinutes < 1){
            totalMinutes = 0;
        }
        const totalMinutesAsString = totalMinutes > 9 ? totalMinutes : ("0".concat(totalMinutes.toString()))
        const totalSecondsAsString = totalSeconds > 9 ? totalSeconds : ("0".concat(totalSeconds.toString()))
        return `${totalMinutesAsString}:${totalSecondsAsString}`
    }
}