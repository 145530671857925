import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TopicMessageData} from "../../communication/message_data/topic_message_data";
import {App} from "../../app";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {ActivatedRoute, Router} from "@angular/router";
import {EmployeeCourseState} from "../../data/employee_course_state";
import {TopicType} from "../../data/topic_type";
import {TeaserAnswerMessageData} from "../../communication/message_data/teaser_answer_message_data";
import {TeaserMessageData} from "../../communication/message_data/teaser_message_data";
import {ContentStatisticType} from "../../data/content_statistic_type";
import {QuizQuestionType} from "../../data/quiz_question_type";
import {Subscription, timer} from "rxjs";

@Component({
    templateUrl: '../../../templates/courses/course_topics.component.html'
})
export class CourseTopicsComponent implements OnInit, OnDestroy {
    @ViewChild('videoplayer', { static: false }) videoplayer: any;

    @HostListener('document:visibilitychange', ['$event'])
    onFocus(event: any) {
        this.onFocusListener();
    }

    visibilityTimestampClickUrl: number = null;
    isKnowledgeCheckPass: boolean = false;
    actionButtonText: string;
    actionButtonState: ActionButtonState;

    @Input() currentTopic: TopicMessageData;
    lastStatisticSendForTopic: TopicMessageData;
    course: CourseMessageData;
    topicId: number = 0;
    private sub: any;
    public app: App = App.instance;

    questionTypes = QuizQuestionType;

    timer: Subscription;

    isTeaser: boolean = false;
    isHtmlUrl: boolean = false;
    isPdf: boolean = false;
    isVideo: boolean = false;
    isImage: boolean = false;
    isExternelUrl: boolean = false;
    isHtmlUrlWithZendeskChat: boolean = false;

    topicQuiz: boolean = false;
    urlToDisplay: string;
    private autoplay = false;
    showUrlWarning: boolean = false;


    context: CourseTopicsComponent;


    //TEASER
    isAnswered: boolean = false;
    selectedAnswers: Array<TeaserAnswerMessageData> = [];
    message: string;
    teaser: TeaserMessageData;
    teaserResultTextLabel: string;
    teaserButtonText: string = "COURSE_TOPIC_QUIZ.SUBMIT";
    teaserButtonState: ActionButtonState = ActionButtonState.SubmitTeaser;

    ngOnInit(): any {
        this.context = this;
        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // (+) converts string 'id' to a number

            let index = +params['index'];
            this.topicId = this.app.data.courses.getValue(id).topics[index].id;
            this.course = this.app.data.courses.getValue(id);
            this.showTopicOrTeaser(index);
        });
    }

    ngOnDestroy(): any {
        this.sendCloseStatistic();
        this.autoplay = false;
        if (this.timer)
            this.stopTimer();
    }

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    updateVisualStates(isTeaser?: boolean) {
        this.isTeaser = isTeaser || false;
        switch (this.currentTopic.topicType) {
            case TopicType.Pdf:
                this.isExternelUrl = false;
                this.isHtmlUrl = false;
                this.isPdf = true;
                this.isVideo = false;
                this.isImage = false;
                this.isHtmlUrlWithZendeskChat = false;
                break;
            case TopicType.Video:
                this.isExternelUrl = false;
                this.isHtmlUrl = false;
                this.isPdf = false;
                this.isVideo = true;
                this.isImage = false;
                this.isHtmlUrlWithZendeskChat = false;
                break;
            case TopicType.Image:
                this.isExternelUrl = false;
                this.isHtmlUrl = false;
                this.isPdf = false;
                this.isVideo = false;
                this.isHtmlUrlWithZendeskChat = false;
                this.isImage = true;
                break;
            case TopicType.HtmlJson:
            case TopicType.Html:
                this.isExternelUrl = false;
                this.isHtmlUrl = true;
                this.isPdf = false;
                this.isVideo = false;
                this.isImage = false;
                this.isHtmlUrlWithZendeskChat = false;
                break
            case TopicType.Url:
            case TopicType.ExternalUrl:
                this.isExternelUrl = true;
                this.isHtmlUrl = false;
                this.isPdf = false;
                this.isVideo = false;
                this.isImage = false;
                this.isHtmlUrlWithZendeskChat = false;
                break;
            case TopicType.DiscusForumUrl://Is not used, DiscusForumUrl is intrepeted as External Url
                this.isExternelUrl = false;
                this.isHtmlUrl = true;
                this.isPdf = false;
                this.isVideo = false;
                this.isImage = false;
                this.isHtmlUrlWithZendeskChat = false;
                break;
            case TopicType.HtmlJsonWithZendeskChat:
                this.isExternelUrl = false;
                this.isHtmlUrl = false;
                this.isPdf = false;
                this.isVideo = false;
                this.isImage = false;
                this.isHtmlUrlWithZendeskChat = true;
                break;
        }
    }

    updateActionButton() {
        if (!this.currentTopic.employeeRecordStatus && this.timer == null) {
            if (this.currentTopic.topicType != TopicType.Url && this.currentTopic.topicType != TopicType.ExternalUrl)
                this.startTimer(this.isKnowledgeCheckPass);
        }
        else {
            if (this.isLastTopic()) {
                if (this.course.forEmployeeState == EmployeeCourseState.quizCompleted) {
                    if (this.hasTopicQuiz() && !this.currentTopic.employeeRecordStatus) {
                        this.actionButtonText = "COURSE_QUIZ.NEXT";
                        this.actionButtonState = ActionButtonState.Next;
                    }
                    else {
                        if (this.isAllowedRecertification()) {
                            this.actionButtonText = "COURSE_QUIZ.POP_QUIZ";
                            this.actionButtonState = ActionButtonState.Quiz;
                        } else {
                            this.actionButtonText = "COURSE_QUIZ.CERTIFICATE";
                            this.actionButtonState = ActionButtonState.Certificate;
                        }
                    }
                }
                else {
                    if (this.hasTopicQuiz() && !this.currentTopic.employeeRecordStatus) {
                        this.actionButtonText = "COURSE_QUIZ.NEXT";
                        this.actionButtonState = ActionButtonState.Next;
                    }
                    else {
                        if (this.course.quiz != null) {
                            this.actionButtonState = ActionButtonState.Quiz;
                            this.actionButtonText = "COURSE_QUIZ.QUIZ";
                        } else {
                            this.actionButtonState = ActionButtonState.Unknown;
                            this.actionButtonText = "";
                        }
                    }
                }
            }
            else {
                this.actionButtonText = "COURSE_QUIZ.NEXT";
                this.actionButtonState = ActionButtonState.Next;
            }
        }
    }

    private startTimer(isHalfTime = false) {
        let time: number;
        if (this.timer != null)
            this.stopTimer();

        if (isHalfTime) {
            time = this.currentTopic.minStayTime / 2;
            time = time > 30 ? 30 : Math.round(time);
            if (time < 2.5) {
                return;
            }
        } else {
            time = this.currentTopic.minStayTime;
        }
        this.actionButtonText = time.toString();
        this.actionButtonState = ActionButtonState.Timer;
        this.timer = timer(0, 1000).subscribe(t => this.tickerFunc(t, time));
    }

    private sendCloseStatistic() {
        if (this.currentTopic != null && this.currentTopic != this.lastStatisticSendForTopic) {
            this.lastStatisticSendForTopic = this.currentTopic;
            this.app.statistic.topicClosed(this.course.id, this.currentTopic.id, ContentStatisticType.topic);
        }
    }

    private stopTimer() {
        if (this.timer == null) return;
        this.timer.unsubscribe();
        this.timer = null;
        this.autoplay = false;
    }

    tickerFunc(tick: number, time: number) {
        let remainingTicks = time - tick;

        if (remainingTicks == 0) {
            if (!this.hasTopicQuiz()) {
                var result = this.currentTopic.updateStateUp(this.course);
                if (!result[0] && !result[1])
                    return;
            }

            this.updateActionButton();
            this.stopTimer();

            return;
        }

        this.actionButtonText = remainingTicks.toString();
        this.actionButtonState = ActionButtonState.Timer;
    }

    isLastTopic(): boolean {
        let index = this.course.topics.indexOf(this.currentTopic);
        return index == this.course.topics.length - 1;
    }

    onActionUrlLinkClick() {
        this.visibilityTimestampClickUrl = null;
        if (!this.currentTopic.employeeRecordStatus)
            this.startTimer(this.isKnowledgeCheckPass);
        window.open(this.currentTopic.body || "");
    }

    onActionButtonClick() {
        switch (this.actionButtonState) {
            case ActionButtonState.Next:
                if (this.hasTopicQuiz() && !this.currentTopic.employeeRecordStatus) {
                    this.tryShowTopicQuiz();
                } else {
                    this.showTopicOrTeaser();
                }
                break;
            case ActionButtonState.Quiz:
                if (this.hasTopicQuiz() && !this.currentTopic.employeeRecordStatus) {
                    this.tryShowTopicQuiz();
                } else {
                    this.tryShowQuiz();
                }
                break;
            case ActionButtonState.Certificate:
                this.tryShowCertificate();
                break;
        }
    }

    public tryShowCertificate() {
        let cert = this.app.data.getCertificateByCourseId(this.course.id);
        if (!cert)
            return;

        this.router.navigate(['main', 'certificate_detail', cert.id]);
    }

    public tryShowQuiz() {
        if (!this.course.allTopicsPassed())
            return;

        this.router.navigate(['course_quiz', this.course.id]);
    }

    private showTopicQuiz() {
        this.topicQuiz = true;
    }

    private showTopicOrTeaser(preferredIndex?: number) {
        this.sendCloseStatistic();
        this.loadNext(preferredIndex);
    }

    tryShowTopicQuiz() {
        if (this.hasTopicQuiz() && !this.currentTopic.employeeRecordStatus) {
            this.showTopicQuiz();
        } else {
            this.loadNext()
        }
    };

    hasTopicQuiz(): boolean {
        return this.app.data.hasTopicQuiz(this.topicId);
    }

    showTopic() {
        this.autoplay = false;
        this.updateVisualStates(false);
        if (this.currentTopic.topicType == TopicType.Url || this.currentTopic.topicType == TopicType.ExternalUrl) {
            if (!this.currentTopic.employeeRecordStatus)
                this.onActionUrlLinkClick()
        }
        this.updateActionButton();
        this.app.statistic.topicOpen(this.course.id, this.currentTopic.id, ContentStatisticType.topic);
    }

    public topicQuizCallback(isTopicQuizPassed: boolean): void {
        this.topicQuiz = false;
        if (isTopicQuizPassed) {
            if (this.isLastTopic()) {
                switch (this.actionButtonState) {
                    case ActionButtonState.Quiz:
                        this.tryShowQuiz();
                        break;
                    case ActionButtonState.Certificate:
                        this.tryShowCertificate();
                        break;
                }
            } else {
                this.showTopicOrTeaser();
            }
        } else {
            if (!this.currentTopic.employeeRecordStatus) {
                this.visibilityTimestampClickUrl = null;
                this.isKnowledgeCheckPass = true;
                this.showTopic();
            }
        }
    }

    showTeaser(teaser: TeaserMessageData) {
        this.isAnswered = false;
        this.autoplay = false;
        this.teaser = teaser;
        this.app.statistic.teaserOpen(this.currentTopic.courseId, this.currentTopic.id, this.teaser.id);
        this.updateVisualStates(true);
    }

    onAnswerClick(answer: TeaserAnswerMessageData) {

        if (this.teaser.questionType == QuizQuestionType.SingleSelect) {
            this.selectedAnswers = this.selectedAnswers.filter(a => a.teaserId != this.teaser.id);
            this.selectedAnswers.push(answer);
        }

        if (this.teaser.questionType == QuizQuestionType.MultiSelectAny || this.teaser.questionType == QuizQuestionType.MultiSelectAll) {
            let answerAllreadySelected = this.selectedAnswers.some(a => a.id == answer.id);
            if (answerAllreadySelected) {
                this.selectedAnswers = this.selectedAnswers.filter(a => a.id != answer.id);
            } else {
                this.selectedAnswers.push(answer);
            }
        }
    }

    isAnyAnswerSelected() {
        let questionAnswersCount = this.selectedAnswers.length;
        return questionAnswersCount > 0;
    }

    isAnswerSelected(answer: TeaserAnswerMessageData) {
        return this.selectedAnswers.some(item => item.id == answer.id);
    }

    isAnswerCorrect(): boolean {
        let selectedQuestionAnswers: Array<TeaserAnswerMessageData> = this.selectedAnswers;
        let questionCorrectAnswersCount: number = this.teaser.answers.filter(ans => ans.isCorrect).length
        let correctSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => ans.isCorrect).length;
        let incorrectSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => !ans.isCorrect).length;

        if (this.teaser.questionType == QuizQuestionType.SingleSelect) {
            return selectedQuestionAnswers[0].isCorrect;
        }

        if (this.teaser.questionType == QuizQuestionType.MultiSelectAny) {
            return correctSelectedAnswersCount > 0 && incorrectSelectedAnswersCount == 0;
        }

        if (this.teaser.questionType == QuizQuestionType.MultiSelectAll) {
            return correctSelectedAnswersCount == questionCorrectAnswersCount && incorrectSelectedAnswersCount == 0;
        }
    }

    onTeaserButtonClick(): void {
        if (this.teaserButtonState == ActionButtonState.SubmitTeaser) {
            this.teaserButtonState = ActionButtonState.Learn;
            if (this.isAnswerCorrect()) {
                this.message = this.teaser.successMessage;
                this.teaserResultTextLabel = "COURSE_TOPIC_QUIZ.SUCCESS";
                this.teaserButtonText = "COURSE_TOPIC_QUIZ.NEXT"
            }
            else {
                this.message = this.teaser.failureMessage;
                this.teaserResultTextLabel = "COURSE_TOPIC_QUIZ.FAILURE";
                this.teaserButtonText = "COURSE_TOPIC_QUIZ.LEARN"
            }
            this.isAnswered = true;
            this.currentTopic.employeeTeaserStatus = true;
        } else if (this.teaserButtonState == ActionButtonState.Learn) {
            this.app.statistic.teaserClosed(this.course.id, this.currentTopic.id, this.currentTopic.teaser.id);
            this.showTopic();
        }
    }

    onMainCourseScreen() {
        this.router.navigate(['main', 'course_detail', this.currentTopic.courseId])

    }

    private loadNext(preferredIndex?: number) {
        this.isKnowledgeCheckPass = false
        preferredIndex = preferredIndex || this.course.topics.indexOf(this.currentTopic) + 1;
        let nextTopic = this.course.topics[preferredIndex];
        this.topicId = nextTopic.id;//TODO:If is null update button state
        this.currentTopic = nextTopic;

        if (nextTopic.teaser != null && !nextTopic.employeeTeaserStatus) {
            this.showTeaser(nextTopic.teaser);
            return;
        }
        this.showTopic();
    }

    private isAllowedRecertification(): boolean {
        return this.course.isAllowedRecertification();
    }

    get imageFileDescriptor(): string {
        if (this.course.brand.imageFileDescriptor) {
            return this.course.brand.imageFileDescriptor;
        } else {
            return '/assets/images/default_brand_category_image.jpg';
        }
    }

    get questionHint() {
        let questionHint = "";
        switch (this.teaser.questionType) {
            case QuizQuestionType.SingleSelect:
                questionHint = 'COMMON.QUESTION_HINTS.SINGLE_SELECT';
                break;
            case QuizQuestionType.MultiSelectAny:
            case QuizQuestionType.MultiSelectAll:
                questionHint = 'COMMON.QUESTION_HINTS.MULTI_SELECT_ALL';
                break;
            default:
                questionHint = ''
                break;
        }
        return questionHint;
    }

    public onFocusListener() {
        if (this.currentTopic.topicType != TopicType.ExternalUrl && this.currentTopic.topicType != TopicType.Url)
            return;

        if (this.visibilityTimestampClickUrl == null) {
            this.visibilityTimestampClickUrl = event.timeStamp;
            return;
        }

        let comeBackTimestampEvent = event.timeStamp;
        let result = (comeBackTimestampEvent - this.visibilityTimestampClickUrl) / 1000;

        let minStayTime = this.currentTopic.minStayTime;
        if (this.isKnowledgeCheckPass) minStayTime = minStayTime / 2;

        if (result >= minStayTime) {
            if (!this.currentTopic.employeeRecordStatus)
                var updateStateresult = this.currentTopic.updateStateUp(this.course);

            this.stopTimer();
            this.updateActionButton();
        }
    }
}

export enum ActionButtonState {
    Unknown,
    Timer,
    Next,
    Quiz,
    Certificate,
    SubmitTeaser,
    Learn
}
