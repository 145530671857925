export enum EmployeePointsReasonType {
    Unknown = 0,
    CourseCertificate = 1,
    CourseReCertificate = 2,
    SocialMediaShareApp = 3,
    SocialMediaShareAward = 4,
    SocialMediaShareCertificate = 5,
    Initial = 6,
    ReferralInvited = 10,
    ReferralInviting = 11,
    PlayzoneSpin = 101,
    InstantAward = 102
}