import {RouterModule, Routes} from "@angular/router";
import {RegisterComponent} from "./ui/register/register.component";
import {SpMainComponent} from "./ui/spmain.component";
import {LoadingComponent} from "./ui/loading.component";
import {LoginComponent} from "./ui/login/login.component";

import {CountryComponent} from "./ui/register/country.component";
import {PreferencesComponent} from "./ui/register/preferences.component";
import {TermsAndConditionsComponent} from "./ui/register/terms_and_conditions.component";
import {EmailVerificationComponent} from "./ui/register/email_verification.component";
import {ChangeEmailComponent} from "./ui/register/change_email.component";
import {CoursesComponent} from "./ui/courses/courses.component";
import {ReferenceComponent} from "./ui/reference/reference.component";
import {SettingsComponent} from "./ui/settings/settings.component";
import {ReferenceDetailComponent} from "./ui/reference/reference_detail.component";
import {HomeComponent} from "./ui/home/home.component";
import {CoursesDetailComponent} from "./ui/courses/courses_detail.component";
import {CommonTopicPdfComponent} from "./ui/common_topics/common_topic_pdf.component";
import {CommonTopicUrlComponent} from "./ui/common_topics/common_topic_url.component";
import {CommonTopicVideoComponent} from "./ui/common_topics/common_topic_video.component";
import {CountriesListComponent} from "./ui/register/countries_list.component";
import {LanguagesListComponent} from "./ui/register/languages_list.component";
import {RetailerEmployerComponent} from "./ui/register/retailer_employer.component";
import {ToolboxComponent} from "./ui/reference/toolbox.component";
import {SettingsPreferencesComponent} from "./ui/settings/settings_preferences.component";
import {SettingsTermsAndConditionsComponent} from "./ui/settings/settings_terms_and_conditions.component";
import {CourseTopicsComponent} from "./ui/courses/course_topics.component";
import {CourseQuizComponent} from "./ui/courses/course_quiz.component";
import {SettingsFeedbackComponent} from "./ui/settings/settings_feedback.component";
import {ProfileComponent} from "./ui/register/profile.component";
import {ProfileMenuComponent} from "./ui/profile/profile.component";
import {DownloadComponent} from "./ui/common/download.component";
import {RetailersListComponent} from "./ui/register/retailers_list.component";
import {StoresListComponent} from "./ui/register/stores_list.component";
import {JobCategoriesListComponent} from "./ui/register/job_categories_list.component";
import {ProfileChangeEmailComponent} from "./ui/profile/profile_change_email.component";
import {ProfileChangePasswordComponent} from "./ui/profile/profile_change_password.component";
import {RetailerCertificatesComponent} from "./ui/profile/retailer/retailer_certificates.component";
import {PreviousCertificatesListComponent} from "./ui/profile/retailer/previous_certificates_list.component";
import {CertificateDetailCompoent} from "./ui/profile/certificate_detail.component";
import {BrandCertificatesComponent} from "./ui/profile/brand/brand_certificates.component";
import {CommonTopicImageComponent} from "./ui/common_topics/common_topic_image.component";
import {UpdateProfileDataComponent} from "./ui/profile/retailer/update_profile_data.component";
import {RetailerLeaderboardComponent} from "./ui/leaderboard/retailer/retailer_leaderboard.component";
import {BrandLeaderboardComponent} from "./ui/leaderboard/brand/brand_leaderboard.component";
import {JobCategoriesComponent} from "./ui/profile/retailer/job_categories.component";
import {CommonTopicHtmlComponent} from "./ui/common_topics/common_topic_html.comoponent";
import {BrandChangeLanguage} from "./ui/profile/brand/brand_change_language.component";
import {EventsComponent} from "./ui/events/events.component";
import {EventDetailComponent} from "./ui/events/event_detail.component";
import {EventAttachmentComponent} from "./ui/events/event_attachment.component";
import {SurveysListComponent} from "./ui/surveys/surveys_list.component";
import {SurveyDetailComponent} from "./ui/surveys/survey_detail.component";
import {UpdatedTermsAndConditionsComponent} from "./ui/settings/updated_terms_and_conditions.component";
import {EmployeeStatesListComponent} from "./ui/register/employee_states_list.component";
import {CommonTopicHtmlWithZendeskChatComponent} from "./ui/common_topics/common_topic_html_with_zendesk_chat.comoponent";


export const appRoutes: Routes = [
    {path: '', redirectTo: '/loading', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'login/:error', component: LoginComponent},
    {
        path: 'register', component: RegisterComponent, children: [
            {path: 'country', component: CountryComponent},
            {path: 'countries_list', component: CountriesListComponent},
            {path: 'languages_list', component: LanguagesListComponent},
            {path: 'retailer_employer', component: RetailerEmployerComponent},
            {path: 'retailers_list', component: RetailersListComponent},
            {path: 'stores_list', component: StoresListComponent},
            {path: 'states_list', component: EmployeeStatesListComponent},
            {path: 'job_categories_list', component: JobCategoriesListComponent},
            {path: 'profile', component: ProfileComponent},
            {path: 'preferences', component: PreferencesComponent},
            {path: 'terms_and_conditions', component: TermsAndConditionsComponent},
            {path: 'email_verification', component: EmailVerificationComponent},
            {path: 'change_email', component: ChangeEmailComponent},
        ]
    },
    {
        path: 'main', component: SpMainComponent, children: [
            {path: '', component: HomeComponent},

            {path: 'courses', component: CoursesComponent},
            {path: 'courses/:ecs', component: CoursesComponent},
            {path: 'course_detail/:id', component: CoursesDetailComponent},
            {path: 'course_topics/:id/:index', component: CourseTopicsComponent},

            {path: 'reference', component: ReferenceComponent},
            {path: 'reference_detail/:id', component: ReferenceDetailComponent},
            {path: 'common_topic_video/:id', component: CommonTopicVideoComponent},
            {path: 'common_topic_url/:id', component: CommonTopicUrlComponent},
            {path: 'common_topic_pdf/:id', component: CommonTopicPdfComponent},
            {path: 'common_topic_image/:id', component: CommonTopicImageComponent},
            {path: 'common_topic_html/:id', component: CommonTopicHtmlComponent},
            {path: 'common_topic_html_with_zendesk_chat/:id', component: CommonTopicHtmlWithZendeskChatComponent},
            //
            {path: 'toolbox', component: ToolboxComponent},
            //
            {path: 'events', component: EventsComponent},
            {path: 'events/:tabId', component: EventsComponent},
            {path: 'event_detail/:id', component: EventDetailComponent},
            {path: 'event_attachment/:id', component: EventAttachmentComponent},
            //
            {path: 'brand_leaderboard', component: BrandLeaderboardComponent},
            {path: 'retailer_leaderboard', component: RetailerLeaderboardComponent},
            {path: 'settings', component: SettingsComponent},
            {path: 'settings_terms_and_conditions', component: SettingsTermsAndConditionsComponent},
            {path: 'settings_preferences', component: SettingsPreferencesComponent},
            //
            {path: 'download/:ist', component: DownloadComponent},
            {path: 'profile_menu', component: ProfileMenuComponent},
            {path: 'update_retailer_profile/:ist', component: UpdateProfileDataComponent},
            {path: 'profile_change_email', component: ProfileChangeEmailComponent},
            {path: 'password_change_password', component: ProfileChangePasswordComponent},
            {path: 'retailer_certificates', component: RetailerCertificatesComponent},
            {path: 'retailer_certificates', component: RetailerCertificatesComponent},
            {path: 'retailer_certificates/:skip', component: RetailerCertificatesComponent},
            {path: 'retailer_job_category', component: JobCategoriesComponent},
            {path: 'brand_certificates', component: BrandCertificatesComponent},
            {path: 'previous_certificates_detail/:id', component: PreviousCertificatesListComponent},
            {path: 'certificate_detail/:id', component: CertificateDetailCompoent},
            {path: 'brand_change_language', component: BrandChangeLanguage},

            {path: 'settings_feedback', component: SettingsFeedbackComponent},
            {path: 'surveys', component: SurveysListComponent}
        ]
    },
    {path: 'survey', component: SurveyDetailComponent},

    {path: 'course_quiz/:id', component: CourseQuizComponent},
    {path: 'loading', component: LoadingComponent},
    {path: 'updated_terms_and_conditions', component: UpdatedTermsAndConditionsComponent},
];

export const appRoutingProviders: any[] = [];

export const routing = RouterModule.forRoot(appRoutes);
