export enum MessageTypes {
    Unknown = 0,
    Close = 1,
    Alive = 2,

    FilesRequest = 11,
    FileResponse = 10011,

    LoginRequest = 12,
    LoginResponse = 10012,

    RegisterRequest = 13,
    RegisterResponse = 10013,

    CoursesAndRelatedRequest = 14,
    CoursesAndRelatedResponse = 10014,

    CertificatesRequest = 15,
    CertificatesResponse = 10015,

    AwardsRequest = 16,
    AwardsResponse = 10016,

    Text = 17,

    RegisterRetailerRequest = 18,
    RegisterRetailerResponse = 10018,

    RegisterBrandRequest = 23,
    RegisterBrandResponse = 10023,

    CountriesRequest = 19,
    CountriesResponse = 10019,

    RetailersRequest = 20,
    RetailersResponse = 10020,

    StoresRequest = 21,
    StoresResponse = 10021,

    ForgottenPasswordRequest = 22,
    ForgottenPasswordResponse = 10022,

    LanguagesRequest = 24,
    LanguagesResponse = 10024,

    JobCategoriesRequest = 25,
    JobCategoriesResponse = 10025,

    RetailerCategoriesRequest = 26,
    RetailerCategoriesResponse = 10026,

    CheckRegistrationEmailRequest = 27,
    CheckRegistrationEmailResponse = 10027,

    CheckRegistrationUsernameRequest = 28,
    CheckRegistrationUsernameResponse = 10028,

    StatesRequest = 29,
    StatesResponse = 10029,

    BrandsRequest = 30,
    BrandsResponse = 10030,

    ActivateEmployeeRequest = 31,
    ActivateEmployeeResponse = 10031,

    ActivateAndLoginEmployeeRequest = 32,
    ActivateAndLoginEmployeeResponse = 10032,

    ChangeEmailRequest = 33,
    ChangeEmailResponse = 10033,

    ChangePasswordRequest = 34,
    ChangePasswordResponse = 10034,

    RetailerEventsRequest = 35,
    RetailerEventsResponse = 10035,

    BrandEventsRequest = 36,
    BrandEventsResponse = 10036,

    ContentStatisticRequest = 37,
    ContentStatisticResponse = 10037,

    ActionStatisticRequest = 38,
    ActionStatisticResponse = 10038,

    EventStatisticRequest = 39,
    EventStatisticResponse = 10039,

    CommonStatisticRequest = 40,
    CommonStatisticResponse = 10040,

    EmployeePointsSyncRequest = 41,
    EmployeePointsSyncResponse = 10041,

    ChangeEmailAfterActivationRequest = 42,
    ChangeEmailAfterActivationResponse = 10042,

    ChangeEmployeeRetailerStoreCategoriesRequest = 43,
    ChangeEmployeeRetailerStoreCategoriesResponse = 10043,

    WinAwardRequest = 44,
    WinAwardResponse = 10044,

    ClaimAwardRequest = 45,
    ClaimAwardResponse = 10045,

    GetPreferredAwardsRequest = 46,
    GetPreferredAwardsResponse = 10046,

    GetAwardsArrangeRequest = 47,
    GetAwardsArrangeResponse = 10047,

    FreeReservedAwardRequest = 48,
    FreeReservedAwardResponse = 10048,

    ChangeEmployeePreferencesRequest = 49,
    ChangeEmployeePreferencesResponse = 10049,

    EmployeeFeedbackRequest = 50,
    EmployeeFeedbackResponse = 10050,

    RecentlyAwardedRequest = 51,
    RecentlyAwardedResponse = 10051,

    EmployeeLeaderBoardRequest = 52,
    EmployeeLeaderBoardResponse = 10052,

    EmployeeRegisterForEventRequest = 54,
    EmployeeRegisterForEventResponse = 10054,

    CheckEmployeeCourseStatesRequest = 55,
    CheckEmployeeCourseStatesResponse = 10055,

    CheckEmployeeTopicStatesRequest = 56,
    CheckEmployeeTopicStatesResponse = 10056,

    CheckEmployeeAwardStatesRequest = 57,
    CheckEmployeeAwardStatesResponse = 10057,

    CheckEmployeeEventStatesRequest = 58,
    CheckEmployeeEventStatesResponse = 10058,

    CheckBrandEventStatesRequest = 59,
    CheckBrandEventStatesResponse = 10059,

    EmployeeLeaderBoardByBrandRequest = 60,
    EmployeeLeaderBoardByBrandResponse = 10060,

    StoreForBrandLeaderboardRequest = 61,
    StoreForBrandLeaderboardResponse = 10061,

    GetContentStateChangesDatesRequest = 62,
    GetContentStateChangesDatesResponse = 10062,

    GetContentChangesDatesRequest = 63,
    GetContentChangesDatesResponse = 10063,

    BrandEventRequest = 64,
    BrandEventResponse = 10064,

    BrandEventInvitationsRequest = 65,
    BrandEventInvitationsResponse = 10065,

    EventInvitationUpdateRequest = 66,
    EventInvitationUpdateResponse = 10066,

    RetailerEventsFreeSearchRequest = 67,
    RetailerEventsFreeSearchResponse = 10067,

    RegisterForNotificationsRequest = 68,
    RegisterForNotificationsResponse = 10068,

    GetPreferredAppVersionRequest = 69,
    GetPreferredAppVersionResponse = 10069,

    ChangeEmployeeJobCategoryRequest = 71,
    ChangeEmployeeJobCategoryResponse = 10071,

    RetailersByBrandRequest = 75,
    RetailersByBrandResponse = 10075,

    CountriesByBrandRequest = 76,
    CountriesByBrandResponse = 10076,

    CertificateForCourseRequest = 83,
    CertificateForCourseResponse = 10083,

    GetSurveysRequest = 84,
    GetSurveysResponse = 10084,

    TryStartSurveyRequest = 85,
    TryStartSurveyResponse = 10085,

    ReportSurveyRequest = 86,
    ReportSurveyResponse = 10086,

    EmployeeQuizAnswersRequest = 87,
    EmployeeQuizAnswersResponse = 10087,

    ClaimInstantAwardRequest = 88,
    ClaimInstantAwardResponse = 10088,

    EmployeePointsSyncWithCheckRequest = 89,
    EmployeePointsSyncWithCheckResponse = 10089,

    EmployeeCheckInEventRequest = 90,
    EmployeeCheckInEventResponse = 10090,

    GrsBalanceRequest = 91,
    GrsBalanceResponse = 10091,

    GetTermsAndConditionsDateRequest = 92,
    GetTermsAndConditionsDateResponse = 10092,

    UpdateTermsAndConditionsEmployeeDateRequest = 93,
    UpdateTermsAndConditionsEmployeeDateResponse = 10093,

    GenerateUserNameRequest = 94,
    GenerateUserNameResponse = 10094,

    EmployeeCancelSurveyRequest = 95,
    EmployeeCancelSurveyResponse = 10095,

    ChatRegisterRequest = 100,
    ChatRegisterResponse = 10101,

    ChatUnregisterRequest = 101,

    ChatGetCompaniesRequest = 102,
    ChatGetCompaniesResponse = 10102,

    ChatGetConversationsRequest = 103,
    ChatGetConversationsResponse = 10103,

    ChatGetMessagesRequest = 104,
    ChatGetMessagesResponse = 10104,

    ChatSendMessageRequest = 105,
    ChatReadMessageRequest = 106,
    ChatMessageNotification = 10107,
    ChatMessageReadNotification = 10108,
    ChatStatusChangeNotification = 10109,

    RegisterEmployeeReferralInvitationRequest = 107,
    RegisterEmployeeReferralInvitationResponse = 10110,

    UpdateEmployeeNotificationSettingsRequest = 111,
    UpdateEmployeeNotificationSettingsResponse = 10111,

    NearestZipRequest = 112,
    NearestZipResponse = 10112,

    QueryGooglePlacesApiRequest = 113,
    QueryGooglePlacesApiResponse = 10113,

    GrsUrlRequest = 114,
    GrsUrlResponse = 10114,

    GetAppCampaignsRequest = 115,
    GetAppCampaignsResponse = 10115,

    ReportAppCampaignTaskRequest = 116,
    ReportAppCampaignTaskResponse = 10116,

    ReportAppCampaignManyTasksRequest = 117,
    ReportAppCampaignManyTasksResponse = 10117,

    QueryGoogleLocationBySearchRequest = 119,
    QueryGoogleLocationBySearchResponse = 10119,

    QueryGooglePlacesByLatLongApiRequest = 120,
    QueryGooglePlacesByLatLongApiResponse = 10120,

    EmployeeLeaderBoard2Request = 130,
    EmployeeLeaderBoard2Response = 10130,

    EmployeeLeaderBoardByBrand2Request = 131,
    EmployeeLeaderBoardByBrand2Response = 10131,

    EmployeeEventAttendanceConfirmationRequest = 138,
    EmployeeEventAttendanceConfirmationResponse = 10138
}
