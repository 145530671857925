

import {Message} from "./message";
import {UUID} from "../common/uuid";
export class RequestMessage extends Message {
    messageId:string;

    constructor() {
        super();
        this.messageId = UUID.create();
    }

    deserialize(jsonObject:any):void {
        super.deserialize(jsonObject);
        this.messageId = jsonObject.messageId;
    }
}
