import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {EmployeeCertificateMessageData} from "../../../communication/message_data/eployee_certificate_message_data";
import {App} from "../../../app";

@Component({
    selector: 'sp-certificates-list',
    templateUrl: '../../../../templates/profile/certificates_list.component.html',
})
export class CertificatesListComponent implements OnInit {
    @Input() certificates: Array<EmployeeCertificateMessageData>;
    public app: App = App.instance;

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    onListItemSelected(cert: EmployeeCertificateMessageData) {

        this.router.navigate(['main', 'certificate_detail', cert.id]);
    }

}
