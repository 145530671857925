import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../../app";


@Component({
    selector: 'sp-brand-profile',
    templateUrl: '../../../../templates/profile/brand/profile_brand.component.html'

})
export class BrandProfileComponent implements OnInit {

    public app: App = App.instance;
    @Input() userNameBrand: string;
    @Input() emailBrand: string;
    @Input() brandName: string;
    @Input() image: string;
    @Input() fullName: string;
    @Input() certificates: string;
    @Input() languageName: string;


    ngOnInit(): any {
        this.image = this.app.data.employee.brand.landscapeImageFileDescriptor;
        this.fullName = this.app.data.employee.firstName + " " + this.app.data.employee.lastName;
        this.userNameBrand = this.app.data.employee.username;
        this.emailBrand = this.app.data.employee.email;
        this.brandName = this.app.data.employee.brand.name;
        this.languageName = this.app.data.employee.languageName;

        this.certificates = this.app.data.getCertificateCount() + "";

    }

    constructor(private router: Router) {
    }

    onChangePasswordClick() {
        this.router.navigate(['main', 'password_change_password']);
    }

    onCertificatesClick() {
        this.router.navigate(['main', 'brand_certificates'])
    }

    onLanguagesClick() {
        this.router.navigate(['main', 'brand_change_language']);
    }
}

