
import {MessageTypes} from "./message_types";
import {IJsonDeserializable} from "./json_deserialization/json_deserialization";
import {API_VERSION} from "./api_version";

export abstract class Message implements IJsonDeserializable {
    length:number;
    type:MessageTypes;
    version: number = API_VERSION;

    deserialize(jsonObject:any):void {
        this.length = jsonObject.length;
        this.type = jsonObject.type;
        this.version = jsonObject.version;
    }
}
