import {ResponseMessage} from "../response_message";
import {EventsMessageData} from "../message_data/events_message_data";
import {EventAttachmentMessageData} from "../message_data/event_attachment_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class RetailerEventsResponseMessage extends ResponseMessage {
    events: Array<EventsMessageData> = [];
    attachments: Array<EventAttachmentMessageData> = [];

    constructor() {
        super();
        this.type = MessageTypes.RetailerEventsRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.events = deserializeArrayOfObject(jsonObject.events, EventsMessageData);
        this.attachments = deserializeArrayOfObject(jsonObject.attachments, EventAttachmentMessageData);
    }
}
