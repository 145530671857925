import {Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {App} from "../../app";
import {EmployeeMessageData} from "../../communication/message_data/employee_message_data";
import {SpModalComponent} from "../common/sp_modal.component";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-settings-preferences',
    templateUrl: '../../../templates/settings/settings_preferences.component.html'

})

export class SettingsPreferencesComponent implements OnInit {

    app: App = App.instance;
    data: PreferencesData = new PreferencesData;
    eventNotificationCheckbox: HTMLElement;

    ngOnInit(): any {
        this.data.byEmployer(this.app.data.employee);
        this.eventNotificationCheckbox = document.getElementById('event_notifications_checkbox');
    }

    constructor(private router: Router, private modal: ModalService) {

    }

    onDoneButtonClick() {
        if (!this.data.hasChanges())
            return;

        this.app.driver.changeEmployeePreferences(this.app.data.employee, this.data)
            .onSuccess(rsp => {
                if (rsp.success) {
                    let emp = this.app.data.employee;
                    emp.eventEmailNotifications = this.data.eventNotificationsEmails;
                    emp.miscMarketingEmails = this.data.miscMarketingEmails;
                    emp.nfContent = this.data.nfContent;
                    emp.nfAwards = this.data.nfAwards;
                    emp.nfEvents = this.data.nfEvents;
                    emp.nfContests = this.data.nfContests;
                    emp.nfTipsAndTricks = this.data.nfTipsAndTricks;
                    emp.nfLastUpdate = this.data.nfLastUpdate;
                    this.modal.show("SETTINGS_PREFERENCES.SUCCESS", "SETTINGS_PREFERENCES.SUCCESS_PREFERENCES_CHANGED");
                } else {
                    this.modal.show("SETTINGS_PREFERENCES.ERROR", "SETTINGS_PREFERENCES.ERROR_PREFERENCES_NOT_CHANGED");
                }
                this.router.navigate(['/main/settings']);
            })
            .onError((error, ec, em) => {
                this.modal.show("SETTINGS_PREFERENCES.ERROR", "SETTINGS_PREFERENCES.ERROR_LOST_CONNECTION");
            }).send();
    }

}

export class PreferencesData {

    origEventNotificationsEmails: boolean;
    origMiscMarketingEmails: boolean;
    origNfContent: boolean;
    origNfAwards: boolean;
    origNfEvents: boolean;
    origNfContests: boolean;
    origNfTipsAndTricks: boolean;
    origNfLastUpdate: Date;

    eventNotificationsEmails: boolean;
    miscMarketingEmails: boolean;

    nfContent: boolean;
    nfAwards: boolean;
    nfEvents: boolean;
    nfContests: boolean;
    nfTipsAndTricks: boolean;
    nfLastUpdate: Date;

    origTo() {
        this.eventNotificationsEmails = this.origEventNotificationsEmails;
        this.miscMarketingEmails = this.origMiscMarketingEmails;
        this.nfContent = this.origNfContent;
        this.nfAwards = this.origNfAwards;
        this.nfEvents = this.origNfEvents;
        this.nfContests = this.origNfContests;
        this.nfTipsAndTricks = this.origNfTipsAndTricks;
        this.nfLastUpdate = this.origNfLastUpdate;
    }

    hasChanges(): boolean {
        return (this.eventNotificationsEmails != this.origEventNotificationsEmails ||
            this.miscMarketingEmails != this.origMiscMarketingEmails ||
            this.nfContent != this.origNfContent ||
            this.nfAwards != this.origNfAwards ||
            this.nfEvents != this.origNfEvents ||
            this.nfContests != this.origNfContests ||
            this.nfTipsAndTricks != this.origNfTipsAndTricks ||
            this.nfLastUpdate != this.origNfLastUpdate);
    }

    byEmployer(employee: EmployeeMessageData) {
        this.origEventNotificationsEmails = employee.eventEmailNotifications == null ? false : employee.eventEmailNotifications;
        this.origMiscMarketingEmails = employee.miscMarketingEmails == null ? false : employee.miscMarketingEmails;
        this.origNfContent = employee.nfContent == null ? false : employee.nfContent;
        this.origNfAwards = employee.nfAwards == null ? false : employee.nfAwards;
        this.origNfEvents = employee.nfEvents == null ? false : employee.nfEvents;
        this.origNfContests = employee.nfContests == null ? false : employee.nfContests;
        this.origNfTipsAndTricks = employee.nfTipsAndTricks == null ? false : employee.nfTipsAndTricks;
        this.origNfLastUpdate = employee.nfLastUpdate;
        this.origTo();
    }

}

