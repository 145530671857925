export enum ActionStatisticType {
    unknown = 0,
    pushNotification = 1,//done
    login = 2,//done
    logout = 3,//done
    newContent = 4,
    emailCertificate = 5,//done
    emailAward = 51,
    spin = 6,
    zeroPoints = 7,
    facebookShareInvite = 8,//done
    facebookShareCertificate = 9,
    facebookShareAward = 10,
    appStart = 11,//done
    appResumed = 12,//done
    appPaused = 13,//done

    landOnAward = 19,
    awardQuiz = 20,
    courseQuiz = 21,


    eventSelfRegistration = 30,
    eventRepRegistration = 31,
    eventCheckInFromRep = 32,
    eventCertificateEmail = 33,
    eventCertificatePrint = 34,

    topicAddedToClipboard = 40,
    topicRemovedFromClipboard = 41,
    rateInMarket = 42,
    rateFormOpen = 43,//done
    rateFormSubmit = 44,//done

    sellPoint = 50,
    employeeCertified = 52,
    topicQuiz = 53
}