import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class CheckRegistrationUsernameResponseMessage extends ResponseMessage {
    isValid: boolean;

    constructor() {
        super();
        this.type = MessageTypes.CheckRegistrationUsernameResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.isValid = jsonObject.isValid;
    }
}
