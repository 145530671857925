import {ResponseMessage} from "../response_message";
import {EmployeeMessageData} from "../message_data/employee_message_data";
import {MessageTypes} from "../message_types";
import {deserializeObject} from "../json_deserialization/json_deserialization";

export class LoginResponseMessage extends ResponseMessage {
    name: string;
    password: string;
    success: boolean;
    employee: EmployeeMessageData;

    constructor() {
        super();
        this.type = MessageTypes.LoginResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.name = jsonObject.name;
        this.password = jsonObject.password;
        this.success = jsonObject.success;
        this.employee = deserializeObject(jsonObject.employee, EmployeeMessageData);
    }
}
