import {ItemMessageData} from "./item_message_data";

export class JobCategoryMessageData extends ItemMessageData {
    jobCategoryMessageDataVersion: number = 0;

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.jobCategoryMessageDataVersion = jsonObject.jobCategoryMessageDataVersion;
    }
}
