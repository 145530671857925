import { Component, OnInit, ViewChild, Directive } from '@angular/core';

import {App} from "../../app";
import {StateMessageData} from "../../communication/message_data/state_message_data";
import {StatesResponseMessage} from "../../communication/messages/states_response_message";
import {SpLoaderComponent} from "../../ui/common/sp_loader.component";
import {ModalService} from 'app/ts/common/services/modal.service';

@Directive()
export abstract class StatesListInternalComponent implements OnInit {
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;
    public app: App = App.instance;
    public term: string = "";
    public records = new Array<StateMessageData>();

    protected constructor(protected modal: ModalService) {
    }

    abstract getCountryId(): number;

    ngOnInit() {
        this.spLoader.start();
        this.app.driver.getStates(this.getCountryId())
            .onSuccess((rsp: StatesResponseMessage) => {
                if (rsp.states) {
                    this.spLoader.stopAndHide();
                    this.records = rsp.states;
                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                this.spLoader.stopAndHide();
            })
            .send();
    }
}
