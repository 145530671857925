import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class ContentChangedDateMessageData implements IJsonDeserializable {
    version: number = 0;

    type: number;
    changedAt: Date;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.type = jsonObject.type;
        this.changedAt = deserializeDateTime(jsonObject.changedAt);
    }
}
