import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeeAwardStateMessageData implements IJsonDeserializable {
    version: number = 0;

    awardId: number;
    win: boolean;
    claimed: boolean;
    dateWin: Date;
    dateClaimed: Date;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.awardId = jsonObject.awardId;
        this.win = jsonObject.win;
        this.claimed = jsonObject.claimed;
        this.dateWin = deserializeDateTime(jsonObject.dateWin);
        this.dateClaimed = deserializeDateTime(jsonObject.dateClaimed);
    }
}
