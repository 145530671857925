import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeeCourseStateMessageData implements IJsonDeserializable {
    version: number = 0;

    courseId: number;

    state: number;
    locked: boolean;
    updatedAt: Date;
    bonusStatus: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.courseId = jsonObject.courseId;

        this.state = jsonObject.state;
        this.locked = jsonObject.locked;
        this.updatedAt = deserializeDateTime(jsonObject.updatedAt);
        this.bonusStatus = jsonObject.bonusStatus || 0;
    }
}
