import {Component, OnInit} from '@angular/core';
import {RegisterData} from "./register.data";
import {RegisterComponent} from "./register.component";
import {Router} from "@angular/router";
import {StoreResponseMessageData} from "../../communication/message_data/store_response_message_data";
import {ModalService} from "../../common/services/modal.service";

import {AddStoraData} from "../../data/add.store.data";

@Component({
    selector: 'sp-stores-list',
    templateUrl: '../../../templates/register/stores_list.component.html',
    styleUrls: ['../../../styles/components/store_list.css']
})
export class StoresListComponent implements OnInit {
    registerData: RegisterData;

    constructor(private registerComponent: RegisterComponent, private router: Router, private modal: ModalService) {
        this.registerData = registerComponent.RegisterData;
    }

    ngOnInit() {
    }

    BackButtonTapped() {
        this.router.navigate(['register', 'retailer_employer']);
    }

    OnSelectedStore(record: StoreResponseMessageData) {
        this.registerData.Store = record;
        this.router.navigate(['register', 'retailer_employer']);
    }

    OnPickStoreOrCreate(store: AddStoraData) {
        this.registerData.CreatedStore = store;
        this.router.navigate(['register', 'retailer_employer']);
    }
}