import {MessageTypes} from "../message_types";
import {ResponseMessage} from "../response_message";

export class ChangeEmployeePreferencesResponseMessage extends ResponseMessage {
    success: boolean;

    constructor() {
        super();
        this.type = MessageTypes.ChangeEmployeePreferencesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.success = jsonObject.success;
    }
}
