import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class ChangeEmployeeJobCategoryRequestMessage extends RequestMessage {
    employeeId: number;
    jobCategoryId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.ChangeEmployeeJobCategoryRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.jobCategoryId = jsonObject.jobCategoryId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
