import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sp-loader',
    templateUrl: '../../../templates/common/sp_loader.component.html'
})

export class SpLoaderComponent implements OnInit {

    @Input() isWhite: boolean = false;

    public animate: boolean = false;
    public hide: boolean = true;

    constructor() {
    }

    stopAndHide() {
        this.animate = false;
        this.hide = true;
    }

    stop() {
        this.animate = false;
    }

    start() {
        this.animate = true;
        this.hide = false;
    }

    ngOnInit(): any {
        return undefined;
    }

}