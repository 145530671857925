import {ThemeMessageData} from "../../communication/message_data/theme_message_data";
import {App} from "../../app";

export class ThemeWrapper {

    private record: ThemeMessageData;
    private foreColorText: string;
    private foreColorIcon: string;
    private backgroundColorUsedTile: string;
    private backgroundColorFreeTile: string;
    private gradientFirstColor: string;
    private gradientSecondColor: string;

    private overWhiteForeColor: string;
    private overWhiteSolidColor: string;
    private playzoneGradientFirstColor: string;
    private playzoneGradientSecondColor: string;
    private topicHeaderColor: string;
    private barTextColor: string;

    private gradient: string;
    private playzoneGradient: string;
    private gradientAngle: number;
    private playzoneGradientAngle: number;


    constructor(theme?: ThemeMessageData) {
        this.record = theme && theme || ThemeMessageData.Default;
    }

    private theme: ThemeMessageData;

    get Theme(): ThemeMessageData {
        return this.theme;
    }

    set Theme(value: ThemeMessageData) {

        if (value != this.record) {
            this.ClearCashes();
        }
        this.record = value;
    }

    get ForeColorText(): string {
        return this.foreColorText != null ? this.foreColorText : this.ConvertIntToHex(this.record.foreColorText);
    }

    set ForeColorText(value: string) {
        this.foreColorText = value;
    }

    get ForeColorIcon(): string {
        return this.foreColorIcon != null ? this.foreColorIcon : this.ConvertIntToHex(this.record.foreColorIcon);
    }

    set ForeColorIcon(value: string) {
        this.foreColorIcon = value;
    }

    get BackgroundColorUsedTile(): string {
        return this.backgroundColorUsedTile != null ? this.backgroundColorUsedTile : this.ConvertIntToHex(this.record.backgroundColorUsedTile);
    }

    set BackgroundColorUsedTile(value: string) {
        this.backgroundColorUsedTile = value;
    }

    get BackgroundColorFreeTile(): string {
        return this.backgroundColorFreeTile != null ? this.backgroundColorFreeTile : this.ConvertIntToHex(this.record.backgroundColorFreeTile);
    }

    set BackgroundColorFreeTile(value: string) {
        this.backgroundColorFreeTile = value;
    }

    get GradientFirstColor(): string {
        return this.gradientFirstColor != null ? this.gradientFirstColor : this.ConvertIntToHex(this.record.gradientFirstColor);
    }

    set GradientFirstColor(value: string) {
        this.gradientFirstColor = value;
    }

    get GradientSecondColor(): string {
        return this.gradientSecondColor != null ? this.gradientSecondColor : this.ConvertIntToHex(this.record.gradientSecondColor);
    }

    set GradientSecondColor(value: string) {
        this.gradientSecondColor = value;
    }

    get GradientAngle(): number {
        return this.gradientAngle;
    }

    set GradientAngle(value: number) {
        this.gradientAngle = value;
    }

    get OverWhiteForeColor(): string {
        return this.overWhiteForeColor != null ? this.overWhiteForeColor : this.ConvertIntToHex(this.record.overWhiteForeColor);
    }

    set OverWhiteForeColor(value: string) {
        this.overWhiteForeColor = value;
    }

    get OverWhiteSolidColor(): string {
        return this.overWhiteSolidColor != null ? this.overWhiteSolidColor : this.ConvertIntToHex(this.record.overWhiteSolidColor);
    }

    set OverWhiteSolidColor(value: string) {
        this.overWhiteSolidColor = value;
    }

    get PlayzoneGradientFirstColor(): string {
        return this.playzoneGradientFirstColor != null ? this.playzoneGradientFirstColor : this.ConvertIntToHex(this.record.playzoneGradientFirstColor);
    }

    set PlayzoneGradientFirstColor(value: string) {
        this.playzoneGradientFirstColor = value;
    }

    get PlayzoneGradientSecondColor(): string {
        return this.playzoneGradientSecondColor != null ? this.playzoneGradientSecondColor : this.ConvertIntToHex(this.record.playzoneGradientSecondColor);
    }

    set PlayzoneGradientSecondColor(value: string) {
        this.playzoneGradientSecondColor = value;
    }

    get PlayzoneGradientAngle(): number {
        return this.playzoneGradientAngle;
    }

    set PlayzoneGradientAngle(value: number) {
        this.playzoneGradientAngle = value;
    }

    get TopicHeaderColor(): string {
        return this.topicHeaderColor != null ? this.topicHeaderColor : this.ConvertIntToHex(this.record.topicHeaderColor);
    }

    set TopicHeaderColor(value: string) {
        this.topicHeaderColor = value;
    }

    get BarTextColor(): string {
        return this.barTextColor != null ? this.barTextColor : this.ConvertIntToHex(this.record.barTextColor);
    }

    set BarTextColor(value: string) {
        this.barTextColor = value;
    }

    get Gradient(): string {
        return this.gradient != null ? this.gradient : ThemeWrapper.CreateGradientBrush(this.ConvertIntToHex(this.record.gradientFirstColor), this.ConvertIntToHex(this.record.gradientSecondColor), this.record.gradientAngle);
    }

    set Gradient(value: string) {
        this.gradient = value;
    }

    get PlayzoneGradient(): string {
        return this.playzoneGradient || ThemeWrapper.CreateGradientBrush(this.ConvertIntToHex(this.record.playzoneGradientFirstColor), this.ConvertIntToHex(this.record.playzoneGradientSecondColor), this.record.playzoneGradientAngle);
    }

    set PlayzoneGradient(value: string) {
        this.playzoneGradient = value;
    }

    private ClearCashes() {
        this.foreColorText = null;
        this.foreColorIcon = null;
        this.backgroundColorUsedTile = null;
        this.backgroundColorFreeTile = null;

        this.gradientFirstColor = null;
        this.gradientSecondColor = null;

        this.overWhiteForeColor = null;
        this.overWhiteSolidColor = null;
        this.playzoneGradientFirstColor = null;
        this.playzoneGradientSecondColor = null;
        this.topicHeaderColor = null;
        this.barTextColor = null;

        this.gradient = null;
        this.playzoneGradient = null;
    }

    private ConvertIntToHex(color: number) {
        return '#' + ('00000' + (color | 0).toString(16)).substr(-6);
    }

    static ConvertIntToHex(color: number) {
        return '#' + ('00000' + (color | 0).toString(16)).substr(-6);
    }

    private static CreateGradientBrush(colorFirst: string, colorSecond: string, angle: number): string {
        let gdAngle = angle == 1 ? '90deg' : '0deg';
        return 'linear-gradient(' + gdAngle + ',' + colorSecond + ',' + colorFirst + ')';
    }

    public static GetActiveThemeOrDefault(): ThemeWrapper {
        var ed = App.instance;
        var themeId = (ed.isRetailer ? ed.data.employee.retailer.themeId : ed.data.employee.brand.themeId);
        var theme = ed.data.themes.getValue(themeId);
        return new ThemeWrapper(theme);
    }
}