import {MessageTypes} from "../message_types";
import {EmployeeCertificateMessageData} from "../message_data/eployee_certificate_message_data";
import {ResponseMessage} from "../response_message";
import {deserializeDateTime, deserializeObject} from "../json_deserialization/json_deserialization";

export class CertificateForCourseResponseMessage extends ResponseMessage {
    isAlreadyExists: boolean;
    success: boolean;
    certificate: EmployeeCertificateMessageData;
    points: number;
    firstCertificateDate: Date;
    lastCertificateDate: Date;
    allowRecertification: boolean;
    recetifactionDays: number;


    constructor() {
        super();
        this.type = MessageTypes.CertificateForCourseResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.isAlreadyExists = jsonObject.isAlreadyExists;
        this.success = jsonObject.success;
        this.certificate = deserializeObject(jsonObject.certificate, EmployeeCertificateMessageData);
        this.points = jsonObject.points;
        this.firstCertificateDate = deserializeDateTime(jsonObject.firstCertificateDate);
        this.lastCertificateDate = deserializeDateTime(jsonObject.lastCertificateDate);
        this.allowRecertification = jsonObject.allowRecertification;
        this.recetifactionDays = jsonObject.recetifactionDays;
    }
}
