import {RequestMessage} from "../request_message";
import {EmployeeSurveyAnswerMessageData} from "../message_data/employee_survey_answer_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class ReportSurveyRequestMessage extends RequestMessage {
    employeeId: number;
    preferredLang: number;
    surveyId: number;
    employeeSurveyId: number;
    answers: Array<EmployeeSurveyAnswerMessageData> = [];

    constructor() {
        super();
        this.type = MessageTypes.ReportSurveyRequest;
    }


    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
        this.surveyId = jsonObject.surveyId;
        this.employeeSurveyId = jsonObject.employeeSurveyId;
        this.answers = deserializeArrayOfObject(jsonObject.answers, EmployeeSurveyAnswerMessageData);
    }
}