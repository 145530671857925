import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class EmployeePointsSyncRequestMessage extends RequestMessage {
    employeeId: number;
    server: number;
    local: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.EmployeePointsSyncRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.server = jsonObject.server;
        this.local = jsonObject.local;
        this.preferredLang = jsonObject.preferredLang;
    }
}
