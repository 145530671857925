import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class UserMessageData implements IJsonDeserializable {
    id: number;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    retailerId: number;
    retailerName: string;
    storeZip: string;
    storeId: number;
    role: number;
    brandId: number;
    isRated: boolean;
    dateRated: Date;
    storeName: string;
    storeCity: string;
    isDemo: boolean;

    deserialize(jsonObject: any): void {
        this.id = jsonObject.id;
        this.email = jsonObject.email;
        this.username = jsonObject.username;
        this.firstName = jsonObject.firstName;
        this.lastName = jsonObject.lastName;
        this.retailerId = jsonObject.retailerId;
        this.retailerName = jsonObject.retailerName;
        this.storeZip = jsonObject.storeZip;
        this.storeId = jsonObject.storeId;
        this.role = jsonObject.role;
        this.brandId = jsonObject.brandId;
        this.isRated = jsonObject.isRated;
        this.dateRated = deserializeDateTime(jsonObject.dateRated);
        this.storeName = jsonObject.storeName;
        this.storeCity = jsonObject.storeCity;
        this.isDemo = jsonObject.isDemo;
    }
}
