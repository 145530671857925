import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {App} from "../../app";
import {TopicMessageData} from "../../communication/message_data/topic_message_data";
import {TopicType} from "../../data/topic_type";
import {ActivatedRoute, Router} from "@angular/router";
import {AppCampaignsProcessorService} from "../../common/services/app_campaigns_processor.service";
import {CourseType} from "../../data/course_type";

@Component({
    selector: 'sp-reference-detail',
    templateUrl: '../../../templates/reference/reference_detail.component.html'
})

export class ReferenceDetailComponent implements OnInit, OnDestroy {

    course: CourseMessageData;
    private sub: any;
    public app: App = App.instance;

    ngOnInit(): any {
        this.sub = this.route.params.subscribe(async params => {
            let id = Number(params['id']);
            this.course = this.app.data.courses.getValue(id);
            if (this.course != null) {
                if (this.course.userType == CourseType.referenceLibrary) {
                    await this.appCampaignProcessor.openedReferenceLibrary(this.app, this.course.brandId, this.course.id, this.course.name);
                }
            }
        });
    }

    constructor(private route: ActivatedRoute, private router: Router, private appCampaignProcessor: AppCampaignsProcessorService) {
    }

    onListItemSelected(topic: TopicMessageData) {
        let control: string;

        control = topic.getCourseTopicControl();

        this.router.navigate(['main', control, topic.id]);

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
