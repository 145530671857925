import {Component, OnInit, ViewChild} from '@angular/core';
import {SpLoaderComponent} from "../../common/sp_loader.component";
import {App} from "../../../app";
import {JobCategoryMessageData} from "../../../communication/message_data/job_category_message_data";
import {Router} from "@angular/router";
import {JobCategoriesResponseMessage} from "../../../communication/messages/job_categories_response_message";
import {SpModalComponent} from "../../common/sp_modal.component";
import {ModalService} from "../../../common/services/modal.service";

@Component({
    selector: 'sp-job-categories',
    templateUrl: '../../../../templates/profile/retailer/job_categories.component.html'
})
export class JobCategoriesComponent implements OnInit {
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;
    jobCategoriesList: Array<JobCategoryMessageDataWrapper> = [];
    oldSelectedJobCategory: JobCategoryMessageData;
    public app: App = App.instance;
    constructor(private router: Router, private modal:ModalService) {
    }

    ngOnInit() {
        this.spLoader.start();
        var empoloyee = this.app.data.employee;

        this.app.driver.getJobCategories(1, empoloyee.id, empoloyee.languageId)
            .onSuccess((rsp: JobCategoriesResponseMessage) => {
                if (rsp.jobCategories) {

                    this.spLoader.stopAndHide();
                    this.jobCategoriesList = rsp.jobCategories.map((jc) => {
                        const isSelected = jc.id === empoloyee.jobCategoryId;
                        if (isSelected){
                            this.oldSelectedJobCategory = jc;
                        }
                        return new JobCategoryMessageDataWrapper(jc, isSelected);
                    });
                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION")
                this.spLoader.stopAndHide();
            })
            .send();
    }

    onListItemSelected(record: JobCategoryMessageDataWrapper) {
        this.jobCategoriesList.forEach((jc) => {
            jc.selected = false
        });
        record.selected = true;
    }

    getSelectedCategory(){
        return this.jobCategoriesList.length > 0 ? (this.jobCategoriesList.filter(jc => jc.selected).length > 0 ? this.jobCategoriesList.filter(jc => jc.selected)[0].jobCategory : null) : null;
    }

    hasChanges(){
        return this.getSelectedCategory() != null ? this.getSelectedCategory().id != this.oldSelectedJobCategory.id : false;
    }

    onDoneButtonClick() {
        if (!this.getSelectedCategory() || this.getSelectedCategory().id === this.oldSelectedJobCategory.id) {
            return;
        }

        let that = this;
        this.app.driver.changeEmployeeJobCategory(this.app.data.employee.id, this.getSelectedCategory().id)
            .onSuccess(rsp => {
                this.app.data.employee.jobCategoryId = this.getSelectedCategory().id;
                this.app.data.employee.jobCategoryName = this.getSelectedCategory().name;
                this.oldSelectedJobCategory = this.getSelectedCategory();
                that.modal.show('JOB_CATEGORIES.SUCCESS', "JOB_CATEGORIES.SUCCESS_MESSAGE");
            })
            .onError((error, ec, em) => {
                that.modal.show('JOB_CATEGORIES.ERROR', "JOB_CATEGORIES.ERROR_MESSAGE");
            })
            .send();
    }


}

export class JobCategoryMessageDataWrapper {
    jobCategory: JobCategoryMessageData;
    selected: boolean;

    constructor(jobCategory: JobCategoryMessageData, selected: boolean) {
        this.jobCategory = jobCategory;
        this.selected = selected;

    }
}
