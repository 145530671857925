import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class AwardQuizQuestionAnswerMessageData implements IJsonDeserializable {
    version: number = 0;
    id: number;
    questionId: number;
    text: string;
    isCorrect: boolean;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.questionId = jsonObject.questionId;
        this.text = jsonObject.text;
        this.isCorrect = jsonObject.isCorrect;
    }
}
