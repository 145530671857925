import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {AppCampaignTaskMessageData} from "./app_campaign_task_message_data";

export class AppCampaignReportTaskData implements IJsonDeserializable {
    version: number = 0;
    taskId: number;
    setTargetId?: number;
    setTargetName: string;
    updatedAt: Date;
    isFormal: boolean = false;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.taskId = jsonObject.taskId;
        this.setTargetId = jsonObject.setTargetId;
        this.setTargetName = jsonObject.setTargetName;
        this.updatedAt = deserializeDateTime(jsonObject.updatedAt);
        this.isFormal = jsonObject.isFormal;
    }

    static createFromAppCampaignTask(appCampaignTask: AppCampaignTaskMessageData) {
        const obj = new AppCampaignReportTaskData();
        obj.taskId = appCampaignTask.id;
        obj.setTargetId = appCampaignTask.employeeResultTargetId;
        obj.setTargetName = appCampaignTask.employeeResultTargetName
        obj.updatedAt = new Date();
        return obj;
    }
}