import {DomSanitizer} from "@angular/platform-browser";
import {Injectable, Pipe, PipeTransform} from "@angular/core";


@Pipe({name: 'safeStyle'})
export class SafeStyle {
    constructor(private sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    transform(style?: any) {
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }
}

@Pipe({name: 'safeHtml'})
export class SafeHtml {
    constructor(private sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    transform(html?: any) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}


@Pipe({name: 'safeTrustedUrl'})
export class SafeTrustedUrl {
    constructor(private sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    transform(value: any) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}

@Pipe({name: 'searchByName'})
@Injectable()
export class SearchByName implements PipeTransform {
    transform(items: any[], args: string[]): any[] {
        if (args) {
            return items.filter((item) => item.name.toLowerCase().includes(args.toString().toLowerCase()))
        }
        return items;

    }
}

@Pipe({name: 'searchByMultiple'})
@Injectable()
export class SearchByMultiple implements PipeTransform {
    transform(items: any[], args: string[]): any[] {
        if (args) {
            var result = items.filter((item) => {
                return item.name.toLowerCase().includes(args.toString().toLowerCase()) ||
                    item.address.toLowerCase().includes(args.toString().toLowerCase()) ||
                    item.city.toLowerCase().includes(args.toString().toLowerCase())
            });
            return result;
        }
        return items;

    }
}