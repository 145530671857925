import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {SurveyQuestionMessageData} from "../../../communication/message_data/survey_question_message_data";
import {BaseAnswer} from "./base_answer";

@Component({
    selector: 'sp-number-answer',
    templateUrl: '../../../../templates/surveys/answer_types/number-answer.component.html'
})
export class NumberAnswerComponent extends BaseAnswer implements OnInit, OnDestroy {


    @Input() question: SurveyQuestionMessageData;
    @Input() surveyId: number;
    @Input() employeeSurveyId: number;
    @Output() answerSelect = new EventEmitter();
    @ViewChild('numberAnswerInput', { static: true }) numberAnswerInput: ElementRef;

    error: string = null;

    constructor() {
        super();
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onChange() {
        this.error = null;
        if (this.inputIsValid()) {
            this.createEmployeeSurveyAnswerMessage(this.question, this.employeeSurveyId, () => {
                this.answerSelect.emit();
            });
        } else {
            this.question.employeeAnswerData = null;
            this.error = 'SURVEYS.IS_NOT_VALID';
        }
    }

    inputIsValid(): boolean {
        let isValid = true;
        if (this.question.answerText.length == 0) {
            isValid = false;
        }

        let regExp = RegExp('^[0-9]+$');
        isValid = regExp.test(this.question.answerText)

        return isValid;
    }

}