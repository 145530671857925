import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class EmployeeLeaderBoardByBrand2RequestMessage extends RequestMessage {
    employeeId: number;
    preferredLang: number;
    filterType: number;
    filterSubType: number;
    brandId: number;
    retailerId: number;
    storeId: number;
    countryId: number;
    count: number;

    constructor() {
        super();
        this.type = MessageTypes.EmployeeLeaderBoardByBrand2Request;
    }
}
