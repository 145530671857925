import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class UpdateTermsAndConditionsEmployeeIdResponseMessage extends ResponseMessage {
    termsAndConditionsId: number;

    constructor() {
        super();
        this.type = MessageTypes.UpdateTermsAndConditionsEmployeeDateResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.termsAndConditionsId = jsonObject.termsAndConditionsId;
    }
}
