import {Component, Input} from '@angular/core';
import {EventsMessageData} from "../../communication/message_data/events_message_data";

@Component({
    selector: 'sp-events-myEvents',
    templateUrl: '../../../templates/events/event_my.component.html'
})
export class MyEventsComponent {
    @Input() items: Array<EventsMessageData>;

    constructor() {
    }

}

