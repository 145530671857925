import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {AwardFulfillmentType} from "../../data/award_fulfillment_type";

export class AwardMessageData implements IJsonDeserializable, Iid {
    version: number = 4;
    id: number;
    brandId: number;
    categoryId: number;
    name: string;
    subName: string;
    description: string;
    disclaimer: string;
    msrp: string;
    body: string;
    imageFileDescriptor: string;
    awardType: number;
    forEmployeeLocked: boolean;
    forEmployeeWin: boolean;
    forEmployeeClaimed: boolean;
    forEmployeeDateWin: Date;
    forEmployeeDateClaimed: Date;

    storefront: number;
    fulfillmentType: AwardFulfillmentType;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.brandId = jsonObject.brandId;
        this.categoryId = jsonObject.categoryId;
        this.name = jsonObject.name;
        this.subName = jsonObject.subName;
        this.description = jsonObject.description;
        this.disclaimer = jsonObject.disclaimer;
        this.msrp = jsonObject.msrp;
        this.body = jsonObject.body;
        this.imageFileDescriptor = jsonObject.imageFileDescriptor;
        this.awardType = jsonObject.awardType;
        this.forEmployeeLocked = jsonObject.forEmployeeLocked;
        this.forEmployeeWin = jsonObject.forEmployeeWin;
        this.forEmployeeClaimed = jsonObject.forEmployeeClaimed;
        this.forEmployeeDateWin = deserializeDateTime(jsonObject.forEmployeeDateWin);
        this.forEmployeeDateClaimed = deserializeDateTime(jsonObject.forEmployeeDateClaimed);

        if (this.version > 3) {
            this.storefront = jsonObject.storefront;
            this.fulfillmentType = jsonObject.fulfillmentType;
        }
    }
}
