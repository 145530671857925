import {CommonStatisticMessageData} from "../communication/message_data/common_statistic_message_data";

export class CommonStatistic {
    guid: string;
    employeeId: number;
    dateOpen: Date;
    dateClose: Date;
    totalTime: number;
    device: string;
    appVersion: string;
    action: number;
    additional1int: number;
    additional2int: number;
    additional3int: number;
    additional1str: string;
    performRelated: number;

    private toMessageData(): CommonStatisticMessageData {
        let commonStatistic: CommonStatisticMessageData = new CommonStatisticMessageData();

        commonStatistic.guid = this.guid;
        commonStatistic.employeeId = this.employeeId;
        commonStatistic.dateOpen = this.dateOpen;
        commonStatistic.dateClose = this.dateClose;
        commonStatistic.totalTime = this.totalTime;
        commonStatistic.device = this.device;
        commonStatistic.appVersion = this.appVersion;
        commonStatistic.action = this.action;
        commonStatistic.additional1int = this.additional1int;
        commonStatistic.additional2int = this.additional2int;
        commonStatistic.additional3int = this.additional3int;
        commonStatistic.additional1str = this.additional1str;
        commonStatistic.performRelated = this.performRelated;

        return commonStatistic;
    }

    public static toMessageData(records: Array<CommonStatistic>): CommonStatisticMessageData[] {
        let results: Array<CommonStatisticMessageData> = [];

        for (let record of records) {
            results.push(record.toMessageData());
        }
        return results;
    }
}