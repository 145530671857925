import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../app";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {ReferenceListComponent} from "./reference_list.component";
import {CoursesFilterComponent} from "../common/courses_filter.component";
import {CategoryMessageData} from "../../communication/message_data/category_message_data";
import {BrandExtendedMessageData} from "../../communication/message_data/brand_extended_message_data";
import {CourseFilterData} from "../common/course_filter_data";

@Component({
    selector: 'sp-toolbox',
    templateUrl: '../../../templates/reference/toolbox.component.html'

})
export class ToolboxComponent implements OnInit {
    app: App = App.instance;
    @Output() courses: Array<CourseMessageData>;
    @ViewChild('cfilter', { static: true }) filter: CoursesFilterComponent;
    @ViewChild('crTools', { static: true }) coursesToolsComponent: ReferenceListComponent;

    isFilterOpen: boolean = false;
    isFilterSet: boolean = false;

    ngOnInit(): any {
        this.courses = this.app.data.getBrandToolbox();
    }

    constructor(private router: Router) {
    }

    public onFilterButtonClick() {
        if (this.isFilterOpen) {
            this.isFilterOpen = false;
            return;
        }

        let fd: CourseFilterData;
        let ab: Array<BrandExtendedMessageData>;
        let ac: Array<CategoryMessageData>;


        fd = this.coursesToolsComponent.filterData;
        ab = this.coursesToolsComponent.getAllBrands();
        ac = this.coursesToolsComponent.getAllCategories();

        this.filter.filterData = fd;
        this.filter.allBrands = ab;
        this.filter.allCategories = ac;

        this.filter.init();
        this.isFilterOpen = true;
    }

    onFilterChanged(fd: CourseFilterData) {
        this.coursesToolsComponent.filterList(fd);
        this.isFilterSet = fd.isSet();
    }
}


