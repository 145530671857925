import {SurveyQuestionMessageData} from "../communication/message_data/survey_question_message_data";
import {SurveyQuestionAnswerMessageData} from "../communication/message_data/survey_question_answer_message_data";
import {SurveyMessageData} from "../communication/message_data/survey_message_data";
import {TryStartSurveyResponseMessage} from "../communication/messages/try_start_survey_response_message";

export class UiSurveyData {
    data: SurveyMessageData;
    surveyId: number = null;
    employeeSurveyId: number = null;

    constructor(surveyData: SurveyMessageData, brandImageUrl: String) {
        this.data = surveyData;
        this.data.brandImage = brandImageUrl;
        console.log(this.data)
    }


    public normalizeSurveysData(rsp: TryStartSurveyResponseMessage) {
        let surveyQuestions: Array<SurveyQuestionMessageData> = rsp.questions;
        let surveyQuestionAnswers: Array<SurveyQuestionAnswerMessageData> = rsp.answers;
        this.surveyId = rsp.surveyId;
        this.employeeSurveyId = rsp.employeeSurveyId;
        for (let surveyQuestion of surveyQuestions) {
            surveyQuestionAnswers.filter(item => item.questionId == surveyQuestion.id).forEach(answer => {
                surveyQuestion.answers.push(answer);
            });
            this.data.questions.push(surveyQuestion);
        }
    }
}