import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class RecentlyAwardedMessageData implements IJsonDeserializable {
    version: number = 1;

    id: number;
    brandId: number;
    brandName: string;
    awardId: number;
    awardName: string;

    employeeName: string;
    employeeLogin: string;
    date: Date;
    awardType: number;

    storeId: number;
    storeName: string;
    stateId: number;
    stateName: string;
    countryId: number;
    countryName: string;

    awardImageFileDescriptor: string;

    retailerId: number;
    retailerName: string;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.brandId = jsonObject.brandId;
        this.brandName = jsonObject.brandName;
        this.awardId = jsonObject.awardId;
        this.awardName = jsonObject.awardName;

        this.employeeName = jsonObject.employeeName;
        this.employeeLogin = jsonObject.employeeLogin;
        this.date = deserializeDateTime(jsonObject.date);
        this.awardType = jsonObject.awardType;

        this.storeId = jsonObject.storeId;
        this.storeName = jsonObject.storeName;
        this.stateId = jsonObject.stateId;
        this.stateName = jsonObject.stateName;
        this.countryId = jsonObject.countryId;
        this.countryName = jsonObject.countryName;

        this.awardImageFileDescriptor = jsonObject.awardImageFileDescriptor;

        this.retailerId = jsonObject.retailerId;
        this.retailerName = jsonObject.retailerName;
    }
}
