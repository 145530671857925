import {DriverWithMessages} from "../communication/driver_with_messages";
import {App} from "../app";
import {EmployeeQuizAttemptMessageData} from "../communication/message_data/employee_quiz_attempt_message_data";
import {EmployeeQuizAnswerMessageData} from "../communication/message_data/employee_quiz_answer_message_data";
import {UUID} from "../common/uuid";
import {QuizQuestionAnswerMessageData} from "../communication/message_data/quiz_question_answer_message_data";
import {QuizQuestionType} from "./quiz_question_type";
import {EmployeeQuizAnswersResponseMessage} from "../communication/messages/employee_quiz_answers_response_message";
import {QuizQuestionMessageData} from "../communication/message_data/quiz_question_message_data";

export class SendQuizStatistic {
    private server: DriverWithMessages;
    public app: App;

    constructor(app: App) {
        this.app = app;
    }

    public quizAnswersToMessageData(selectedAnswers: Array<QuizQuestionAnswerMessageData>, quizGuid: string, quizQuestions: Array<QuizQuestionMessageData>): Array<EmployeeQuizAnswerMessageData> {
        let result: Array<EmployeeQuizAnswerMessageData> = new Array<EmployeeQuizAnswerMessageData>();

        for (let question of quizQuestions) {
            let md: EmployeeQuizAnswerMessageData = new EmployeeQuizAnswerMessageData();
            let selectedQuestionAnswers = selectedAnswers.filter(answer => answer.questionId == question.id);
            let questionAnswers = question.answers;
            let questionCorrectAnswersCount: number = questionAnswers.filter(ans => ans.isCorrect).length;
            let correctSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => ans.isCorrect).length;
            let incorrectSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => !ans.isCorrect).length;

            md.guid = UUID.create();
            md.attemptGuid = quizGuid;
            md.questionId = question.id;
            md.questionType = question.questionType;
            md.stringContent = null;
            md.answeredAt = questionAnswers[0].answeredAt;
            md.questionAnswerId = selectedQuestionAnswers[0].id;

            if (question.questionType == QuizQuestionType.SingleSelect) {
                md.correct = questionAnswers[0].isCorrect;
            }

            if (question.questionType == QuizQuestionType.MultiSelectAny) {
                md.correct = correctSelectedAnswersCount > 0 && incorrectSelectedAnswersCount == 0;
            }

            if (question.questionType == QuizQuestionType.MultiSelectAll) {
                md.correct = correctSelectedAnswersCount == questionCorrectAnswersCount && incorrectSelectedAnswersCount == 0;
            }

            selectedQuestionAnswers.forEach((a, index, array) => {
                md.questionAnswerIds += a.id.toString();
                if (array.length - 1 > index) {
                    md.questionAnswerIds += ",";
                }
            });
            result.push(md);
        }
        return result;
    }

    public trySendData(attempt: EmployeeQuizAttemptMessageData, answers: Array<EmployeeQuizAnswerMessageData>) {
        let attempts = new Array<EmployeeQuizAttemptMessageData>();
        attempts.push(attempt);
        this.app.driver.reportQuizAnswers(attempts, answers).onSuccess((rsp: EmployeeQuizAnswersResponseMessage) => {
            if (rsp.error != null)
                console.log("something went wrong with 'EmployeeQuizAnswersRequestMessage'");

        }).onError((e, ec, em, responce) => {
            console.log("something went wrong with 'EmployeeQuizAnswersRequestMessage'");
        }).send();
    }

}
