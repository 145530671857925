export enum QuizQuestionType {
    Unknown = 0,
    SingleSelect = 1,
    MultiSelectAny = 2,
    MultiSelectAll = 3,

    ImageCamera = 4,
    ImageLibrary = 5,
    OpenTextSingleLine = 6,
    OpenTextSingleLineMaxChars = 7,
    OpenTextSingleLineRegex = 8,
    OpenTextMultiLine = 9,
    OpenTextMultiLineMaxChars = 10,
    Numbers = 11,
    DateTime = 12,
    Rate = 13
}