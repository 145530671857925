import {Injectable, ViewChild} from '@angular/core';
import {SpModalComponent} from 'app/ts/ui/common/sp_modal.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public modal: SpModalComponent

    constructor() {
    }

    show(title: string, message: string, okButtonText?: string, cancelButtonText?: string, onSubmit?: () => void, onCancel?: () => void) {
        this.modal.show(title, message, okButtonText, cancelButtonText, onSubmit, onCancel);
    }

}
