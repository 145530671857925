import {MessageTypes} from "../message_types";
import {ResponseMessage} from "../response_message";

export class ReportSurveyResponseMessage extends ResponseMessage {

    constructor() {
        super();
        this.type = MessageTypes.ReportSurveyResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
    }
}