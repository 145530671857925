import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'survey-file-upload',
    templateUrl: '../../../../templates/surveys/helpers/survey-file-upload.component.html'
})
export class SurveyFileUploadComponent {
    @Input() private allowed_extensions: Array<string> = [];
    @Input() public uploadedImageData: any = null;
    @Output() filesChange = new EventEmitter<any>();
    @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
    @ViewChild('imageWrapper', { static: true }) imageWrapper: ElementRef;
    @ViewChild('input', { static: true }) inputField: ElementRef;
    error: string = "";
    public imageWidth: string = "";
    public imageHeight: string = "";
    private imageData = {
        base64Data: "",
        name: "",
        base64DataServer: ""
    };

    constructor() {
    }


    ngOnInit(): void {
    }

    ngOnChanges() {
        if (this.uploadedImageData.length > 0) {
            this.imageLoad(this.uploadedImageData);
        }
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.wrapper.nativeElement.style.background = '#999'
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.wrapper.nativeElement.style.background = '#eee'
    }

    @HostListener('drop', ['$event'])
    public onDrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.wrapper.nativeElement.style.background = '#eee'
        let files = evt.dataTransfer.files;
        this.processFile(files);
    }

    private processFile(files: FileList) {
        if (files.length > 0) {
            let file = files[0];
            let ext = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
            if (this.allowed_extensions.lastIndexOf(ext) != -1) {
                this.error = null;
                this.getBase64String(file, (result: string) => {
                    this.imageData.base64Data = result;
                    this.imageData.name = file.name;
                    this.imageLoad(result);
                    this.filesChange.emit(this.imageData);
                });
            }
            else {
                this.imageData = {
                    base64Data: "",
                    name: "",
                    base64DataServer: ""
                };
                this.error = 'SURVEYS.FILE_NOT_SUPPORTED';
                this.filesChange.emit(null);
            }
        }
    }

    private getBase64String(file: File, callBack: any): void {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callBack(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    imageLoad(source: string) {
        let newImg = new Image();
        newImg.onload = () => {
            let imageWidth = +newImg.width;
            let imageHeight = +newImg.height;
            let clientWidth = +this.imageWrapper.nativeElement.clientWidth;
            let clientHeight = +this.imageWrapper.nativeElement.clientHeight;
            let xRatio = imageWidth / clientWidth;
            let yRatio = imageHeight / clientHeight;
            let ratio = Math.max(xRatio, yRatio);
            if (ratio > 1) {
                imageWidth = imageWidth / ratio;
                imageHeight = imageHeight / ratio;
            }
            this.imageWidth = "" + imageWidth;
            this.imageHeight = "" + imageHeight;
        };
        newImg.src = source;
        this.uploadedImageData = source;
    }

    onClick() {
        let event = new MouseEvent('click', {bubbles: false});
        this.inputField.nativeElement.dispatchEvent(event);
    }

    onFileSelected(event: any) {
        this.processFile(this.inputField.nativeElement.files)
    }

}
