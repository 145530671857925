import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class TryStartSurveyRequestMessage extends RequestMessage {

    employeeId: number;
    preferredLang: number;
    surveyId: number;
    deviceUUID: string;

    constructor() {
        super();
        this.type = MessageTypes.TryStartSurveyRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
        this.surveyId = jsonObject.surveyId;
        this.deviceUUID = jsonObject.deviceUUID;
    }
}