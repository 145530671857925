import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class RegisterStoreMessageData implements IJsonDeserializable {
    version: number = 2;
    storeNumber: string;
    address: string;
    city: string;
    stateId: number;
    zip: string;
    description: string;
    retailerId: number;
    googlePlaceId: string;
    lat: number;
    lng: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.storeNumber = jsonObject.storeNumber;
        this.address = jsonObject.address;
        this.city = jsonObject.city;
        this.stateId = jsonObject.stateId;
        this.zip = jsonObject.zip;
        this.description = jsonObject.description;
        this.retailerId = jsonObject.retailerId;
        this.googlePlaceId = jsonObject.googlePlaceId;
        this.lat = jsonObject.lat;
        this.lng = jsonObject.lng;
    }
}
