import {Component} from '@angular/core';

@Component({
    selector: 'sp-register-employeer',
    templateUrl: '../../../templates/register/employer.component.html'

})

export class EmployerComponent {
}
