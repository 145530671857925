import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class AwardToCourseRelationMessageData implements IJsonDeserializable {
    version: number = 0;

    awardId: number;
    courseId: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.awardId = jsonObject.awardId;
        this.courseId = jsonObject.courseId;
    }
}
