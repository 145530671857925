import {EventsMessageData} from "../communication/message_data/events_message_data";
import {EventLocationType} from "./event_location_type";
import {EventAttachmentMessageData} from "../communication/message_data/event_attachment_message_data";
import Dictionary from "../collections/Dictionary";
import {HelperMethods} from "../common/helper_methods";
import {RegistrationStatus} from "./event_registration_status";
import {Observable, Subscription, timer} from "rxjs";

export class UIEventData {
    private _attachments: Array<EventAttachmentMessageData>;
    private _event: EventsMessageData;
    private _attachmentType: Dictionary<number, string> = new Dictionary<number, string>();
    public imageUrl: string;
    private _remainingTimeTimerSubscription: Subscription;
    public timeRemaining: string;

    public onlineEventCheckInAvailable: boolean;
    public onlineEventCheckInExpired: boolean;
    private registrationStatus: RegistrationStatus = RegistrationStatus.unknown;
    private registrationStatusText: string;
    private tickerObservable: Observable<number>;
    private _attendanceCodeFormTimerSubscription: Subscription;
    public isAttendanceCodeFormVisible: boolean = false;

    constructor(event: EventsMessageData, attachments: Array<EventAttachmentMessageData>) {
        this._event = event;
        this._attachments = attachments;
        this.tickerObservable = timer(0, 1000);
        this.updateRegistrationStatusText();
        if (this._attachments.length > 0) {
            this._attachments.forEach(item => {
                let type: string = HelperMethods.getFileExtension(item.fileDescriptor);
                this._attachmentType.setValue(item.id, this.getAttachmetIconByType(type));
            });
        }
    }

    get locationType(): string {
        switch (this._event.locationType) {
            case EventLocationType.Physical:
                return 'EVENTS.LIVE_EVENT';
            case EventLocationType.Virtual:
                return 'EVENTS.VIRTUAL_EVENT';
            case EventLocationType.SingleStore:
                return 'EVENTS.LIVE_EVENT';
            default:
                return "";
        }
    }

    get locationInfoListItem(): string {
        switch (this._event.locationType) {
            case EventLocationType.Virtual:
                return 'EVENTS.VIRTUAL_EVENT';
            case EventLocationType.Physical:
            case EventLocationType.SingleStore:
                return String(this._event.locationCity.toString() + ', ' + this._event.locationStateAbrv.toString() + ', ' + this._event.locationZip.toString());
            default:
                return "";
        }
    }

    get seatsLeft(): string {
        let count = this._event.maxOccupancy - this._event.countRegistered;
        if (count <= 50 || count == 0) {
            return count.toString();
        } else {
            return String();
        }
    }

    get imageFileDescriptor(): string {
        if (this._event.brand.imageFileDescriptor) {
            return this._event.brand.imageFileDescriptor;
        } else {
            return '/assets/images/default_brand_category_image.jpg';
        }
    }

    public loadData() {
        this.updateRegistrationStatusText();
        this.stopTimers();
        if (this._event.locationType == EventLocationType.Virtual) {
            if(this._event.checkInAllowBeforeMin > 0){
                this.startRemainingTimeTimer();
            }

            if(this._event.requiredAttendanceCode && this._event.employeeIsJoined && !this._event.employeeIsAttend){
                this.startAttendanceCodeFormTimerAndShowForm();
            }

            this.normalizeDataForVirtualEvent();
        }
    }

    get eventDate(): number {
        return this._event.dateUtcMillis;
    }

    public stopRemainingTimeTimer() {
        if (this._remainingTimeTimerSubscription != null)
            this._remainingTimeTimerSubscription.unsubscribe();
        this._remainingTimeTimerSubscription = null;
    }

    private startRemainingTimeTimer() {
        if (this._remainingTimeTimerSubscription != null)
            this.stopRemainingTimeTimer();
        this._remainingTimeTimerSubscription = this.tickerObservable.subscribe(t => this.updateRemainingTimerTextAndUIRegisteredAvailability(t));
    }

    updateRemainingTimerTextAndUIRegisteredAvailability(tick: number) {
        let nowUtc = Date.now();
        let eventUtc = this._event.dateUtcMillis - (this._event.checkInAllowBeforeMin * 1000 * 60);
        let diff: number = eventUtc - nowUtc;

        if (diff <= 0) {
            this.stopRemainingTimeTimer();
            this.updateUIForRegisteredAvailableCheckIn();
            return;
        }
        this.timeRemaining = this.countString(diff);
    }

    public stopAttendanceCodeFormTimer() {
        if (this._attendanceCodeFormTimerSubscription != null)
            this._attendanceCodeFormTimerSubscription.unsubscribe();
        this._attendanceCodeFormTimerSubscription = null;
    }

    private startAttendanceCodeFormTimerAndShowForm() {
        this.hideAttendanceCodeForm();
        if (this._attendanceCodeFormTimerSubscription != null)
            this.stopAttendanceCodeFormTimer();
        this._attendanceCodeFormTimerSubscription = this.tickerObservable.subscribe(t => this.updateAttendanceCodeFormUI(t));
    }

    updateAttendanceCodeFormUI(tick: number) {
        if(this.showAttendanceForm){
            this.showAttendanceCodeForm();
            this.stopAttendanceCodeFormTimer();
        }
    }

    get showAttendanceForm(){
        const fiveTeenMinutes = 15 * 60000;
        let nowUtc = new Date().getTime();
        let eventUtc = this._event.dateUtcMillis;
        return (nowUtc >= (eventUtc + fiveTeenMinutes)) && this._event.requiredAttendanceCode && this._event.employeeIsJoined && !this._event.employeeIsAttend;
    }

    stopTimers(){
        this.stopRemainingTimeTimer();
        this.stopAttendanceCodeFormTimer();
    }

    private countString(distance: number): string {
        var hours = "" + Math.floor(distance / (1000 * 60 * 60));
        var minutes = "" + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = "" + Math.floor((distance % (1000 * 60)) / 1000);

        if (hours.length < 2)
            hours = "0" + hours;
        if (minutes.length < 2)
            minutes = "0" + minutes;
        if (seconds.length < 2)
            seconds = "0" + seconds;

        return hours + ":" + minutes + ":" + seconds;
    }

    getAttachmetIconByType(attachmentExtension: string): string {
        switch (attachmentExtension) {
            case "png":
            case "jpeg":
            case "jpg":
                return "sp-image";
            case "pdf":
                return "sp-pdf";
            case "mp4":
                return "sp-video";
            default:
                return '';
        }
    }

    private normalizeDataForVirtualEvent() {
        let nowUtc = new Date().getTime();
        let eventUtc = this._event.dateUtcMillis;

        let startChecking = eventUtc - this._event.checkInAllowBeforeMin * 60000;
        let endChecking = eventUtc + this._event.checkInAllowAfterMin * 60000;

        if (!this._event.employeeIsRegistered) {
            this.updateUIForRegistration();
            return;
        }

        if (this._event.employeeIsAttend) {
            if (nowUtc > endChecking) {
                this.updateUIForRegisteredCheckInExpiried();
                return;
            }
            this.updateUIForRegisteredAvailableCheckIn();
            return;
        }
        if (nowUtc < startChecking) {
            this.updateUIForRegisteredUnavailableCheckIn();
            return;
        }
        if (nowUtc > endChecking) {
            this.updateUIForRegisteredCheckInExpiried();
            return;
        } else {
            this.updateUIForRegistration();
            return;
        }
    }

    private updateUIForRegistration() {
        this.onlineEventCheckInAvailable = false;
    }

    private showAttendanceCodeForm() {
        this.isAttendanceCodeFormVisible = true;
    }

    private hideAttendanceCodeForm() {
        this.isAttendanceCodeFormVisible = false;
    }

    private updateUIForRegisteredAvailableCheckIn() {
        this.onlineEventCheckInAvailable = true;
    }

    private updateUIForRegisteredUnavailableCheckIn() {
        this.onlineEventCheckInAvailable = false;
    }

    private updateUIForRegisteredCheckInExpiried() {
        this.stopRemainingTimeTimer();
        this.onlineEventCheckInAvailable = false;
        this.onlineEventCheckInExpired = true;
    }

    public attachmentType(id: number): string {
        return this._attachmentType.getValue(id);
    }

    get addressDetail(): string {
        let address = "";

        switch (this._event.locationType) {
            case EventLocationType.Virtual:
                address += this._event.address;
                break;
        }

        return address;
    }

    get event(): EventsMessageData {
        return this._event;
    }

    get attachments(): Array<EventAttachmentMessageData> {
        return this._attachments;
    }

    private getRegistrationStatus(): RegistrationStatus {
        let nowUtc = new Date().getTime();
        let eventUtc = this._event.dateUtcMillis;

        let startChecking = eventUtc - this._event.checkInAllowBeforeMin * 60000;
        let endChecking = eventUtc + this._event.checkInAllowAfterMin * 60000;

        if (!this._event.employeeIsRegistered) {
            if (nowUtc > endChecking) {
                return RegistrationStatus.closedRegistration;
            }
            return RegistrationStatus.awaitRegistration;
        }

        if (this._event.employeeIsAttend) {
            if (nowUtc > endChecking)
                return RegistrationStatus.closedJoin;

            return RegistrationStatus.checkedIn;
        }
        if (nowUtc < startChecking)
            return RegistrationStatus.awaitCheckIn;

        if (nowUtc > endChecking)
            return RegistrationStatus.closedCheckIn;

        return RegistrationStatus.allowCheckIn;
    }

    public updateRegistrationStatusText(): void {
        let status: string = "";
        if (this._event.requiredAttendanceCode && this._event.employeeIsJoined && !this._event.employeeIsAttend) {
            status = "EVENTS.JOINED";
        } else if (this._event.requiredAttendanceCode && this._event.employeeIsJoined && this._event.employeeIsAttend) {
            status = "EVENTS.ATTENDED";
        } else {
            this.registrationStatus = this.getRegistrationStatus();
            switch (this.registrationStatus) {
                case RegistrationStatus.closedRegistration:
                    status = "EVENTS.REGISTRATION_IS_NOW_CLOSED";
                    break;
                case RegistrationStatus.closedCheckIn:
                    status = "EVENTS.CHECK_IN_IS_NOW_CLOSED";
                    break;
                case RegistrationStatus.closedJoin:
                    status = "EVENTS.NO_LONGER_JOIN_THIS_EVENT";
                    break;
                case RegistrationStatus.awaitCheckIn:
                    status = "EVENTS.AWAIT_CHECK_IN";
                    break;
                case RegistrationStatus.awaitRegistration:
                    status = "EVENTS.NOT_REGISTERED";
                    break;
                case RegistrationStatus.allowCheckIn:
                    status = "EVENTS.REGISTERED";
                    break;
                case RegistrationStatus.checkedIn:
                    status = "EVENTS.CHECKED";
            }
        }
        this.registrationStatusText = status;
    }

}
