import {Component, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {RegisterComponent} from "./register.component";
import {StringExtensions} from "../../common/extensions";
import {RegistrationHelper} from "./RegistrationHelper";
import {CheckRegistrationEmailResponseMessage} from "../../communication/messages/check_registration_email_response_message";
import {ChangeEmailResponseMessage} from "../../communication/messages/change_email_response_message";
import {SpModalComponent} from "../common/sp_modal.component";
import {EmployeeType} from "../../data/employee_type";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-register-change-email',
    templateUrl: '../../../templates/register/change_email.component.html'

})
export class ChangeEmailComponent {
    public registerData: RegisterData;
    public app: App = App.instance;
    public model: ChangeEmailData;

    constructor(private registerComponent: RegisterComponent, private router: Router, private modal:ModalService) {
        this.registerData = registerComponent.RegisterData;
        this.model = new ChangeEmailData();
        this.model.registerData = this.registerData;
    }


    ActionButtonTapped() {
        this.app.driver.changeEmail(this.registerData.UserId != null ? this.registerData.UserId : 0, this.model.newEmail, this.registerData.Email, true)
            .onSuccess((rsp: ChangeEmailResponseMessage) => {
                if (rsp.success) {
                    this.registerData.Email = this.model.newEmail;
                    this.modal.show('CHANGE_EMAIL.SUCCESS', "CHANGE_EMAIL.EMAIL_SUCCESSFULLY_CHANGED");
                } else {
                    this.modal.show('CHANGE_EMAIL.ERROR', "CHANGE_EMAIL.EMAIL_IS_NOT_VALID");
                }

            })
            .onError((e, ec, em) => {
                this.modal.show('CHANGE_EMAIL.ERROR', "CHANGE_EMAIL.LOST_CONNECTION_TO_SERVER");
            })
            .send();
    }

    BackButtonTapped() {
        this.router.navigate(['register', 'email_verification'])
    }
}

export class ChangeEmailData {


    private _newEmail: string;
    private _confirmEmail: string;
    private _newEmailError: string = "";
    private _confirmEmailError: string = "";
    public isEmailValidOnServer = false;
    public registerData: RegisterData;

    get newEmail(): string {
        return this._newEmail;
    }

    set newEmail(value: string) {
        this._newEmail = value;
    }

    get confirmEmail(): string {
        return this._confirmEmail;
    }

    set confirmEmail(value: string) {
        this._confirmEmail = value;
    }

    get newEmailError(): string {
        return this._newEmailError;
    }

    set newEmailError(value: string) {
        this._newEmailError = value;
    }

    get confirmEmailError(): string {
        return this._confirmEmailError;
    }

    set confirmEmailError(value: string) {
        this._confirmEmailError = value;
    }

    get isValid(): boolean {
        if (StringExtensions.isNullorEmpty(this.newEmailError) && StringExtensions.isNullorEmpty(this.confirmEmailError)) {
            return true;
        }
        return false;
    }

    public Validate() {

        if (StringExtensions.isNullorEmpty(this.newEmail)) {
            this.newEmailError = "CHANGE_EMAIL.EMAIL_IS_REQUIRED";
        }
        else {
            if (!StringExtensions.isNullorEmpty(this.newEmail) && !StringExtensions.IsEmail(this.newEmail)) {
                this.newEmailError = "CHANGE_EMAIL.EMAIL_IS_NOT_VALID";
            }
            else {

                this.isEmailValidOnServer = false;
                let that = this;
                RegistrationHelper.IsEmailAvailable(this.newEmail, function (rsp: CheckRegistrationEmailResponseMessage) {
                    if (rsp != null) {
                        switch (that.registerData.UserType) {
                            case EmployeeType.retailer:
                                if (rsp.isValidForRetailer) {
                                    that.newEmailError = '';
                                    that.isEmailValidOnServer = true;
                                }
                                else {
                                    that.newEmailError = "CHANGE_EMAIL.EMAIL_IS_ALREADY_IN_USE";
                                }

                                break;

                            case EmployeeType.brand:
                                if (rsp.isValidForBrand) {
                                    that.newEmailError = '';
                                    that.isEmailValidOnServer = true;
                                }
                                else {
                                    that.newEmailError = "CHANGE_EMAIL.EMAIL_IS_NOT_ALLOWED";
                                }

                                break;
                        }
                    }
                }, function (er) {
                    that.newEmailError = "CHANGE_EMAIL.SERVER_DID_NOT_RESPOND";
                });
            }
            if (StringExtensions.isNullorEmpty(this.confirmEmail)) {
                this.confirmEmailError = "CHANGE_EMAIL.CONFIRM_EMAIL_IS_REQUIRED";
            }
            else {
                if (!StringExtensions.isNullorEmpty(this.newEmail) && this.newEmail != this.confirmEmail) {
                    this.confirmEmailError = "CHANGE_EMAIL.EMAILS_DO_NOT_MATCH";
                }
                else {
                    this.confirmEmailError = '';
                }
            }
        }
    }

}

