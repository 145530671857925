import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class CommonStatisticMessageData implements IJsonDeserializable {
    version: number = 0;

    guid: string;
    employeeId: number;
    dateOpen: Date;
    dateClose: Date;
    totalTime: number;
    device: string;
    appVersion: string;
    action: number;
    additional1int: number;
    additional2int: number;
    additional3int: number;
    additional1str: string;
    performRelated: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.guid = jsonObject.guid;
        this.employeeId = jsonObject.employeeId;
        this.dateOpen = jsonObject.dateOpen;
        this.dateClose = jsonObject.dateClose;
        this.totalTime = jsonObject.totalTime;
        this.device = jsonObject.device;
        this.appVersion = jsonObject.appVersion;
        this.action = jsonObject.action;
        this.additional1int = jsonObject.additional1int;
        this.additional2int = jsonObject.additional2int;
        this.additional3int = jsonObject.additional3int;
        this.additional1str = jsonObject.additional1str;
        this.performRelated = jsonObject.performRelated;
    }
}
