import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class CheckRegistrationUsernameRequestMessage extends RequestMessage {
    userType: number;
    userName: string;
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.CheckRegistrationUsernameRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.userType = jsonObject.userType;
        this.userName = jsonObject.userName;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
