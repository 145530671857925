import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {ActionStatisticMessageData} from "../message_data/action_statistic_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class ActionStatisticRequestMessage extends RequestMessage {
    records = new Array<ActionStatisticMessageData>();
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.ActionStatisticRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.records = deserializeArrayOfObject(jsonObject.records, ActionStatisticMessageData);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
