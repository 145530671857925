import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {SurveyQuestionMessageData} from "../../../communication/message_data/survey_question_message_data";
import {QuizQuestionType} from "../../../data/quiz_question_type";
import {BaseAnswer} from "./base_answer";

@Component({
    selector: 'sp-open-text-answer',
    templateUrl: '../../../../templates/surveys/answer_types/open-text-answer.component.html'
})
export class OpenTextAnswerComponent extends BaseAnswer implements OnInit, OnDestroy {

    @Input() question: SurveyQuestionMessageData;
    @Input() surveyId: number;
    @Input() employeeSurveyId: number;
    @Output() answerSelect = new EventEmitter();
    @ViewChild('openAnswerInput', { static: false }) openAnswerInput: ElementRef;

    error: string = null;

    constructor() {
        super();
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onChange() {
        this.error = null;
        if (this.inputIsValid()) {
            this.createEmployeeSurveyAnswerMessage(this.question, this.employeeSurveyId, () => {
                this.answerSelect.emit();
            });
        } else {
            this.question.employeeAnswerData = null;
            this.error = 'SURVEYS.IS_NOT_VALID';
        }
    }

    inputIsValid(): boolean {
        let isValid = true;
        if (this.question.answerText.length == 0) {
            isValid = false;
        }

        switch (this.question.type) {
            case QuizQuestionType.OpenTextSingleLineMaxChars:
            case QuizQuestionType.OpenTextMultiLineMaxChars:
                isValid = this.question.answerText.length <= Number(this.question.filter) && this.question.answerText.length > 0;
                break;
            case QuizQuestionType.OpenTextSingleLineRegex:
                let regExp = RegExp(this.question.filter);
                isValid = regExp.test(this.question.answerText)
                break;
        }

        return isValid;
    }

    isMultiLineAnswer(): boolean {
        return this.question.type == QuizQuestionType.OpenTextMultiLine || this.question.type == QuizQuestionType.OpenTextMultiLineMaxChars
    }
}