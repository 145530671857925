import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterComponent} from "./register.component";
import {App} from "../../app";
import {RegisterData} from "./register.data";

@Component({
    selector: 'sp-register-profile',
    templateUrl: '../../../templates/register/profile.component.html'

})

export class ProfileComponent {
    public registerData: RegisterData;
    public app: App = App.instance;

    constructor(private registerComponent: RegisterComponent, private router: Router) {
        this.registerData = registerComponent.RegisterData;
    }


    ActionButtonTapped() {
        if (this.registerData.IsEmailValidOnServer
            && this.registerData.IsProfileStepValid) {
            this.router.navigate(['register', 'preferences'])
        }

    }

    BackButtonTapped() {
        this.router.navigate(['register', 'retailer_employer'])
    }

    validate() {
        this.registerData.ValidateProfile();
    }
}

