import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class JobCategoriesRequestMessage extends RequestMessage {
    userType: number;
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.JobCategoriesRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.userType = jsonObject.userType;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
