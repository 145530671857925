import {Injectable} from "@angular/core";
import {UiSurveyData} from "../../../data/ui_survey_data";


@Injectable()
export class SurveyDetailService {

    private data: UiSurveyData;

    constructor() {
    }

    setSurverData(surveyData: UiSurveyData) {
        this.data = surveyData;
    }

    getSurverData() {
        return this.data;
    }

    resetSurveyData() {
        this.data = null
    }

}