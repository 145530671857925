import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {CourseFilterData} from "../common/course_filter_data";
import {BrandExtendedMessageData} from "../../communication/message_data/brand_extended_message_data";
import {CategoryMessageData} from "../../communication/message_data/category_message_data";

@Component({
    selector: 'sp-reference-list',
    templateUrl: '../../../templates/reference/reference_list.component.html'
})
export class ReferenceListComponent implements OnInit {
    @Input() courses: Array<CourseMessageData>;
    filteredList: Array<CourseMessageData>;

    filterData: CourseFilterData = new CourseFilterData();

    router: Router;

    constructor(router: Router) {
        this.router = router;
    }

    ngOnInit(): any {
        this.filteredList = this.courses;
        this.updateData();
    }

    getAllBrands(): Array<BrandExtendedMessageData> {
        let d: Array<BrandExtendedMessageData> = [];
        for (let c of this.courses) {
            let b = c.brand;
            if (d.some(p => p.id == b.id))
                continue;

            d.push(b);
        }

        return d;
    }

    getAllCategories(): Array<CategoryMessageData> {
        let d: Array<CategoryMessageData> = [];
        for (let c of this.courses) {
            let b = c.category;
            if (d.some(p => p.id == b.id))
                continue;

            d.push(b);
        }

        return d;
    }

    onListItemSelected(course: CourseMessageData) {
        this.router.navigate(['main', 'reference_detail', course.id]);
    }

    filterList(fd: CourseFilterData) {
        this.filterData = fd;
        this.updateData();
    }

    updateData() {
        this.filteredList = this.courses.filter(p => this.filterData.match(p)).sort((a, b) => {
                switch (this.filterData.sortType) {
                    case CourseFilterData.ByLastUpdated:
                        return a.id - b.id;
                    case CourseFilterData.ByTitle:
                        return a.decoratedNameByState() > b.decoratedNameByState() ? 1 : -1;
                    case CourseFilterData.ByBrand:
                        return a.brand.name > b.brand.name ? 1 : -1;
                }
            }
        );
    }
}
