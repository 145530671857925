import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class ActionStatisticMessageData implements IJsonDeserializable {
    version: number = 0;

    guid: string;
    employeeId: number;
    action: number;
    dateTime: Date;
    reason: number;
    place: number;
    device: string;
    appVersion: string;
    additional1int: number;
    additional2int: number;
    additional3int: number;
    additional1str: string;

    performRelated: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.guid = jsonObject.guid;
        this.employeeId = jsonObject.employeeId;
        this.action = jsonObject.action;
        this.dateTime = deserializeDateTime(jsonObject.dateTime);
        this.reason = jsonObject.reason;
        this.place = jsonObject.place;
        this.device = jsonObject.device;
        this.appVersion = jsonObject.appVersion;
        this.additional1int = jsonObject.additional1int;
        this.additional2int = jsonObject.additional2int;
        this.additional3int = jsonObject.additional3int;
        this.additional1str = jsonObject.additional1str;

        this.performRelated = jsonObject.performRelated;
    }
}
