import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../../app";
import {CourseMessageData} from "../../../communication/message_data/course_message_data";
import {EmployeeType} from "../../../data/employee_type";
import {MenuComponent, MenuItemName} from "../../common/menu";

@Component({
    selector: 'sp-retailer-home',
    templateUrl: '../../../../templates/home/retailer/home_retailer.component.html'

})
export class RetailerComponent implements OnInit {


    public app: App = App.instance;
    countCourses: number;
    @Input() courses: Array<CourseMessageData>;
    @Input() fullName: string;
    @Input() details: string;
    @Input() image: string;
    @Input() certificates: string;
    empPoints: string;
    countEvents: number;
    menuItemName: MenuItemName;
    private router: Router;

    grsBalance: number;
    withoutStoreFront: boolean;


    ngOnInit(): any {
        this.countCourses = this.app.data.getCountInProgressCourses();
        this.countEvents = this.app.data.getMyEventsCount();
        this.image = this.app.data.employee.retailer.imageFileDescriptor;
        if (!this.image) {
            this.image = '/assets/images/sign_in_logo.png';
        }
        else {
            this.image = this.app.data.employee.retailer.imageFileDescriptor;
        }
        this.fullName = this.app.data.employee.firstName + " " + this.app.data.employee.lastName;
        if (this.app.data.employee.role == EmployeeType.retailer) {
            this.details = this.app.data.employee.retailerName + " # " + this.app.data.employee.storeName;
        }
        else {
            this.details = this.app.data.employee.role + "";
        }
        this.certificates = this.app.data.getCertificateCount() + "";
        this.empPoints = this.app.getEmployeePoints().toString();
        this.withoutStoreFront = this.app.data.employee.retailer.withoutStorefront;
    }

    OnCourseTileClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Courses);
        this.router.navigate(['main', 'courses', 2]);
    }

    OnDownloadEventsTileClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Events);

        this.router.navigate(['main', 'events', 2]);
    }

    onDownloadPlayzoneTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.PlayZone);
        this.router.navigate(['main', 'download', 4]);
    }

    onDownlonadNotificationTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Notifications);
        this.router.navigate(['main', 'download', 1]);
    }

    onDownloadProShopTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.ProShop);
        this.router.navigate(['main', 'download', 6]);
    }

    onSurveyTitleClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.Survey);
        this.router.navigate(['main', 'surveys']);
    }

    OnTheMallTileClick() {
        MenuComponent.instance.selectMenuItem(MenuItemName.TheMall);
        this.router.navigate(['main', 'download', 7]);
    }

    constructor(router: Router) {
        this.router = router;
    }
}




