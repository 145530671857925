import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../../app";
import {SpModalComponent} from "../../common/sp_modal.component";
import {ModalService} from "../../../common/services/modal.service";

@Component({
    selector: 'sp-retailer-profile',
    templateUrl: '../../../../templates/profile/retailer/profile_retailer.component.html'

})
export class RetailerProfileComponent implements OnInit {


    public app: App = App.instance;

    @Input() fullName: string;
    @Input() userName: string;
    @Input() changeEmailUser: string;
    @Input() country: string;
    @Input() language: string;
    @Input() details: string;
    @Input() storeDetailsUser: string;
    @Input() certificatesCount: string;

    ngOnInit(): any {

        this.fullName = this.app.data.employee.firstName + " " + this.app.data.employee.lastName;
        this.userName = this.app.data.employee.username;
        this.changeEmailUser = this.app.data.employee.email;
        this.country = this.app.data.employee.countryName;
        this.language = this.app.data.employee.languageName;
        this.details = this.app.data.employee.retailerName;
        this.storeDetailsUser = "Store # " + this.app.data.employee.storeName + " "
            + this.app.data.store.address + " "
            + this.app.data.store.city + " " + this.app.data.store.abrv + ", " + this.app.data.store.zip;

        this.certificatesCount = this.app.data.getCertificateCount() + "";
    }

    constructor(private router: Router, private modal: ModalService) {

    }

    onChangeEmailClick() {
        this.router.navigate(['main', 'profile_change_email']);
    }

    onChangePasswordClick() {
        this.router.navigate(['main', 'password_change_password']);
    }

    onCertificatesClick() {
        this.router.navigate(['main', 'retailer_certificates']);
    }

    onJobCategoryClick() {
        this.router.navigate(['main', 'retailer_job_category']);
    }

    onChangeCountry() {
        this.router.navigate(['main', 'update_retailer_profile', 1]);
    }

    onChangeLanguage() {
        this.router.navigate(['main', 'update_retailer_profile', 2]);
    }

    onChangeCompany() {
        this.modal.show('PROFILE.ARE_YOU_SURE', 'PROFILE.RETAILER_SWITCH_MESSAGE', 'PROFILE.YES', 'PROFILE.NO', () => {
            this.router.navigate(['main', 'update_retailer_profile', 3]);
        }, () => {
            return;
        });
    }

    onChangeStore() {
        this.router.navigate(['main', 'update_retailer_profile', 4]);
    }


}




