import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {deserializeDateTime} from "../json_deserialization/json_deserialization";

export class ChangeEmployeePreferencesRequestMessage extends RequestMessage {
    employeeId: number;
    sellProEmails: boolean;
    miscMarketingEmails: boolean;
    fullSyncOnCellularData: boolean;
    preferredLang: number;
    nfContent: boolean;
    nfAwards: boolean;
    nfEvents: boolean;
    nfContests: boolean;
    nfTipsAndTricks: boolean;
    nfLastUpdate: Date;

    constructor() {
        super();
        this.type = MessageTypes.ChangeEmployeePreferencesRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.sellProEmails = jsonObject.sellProEmails;
        this.miscMarketingEmails = jsonObject.miscMarketingEmails;
        this.fullSyncOnCellularData = jsonObject.fullSyncOnCellularData;
        this.preferredLang = jsonObject.preferredLang;
        this.nfContent = jsonObject.nfContent;
        this.nfAwards = jsonObject.nfAwards;
        this.nfEvents = jsonObject.nfEvents;
        this.nfContests = jsonObject.nfContests;
        this.nfTipsAndTricks = jsonObject.nfTipsAndTricks;
        this.nfLastUpdate = deserializeDateTime(jsonObject.nfLastUpdate);
    }
}
