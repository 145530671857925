import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../app";
import {CoursesListComponent} from "../courses/courses_list.component";

@Component({
    selector: 'sp-profile',
    templateUrl: '../../../templates/profile/profile.component.html'

})
export class ProfileMenuComponent implements OnInit {

    public app: App = App.instance;
    @Input() isRetailer: boolean = false;
    @ViewChild('sp-profile-change_password', { static: false }) coursesNewComponent: CoursesListComponent;

    @Input() tiles: any;

    ngOnInit(): any {
        this.isRetailer = this.app.data.employee.isRetailer();
    }

    constructor(private router: Router) {

    }
}
