import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class ItemMessageData implements IJsonDeserializable {
    version: number = 0;
    id: number;
    name: string;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.name = jsonObject.name;
    }
}
