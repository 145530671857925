import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class GrsBalanceResponseMessage extends ResponseMessage {

    balance: number;

    constructor() {
        super();
        this.type = MessageTypes.GrsBalanceResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.balance = jsonObject.balance;
    }
}