import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {BrandLeaderboardListComponent} from "./internal/brand_leaderboard_list.component";
import {LeaderBoardInfoMessageData} from "../../../communication/message_data/leaderboard_info_message_data";
import {StoreForBrandLeaderboardMessageData} from "../../../communication/message_data/store_for_brand_leaderboard_message_data";
import {App} from "../../../app";
import {LeaderBoardType, LeaderBoardBrandSubType} from "../../../data/leader_board_types";
import {SpLoaderComponent} from "../../common/sp_loader.component";
import {EmployeeLeaderBoardResponseMessage} from "../../../communication/messages/employee_leaderboard_response_message";
import {ModalService} from "../../../common/services/modal.service";

@Component({
    selector: 'sp-brand-leaderboard',
    templateUrl: '../../../../templates/leaderboard/brand/brand_leaderboard.component.html'
})
export class BrandLeaderboardComponent {
    app: App = App.instance;
    LeaderBoardTypes = LeaderBoardType
    selectedLeaderBoardType: LeaderBoardType = LeaderBoardType.TopInStore;
    selectedLeaderBoardSubType: LeaderBoardBrandSubType = null;
    prevSelectedLeaderBoardSubType: LeaderBoardBrandSubType = null;
    loading: boolean = false;
    @ViewChild('sploader') spLoader: SpLoaderComponent;
    records: Array<LeaderBoardInfoMessageData> = [];
    selectedStore: StoreForBrandLeaderboardMessageData;

    constructor(private modal: ModalService, private changeDetectorRef: ChangeDetectorRef) {
    }

    onLeaderBoardTypeChanged(type: LeaderBoardType) {
        this.selectedLeaderBoardType = type;
        this.fetchData();
    }

    changeLeaderBoardSubTypeSelected() {
        this.prevSelectedLeaderBoardSubType = this.selectedLeaderBoardSubType;
        this.selectedLeaderBoardSubType = null;
    }

    fetchData(){
        this.showLoading();
        this.app.driver.getBrandLeaderboard(this.app.getEmployeeId(),
            this.app.getEmployee().brandId,
            this.selectedLeaderBoardType,
            this.selectedLeaderBoardSubType,
            this.selectedStore.retailerId,
            this.selectedStore.id,
            this.selectedStore.countryId,10)
            .onSuccess((rsp: EmployeeLeaderBoardResponseMessage) => {
                this.records = rsp.data;
                this.hideLoading();
            })
            .onError((error, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                this.hideLoading();
            })
            .send();
    }

    onStoreSelected(store: StoreForBrandLeaderboardMessageData){
        this.selectedStore = store;
    }

    onLeaderBoardSubTypeSelected(leaderBoardSubType:LeaderBoardBrandSubType){
        this.selectedLeaderBoardSubType = leaderBoardSubType;
        this.fetchData();
    }

    showLoading(){
        this.loading = true;
        this.changeDetectorRef.detectChanges();
        this.spLoader.start();
    }

    hideLoading(){
        this.spLoader.stopAndHide();
        this.loading = false;
        this.changeDetectorRef.detectChanges();
    }

    get showList(){
        return this.selectedStore != null && this.selectedLeaderBoardSubType != null;
    }


}
