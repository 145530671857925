import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {RegisterStoreMessageData} from "../message_data/register_store_message_data";
import {deserializeObject} from "../json_deserialization/json_deserialization";

export class ChangeEmployeeRetailerStoreCategoriesRequestMessage extends RequestMessage {
    employeeId: number;

    changeLanguage: boolean = false;
    languageId: number;

    changeRetailer: boolean = false;
    retailerId: number;

    changeStoreId: boolean = false;
    storeId: number;

    store: RegisterStoreMessageData;

    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.ChangeEmployeeRetailerStoreCategoriesRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.changeLanguage = jsonObject.changeLanguage;
        this.languageId = jsonObject.languageId;
        this.changeRetailer = jsonObject.changeRetailer;
        this.retailerId = jsonObject.retailerId;
        this.changeStoreId = jsonObject.changeStoreId;
        this.storeId = jsonObject.storeId;
        this.store = deserializeObject(jsonObject.store, RegisterStoreMessageData);
        this.preferredLang = jsonObject.preferredLang;
    }
}

