import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class EmployeeEventAttendanceConfirmationResponseMessage extends ResponseMessage{
    isAlreadyAttend: boolean;
    success: boolean;
    constructor() {
        super();
        this.type = MessageTypes.EmployeeEventAttendanceConfirmationResponse;

    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.isAlreadyAttend = jsonObject.isAlreadyAttend;
        this.success = jsonObject.success;

    }
}