import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeeTopicStateMessageData implements IJsonDeserializable {
    version: number = 0;

    topicId: number;

    status: boolean;
    teaserStatus: boolean;
    updatedAt: Date;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.topicId = jsonObject.topicId;

        this.status = jsonObject.status;
        this.teaserStatus = jsonObject.teaserStatus;
        this.updatedAt = deserializeDateTime(jsonObject.updatedAt);
    }
}
