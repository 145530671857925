import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {StateMessageData} from "../../communication/message_data/state_message_data";
import {ModalService} from "../services/modal.service";
import { StatesListInternalComponent } from './states_list.internal.component';

@Component({
    selector: 'select-states-list-internal',
    templateUrl: '../../../templates/common/components/states_list.component.html'
})
export class SelectStoreStatesListInternalComponent extends StatesListInternalComponent implements OnInit {
    @Input() countryId: number;
    @Input() useThemeHeader: boolean;
    @Output() onSelected: EventEmitter<StateMessageData> = new EventEmitter<StateMessageData>();
    @Output() onBackButtonTapped: EventEmitter<void> = new EventEmitter<void>();

    constructor(modal: ModalService) {
        super(modal);
    }

    OnInit(){
        super.ngOnInit();
    }

    selected(record: StateMessageData) {
        this.onSelected.emit(record);
    }

    backButtonTapped() {
        this.onBackButtonTapped.emit();
    }

    getCountryId(): number {
        return this.countryId;
    }
}