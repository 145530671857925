import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {ActionStatisticMessageData} from "../message_data/action_statistic_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";
import {AppCampaignReportTaskData} from "../message_data/app_campaign_report_task_data";

export class ReportAppCampaignTaskRequestMessage extends RequestMessage {
    preferredLang: number;
    employeeId: number;
    device: string;

    appCampaignId: number;
    taskData: AppCampaignReportTaskData;

    constructor() {
        super();
        this.type = MessageTypes.ReportAppCampaignTaskRequest;
    }

    static create(
        deviceId: string,
        preferredLang: number,
        employeeId: number,
        appCampaignId: number,
        taskData: AppCampaignReportTaskData) {
        const obj = new ReportAppCampaignTaskRequestMessage();
        obj.preferredLang = preferredLang;
        obj.employeeId = employeeId;
        obj.device = deviceId;
        obj.appCampaignId = appCampaignId;
        obj.taskData = taskData;
        return obj;
    }
}
