import {Component} from "@angular/core";
import {App} from "../../app";
import {EmployeeType} from "../../data/employee_type";
import {Router} from "@angular/router";
import {UserSessionProviderService} from "../../common/services/user-session-provider.service";

@Component({
    selector: 'sp-menu',
    templateUrl: '../../../templates/common/menu.component.html',
})


export class MenuComponent {
    public app: App;
    static instance: MenuComponent;
    private router: Router;
    public menuItems: Array<MenuItem> = new Array<MenuItem>();
    public selecteditem: MenuItem;

    constructor(router: Router, private userSessionProviderService:UserSessionProviderService) {
        MenuComponent.instance = this;
        this.app = App.instance;
        this.router = router;
        if (this.app.data === undefined)
            return;

        if (this.app.data.employee.role == EmployeeType.retailer) {
            let home = new MenuItem("MENU.HOME", "sp-home", "/main", MenuItemName.Home);
            home.selected = true;
            this.selecteditem = home;
            this.menuItems.push(home)
            this.menuItems.push(new MenuItem("MENU.COURSES", "sp-courses", "/main/courses", MenuItemName.Courses));
            this.menuItems.push(new MenuItem("MENU.REFERENCE", "sp-reference", "/main/reference", MenuItemName.Reference));
            this.menuItems.push(new MenuItem("MENU.CLIPBOARD", "sp-pin-active", "/main/download/3", MenuItemName.Clipboard));
            this.menuItems.push(new MenuItem("MENU.EVENTS", "sp-events", "/main/events", MenuItemName.Events));
            this.menuItems.push(new MenuItem("MENU.LEADERBOARD", "sp-leaderboard", "/main/retailer_leaderboard", MenuItemName.Leaderboard));
            this.menuItems.push(new MenuItem("MENU.SHARE_SP", "sp-share", "/main/download/2", MenuItemName.Share));
            this.menuItems.push(new MenuItem("MENU.PLAYZONE", "sp-playzone", "/main/download/4", MenuItemName.PlayZone));
            this.menuItems.push(new MenuItem("MENU.PRO_SHOP", "sp-proshop", "/main/download/6", MenuItemName.ProShop));
            if (!this.app.data.employee.retailer.withoutStorefront) {
                this.menuItems.push(new MenuItem("MENU.MALL", "sp-mall", "/main/download/7", MenuItemName.TheMall));
            }
            this.menuItems.push(new MenuItem("MENU.FEED", "sp-tile-notifications", "/main/download/1", MenuItemName.Notifications));
            this.menuItems.push(new MenuItem("MENU.SETTINGS", "sp-settings", "/main/settings", MenuItemName.Settings));
        } else {
            let home = new MenuItem("Home", "sp-home", "/main", MenuItemName.Home);
            home.selected = true;
            this.selecteditem = home;
            this.menuItems.push(home)
            this.menuItems.push(new MenuItem("MENU.COURSES", "sp-courses", "/main/courses", MenuItemName.Courses));
            this.menuItems.push(new MenuItem("MENU.REFERENCE", "sp-reference", "/main/reference", MenuItemName.Reference));
            this.menuItems.push(new MenuItem("MENU.TOOLBOX", "sp-toolbox", "/main/toolbox", MenuItemName.Toolbox))
            this.menuItems.push(new MenuItem("MENU.CLIPBOARD", "sp-pin-active", "/main/download/3", MenuItemName.Clipboard))
            this.menuItems.push(new MenuItem("MENU.EVENTS", "sp-events", "/main/download/5", MenuItemName.Events))
            this.menuItems.push(new MenuItem("MENU.LEADERBOARD", "sp-leaderboard", "/main/brand_leaderboard", MenuItemName.Leaderboard))
            this.menuItems.push(new MenuItem("MENU.SHARE_SP", "sp-share", "/main/download/2", MenuItemName.Share))
            this.menuItems.push(new MenuItem("MENU.PLAYZONE", "sp-playzone", "/main/download/4", MenuItemName.PlayZone))
            this.menuItems.push(new MenuItem("MENU.PRO_SHOP", "sp-proshop", "/main/download/6", MenuItemName.ProShop));
            if (!this.app.data.employee.brand.withoutStorefront) {
                this.menuItems.push(new MenuItem("MENU.MALL", "sp-mall", "/main/download/7", MenuItemName.TheMall));
            }
            this.menuItems.push(new MenuItem("MENU.FEED", "sp-tile-notifications", "/main/download/1", MenuItemName.Notifications))
            this.menuItems.push(new MenuItem("MENU.SETTINGS", "sp-settings", "/main/settings", MenuItemName.Settings))
        }
    }

    onMenuItemClick(item: MenuItem) {
        this.menuItems.forEach((p) => p.selected = false);
        this.selecteditem = item;
        item.selected = true;
        this.router.navigateByUrl(item.route);
    }

    selectMenuItem(itemName: MenuItemName) {
        if (this.selecteditem && this.selecteditem.type == itemName) {
            return;
        }
        this.menuItems.forEach((p) => p.selected = false);
        this.menuItems.map((p) => p.type == itemName)
        let selectedItem = this.menuItems.filter((p) => p.type == itemName)[0];
        if (selectedItem) {
            selectedItem.selected = true;
        }

    }

    logout() {
        this.app.statistic.logoutAction();
        this.userSessionProviderService.logoutUser();
        this.router.navigate(['login']);
    }


}

export enum MenuItemName {
    Undefined = 0,
    Home = 1,
    Courses = 2,
    Reference = 3,
    Clipboard = 4,
    Events = 5,
    Leaderboard = 6,
    Share = 7,
    PlayZone = 8,
    Notifications = 9,
    Settings = 10,
    Toolbox = 11,
    ProShop = 12,
    TheMall = 13,
    Survey = 14
}

export class MenuItem {

    public name: string;
    public icon: string;
    public route: string;
    public selected: boolean = false;
    public type: MenuItemName;

    public get Name(): string {
        return this.name;
    }

    public get Selected(): boolean {
        return this.selected;
    }

    constructor(name: string, icon: string, route: string, type: MenuItemName) {
        this.name = name;
        this.icon = icon;
        this.route = route;
        this.type = type;
    }

}
