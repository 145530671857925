import {CourseMessageData} from "../communication/message_data/course_message_data";
import {TopicMessageData} from "../communication/message_data/topic_message_data";
import Dictionary from "../collections/Dictionary";
import {TeaserMessageData} from "../communication/message_data/teaser_message_data";
import {RetailerExtendedMessageData} from "../communication/message_data/retailer_extended_message_data";
import {BrandCategoryMessageData} from "../communication/message_data/brand_category_message_data";
import {ThemeMessageData} from "../communication/message_data/theme_message_data";
import {EmployeeCertificateMessageData} from "../communication/message_data/eployee_certificate_message_data";
import {BrandExtendedMessageData} from "../communication/message_data/brand_extended_message_data";
import {CategoryMessageData} from "../communication/message_data/category_message_data";
import {TeaserAnswerMessageData} from "../communication/message_data/teaser_answer_message_data";
import {QuizQuestionAnswerMessageData} from "../communication/message_data/quiz_question_answer_message_data";
import {QuizQuestionMessageData} from "../communication/message_data/quiz_question_message_data";
import {QuizMessageData} from "../communication/message_data/quiz_message_data";
import {EmployeeMessageData} from "../communication/message_data/employee_message_data";
import {StoreResponseMessageData} from "../communication/message_data/store_response_message_data";
import {CoursesAndRelatedResponseMessage} from "../communication/messages/courses_and_related_response_message";
import {CourseType} from "./course_type";
import {CourseState} from "./course_state";
import {EmployeeCourseState} from "./employee_course_state";
import {EmployeePoints} from "./employee_points";
import {EventsMessageData} from "../communication/message_data/events_message_data";
import {EventAttachmentMessageData} from "../communication/message_data/event_attachment_message_data";
import {AppCampaignMessageData} from "../communication/message_data/app_campaign_message_data";
import {AppCampaignTaskMessageData} from "../communication/message_data/app_campaign_task_message_data";
import {ReportAppCampaignTaskResponseMessage} from "../communication/messages/report_app_campaign_task_response_message";

export class Data {
    courses: Dictionary<number, CourseMessageData> = new Dictionary<number, CourseMessageData>();
    topics: Dictionary<number, TopicMessageData> = new Dictionary<number, TopicMessageData>();

    teasers: Dictionary<number, TeaserMessageData> = new Dictionary<number, TeaserMessageData>();
    teaserAnswers: Dictionary<number, TeaserAnswerMessageData> = new Dictionary<number, TeaserAnswerMessageData>();

    quizzes: Dictionary<number, QuizMessageData> = new Dictionary<number, QuizMessageData>();
    quizQuestions: Dictionary<number, QuizQuestionMessageData> = new Dictionary<number, QuizQuestionMessageData>();
    quizQuestionAnswers: Dictionary<number, QuizQuestionAnswerMessageData> = new Dictionary<number, QuizQuestionAnswerMessageData>();

    categories: Dictionary<number, CategoryMessageData> = new Dictionary<number, CategoryMessageData>();
    brandCategories: Dictionary<number, BrandCategoryMessageData> = new Dictionary<number, BrandCategoryMessageData>();
    brands: Dictionary<number, BrandExtendedMessageData> = new Dictionary<number, BrandExtendedMessageData>();

    certificates: Dictionary<number, EmployeeCertificateMessageData> = new Dictionary<number, EmployeeCertificateMessageData>();
    themes: Dictionary<number, ThemeMessageData> = new Dictionary<number, ThemeMessageData>();

    events: Dictionary<number, EventsMessageData> = new Dictionary<number, EventsMessageData>();
    eventAttachments: Dictionary<number, EventAttachmentMessageData> = new Dictionary<number, EventAttachmentMessageData>();

    appCampaigns: Dictionary<number, AppCampaignMessageData> = new Dictionary<number, AppCampaignMessageData>();
    appCampaignTasks: Dictionary<number, AppCampaignTaskMessageData> = new Dictionary<number, AppCampaignTaskMessageData>();

    employee: EmployeeMessageData;
    retailer: RetailerExtendedMessageData;
    store: StoreResponseMessageData;
    points: EmployeePoints;

    constructor() {
    }

    normalize() {
        this.normalizeCourses();
        this.normalizeTopics();
        this.normalizeQuizzes();
        this.normalizeBrandCategories();
        this.normalizeBrands();
        this.normalizeRetailer();
        this.normalizeEmployee();
        this.normalizeCampaigns();
    }

    private normalizeTopics() {
        for (let teaser of this.teasers.values()) {
            let topic = this.topics.getValue(teaser.topicId);
            if (topic)
                topic.teaser = teaser;
        }

        for (let answer of this.teaserAnswers.values()) {
            let teaser = this.teasers.getValue(answer.teaserId);
            if (teaser)
                teaser.answers.push(answer);
        }
    }

    private normalizeCourses() {
        for (let topic of this.topics.values()) {
            let course = this.courses.getValue(topic.courseId);
            if (course)
                course.topics.push(topic);
        }

        for (let course of this.courses.values()) {
            let brand = this.brands.getValue(course.brandId);
            let category = this.categories.getValue(course.categoryId);

            course.brand = brand;
            course.category = category;
            course.quiz = null;

            course.topics.sort((n1, n2) => {
                if (n1.order == n2.order)
                    return n1.id - n2.id;

                return n1.order - n2.order;
            })
        }
    }

    private normalizeQuizzes() {
        for (let quiz of this.quizzes.values()) {
            let course = this.courses.getValue(quiz.courseId);
            if (course)
                course.quiz = quiz;
        }

        for (let quizQuestion of this.quizQuestions.values()) {
            let quiz = this.quizzes.getValue(quizQuestion.quizId);
            if (quiz)
                quiz.questions.push(quizQuestion);
        }

        for (let answer of this.quizQuestionAnswers.values()) {
            let question = this.quizQuestions.getValue(answer.questionId);
            if (question)
                question.answers.push(answer);
        }

        for (let quizQuestion of this.quizQuestions.values()) {
            quizQuestion.answers.sort((a, b) => {
                return a.id - b.id;
            });
        }

        for (let quiz of this.quizzes.values()) {
            quiz.questions.sort((a, b) => {
                return a.id - b.id;
            });
        }
    }

    private normalizeBrandCategories() {
        for (let brandCategory of this.brandCategories.values()) {
            let brand = this.brands.getValue(brandCategory.brandId);
            let category = this.categories.getValue(brandCategory.categoryId);

            if (brand)
                brand.brandCategories.push(brandCategory);

            if (category)
                category.brandCategories.push(brandCategory);
        }
    }

    private normalizeBrands() {
        for (let brand of this.brands.values()) {
            brand.theme = this.themes.getValue(brand.themeId);
        }
    }

    private normalizeRetailer() {
        if (this.retailer == null)
            return;

        this.retailer.theme = this.themes.getValue(this.retailer.themeId);
    }

    private normalizeEmployee() {
        if (this.employee.brandId)
            this.employee.brand = this.brands.getValue(this.employee.brandId);

        this.employee.retailer = this.retailer;
        this.employee.store = this.store;
    }

    private normalizeCampaigns() {
        for (let campaign of this.appCampaigns.values()) {
            let tasks = this.appCampaignTasks.values().filter(p => p.appCampaignId == campaign.id);
            campaign.tasks = [];
            campaign.tasks.push(...tasks);
        }
    }

    public populateDataByCoursesAndRelated(response: CoursesAndRelatedResponseMessage) {
        this.insertDataIntoCache<CourseMessageData>(response.courses, this.courses);
        this.insertDataIntoCache<QuizMessageData>(response.quizzes, this.quizzes);
        this.insertDataIntoCache<QuizQuestionMessageData>(response.quizQuestions, this.quizQuestions);
        this.insertDataIntoCache<QuizQuestionAnswerMessageData>(response.quizQuestionAnswers, this.quizQuestionAnswers);
        this.insertDataIntoCache<TopicMessageData>(response.topics, this.topics);
        this.insertDataIntoCache<TeaserMessageData>(response.teasers, this.teasers);
        this.insertDataIntoCache<TeaserAnswerMessageData>(response.teaserAnswers, this.teaserAnswers);
        this.insertDataIntoCache<CategoryMessageData>(response.categories, this.categories);
        this.insertDataIntoCache<BrandExtendedMessageData>(response.brands, this.brands);
        this.insertDataIntoCache<EmployeeCertificateMessageData>(response.certificates, this.certificates);
        this.insertDataIntoCache<ThemeMessageData>(response.themes, this.themes);
        this.insertDataIntoCache<BrandCategoryMessageData>(response.brandCategories, this.brandCategories);
        this.insertDataIntoCache<AppCampaignMessageData>(response.appCampaigns, this.appCampaigns);
        this.insertDataIntoCache<AppCampaignTaskMessageData>(response.appCampaignTasks, this.appCampaignTasks);

        this.retailer = response.retailer;
        this.store = response.store;

        this.employee = response.employee;

        this.normalize();
    }

    public populateDataEvents(events: Array<EventsMessageData>, eventAttachments: Array<EventAttachmentMessageData>) {
        this.insertDataIntoCache<EventsMessageData>(events, this.events);
        this.insertDataIntoCache<EventAttachmentMessageData>(eventAttachments, this.eventAttachments);
    }

    private insertDataIntoCache<T extends Iid>(received: Array<T>, cache: Dictionary<number, T>) {
        if (received == null)
            return;

        for (let r of received) {
            cache.setValue(r.id, r);
        }
    }

    public getNewCourses(): CourseMessageData[] {
        let result = new Array<CourseMessageData>();

        this.courses.values().forEach((value) => {
            if (
                value.userType == CourseType.course
                && (value.recordStatus == CourseState.active || value.recordStatus == CourseState.inAppPreview || value.recordStatus == CourseState.pendingQa)
                && (value.forEmployeeState == EmployeeCourseState.noAnyTopicOpen || value.forEmployeeState == EmployeeCourseState.unknown)
            ) {
                result.push(value);
            }
        });

        return result;
    }

    public getInProgressCourses(): CourseMessageData[] {
        let result = new Array<CourseMessageData>();

        this.courses.values().forEach((value) => {
            if (
                value.userType == CourseType.course
                && (value.recordStatus == CourseState.active || value.recordStatus == CourseState.inAppPreview || value.recordStatus == CourseState.pendingQa)
                && (value.forEmployeeState == EmployeeCourseState.inProgress || value.isAllowedRecertification())
            ) {
                result.push(value);
            }
        });

        return result;
    }

    public getInProgressCount(): number {
        return this.getInProgressCourses().length;
    }

    public getCertificateCount(): number {
        return this.getCertificates().length;
    }

    public getCompletedCourses(): CourseMessageData[] {
        let result = new Array<CourseMessageData>();

        this.courses.values().forEach((value) => {
            if (
                value.userType == CourseType.course
                && (value.recordStatus == CourseState.active || value.recordStatus == CourseState.inAppPreview || value.recordStatus == CourseState.pendingQa)
                && value.forEmployeeState == EmployeeCourseState.quizCompleted
                && !value.isAllowedRecertification()
            ) {
                result.push(value);
            }
        });

        return result;
    }

    public getRetailerReferenceLibrary(): CourseMessageData[] {
        let result = new Array<CourseMessageData>();

        this.courses.values().forEach((value) => {
            if (value.userType == CourseType.referenceLibrary) {
                result.push(value);
            }
        });
        return result;
    }

    public getBrandToolbox(): CourseMessageData[] {
        let result = new Array<CourseMessageData>();

        this.courses.values().forEach((value) => {
            if (value.userType == CourseType.trainingLibrary) {
                result.push(value);
            }
        });
        return result;
    }


    public getRetailerArchive(): CourseMessageData[] {
        let result = new Array<CourseMessageData>();

        this.courses.values().forEach((value) => {
            if (value.recordStatus == CourseState.archive) {
                result.push(value);
            }
        });
        return result;
    }

    public getCountNewTools(): number {
        var result = 0;
        this.courses.values().forEach((value) => {
            if (
                value.userType == CourseType.trainingLibrary
                && value.recordStatus == CourseState.active
                && value.forEmployeeState == EmployeeCourseState.noAnyTopicOpen
            ) {
                result++;
            }
        });
        return result;
    }

    public getCountAllNewCourses(): number {
        var result = 0;
        this.courses.values().forEach((value) => {
            if (
                value.userType == CourseType.course
                && (value.recordStatus == CourseState.active || value.recordStatus == CourseState.inAppPreview || value.recordStatus == CourseState.pendingQa)
                && value.forEmployeeState == EmployeeCourseState.noAnyTopicOpen
            ) {
                result++;
            }
        });
        return result;
    }

    public getCountInProgressCourses(): number {
        var result: Array<CourseMessageData> = [];
        result = this.courses.values().filter((course) => {
            if (course.userType == CourseType.course && (course.recordStatus == CourseState.active || course.recordStatus == CourseState.inAppPreview || course.recordStatus == CourseState.pendingQa)
                && (course.forEmployeeState == EmployeeCourseState.inProgress || course.isAllowedRecertification()))
                return true;
        });
        return result.length;
    }

    public getCountNewReferences(): number {
        var result = 0;
        this.courses.values().forEach((value) => {
            if (
                (
                    (value.userType == CourseType.referenceLibrary && value.recordStatus == CourseState.active)
                    || (value.userType == CourseType.course && value.recordStatus == CourseState.archive)
                )
                && value.forEmployeeState == EmployeeCourseState.noAnyTopicOpen
            ) {
                result++;
            }
        });
        return result;
    }

    public getCertificates(): Array<EmployeeCertificateMessageData> {
        return this.certificates.values().sort((a, b) => {
            return b.dateCreated.valueOf() - a.dateCreated.valueOf()
        });
    }

    public getCertificateByCourseId(courseId: number): EmployeeCertificateMessageData {
        var result: EmployeeCertificateMessageData;
        this.certificates.values().forEach(p => {
            if (p.courseId == courseId)
                result = p;
        });
        return result;
    }

    public getPreviousCertificates(retailerId: number): Array<EmployeeCertificateMessageData> {
        return this.certificates.values().filter(c => c.retailerId != retailerId).sort((a, b) => {
            return b.dateCreated.valueOf() - a.dateCreated.valueOf()
        });
    }

    public getCertificatesByRetailer(retailerId: number): Array<EmployeeCertificateMessageData> {
        return this.certificates.values().filter(c => c.retailerId == retailerId).sort((a, b) => {
            return b.dateCreated.valueOf() - a.dateCreated.valueOf()
        });
    }

    public getBrandCategory(brandId: number, categoryId: number): BrandCategoryMessageData {
        var result: BrandCategoryMessageData;
        this.brandCategories.values().forEach(p => {
            if (p.brandId == brandId && p.categoryId == categoryId)
                result = p;
        });

        return result;
    }

    public getEmployeePoints(): EmployeePoints {
        if (!this.points)
            this.points = new EmployeePoints(this.employee.id);

        return this.points;
    }

    public addPoints(points: number) {
        if (points == 0)
            return;

        var ep = this.getEmployeePoints();
        if (ep != null) {
            ep.local += points;
            ep.lastUpdatedLocal = new Date();
        }
    }

    public getMyEvents(): Array<EventsMessageData> {
        let result: Array<EventsMessageData> = [];
        result = this.events.values().filter((p) => p.employeeIsRegistered == true);
        result.forEach(event => {
            event.brand = this.brands.getValue(event.brandId)
        });
        return result.sort((a, b) => {
            return b.date.valueOf() - a.date.valueOf()
        });
    }

    public getUpcomingEvents(): Array<EventsMessageData> {

        let result: Array<EventsMessageData> = [];
        result = this.events.values().filter((p) => p.employeeIsRegistered == false);
        result.forEach(event => {
            event.brand = this.brands.getValue(event.brandId)
        });
        return result.sort((a, b) => {
            return b.date.valueOf() - a.date.valueOf()
        });
    }

    public getMyEventsCount(): number {
        let my = this.getMyEvents();
        if (my.length > 0)
            return my.length;

        return 0;
    }

    public getEventAttachmentsByEventId(eventId: number): Array<EventAttachmentMessageData> {
        return this.eventAttachments.values().filter(p => p.eventId == eventId);
    }

    public getEventByEventId(eventId: number): EventsMessageData {
        return this.events.getValue(eventId);
    }


    public getEventAttachmentById(eventAttachmentId: number): EventAttachmentMessageData {
        return this.eventAttachments.getValue(eventAttachmentId);
    }

    public updateCampaignData(appCampaign: AppCampaignMessageData, tasks: AppCampaignTaskMessageData[]) {
        this.appCampaigns.getValue(appCampaign.id).copyFrom(appCampaign);
        tasks.forEach(task => this.appCampaignTasks.setValue(task.id, Object.assign({}, task)));
        this.normalizeCampaigns();
    }

    hasTopicQuiz(topicId: number): boolean {
        const topicQuizQuestion = this.quizQuestions.values().find(p => p.topicId == topicId);
        return topicQuizQuestion != null;
    }
}