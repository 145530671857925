import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../app";
import {AddStoraData} from "../../data/add.store.data";
import {StateMessageData} from "../../communication/message_data/state_message_data";
import {CountryMessageData} from "../../communication/message_data/country_message_data";

@Component({
    selector: 'sp-create-store-internal',
    templateUrl: '../../../templates/common/components/add_store.component.html'
})

export class AddStoreInternalComponent {
    app: App = App.instance;
    @Input() country: CountryMessageData;
    @Input() storeData: AddStoraData;
    @Input() useThemeHeader: boolean;
    @Output() onActionButtonTapped: EventEmitter<AddStoraData> = new EventEmitter<AddStoraData>();
    @Output() onBackButtonTapped: EventEmitter<void> = new EventEmitter<void>();

    pickingState: boolean = false;

    constructor() {
    }

    actionButtonTapped() {
        this.onActionButtonTapped.emit(this.storeData);
    }

    backButtonTapped() {
        this.onBackButtonTapped.emit();
    }

    onStateButtonTapped() {
        this.pickingState = true;
    }

    onStateSelect(state: StateMessageData) {
        this.pickingState = false;
        this.storeData.State = state;
    }

    onStateSelectCancel() {
        this.pickingState = false;
    }
}
