import {IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {QuizQuestionMessageData} from "./quiz_question_message_data";

export class QuizMessageData implements IJsonDeserializable, Iid {
    version: number = 1;

    id: number;
    courseId: number;
    name: string;
    maxQuestionsToShow: number;

    questions: Array<QuizQuestionMessageData> = [];

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.courseId = jsonObject.courseId;
        this.name = jsonObject.name;
        this.maxQuestionsToShow = jsonObject.maxQuestionsToShow;
    }
}
