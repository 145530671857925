export class EmployeePoints {
    id: number;
    employeeId: number;
    server: number = 0;
    local: number = 0;
    lastUpdatedLocal: Date;
    lastUpdatedServer: Date;

    resultPoint(): number {
        let result = this.server + this.local;
        return result < 0 ? 0 : result;
    }

    constructor(employeeId: number) {
        this.employeeId = employeeId;
    }
}
