import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {App} from "../../../../app";
import {StoreForBrandLeaderboardMessageData} from "../../../../communication/message_data/store_for_brand_leaderboard_message_data";
import {StoreForBrandLeaderboardResponseMessage} from "../../../../communication/messages/store_for_brand_leaderboard_response_message";
import {StringExtensions} from "../../../../common/extensions";
import {SpLoaderComponent} from "../../../common/sp_loader.component";
import {ModalService} from "../../../../common/services/modal.service";
import {LeaderBoardBrandSubType} from "../../../../data/leader_board_types";

@Component({
    selector: 'sp-brand-leaderboard-filter',
    templateUrl: '../../../../../templates/leaderboard/brand/internal/brand_leaderboard_filter.component.html',
    styleUrls:['./../../../../../styles/leaderboard/brand/internal/brand_leaderboard_filter.css']
})
export class BrandLeaderboardFilterComponent implements OnInit {
    app: App = App.instance;

    searchQuery:string;
    radius:string;

    @Input('selectedStore') selectedStore: StoreForBrandLeaderboardMessageData = null;
    @Input('prevSelectedLeaderBoardSubType') prevSelectedLeaderBoardSubType: LeaderBoardBrandSubType = null;

    @ViewChild('sploader') spLoader: SpLoaderComponent;
    data: Array<StoreForBrandLeaderboardMessageData> = [];
    loading: boolean = false;
    prevSelectedStore: StoreForBrandLeaderboardMessageData = null;
    LeaderBoardBrandSubType = LeaderBoardBrandSubType;
    @Output('onStoreSelected') onStoreSelected:EventEmitter<StoreForBrandLeaderboardMessageData> = new EventEmitter<StoreForBrandLeaderboardMessageData>();
    @Output('onLeaderBoardSubTypeSelected') onLeaderBoardSubTypeSelected:EventEmitter<LeaderBoardBrandSubType> = new EventEmitter<LeaderBoardBrandSubType>();


    constructor(private modal:ModalService) {
    }

    ngOnInit(): any {
    }

    onSearchStoreButtonClick() {
        if (this.loading)
            return;

        this.showLoading();

        if (StringExtensions.isNullorEmpty(this.searchQuery)) {
            this.modal.show("BRAND_LEADERBOARD_FILTER.ERROR", "BRAND_LEADERBOARD_FILTER.ERROR_ZIP");
            this.hideLoading();
            return;
        }

        if (StringExtensions.isNullorEmpty(this.radius)) {
            this.modal.show("BRAND_LEADERBOARD_FILTER.ERROR", "BRAND_LEADERBOARD_FILTER.ERROR_MILE_RADIUS");
            this.hideLoading();
            return;
        }

        let mileRadius = parseInt(this.radius);
        if (isNaN(mileRadius) || mileRadius < 1 || mileRadius > 100) {
            this.modal.show("BRAND_LEADERBOARD_FILTER.ERROR", "BRAND_LEADERBOARD_FILTER.ERROR_MILE_RADIUS_RANGE");
            this.hideLoading();
            return;
        }

        this.showLoading();
        this.app.driver.storesForBrandLeaderboard(this.app.data.employee.brandId, this.app.data.employee.countryId, this.searchQuery, mileRadius)
            .onSuccess((rsp: StoreForBrandLeaderboardResponseMessage) => {
                this.data = rsp.stores;
                this.hideLoading();
                if (this.data.length <= 0)
                    this.modal.show("BRAND_LEADERBOARD_FILTER.NO_RECORDS", "BRAND_LEADERBOARD_FILTER.NO_RECORDS_FOUND");
            })
            .onError((error, ec, em) => {
                this.data = [];
                this.hideLoading();
                this.modal.show("BRAND_LEADERBOARD_FILTER.NO_RECORDS", "BRAND_LEADERBOARD_FILTER.NO_RECORDS_FOUND");
            })
            .send();
    }

    showLoading(){
        this.loading = true;
        this.spLoader.start();
    }

    hideLoading(){
        this.loading = false;
        this.spLoader.stopAndHide();
    }

    onStoreItemClick(store: StoreForBrandLeaderboardMessageData) {
        this.selectedStore = store;
        this.onStoreSelected.emit(store);
    }

    onChangeStore() {
        if(this.prevSelectedLeaderBoardSubType == null){
            this.prevSelectedStore = this.selectedStore;
        }else{
            this.prevSelectedStore = null
        }
        this.selectedStore = null;
    }

    onLeaderBoardBrandSubTypeChanged(leaderBoardSubType:LeaderBoardBrandSubType) {
        this.onLeaderBoardSubTypeSelected.emit(leaderBoardSubType);
    }

    get employeeBrandName(){
        return this.app.getEmployee().brand.name;
    }
}
