import {Router} from "@angular/router";
import {App} from "./app";
import {Component, OnInit, ViewChild} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {SpModalComponent} from "./ui/common/sp_modal.component";
import {ModalService} from "./common/services/modal.service";
import {UserSessionProviderService} from "./common/services/user-session-provider.service";

@Component({
    selector: 'my-app',
    templateUrl: '../templates/app.component.html'
})

export class AppComponent implements OnInit {
    @ViewChild('modal', { static: true }) modal: SpModalComponent;
    router: Router;
    app: App;

    constructor(router: Router, http: HttpClient, translate: TranslateService, private modalService: ModalService, private userSessionProviderService: UserSessionProviderService) {
        App.init(http, router, translate, modalService, userSessionProviderService);
        this.app = App.instance;
        this.router = router;
    }

    ngOnInit(): void {
        this.modalService.modal = this.modal;
    }
}
