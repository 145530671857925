import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {ActionStatisticMessageData} from "../message_data/action_statistic_message_data";
import {deserializeArrayOfObject, deserializeObject} from "../json_deserialization/json_deserialization";
import {AppCampaignReportTaskData} from "../message_data/app_campaign_report_task_data";
import {ResponseMessage} from "../response_message";
import {ReportAppCampaignTaskRequestMessage} from "./report_app_campaign_task_request_message";
import {AppCampaignMessageData} from "../message_data/app_campaign_message_data";
import {AppCampaignTaskMessageData} from "../message_data/app_campaign_task_message_data";


export class ReportAppCampaignTaskResponseMessage extends ResponseMessage {
    appCampaign: AppCampaignMessageData;
    tasks: AppCampaignTaskMessageData[];
    processingStatus: number;

    constructor() {
        super();
        this.type = MessageTypes.ReportAppCampaignTaskResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.appCampaign = deserializeObject(jsonObject.appCampaign, AppCampaignMessageData);
        this.tasks = deserializeArrayOfObject(jsonObject.tasks, AppCampaignTaskMessageData);
        this.processingStatus = jsonObject.processingStatus;
    }
}