import {Component, OnInit, ViewChild} from '@angular/core';
import {App} from "../../app";
import {UpComingEventsComponent} from "./events_upcoming.component";
import {SearchEventsComponent} from "./events_search.component";
import {MyEventsComponent} from "./events_my.component";
import {UIEventData} from "../../data/ui_event_data";
import {ActivatedRoute} from "@angular/router";
import {EventTabState} from "../../data/event_tab_state";
import {EventsMessageData} from "../../communication/message_data/events_message_data";
import {EventAttachmentMessageData} from "../../communication/message_data/event_attachment_message_data";

@Component({
    selector: 'sp-events',
    templateUrl: '../../../templates/events/events.component.html'
})
export class EventsComponent implements OnInit {
    @ViewChild('upComingEvents', { static: false }) upComingEvents: UpComingEventsComponent;
    @ViewChild('myEvents', { static: false }) myEvents: MyEventsComponent;
    @ViewChild('searchEvents', { static: false }) searchEvents: SearchEventsComponent;

    private subscriber: any;

    app: App = App.instance;
    upcomingEventsData: Array<UIEventData> = [];
    myEventsData: Array<UIEventData> = [];

    activeTab: HTMLElement;
    activePage: HTMLElement;


    constructor(private route: ActivatedRoute) {
    }


    ngOnInit(): any {
        this.loadData();
        this.subscriber = this.route.params.subscribe(params => {
            let id = +params['tabId'];
            if (id)
                this.onTabClick(id);
            else
                this.showUpComingEventsPage();
        });
    }

    private loadData() {
        this.myEventsData = [];
        this.upcomingEventsData = [];
        let myEvents = this.app.data.getMyEvents();
        let upcomingEvents = this.app.data.getUpcomingEvents();
        this.myEventsData = this.loadUIEventData(myEvents);
        this.upcomingEventsData = this.loadUIEventData(upcomingEvents);
    }

    private loadUIEventData(eventsData: Array<EventsMessageData>) {
        let eventsUIData: Array<UIEventData> = [];
        eventsData.forEach((event) => {
                let attachments: Array<EventAttachmentMessageData> = this.app.data.getEventAttachmentsByEventId(event.id);
                eventsUIData.push(new UIEventData(event, attachments));
            }
        );
        return eventsUIData;
    }

    onTabClick(index: number) {
        this.loadData();
        switch (index) {
            case EventTabState.upcoming:
                this.showUpComingEventsPage();
                break;
            case EventTabState.myEvents:
                this.showMyEventsPage();
                break;
            case EventTabState.search:
                this.showSearchEventsPage();
                break;
        }
    }

    showUpComingEventsPage() {
        this.updateActive('tab-upcoming_events', 'upcoming_events');
    }

    showMyEventsPage() {
        this.updateActive('tab-my_events', 'my_events');
    }

    showSearchEventsPage() {
        this.updateActive('tab-search_events', 'search_events');
    }

    private updateActive(tabId: string, pageId: string) {
        let tab = document.getElementById(tabId);
        let page = document.getElementById(pageId);

        if (this.activeTab) {
            this.activeTab.classList.remove("active");
        }

        if (this.activePage)
            this.activePage.classList.remove("active");

        this.activeTab = tab;
        this.activePage = page;

        this.activeTab.classList.add("active");
        this.activePage.classList.add("active");
    }


    ngOnDestroy() {
        this.subscriber.unsubscribe();
    }


}


