import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class EmployeeQuizAnswersResponseMessage extends ResponseMessage {
    constructor() {
        super();
        this.type = MessageTypes.EmployeeQuizAnswersResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
    }
}