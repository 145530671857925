import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class StatesRequestMessage extends RequestMessage {
    countryId: number;
    employeeId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.StatesRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.countryId = jsonObject.countryId;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
