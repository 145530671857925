import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

import {LeaderBoardInfoMessageData} from "../message_data/leaderboard_info_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class EmployeeLeaderBoardByBrand2ResponseMessage extends ResponseMessage {
    data: Array<LeaderBoardInfoMessageData> = [];

    constructor() {
        super()
        this.type = MessageTypes.EmployeeLeaderBoardByBrand2Request;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.data = deserializeArrayOfObject(jsonObject.data, LeaderBoardInfoMessageData);
    }
}
