import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class EmployeeRegisterForEventRequestMessage extends RequestMessage {
    employeeId: number;
    eventId: number;
    employeeEventInvitationId: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.EmployeeRegisterForEventRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.eventId = jsonObject.eventId;
        this.employeeEventInvitationId = jsonObject.employeeEventInvitationId ? jsonObject.employeeEventInvitationId : 0;
        this.preferredLang = jsonObject.preferredLang;
    }
}
