import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class StoresRequestMessage extends RequestMessage {
    retailerId: number;
    zipCode: string;
    employeeId: number;
    preferredLang: number;
    latitude: number;
    longitude: number;

    constructor() {
        super();
        this.type = MessageTypes.StoresRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.retailerId = jsonObject.retailerId;
        this.zipCode = jsonObject.zipCode;
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
