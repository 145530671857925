import {ResponseMessage} from "../response_message";
import {RetailerMessageData} from "../message_data/retailer_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class RetailersResponseMessage extends ResponseMessage {
    retailers: Array<RetailerMessageData>;

    constructor() {
        super();
        this.type = MessageTypes.RetailersResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.retailers = deserializeArrayOfObject(jsonObject.retailers, RetailerMessageData);
    }
}
