import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TopicMessageData} from "../../communication/message_data/topic_message_data";
import {App} from "../../app";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'sp-common-image',
    templateUrl: '../../../templates/common_topics/common_topic_image.component.html'

})
export class CommonTopicImageComponent implements OnInit, OnDestroy {
    @ViewChild('wrapper', { static: true }) imageWrapper: ElementRef;
    @Input() topic: TopicMessageData;
    private sub: any;
    public app: App = App.instance;
    public imageWidth: string = "";
    public imageHeight: string = "";

    ngOnInit(): any {
        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // (+) converts string 'id' to a number
            this.topic = this.app.data.topics.getValue(id);

            let cst = this.topic.getStatisticsTypeByCourse(this.app.data.courses.getValue(this.topic.courseId));
            this.app.statistic.topicOpen(this.topic.courseId, this.topic.id, cst)
        });
    }

    imageLoad() {
        let newImg = new Image();
        newImg.onload = () => {
            let imageWidth = +newImg.width;
            let imageHeight = +newImg.height;
            let clientWidth = +this.imageWrapper.nativeElement.clientWidth;
            let clientHeight = +this.imageWrapper.nativeElement.clientHeight;
            let xRatio = imageWidth / clientWidth;
            let yRatio = imageHeight / clientHeight;
            let ratio = Math.max(xRatio, yRatio);
            if (ratio > 1) {
                imageWidth = imageWidth / ratio;
                imageHeight = imageHeight / ratio;
            }
            this.imageWidth = "" + imageWidth;
            this.imageHeight = "" + imageHeight;
        };
        newImg.src = this.topic.fileDescriptor;
    }

    ngOnDestroy(): any {
        let cst = this.topic.getStatisticsTypeByCourse(this.app.data.courses.getValue(this.topic.courseId));
        this.app.statistic.topicClosed(this.topic.courseId, this.topic.id, cst)
    }

    constructor(private route: ActivatedRoute, private router: Router) {

    }

    onMainToolBoxScreen() {
        this.router.navigate(['main', 'reference_detail', this.topic.courseId]);
    }

}
