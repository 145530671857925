import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UiSurveyData} from "../../data/ui_survey_data";
import {App} from "../../app";
import {SurveyQuestionMessageData} from "../../communication/message_data/survey_question_message_data";
import {SurveyDetailService} from "./helpers/survey_detail.service";
import {EmployeeSurveyAnswerMessageData} from "../../communication/message_data/employee_survey_answer_message_data";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {Router} from "@angular/router";
import {SpModalComponent} from "../common/sp_modal.component";
import {QuizQuestionType} from "../../data/quiz_question_type";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-survey-detail',
    templateUrl: '../../../templates/surveys/surveys-detail.component.html'
})
export class SurveyDetailComponent implements OnInit, OnDestroy {
    app: App = App.instance;
    survey: UiSurveyData;

    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;
    isLoading: boolean = false;

    currentIndex: number = 0;
    currentQuestion: SurveyQuestionMessageData;


    leftActionButtonText: string;
    leftActionButtonState: SQActionButtonState;

    rightActionButtonText: string;
    rightActionButtonState: SQActionButtonState;


    constructor(private dataHelper: SurveyDetailService, private router: Router, private modal: ModalService) {

    }

    ngOnInit(): void {
        this.survey = this.dataHelper.getSurverData();
        this.currentQuestion = this.survey.data.questions[this.currentIndex];
        this.updateActionButtonState();
    }

    ngOnDestroy(): void {
        this.dataHelper.resetSurveyData();
    }

    onAnswerSelected() {
    }

    private updateActionButtonState() {
        if (this.currentIndex == 0) {
            this.leftActionButtonState = SQActionButtonState.Cancel;
            this.leftActionButtonText = "SURVEYS.CANCEL";
        } else {
            this.leftActionButtonState = SQActionButtonState.Previous;
            this.leftActionButtonText = "SURVEYS.PREVIOUS";
        }

        if (this.currentIndex == this.survey.data.questions.length - 1) {
            this.rightActionButtonState = SQActionButtonState.Submit;
            this.rightActionButtonText = "SURVEYS.SUBMIT";
        } else {
            this.rightActionButtonState = SQActionButtonState.Next;
            this.rightActionButtonText = "SURVEYS.NEXT";
        }
    }

    onLeftActionButtonClick() {
        if (this.leftActionButtonState == SQActionButtonState.Cancel) {
            this.modal.show("SURVEYS.ERROR_MESSAGES.CANCEL_HEADER_MESSAGE",
                "SURVEYS.ERROR_MESSAGES.CANCEL_MESSAGE",
                "SURVEYS.YES",
                "SURVEYS.NO", () => {
                    this.router.navigate(['main', 'surveys']);
                }, () => {
                    return;
                });
            return;
        }
        this.currentIndex--;
        this.currentQuestion = this.survey.data.questions[this.currentIndex];
        this.updateActionButtonState();
    }

    onRightActionButtonClick() {
        if (this.rightActionButtonState == SQActionButtonState.Next)
            this.tryProcessForward();
        else if (this.rightActionButtonState == SQActionButtonState.Submit)
            this.trySubmitSurvey();
    }

    trySubmitSurvey() {
        if (!this.currentQuestion.anyAnswerSelected())
            return;

        let surveyEmployeeAnswers: Array<EmployeeSurveyAnswerMessageData> = [];
        this.survey.data.questions.forEach(question => {
            surveyEmployeeAnswers.push(question.employeeAnswerData);
        });

        this.isLoading = true;
        this.spLoader.start();

        this.app.driver.tryFinishReportSurvey(this.app.getEmployeeId(), this.survey, surveyEmployeeAnswers).onSuccess(rsp => {
            this.isLoading = false;
            this.spLoader.stopAndHide();
            this.router.navigate(['main', 'surveys']);
        }).onError((rsp, errorCode) => {
            this.modal.show("SURVEYS.ERROR_MESSAGES.DEFAULT",
                "SURVEYS.ERROR_MESSAGES.SUBMIT_ERROR_TRY_AGAIN",
                "SURVEYS.ERROR_MESSAGES.SUBMIT_ERROR_BUTTON_TRY_AGAIN",
                "SURVEYS.ERROR_MESSAGES.SUBMIT_ERROR_BUTTON_BACK_TO_LIST", () => {
                    this.trySubmitSurvey();
                }, () => {
                    this.router.navigate(['main', 'surveys']);
                });
            this.isLoading = false;
            this.spLoader.stopAndHide();
        }).send();
    }

    tryProcessForward() {
        if (!this.currentQuestion.anyAnswerSelected())
            return;

        this.currentIndex++;
        this.currentQuestion = this.survey.data.questions[this.currentIndex];
        this.updateActionButtonState();
    }

    get questionHint() {
        let questionHint = "";
        switch (this.currentQuestion.type) {
            case QuizQuestionType.SingleSelect:
                questionHint = 'COMMON.QUESTION_HINTS.SINGLE_SELECT';
                break;
            case QuizQuestionType.MultiSelectAny:
            case QuizQuestionType.MultiSelectAll:
                questionHint = 'COMMON.QUESTION_HINTS.MULTI_SELECT_ALL';
                break;
            default:
                questionHint = ''
                break;
        }
        return questionHint;
    }
}


enum SQActionButtonState {
    Cancel,
    Previous,
    Next,
    Submit
}






























