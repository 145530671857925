export enum LeaderBoardType {
    TopInStore = 0,
    TopPros = 1,
    TopStores = 2
}

export enum LeaderBoardBrandSubType {
    MainBrand = 0,
    Overall = 1,
    MainBrandWithSubBrands = 2
}