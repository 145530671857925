export enum ContentStatisticType {
    unknown = 0,
    course = 10,
    teaser = 12,
    topic = 11,
    quiz = 13,
    certificate = 14,
    topicQuiz = 15,
    archiveCourse = 20,
    archiveTopic = 21,

    referenceCourse = 30,
    referenceTopic = 31,

    trainingCourse = 40,
    trainingTopic = 41
}
