import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {App} from "../../app";
import {EmployeeCertificateMessageData} from "../../communication/message_data/eployee_certificate_message_data";

@Component({
    selector: 'sp-certificate-detail',
    templateUrl: '../../../templates/profile/certificate_detail.component.html'

})
export class CertificateDetailCompoent implements OnInit, OnDestroy {


    public app: App = App.instance;
    private sub: any;

    @Input() tiles: any;
    private certificateId: number;
    public certificate: EmployeeCertificateMessageData;

    constructor(private router: Router, private route: ActivatedRoute) {

    }

    ngOnInit(): any {
        let that = this;
        this.sub = this.route.params.subscribe(params => {
            that.certificateId = +params['id'];
            that.certificate = that.app.data.certificates.getValue(that.certificateId);
            that.app.statistic.openCourseCertificate(that.certificate.courseId, that.certificateId);
        });
    }

    ngOnDestroy(): any {
        this.app.statistic.closeCourseCertificate(this.certificate.courseId, this.certificateId);
    }

    onBackButtonClick() {
        if (this.app.isRetailer) {
            if (this.certificate.retailerId == this.app.data.employee.retailerId) {
                this.router.navigate(['main', 'retailer_certificates']);
            } else {
                this.router.navigate(['main', 'previous_certificates_detail', this.certificate.retailerId]);
            }

        } else {
            this.router.navigate(['main', 'brand_certificates']);
        }
    }


}
