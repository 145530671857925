import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {RegisterData} from "./register.data";
import {EmployeeType} from "../../data/employee_type";

@Component({
    selector: 'sp-register',
    templateUrl: '../../../templates/register/register.component.html',
    changeDetection: ChangeDetectionStrategy.Default

})

export class RegisterComponent implements OnInit {

    public RegisterData: RegisterData;

    constructor() {
        this.RegisterData = new RegisterData();
        this.RegisterData.UserType = EmployeeType.retailer;
    }

    ngOnInit(): any {

    }

}
