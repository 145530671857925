import {RegisterStoreMessageData} from "./register_store_message_data";
import {deserializeObject, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {App} from "../../app";

export class RegisterRetailerData implements IJsonDeserializable {
    version: number = 1;

    userType: number;
    referralCode: string;
    countryId: number;

    languageId: number;
    retailerId: number;
    storeId: number;
    store: RegisterStoreMessageData;
    firstName: string;
    lastName: string;
    password: string;
    sellProEmailNotifications: boolean;
    miscEmailNotifications: boolean;
    fullSyncOnCellularData: boolean;
    jobCategory: number;
    stateId: number;
    termsAndConditionsId: number;

    email: string;
    userName: string;
    zipCode: string;

    osType: number = App.instance.appType;

    demo: boolean;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.userType = jsonObject.userType;
        this.referralCode = jsonObject.referralCode;
        this.countryId = jsonObject.countryId;

        this.languageId = jsonObject.languageId;
        this.retailerId = jsonObject.retailerId;
        this.storeId = jsonObject.storeId;
        this.store = deserializeObject(jsonObject.store, RegisterStoreMessageData);
        this.firstName = jsonObject.firstName;
        this.lastName = jsonObject.lastName;
        this.password = jsonObject.password;
        this.sellProEmailNotifications = jsonObject.sellProEmailNotifications;
        this.miscEmailNotifications = jsonObject.miscEmailNotifications;
        this.fullSyncOnCellularData = jsonObject.fullSyncOnCellularData;
        this.jobCategory = jsonObject.jobCategory;
        this.stateId = jsonObject.stateId;
        this.termsAndConditionsId = jsonObject.termsAndConditionsId;

        this.email = jsonObject.email;
        this.userName = jsonObject.userName;
        this.zipCode = jsonObject.zipCode;
        this.demo = jsonObject.demo;
    }
}
