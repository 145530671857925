import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";

export class RetailerEventsRequestMessage extends RequestMessage {
    employeeId: number;
    retailerId: number;
    storeId: number;

    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.RetailerEventsRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.retailerId = jsonObject.retailerId;
        this.storeId = jsonObject.storeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
