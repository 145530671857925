import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {AppCampaignTaskMessageData} from "./app_campaign_task_message_data";
import {AppCampaignTaskTargetType} from "app/ts/data/app_campaign_task_target_type";
import {AppCampaignReportTaskData} from "./app_campaign_report_task_data";
import {AppCampaignEmployeeStatus} from "../../data/app_campaign_employee_status";
import {ICopyFrom} from "../../data/icopy_from";

export class AppCampaignMessageData implements IJsonDeserializable, ICopyFrom<AppCampaignMessageData> {
    version: number = 0;

    id: number;
    name: string;
    description: string;
    brandId: number;
    type: number;
    explicitTaskViewOrder: number;
    languageId: number;
    dateFrom: Date
    dateTo: Date;
    recordStatus: number;

    updatedAt: Date;
    activatedAt: Date;

    employeeAppCampaignStatus: number;
    employeeAppCampaignId: number;
    employeeAppCampaignUpdatedAt: Date;

    //Local
    tasks: AppCampaignTaskMessageData[] = [];

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.description = jsonObject.description;
        this.brandId = jsonObject.brandId;
        this.type = jsonObject.type;
        this.explicitTaskViewOrder = jsonObject.explicitTaskViewOrder;
        this.languageId = jsonObject.languageId;
        this.dateFrom = deserializeDateTime(jsonObject.dateFrom);
        this.dateTo = deserializeDateTime(jsonObject.dateTo);
        this.recordStatus = jsonObject.recordStatus;
        this.updatedAt = deserializeDateTime(jsonObject.updatedAt);
        this.activatedAt = deserializeDateTime(jsonObject.activatedAt);
        this.employeeAppCampaignStatus = jsonObject.employeeAppCampaignStatus;
        this.employeeAppCampaignId = jsonObject.employeeAppCampaignId;
        this.employeeAppCampaignUpdatedAt = jsonObject.employeeAppCampaignUpdatedAt;
    }

    tryMarkAndGetTask(employeeId: number, brandId: number, targetId: number, targetName: string, targets: AppCampaignTaskTargetType[]): AppCampaignTaskMessageData {

        const suitableTasks = this.tasks.filter(task => targets.some(t => t == task.target)).sort((a, b) => {
            if (a.target != b.target)
                return a.target > b.target ? 1 : -1;

            if (a.viewOrder != b.viewOrder)
                return a.viewOrder > b.viewOrder ? 1 : -1;//TODO:Ask if value is smaller is first or ???

            if (a.id != b.id)
                return a.id > b.id ? 1 : -1;

            return 0;
        });

        let selectedTask: AppCampaignTaskMessageData = null;
        for (let i = 0; i < suitableTasks.length; i++) {
            const task = suitableTasks[i];
            if (targetId != null && task.employeeResultTargetId == targetId)
                break;

            if (task.employeeResultTargetId != null)
                continue;

            if (task.targetId == null && brandId != null && task.brandId != brandId)
                continue;

            if (task.targetId == null || task.targetId == targetId) {
                selectedTask = task;
                break;
            }
        }

        if (selectedTask != null) {
            selectedTask.employeeStatus = AppCampaignEmployeeStatus.InAction;
            selectedTask.employeeTaskUpdatedAt = new Date();
            selectedTask.employeeResultTargetId = targetId;
            selectedTask.employeeResultTargetName = targetName;
            return selectedTask;
        }

        return null;
    }

    copyFrom(obj: AppCampaignMessageData): void {
        this.version = obj.version;
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
        this.brandId = obj.brandId;
        this.type = obj.type;
        this.explicitTaskViewOrder = obj.explicitTaskViewOrder;
        this.languageId = obj.languageId;
        this.dateFrom = obj.dateFrom;
        this.dateTo = obj.dateTo;
        this.recordStatus = obj.recordStatus;
        this.updatedAt = obj.updatedAt;
        this.activatedAt = obj.activatedAt;
        this.employeeAppCampaignStatus = obj.employeeAppCampaignStatus;
        this.employeeAppCampaignId = obj.employeeAppCampaignId;
        this.employeeAppCampaignUpdatedAt = obj.employeeAppCampaignUpdatedAt;
    }
}