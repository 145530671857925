import {ResponseMessage} from "../response_message";
import {SurveyMessageData} from "../message_data/survey_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class GetSurveysResponseMessage extends ResponseMessage {
    surveys: Array<SurveyMessageData> = [];

    constructor() {
        super();
        this.type = MessageTypes.GetSurveysResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.surveys = deserializeArrayOfObject(jsonObject.surveys, SurveyMessageData);
    }
}