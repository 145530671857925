import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeeFeedbackMessageData implements IJsonDeserializable {
    version: number = 0;

    syncingImprove: boolean;
    playzoneImprove: boolean;
    quizzesImprove: boolean;
    coursesImprove: boolean;
    crashingImprove: boolean;
    awardsImprove: boolean;
    printingImprove: boolean;
    memoryUsageImprove: boolean;

    comment: string;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.syncingImprove = jsonObject.syncingImprove;
        this.playzoneImprove = jsonObject.playzoneImprove;
        this.quizzesImprove = jsonObject.quizzesImprove;
        this.coursesImprove = jsonObject.coursesImprove;
        this.crashingImprove = jsonObject.crashingImprove;
        this.awardsImprove = jsonObject.awardsImprove;
        this.printingImprove = jsonObject.printingImprove;
        this.memoryUsageImprove = jsonObject.memoryUsageImprove;

        this.comment = jsonObject.comment;
    }
}
