import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeeQuizAttemptMessageData implements IJsonDeserializable {
    version: number = 0;

    guid: string;
    employeeId: number;
    passed: boolean;
    score: number;
    quizType: number;
    quizId: number;
    courseId: number;
    topicId: number;
    awardId: number;
    startAt: Date;
    endAt: Date;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.guid = jsonObject.guid;
        this.employeeId = jsonObject.employeeId;
        this.passed = jsonObject.passed;
        this.score = jsonObject.score;
        this.quizType = jsonObject.quizType;
        this.quizId = jsonObject.quizId;
        this.courseId = jsonObject.courseId;
        this.topicId = jsonObject.topicId;
        this.awardId = jsonObject.awardId;
        this.startAt = jsonObject.startAt;
        this.endAt = jsonObject.endAt;
    }

}