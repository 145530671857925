import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {ActionStatisticMessageData} from "../message_data/action_statistic_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";
import {QueryGooglePlaceMessageData} from "../message_data/query_google_place_message_data";
import {ResponseMessage} from "../response_message";

export class QueryGooglePlacesByLatLongApiResponseMessage extends ResponseMessage {
    data: Array<QueryGooglePlaceMessageData>

    constructor() {
        super();
        this.type = MessageTypes.QueryGooglePlacesByLatLongApiResponse;
    }

    deserialize(jsonObject: any) {
        super.deserialize(jsonObject);
        this.data = deserializeArrayOfObject(jsonObject.data, QueryGooglePlaceMessageData);
    }
}
