import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

import {BrandMessageData} from "../message_data/brand_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class BrandsResponseMessage extends ResponseMessage {
    brands = new Array<BrandMessageData>();

    constructor() {
        super();
        this.type = MessageTypes.BrandsResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.brands = deserializeArrayOfObject(jsonObject.brands, BrandMessageData);
    }
}
