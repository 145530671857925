import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {LeaderBoardInfoMessageData} from "../../../../communication/message_data/leaderboard_info_message_data";
import {App} from "../../../../app";
import {EmployeeLeaderBoardResponseMessage} from "../../../../communication/messages/employee_leaderboard_response_message";
import {StoreForBrandLeaderboardMessageData} from "../../../../communication/message_data/store_for_brand_leaderboard_message_data";
import {SpLoaderComponent} from "../../../common/sp_loader.component";
import {ModalService} from "../../../../common/services/modal.service";
import {LeaderBoardType} from "../../../../data/leader_board_types";

@Component({
    selector: 'sp-brand-leaderboard-list',
    templateUrl: '../../../../../templates/leaderboard/brand/internal/brand_leaderboard_list.component.html'

})
export class BrandLeaderboardListComponent {

    @Input() records: Array<LeaderBoardInfoMessageData>;
    @Input() leaderBoardType: LeaderBoardType;
    @Input() selectedStore: StoreForBrandLeaderboardMessageData;
    LeaderBoardTypes = LeaderBoardType

    constructor() {
    }

    get recordsForDisplay(){
        return this.records.slice(0,10);
    }

    get myStoreScore(){
        const record = this.records.find(p => p.my);
        return record != null ? record.position : ' N/A';
    }
}

