import {Component, OnInit, ViewChild} from '@angular/core';
import {CertificatesListComponent} from "./certificates_list.component";
import {ActivatedRoute} from "@angular/router";
import {EmployeeCertificateMessageData} from "../../../communication/message_data/eployee_certificate_message_data";
import {App} from "../../../app";
import {PreviousRetailersListComponent} from "./previous_retailers_list.component";

@Component({
    selector: 'sp-retailer-certificates',
    templateUrl: '../../../../templates/profile/retailer/retailer_certificates.component.html'
})
export class RetailerCertificatesComponent implements OnInit {
    app: App = App.instance;
    @ViewChild('crCurrent', { static: true }) coursesLibraryComponent: CertificatesListComponent;
    @ViewChild('crPrevious', { static: true }) coursesArchiveComponent: PreviousRetailersListComponent;

    currentCertificates: Array<EmployeeCertificateMessageData>;
    previousCertificates: Array<EmployeeCertificateMessageData>;
    activeTab: HTMLElement;
    activePage: HTMLElement;
    skipTab: boolean = false;
    private sub: any;

    constructor(private route: ActivatedRoute) {
        this.skipTab = false;

    }

    ngOnInit() {
        this.currentCertificates = this.app.data.getCertificatesByRetailer(this.app.data.employee.retailerId);
        this.previousCertificates = this.app.data.getPreviousCertificates(this.app.data.employee.retailerId);
        this.showCurrentPage();
        this.sub = this.route.params.subscribe(params => {
            let skip = params['skip'];
            if (skip) {
                this.showPreviousPage();
            }
        });
    }

    onTabClick(index: number) {
        switch (index) {
            case 0:
                this.showCurrentPage();
                break;
            case 1:
                this.showPreviousPage();
                break;
        }
    }

    showCurrentPage() {
        this.updateActive('tab-current', 'current');
    }

    showPreviousPage() {
        this.updateActive('tab-previous', 'previous');
    }

    private updateActive(tabId: string, pageId: string) {
        let tab = document.getElementById(tabId);
        let page = document.getElementById(pageId);

        if (this.activeTab) {
            this.activeTab.classList.remove("active");
        }

        if (this.activePage)
            this.activePage.classList.remove("active");

        this.activeTab = tab;
        this.activePage = page;

        this.activeTab.classList.add("active");
        this.activePage.classList.add("active");
    }

}