import {Component, EventEmitter, Input, OnDestroy, Output} from "@angular/core";
import {SurveyQuestionMessageData} from "../../../communication/message_data/survey_question_message_data";
import {BaseAnswer} from "./base_answer";

@Component({
    selector: 'sp-image-library-answer',
    templateUrl: '../../../../templates/surveys/answer_types/image-library-answer.component.html',
})
export class ImageLibraryAnswerComponent extends BaseAnswer implements OnDestroy {

    @Input() question: SurveyQuestionMessageData;
    @Input() surveyId: number;
    @Input() employeeSurveyId: number;
    @Output() answerSelect = new EventEmitter<string>();
    uploadedImageData: string = "";

    constructor() {
        super();
    }


    ngOnInit(): void {
    }

    ngOnChanges() {
        if (this.question.employeeAnswerData != null) {
            this.uploadedImageData = this.question.imageBase64Data;
        } else {
            this.uploadedImageData = "";
        }
    }


    ngOnDestroy(): void {
    }


    onImageUploaded(imageData: any) {
        if (imageData != null) {
            this.uploadedImageData = imageData.base64Data;
            imageData.base64DataServer = this.getOnlyBase64Code(imageData.base64Data);
            this.createEmployeeSurveyAnswerMessage(this.question, this.employeeSurveyId, () => {
                this.question.imageBase64Data = imageData.base64Data;
                this.answerSelect.emit();
            }, imageData);
        } else {
            this.question.employeeAnswerData = null;
            this.question.imageBase64Data = null;
            this.uploadedImageData = "";
        }
    }

    getOnlyBase64Code(data: any) {
        let index = data.lastIndexOf(',');

        if (index < 0) {
            index = 0;
        } else {
            index++;
        }

        let resultBase64ForSever = data.substr(index);

        return resultBase64ForSever;
    }


}