import {Injectable} from '@angular/core';
import {Data} from "../../data/data";
import {AppCampaignTaskTargetType} from "../../data/app_campaign_task_target_type";
import {AppCampaignReportTaskData} from "../../communication/message_data/app_campaign_report_task_data";
import {ReportAppCampaignTaskRequestMessage} from "../../communication/messages/report_app_campaign_task_request_message";
import {App} from "../../app";
import {ReportAppCampaignTaskResponseMessage} from "../../communication/messages/report_app_campaign_task_response_message";

@Injectable({
    providedIn: 'root'
})
export class AppCampaignsProcessorService {

    constructor() {
    }

    //TODO:Add for all types

    public async coursePassed(appInstance: App, brandId: number, targetId?: number, targetName?: string) {
        return new Promise<void>(async resolve => {
            const reportsAppCampaignTaskRequestMessages =
                this.markTaskIfExistAndGetRequestMessage(appInstance.data,
                    appInstance.deviceUUID, brandId, targetId, targetName,
                    AppCampaignTaskTargetType.CertifiedForCourse, AppCampaignTaskTargetType.CertifiedForAnyCourse);
            if (reportsAppCampaignTaskRequestMessages == null || reportsAppCampaignTaskRequestMessages.length == 0) {
                resolve();
                return;
            }

            await this.sendReportAppCampaignRequestMessages(appInstance, reportsAppCampaignTaskRequestMessages);
            resolve();
        });
    }

    public async eventAttended(appInstance: App, brandId: number, targetId?: number, targetName?: string) {
        return new Promise<void>(async resolve => {
            const reportsAppCampaignTaskRequestMessages =
                this.markTaskIfExistAndGetRequestMessage(appInstance.data,
                    appInstance.deviceUUID, brandId, targetId, targetName,
                    AppCampaignTaskTargetType.AttendAnyEvent);
            if (reportsAppCampaignTaskRequestMessages == null || reportsAppCampaignTaskRequestMessages.length == 0) {
                resolve();
                return;
            }

            await this.sendReportAppCampaignRequestMessages(appInstance, reportsAppCampaignTaskRequestMessages);
            resolve();
        });
    }

    public async openedReferenceLibrary(appInstance: App, brandId: number, targetId?: number, targetName?: string) {
        return new Promise<void>(async resolve => {
            const reportsAppCampaignTaskRequestMessages =
                this.markTaskIfExistAndGetRequestMessage(appInstance.data,
                    appInstance.deviceUUID, brandId, targetId, targetName,
                    AppCampaignTaskTargetType.AttendReferenceLibrary, AppCampaignTaskTargetType.AttendAnyReferenceLibrary);
            if (reportsAppCampaignTaskRequestMessages == null || reportsAppCampaignTaskRequestMessages.length == 0) {
                resolve();
                return;
            }

            await this.sendReportAppCampaignRequestMessages(appInstance, reportsAppCampaignTaskRequestMessages);
            resolve();
        });
    }

    // public async openedToolbox(appInstance: App, brandId: number, targetId?: number, targetName?: string) {
    //     return new Promise(async resolve => {
    //         const reportsAppCampaignTaskRequestMessages =
    //             this.markTaskIfExistAndGetRequestMessage(appInstance.data,
    //                 appInstance.deviceUUID, brandId, targetId, targetName,
    //                 AppCampaignTaskTargetType.AttendToolbox, AppCampaignTaskTargetType.AttendAnyToolbox);
    //         if (reportsAppCampaignTaskRequestMessages == null || reportsAppCampaignTaskRequestMessages.length == 0) {
    //             resolve();
    //             return;
    //         }
    //
    //         await this.sendReportAppCampaignRequestMessages(appInstance, reportsAppCampaignTaskRequestMessages);
    //         resolve();
    //     });
    // }

    private async sendReportAppCampaignRequestMessages(appInstance: App, reportsAppCampaignTaskRequestMessages: ReportAppCampaignTaskRequestMessage[]) {
        await Promise.all(reportsAppCampaignTaskRequestMessages.map(ractrm => {
            return new Promise<void>(resolve1 => {
                appInstance.driver.markAppCampaignTask(ractrm)
                    .onSuccess((rsp: ReportAppCampaignTaskResponseMessage) => {
                        appInstance.data.updateCampaignData(rsp.appCampaign, rsp.tasks);
                        resolve1();
                    }).onError((error: any) => {
                    resolve1();
                }).send();
            })
        }))
    }

    private markTaskIfExistAndGetRequestMessage(data: Data, deviceId: string, brandId: number, targetId?: number, targetName?: string, ...targets: AppCampaignTaskTargetType[]): ReportAppCampaignTaskRequestMessage[] {
        const reportsAppCampaignTaskRequestMessages = data.appCampaigns.values()
            .map(campaigns => campaigns.tryMarkAndGetTask(data.employee.id, brandId, targetId, targetName, targets))
            .filter(appCampaignTask => appCampaignTask != null)
            .map(appCampaignTask => ReportAppCampaignTaskRequestMessage.create(deviceId, data.employee.languageId, data.employee.id, appCampaignTask.appCampaignId, AppCampaignReportTaskData.createFromAppCampaignTask(appCampaignTask)));
        return reportsAppCampaignTaskRequestMessages;
    }

}
