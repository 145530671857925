import {MessageTypes} from "../message_types";
import {RequestMessage} from "../request_message";
import {App} from "../../app";

export class UpdateTermsAndConditionsEmployeeIdRequestMessage extends RequestMessage {
    employeeId: number;
    preferredLang: number;
    osType: number;
    termsAndConditionsId: number;

    constructor() {
        super();
        this.type = MessageTypes.UpdateTermsAndConditionsEmployeeDateRequest;
        this.osType = App.instance.appType;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
        this.osType = jsonObject.osType;
        this.termsAndConditionsId = jsonObject.termsAndConditionsId;
    }
}
