import {Component, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {RegisterComponent} from "./register.component";
import {ChangeEmailResponseMessage} from "../../communication/messages/change_email_response_message";
import {SpModalComponent} from "../common/sp_modal.component";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-register-email-verification',
    templateUrl: '../../../templates/register/email_verification.component.html'

})


export class EmailVerificationComponent {
    public registerData: RegisterData;
    public app: App = App.instance;
    public forCanada: boolean;

    constructor(private registerComponent: RegisterComponent, private router: Router, private modal:ModalService) {
        this.registerData = registerComponent.RegisterData;
    }

    ngOnInit() {
    }

    ActionButtonTapped() {
        this.router.navigate(['login'])
    }

    OnChangeEmail() {
        this.router.navigate(['register', 'change_email'])
    }

    OnResendVerificationEmail() {
        this.app.driver.changeEmail(this.registerData.UserId != null ? this.registerData.UserId : 0, this.registerData.Email, this.registerData.Email, true)
            .onSuccess((rsp: ChangeEmailResponseMessage) => {
                if (rsp.success)
                    this.modal.show('EMAIL_VERIFICATION.SUCCESS', "EMAIL_VERIFICATION.RESEND_MESSAGE_SUCCESS");
                else
                    this.modal.show('EMAIL_VERIFICATION.ERROR', "EMAIL_VERIFICATION.RESEND_MESSAGE_ERROR");
            })
            .onError((e, ec, em) => {
                this.modal.show('EMAIL_VERIFICATION.ERROR', "EMAIL_VERIFICATION.RESEND_MESSAGE_SECOND_ERROR");
            })
            .send();
    }
}
