import {
    deserializeDateTime,
    deserializeDateTimeUtc,
    IJsonDeserializable
} from "../json_deserialization/json_deserialization";
import {BrandExtendedMessageData} from "./brand_extended_message_data";

export class EventsMessageData implements IJsonDeserializable {
    version: number = 0;

    id: number;
    name: string;
    code: string;
    radius: number;
    date: Date;
    dateUtc: Date;
    dateUtcMillis: number;
    timeZoneIndex: number;
    timeZoneName: string;
    status: number;

    address: string;
    locationType: number;
    locationName: string;
    locationCity: string;
    locationStateAbrv: string;
    locationDescription: string;

    locationZip: string;
    locationLatitude: number;
    locationLongitude: number;

    hostFirstName: string;
    hostLastName: string;

    maxOccupancy: number;
    countRegistered: number;

    promoMessage: string;
    description: string;

    brandId: number;
    updatedAt: Date;

    employeeInvitationId: number;
    employeeIsRegistered: boolean;
    employeeInvitationPersonalCode: string;
    employeeIsAttend: boolean;

    isHosted: boolean;
    eventVersion: number;
    checkInAllowBeforeMin: number;
    checkInAllowAfterMin: number;
    requiredAttendanceCode: boolean;
    employeeIsJoined: boolean;

    // private local fields

    brand: BrandExtendedMessageData;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.id = jsonObject.id;
        this.name = jsonObject.name;
        this.code = jsonObject.code;
        this.radius = jsonObject.radius;
        this.date = deserializeDateTime(jsonObject.date);
        this.timeZoneIndex = jsonObject.timeZoneIndex;
        this.timeZoneName = jsonObject.timeZoneName;
        this.status = jsonObject.status;

        this.address = jsonObject.address;
        this.locationType = jsonObject.locationType;
        this.locationName = jsonObject.locationName;
        this.locationCity = jsonObject.locationCity;
        this.locationStateAbrv = jsonObject.locationStateAbrv;
        this.locationDescription = jsonObject.locationDescription;

        this.locationZip = jsonObject.locationZip;
        this.locationLatitude = jsonObject.locationLatitude;
        this.locationLongitude = jsonObject.locationLongitude;

        this.hostFirstName = jsonObject.hostFirstName;
        this.hostLastName = jsonObject.hostLastName;

        this.maxOccupancy = jsonObject.maxOccupancy;
        this.countRegistered = jsonObject.countRegistered;

        this.promoMessage = jsonObject.promoMessage;
        this.description = jsonObject.description;

        this.brandId = jsonObject.brandId;
        this.updatedAt = deserializeDateTime(jsonObject.updatedAt);

        this.employeeInvitationId = jsonObject.employeeInvitationId;
        this.employeeIsRegistered = jsonObject.employeeIsRegistered;
        this.employeeInvitationPersonalCode = jsonObject.employeeInvitationPersonalCode;

        this.isHosted = jsonObject.isHosted;

        if (this.version > 0) {
            this.eventVersion = jsonObject.eventVersion;
            this.checkInAllowBeforeMin = jsonObject.checkInAllowBeforeMin;
            this.checkInAllowAfterMin = jsonObject.checkInAllowAfterMin;
        }

        if (this.version > 1) {
            this.employeeIsAttend = jsonObject.employeeIsAttend;
            this.dateUtc = deserializeDateTimeUtc(jsonObject.dateUtc);
            this.dateUtcMillis = jsonObject.dateUtcMillis;
        }

        if(this.version > 34){
            this.requiredAttendanceCode = jsonObject.requiredAttendanceCode;
            this.employeeIsJoined = jsonObject.employeeIsJoined;
        }
    }
}
