import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class SurveyQuestionAnswerMessageData implements IJsonDeserializable, Iid {
    version: number = 0;

    id: number;
    questionId: number;
    correct: boolean;
    content: string;
    gotoQuestionId: number;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.questionId = jsonObject.questionId;
        this.correct = jsonObject.correct;
        this.content = jsonObject.content;
        this.gotoQuestionId = jsonObject.gotoQuestionId;
    }
}