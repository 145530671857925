import {Component, OnInit, ViewChild} from "@angular/core";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {App} from "../../app";
import {UiSurveyData} from "../../data/ui_survey_data";
import {Router} from "@angular/router";
import {SurveyDetailService} from "./helpers/survey_detail.service";
import {SpModalComponent} from "../common/sp_modal.component";
import {EmployeeSurveyErrorHelper} from "./helpers/employee_survey_error";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-surveys-list',
    templateUrl: '../../../templates/surveys/surveys_list.component.html'
})
export class SurveysListComponent implements OnInit {

    app = App.instance;
    isLoading: boolean = true;
    surveys: Array<UiSurveyData> = [];
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;


    constructor(private dataHelper: SurveyDetailService, private router: Router,private modal:ModalService) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.spLoader.start();
        this.isLoading = true;
        this.app.driver.getSurveys(this.app.getEmployeeId()).onSuccess(rsp => {
            this.surveys = [];
            rsp.surveys.forEach(survey => {
                let brand = this.app.data.brands.getValue(survey.brandId);
                let brandImageUrl = brand ? brand.imageFileDescriptor : "";
                this.surveys.push(new UiSurveyData(survey, brandImageUrl))
            })
            this.isLoading = false;
            this.spLoader.stopAndHide();
        }).onError(error2 => {
           this.modal.show
        }).send();
    }

    onSurveyClicked(survey: UiSurveyData) {
        this.spLoader.start();
        this.isLoading = true;
        this.app.driver.tryStartSurvey(this.app.getEmployeeId(), survey.data.id, this.app.deviceUUID).onSuccess(rsp => {
            if (rsp.success) {
                survey.normalizeSurveysData(rsp);
                this.dataHelper.setSurverData(survey);
                this.isLoading = false;
                this.spLoader.stopAndHide();
            }
            this.router.navigate(['main', 'surveys']);
        }).onError((e, errorCode) => {
            this.modal.show("SURVEYS.ERROR_MESSAGES.DEFAULT", EmployeeSurveyErrorHelper.getErrorMessage(errorCode));
            this.isLoading = false;
            this.spLoader.stopAndHide();
        }).send();
    }
}






























