import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class CheckRegistrationEmailResponseMessage extends ResponseMessage {
    isDemoInternal: boolean;
    isUsedInternal: boolean;
    isValidForBrandInternal: boolean;

    get isValidForBrand() {
        return this.isValidForBrandInternal && (!this.isUsedInternal || this.isDemoInternal);
    }

    get isValidForRetailer() {
        return !this.isValidForBrandInternal && (!this.isUsedInternal || this.isDemoInternal);
    }

    get isValidForDemo() {
        return !this.isValidForBrandInternal && !this.isUsedInternal;
    }

    constructor() {
        super();
        this.type = MessageTypes.CheckRegistrationEmailResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.isDemoInternal = jsonObject.isDemo;
        this.isUsedInternal = jsonObject.isUsed;
        this.isValidForBrandInternal = jsonObject.isAllowedForBrand;
    }
}