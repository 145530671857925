import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";
import {AppCampaignTaskTargetType} from "../../data/app_campaign_task_target_type";

export class AppCampaignTaskMessageData implements IJsonDeserializable {
    version: number = 0;

    id: number;
    appCampaignId: number;
    name: string;
    description: string;
    brandId: number;
    target: AppCampaignTaskTargetType;
    targetId?: number;
    viewOrder: number;

    employeeStatus: number;
    employeeTaskId?: number;
    employeeTaskUpdatedAt: Date;
    employeeResultTargetName: string;
    employeeResultTargetId?: number;
    employeeResultIsFormal: boolean;
    targetContentMissing: boolean;

    //TODO: is TargetContentMissing has valid data

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.id = jsonObject.id;
        this.appCampaignId = jsonObject.appCampaignId;
        this.name = jsonObject.name;
        this.description = jsonObject.description;
        this.brandId = jsonObject.brandId;
        this.target = jsonObject.target;
        this.targetId = jsonObject.targetId;
        this.viewOrder = jsonObject.viewOrder;
        this.employeeStatus = jsonObject.employeeStatus;
        this.employeeTaskId = jsonObject.employeeTaskId;
        this.employeeTaskUpdatedAt = deserializeDateTime(jsonObject.employeeTaskUpdatedAt);
        this.employeeResultTargetName = jsonObject.employeeResultTargetName;
        this.employeeResultTargetId = jsonObject.employeeResultTargetId;
        this.employeeResultIsFormal = jsonObject.employeeResultIsFormal;
        this.targetContentMissing = jsonObject.targetContentMissing;
    }

}