import {deserializeDateTime, IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeeSurveyAnswerMessageData implements IJsonDeserializable {
    version: number = 0;
    guid: string;
    employeeSurveyId: number;
    questionId: number;
    questionAnswerId: number;
    type: number;
    fileName: string;
    stringContent: string;
    bytesContent: string;
    correct: boolean;
    answeredAt: Date;
    questionAnswerIds: string;


    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;
        this.guid = jsonObject.guid;
        this.employeeSurveyId = jsonObject.employeeSurveyId;
        this.questionId = jsonObject.questionId;
        this.questionAnswerId = jsonObject.questionAnswerId;
        this.type = jsonObject.type;
        this.fileName = jsonObject.fileName;
        this.stringContent = jsonObject.stringContent;
        this.bytesContent = jsonObject.bytesContent;
        this.correct = jsonObject.correct;
        this.answeredAt = deserializeDateTime(jsonObject.answeredAt);
        this.questionAnswerIds = jsonObject.questionAnswerIds;
    }
}