import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class EmployeePointsSyncWithCheckResponseMessage extends ResponseMessage {
    server: number;
    local: number;
    serverBalance: number;
    dailyPoints: number;
    dailyPointsExpiredAfterSec: number;

    constructor() {
        super()
        this.type = MessageTypes.EmployeePointsSyncWithCheckResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.server = jsonObject.server;
        this.local = jsonObject.local;
        this.serverBalance = jsonObject.serverBalance;
        this.dailyPoints = jsonObject.dailyPoints;
        this.dailyPointsExpiredAfterSec = jsonObject.dailyPointsExpiredAfterSec;
    }
}
