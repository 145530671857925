import {MessageTypes} from "../message_types";
import {StoreResponseMessageData} from "../message_data/store_response_message_data";
import {RetailerExtendedMessageData} from "../message_data/retailer_extended_message_data";
import {BrandCategoryMessageData} from "../message_data/brand_category_message_data";
import {ThemeMessageData} from "../message_data/theme_message_data";
import {AwardToCourseRelationMessageData} from "../message_data/award_to_course_relation_message_data";
import {EmployeeCertificateMessageData} from "../message_data/eployee_certificate_message_data";
import {AwardQuizQuestionAnswerMessageData} from "../message_data/award_quiz_question_answer_message_data";
import {AwardQuizQuestionMessageData} from "../message_data/award_quiz_question_message_data";
import {AwardQuizMessageData} from "../message_data/award_quiz_message_data";
import {AwardMessageData} from "../message_data/award_message_data";
import {BrandExtendedMessageData} from "../message_data/brand_extended_message_data";
import {CategoryMessageData} from "../message_data/category_message_data";
import {TeaserAnswerMessageData} from "../message_data/teaser_answer_message_data";
import {TeaserMessageData} from "../message_data/teaser_message_data";
import {TopicMessageData} from "../message_data/topic_message_data";
import {QuizQuestionAnswerMessageData} from "../message_data/quiz_question_answer_message_data";
import {QuizQuestionMessageData} from "../message_data/quiz_question_message_data";
import {QuizMessageData} from "../message_data/quiz_message_data";
import {CourseMessageData} from "../message_data/course_message_data";
import {EmployeeMessageData} from "../message_data/employee_message_data";
import {ResponseMessage} from "../response_message";
import {deserializeArrayOfObject, deserializeObject} from "../json_deserialization/json_deserialization";
import { AppCampaignMessageData } from "../message_data/app_campaign_message_data";
import { AppCampaignTaskMessageData } from "../message_data/app_campaign_task_message_data";

export class CoursesAndRelatedResponseMessage extends ResponseMessage {
    employee: EmployeeMessageData;
    courses: Array<CourseMessageData> = [];
    quizzes: Array<QuizMessageData> = [];
    quizQuestions: Array<QuizQuestionMessageData> = [];
    quizQuestionAnswers: Array<QuizQuestionAnswerMessageData> = [];
    topics: Array<TopicMessageData> = [];
    teasers: Array<TeaserMessageData> = [];
    teaserAnswers: Array<TeaserAnswerMessageData> = [];

    categories: Array<CategoryMessageData> = [];
    brands: Array<BrandExtendedMessageData> = [];
    awards: Array<AwardMessageData> = [];
    awardQuizzes: Array<AwardQuizMessageData> = [];
    awardQuizQuestions: Array<AwardQuizQuestionMessageData> = [];
    awardQuizQuestionAnswers: Array<AwardQuizQuestionAnswerMessageData> = [];


    certificates: Array<EmployeeCertificateMessageData> = [];
    awardToCourses: Array<AwardToCourseRelationMessageData> = [];
    themes: Array<ThemeMessageData> = [];
    brandCategories: Array<BrandCategoryMessageData> = [];
    retailer: RetailerExtendedMessageData;
    store: StoreResponseMessageData;

    appCampaigns: AppCampaignMessageData[] = [];
    appCampaignTasks: AppCampaignTaskMessageData[] = [];

    constructor() {
        super();
        this.type = MessageTypes.CoursesAndRelatedResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employee = deserializeObject(jsonObject.employee, EmployeeMessageData);
        this.courses = deserializeArrayOfObject(jsonObject.courses, CourseMessageData);
        this.quizzes = deserializeArrayOfObject(jsonObject.quizzes, QuizMessageData);
        this.quizQuestions = deserializeArrayOfObject(jsonObject.quizQuestions, QuizQuestionMessageData);
        this.quizQuestionAnswers = deserializeArrayOfObject(jsonObject.quizQuestionAnswers, QuizQuestionAnswerMessageData);
        this.topics = deserializeArrayOfObject(jsonObject.topics, TopicMessageData);
        this.teasers = deserializeArrayOfObject(jsonObject.teasers, TeaserMessageData);
        this.teaserAnswers = deserializeArrayOfObject(jsonObject.teaserAnswers, TeaserAnswerMessageData);
        this.categories = deserializeArrayOfObject(jsonObject.categories, CategoryMessageData);
        this.brands = deserializeArrayOfObject(jsonObject.brands, BrandExtendedMessageData);
        this.awards = deserializeArrayOfObject(jsonObject.awards, AwardMessageData);
        this.awardQuizzes = deserializeArrayOfObject(jsonObject.awardQuizzes, AwardQuizMessageData);
        this.awardQuizQuestions = deserializeArrayOfObject(jsonObject.awardQuizQuestions, AwardQuizQuestionMessageData);
        this.awardQuizQuestionAnswers = deserializeArrayOfObject(jsonObject.awardQuizQuestionAnswers, AwardQuizQuestionAnswerMessageData);
        this.certificates = deserializeArrayOfObject(jsonObject.certificates, EmployeeCertificateMessageData);
        this.awardToCourses = deserializeArrayOfObject(jsonObject.awardToCourses, AwardToCourseRelationMessageData);
        this.themes = deserializeArrayOfObject(jsonObject.themes, ThemeMessageData);
        this.brandCategories = deserializeArrayOfObject(jsonObject.brandCategories, BrandCategoryMessageData);
        this.retailer = deserializeObject(jsonObject.retailer, RetailerExtendedMessageData);
        this.store = deserializeObject(jsonObject.store, StoreResponseMessageData);
        this.appCampaigns = deserializeArrayOfObject(jsonObject.appCampaigns, AppCampaignMessageData);
        this.appCampaignTasks = deserializeArrayOfObject(jsonObject.appCampaignTasks, AppCampaignTaskMessageData);
    }
}
