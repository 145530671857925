import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {App} from "../../app";
import {StoresResponseMessage} from "../../communication/messages/stores_response_message";
import {StoreResponseMessageData} from "../../communication/message_data/store_response_message_data";
import {QueryGooglePlaceMessageData} from "../../communication/message_data/query_google_place_message_data";
import {StoreFieldsRequirementValues} from 'app/ts/data/store_fields_requirement_values';
import {StringExtensions} from "../extensions";
import {SpLoaderComponent} from 'app/ts/ui/common/sp_loader.component';
import {ModalService} from '../services/modal.service';
import {IMarker} from 'app/ts/common/components/pick_store_from_google_maps.component';
import {AddStoraData} from "../../data/add.store.data";
import {CountryMessageData} from "../../communication/message_data/country_message_data";

@Component({
    selector: 'sp-stores-list-internal',
    templateUrl: '../../../templates/common/components/stores_list.component.html',
    styleUrls: ['../../../styles/components/store_list.css']
})
export class StoresListInternalComponent implements OnInit {
    @Input() retailerId: number;
    @Input() retailerName: string;
    @Input() languageId: number;
    @Input() stateId: number;
    @Input() country: CountryMessageData;
    @Input() useThemeHeader: boolean;

    @Output() onStoreSelected: EventEmitter<StoreResponseMessageData> = new EventEmitter<StoreResponseMessageData>();
    @Output() onStoreCreatedOrPicked: EventEmitter<AddStoraData> = new EventEmitter<AddStoraData>();
    @Output() onStoreSelectCancel: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('sploader', { static: false }) spLoader: SpLoaderComponent;
    app: App = App.instance;
    searchQuery: string = "";
    showGoogleMapPicker = false;
    errorMessage: string = null;
    storesForPickUp: Array<QueryGooglePlaceMessageData> = null;
    showStoresNotListedButton = false;
    targetLocationCordinates: LocationCordinates = null;
    storesList: Array<StoreResponseMessageData>;

    isCreatingStore: boolean = false;
    storeData: AddStoraData;

    constructor(private modal: ModalService) {
    }

    ngOnInit() {
        this.storeData = new AddStoraData(this.country.storeZipRequirement, this.country.storeDescriptionRequirement, this.country.name);
        this.storeData.RetailerId = this.retailerId;
    }

    async SearchOnServer(useMyLocation: boolean) {
        this.showGoogleMapPicker = false;
        if (useMyLocation)
            this.searchQuery = null;

        if (StringExtensions.isNullorEmpty(this.searchQuery) && !useMyLocation) {
            this.modal.show("", this.enterValueMessage);
            return;
        }
        this.showStoresNotListedButton = false;
        this.spLoader.start();
        this.targetLocationCordinates = await this.getCordinatesLocation(useMyLocation)
        if (this.targetLocationCordinates == null || this.targetLocationCordinates.latitude == null || this.targetLocationCordinates.longitude == null) {
            this.spLoader.stopAndHide();
            this.errorMessage = this.enterValidValueMessage;
            return;
        }
        this.errorMessage = null;
        this.app.driver.getStores(this.retailerId, this.targetLocationCordinates.longitude, this.targetLocationCordinates.latitude)
            .onSuccess((rsp: StoresResponseMessage) => {
                this.showStoresNotListedButton = true;
                this.spLoader.stopAndHide();
                if (rsp.stores.length == 0) {
                    return;
                }
                this.errorMessage = null;
                this.storesList = rsp.stores;
                if (!rsp.orderedByZip) {
                    this.storesList.sort((a, b) => {
                        if (a.city.toLowerCase() > b.city.toLowerCase()) return 1;
                        if (a.city.toLowerCase() < b.city.toLowerCase()) return -1;
                        if (a.address.toLowerCase() > b.address.toLowerCase()) return 1;
                        if (a.address.toLowerCase() < b.address.toLowerCase()) return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                    });
                }

            })
            .onError((e, ec, em) => {
                this.errorMessage = this.enterValidValueMessage;
                this.spLoader.stopAndHide();
                this.showStoresNotListedButton = false;
            })
            .send();
    }

    OnCreateStore() {
        this.modal.show("COMMON.ARE_YOU_SURE", "STORES_LIST.YOUR_PROFILE_WILL_BE_CREATED_WITH_AN_UNLISTED_STORE",
            "COMMON.YES", "COMMON.NO",
            () => {
                this.isCreatingStore = true;
            }, () => {
            });
    }

    OnStoreCreated(store: AddStoraData) {
        this.storeData = store;
        this.isCreatingStore = false;
        this.onStoreCreatedOrPicked.emit(store);
    }

    OnStoreCreateCancel() {
        this.isCreatingStore = false;
    }

    OnEnablePickStore() {
        this.spLoader.start();
        this.app.driver.getGooglePlacesByCordinatesAndRetailerName(this.retailerName,
            this.targetLocationCordinates.latitude,
            this.targetLocationCordinates.longitude,
            this.languageId)
            .onSuccess(rsp => {
                this.showGoogleMapPicker = true;
                this.showStoresNotListedButton = false;
                this.spLoader.stopAndHide();
                if (rsp.data.length == 0) {
                    this.errorMessage = this.noRecordsFoundMessage;
                    return;
                }
                this.errorMessage = null;
                this.storesForPickUp = rsp.data;
            }).onError(error => {
            this.errorMessage = this.noRecordsFoundMessage;
        }).send()
    }

    OnPickStore(marker: IMarker) {
        this.storeData.GooglePlaceId = marker.placeId;
        this.storeData.StoreNumber = marker.name;
        this.storeData.Address = marker.address;
        this.storeData.Lat = marker.latitude;
        this.storeData.Lng = marker.longitude;
        this.onStoreCreatedOrPicked.emit(this.storeData);
    }

    BackButtonTapped() {
        if (this.showGoogleMapPicker) {
            this.showGoogleMapPicker = false;
            this.showStoresNotListedButton = true;
            this.errorMessage = null;
        } else {
            this.onStoreSelectCancel.emit();
        }
    }

    Selected(record: StoreResponseMessageData) {
        this.onStoreSelected.emit(record);
    }

    getMyCurrentLocation(): Promise<LocationCordinates> {
        return new Promise(resolve => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((location) => {
                    resolve({latitude: location.coords.latitude, longitude: location.coords.longitude})
                }, (error) => {
                    this.modal.show("COMMON.ERROR", "STORES_LIST.GEOLOCATION_IS_NOT_ENABLED");
                    resolve(null);
                });
            } else {
                this.modal.show("COMMON.ERROR", "STORES_LIST.GEOLOCATION_IS_NOT_SUPPORTED");
                resolve(null);
            }
        });
    }

    getCordinatesLocationBySearchQuery(): Promise<LocationCordinates> {
        return new Promise(resolve => {
            this.app.driver.getGoogleLocationBySearchQuery(this.searchQuery, this.country.id, this.stateId, this.languageId)
                .onSuccess((rsp) => {
                    resolve({
                        latitude: rsp.latitude,
                        longitude: rsp.longitude
                    });
                }).onError(error => {
                resolve(null);
            }).send();
        })
    }

    getCordinatesLocation(useMyLocation: boolean): Promise<LocationCordinates> {
        if (useMyLocation) {
            return this.getMyCurrentLocation();
        } else {
            return this.getCordinatesLocationBySearchQuery();
        }
    }

    get enterValueMessage() {
        switch (this.country.storeZipRequirement) {
            case StoreFieldsRequirementValues.Required:
                return 'STORES_LIST.ENTER_CITY_OR_STATE_OR_ZIP';
            case StoreFieldsRequirementValues.No:
                return 'STORES_LIST.ENTER_CITY_OR_STATE';
        }
        return ''
    }

    get enterValidValueMessage() {
        switch (this.country.storeZipRequirement) {
            case StoreFieldsRequirementValues.Required:
                return 'STORES_LIST.ENTER_VALID_CITY_AND_STATE_OR_ZIP';
            case StoreFieldsRequirementValues.No:
                return 'STORES_LIST.ENTER_VALID_CITY_AND_STATE';
        }
        return ''
    }

    get noRecordsFoundMessage() {
        switch (this.country.storeZipRequirement) {
            case StoreFieldsRequirementValues.Required:
                return 'STORES_LIST.STORES_MAP_EMPTY_RESULT_SET_MESSAGE_ZIP_TEXT_BLOCK';
            case StoreFieldsRequirementValues.No:
                return 'STORES_LIST.STORES_MAP_EMPTY_RESULT_SET_MESSAGE_NO_ZIP_TEXT_BLOCK';
        }
        return ''
    }
}


export interface LocationCordinates {
    latitude: number;
    longitude: number;
}