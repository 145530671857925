import {ActionStatisticMessageData} from "../communication/message_data/action_statistic_message_data";

export class ActionStatistic {
    guid: string;
    employeeId: number;
    action: number;
    dateTime: Date;
    reason: number;
    place: number;
    device: string;
    appVersion: string;
    additional1int: number;
    additional2int: number;
    additional3int: number;
    additional1str: string;
    performRelated: number;

    private toMessageData(): ActionStatisticMessageData {
        let actionStatistic: ActionStatisticMessageData = new ActionStatisticMessageData();

        actionStatistic.guid = this.guid;
        actionStatistic.employeeId = this.employeeId;
        actionStatistic.action = this.action;
        actionStatistic.dateTime = this.dateTime;
        actionStatistic.reason = this.reason;
        actionStatistic.place = this.place;
        actionStatistic.device = this.device;
        actionStatistic.appVersion = this.appVersion;
        actionStatistic.additional1int = this.additional1int;
        actionStatistic.additional2int = this.additional2int;
        actionStatistic.additional3int = this.additional3int;
        actionStatistic.additional1str = this.additional1str;
        actionStatistic.performRelated = this.performRelated;

        return actionStatistic;
    }

    public static toMessageData(records: Array<ActionStatistic>): ActionStatisticMessageData[] {
        let results: Array<ActionStatisticMessageData> = [];

        for (let record of records) {
            results.push(record.toMessageData());
        }
        return results;
    }
}