import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {App} from "../../app";

@Component({
    selector: 'sp-settings',
    templateUrl: '../../../templates/settings/settings.component.html',


})
export class SettingsComponent {
    app: App = App.instance;
    currentYear: number;

    constructor(private router: Router) {
        this.currentYear = new Date().getFullYear();
    }

    showTermsAndConditions() {
        this.router.navigate(['main', 'settings_terms_and_conditions']);
    }

    showPreferences() {
        this.router.navigate(['main', 'settings_preferences']);
    }

    showFeedback() {
        this.router.navigate(['main', 'settings_feedback']);
    }


}

