import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class ChangeEmailAfterActivationRequestMessage extends RequestMessage {
    employeeId: number;
    newEmail: string;
    oldEmail: string;
    sentEmail: boolean;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.ChangeEmailAfterActivationRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.newEmail = jsonObject.newEmail;
        this.oldEmail = jsonObject.oldEmail;
        this.sentEmail = jsonObject.sentEmail;
        this.preferredLang = jsonObject.preferredLang;
    }
}
