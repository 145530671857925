import {CourseMessageData} from "../../communication/message_data/course_message_data";

export class CourseFilterData {
    public static ByLastUpdated: number = 1;
    public static ByTitle: number = 2;
    public static ByBrand: number = 3;

    brandIds: Array<number> = [];
    categoryIds: Array<number> = [];

    sortType: number = CourseFilterData.ByLastUpdated;
    filterText: string = null;

    public match(course: CourseMessageData): boolean {
        if (this.brandIds.length > 0 && !this.brandIds.some((v) => v == course.brandId))
            return false;

        if (this.categoryIds.length > 0 && !this.categoryIds.some((v) => v == course.categoryId))
            return false;

        if (this.filterText && (course.decoratedNameByState().toLowerCase().indexOf(this.filterText) < 0
            && !course.topics.some(t => t.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1)))
            return false;

        return true;
    }

    reset() {
        this.brandIds = [];
        this.categoryIds = [];
        this.sortType = CourseFilterData.ByLastUpdated;
    }

    isSet(): boolean {
        return this.brandIds.length > 0 || this.categoryIds.length > 0;
    }
}
