import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {EmployeeCertificateMessageData} from "../../../communication/message_data/eployee_certificate_message_data";
import Dictionary from "../../../collections/Dictionary";

@Component({

    selector: 'sp-previous-retailers-list',
    templateUrl: '../../../../templates/profile/retailer/previous_retailers_list.component.html'
})
export class PreviousRetailersListComponent implements OnInit {
    @Input() initialData: Array<EmployeeCertificateMessageData>;
    certificates: Array<CertificatesWithRetailerName> = [];

    constructor(private router: Router) {
    }

    ngOnInit() {
        let d: Dictionary<number, CertificatesWithRetailerName> = new Dictionary<number, CertificatesWithRetailerName>();
        for (let p of this.initialData) {
            let temp: CertificatesWithRetailerName = d.getValue(p.retailerId);
            if (!temp)
                temp = new CertificatesWithRetailerName(p.retailerId, p.retailerName);

            temp.certificates.push(p);
            d.setValue(p.retailerId, temp);
        }

        this.certificates = d.values();
    }

    onListItemSelected(cert: CertificatesWithRetailerName) {
        this.router.navigate(['main', 'previous_certificates_detail', cert.retailerId]);
    }


}

export class CertificatesWithRetailerName {
    retailerId: number;
    retailerName: string;
    certificates: Array<EmployeeCertificateMessageData> = [];

    constructor(retailerId: number, retailerName: string) {
        this.retailerId = retailerId;
        this.retailerName = retailerName;
    }
}
