export class EventFilterData {

    private _zip: string;
    private _radius: string;
    private _eventCode: string


    get eventCode(): string {
        return this._eventCode;
    }

    set eventCode(value: string) {
        this._eventCode = value;
    }

    get radius(): string {
        return this._radius;
    }

    set radius(value: string) {
        this._radius = value;
    }

    get zip(): string {
        return this._zip;
    }

    set zip(value: string) {
        this._zip = value;
    }
}
