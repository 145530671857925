import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {App} from "../../app";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {BrandCategoryMessageData} from "../../communication/message_data/brand_category_message_data";
import {EmployeeCourseState} from "../../data/employee_course_state";
import {ContentStatisticType} from "../../data/content_statistic_type";
import {ThemeWrapper} from "../common/theme_wrapper";

@Component({
    selector: 'sp-course-detail',
    templateUrl: '../../../templates/courses/courses_detail.component.html'

})
export class CoursesDetailComponent implements OnInit, OnDestroy {

    @Input() course: CourseMessageData;
    brandCategory: BrandCategoryMessageData;
    private sub: any;
    public app: App = App.instance;
    actionButtonOpacity: number;
    actionButtonText: string;
    actionButtonState: CDActionButtonState;

    ngOnInit(): any {
        let that = this;
        that.sub = that.route.params.subscribe(params => {
            let id = +params['id'];
            that.course = that.app.data.courses.getValue(id);
            that.updateActionButton();
            that.brandCategory = that.app.data.getBrandCategory(that.course.brandId, that.course.categoryId);
            that.app.statistic.courseOpen(that.course.id, ContentStatisticType.course);
        });
    }

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    onTopicClick(index: number) {
        if (!this.isTopicAllowed(index))
            return;

        this.router.navigate(['main', 'course_topics', this.course.id, index]);
    }

    getTopicOpacity(index: number): string {
        return this.isTopicAllowed(index) ? "1" : "0.3";
    }

    isTopicAllowed(index: number): boolean {
        if (index == 0)
            return true;

        return this.course.topics[index - 1].employeeRecordStatus;
    }

    updateActionButton() {
        if (this.course.forEmployeeState == EmployeeCourseState.quizCompleted) {
            this.actionButtonOpacity = 1;
            if (this.course.isAllowedRecertification()) {
                console.log("POP_QUIZ");
                this.actionButtonText = "COURSE_QUIZ.POP_QUIZ";
                this.actionButtonState = CDActionButtonState.Quiz;
            } else {
                console.log("CERTIFICATE");
                this.actionButtonText = "COURSE_QUIZ.CERTIFICATE";
                this.actionButtonState = CDActionButtonState.Certificate;
            }
        }
        else {
            if (!this.course.allTopicsPassed() || this.course.quiz == null) {
                this.actionButtonText = "COURSE.QUIZ_BUTTON";
                this.actionButtonOpacity = 0.5;
                this.actionButtonState = CDActionButtonState.None;
            }
            else {
                this.actionButtonOpacity = 1;
                this.actionButtonText = "COURSE.QUIZ_BUTTON";
                this.actionButtonState = CDActionButtonState.Quiz;
            }
        }
    }

    onActionButtonClick() {
        switch (this.actionButtonState) {
            case CDActionButtonState.Quiz:
                this.showQuiz();
                break;
            case CDActionButtonState.Certificate:
                this.showCertificate();
                break;
        }
    }

    get imageFileDescriptor(): string {

        if (this.brandCategory && this.brandCategory.imageFileDescriptor) {
            return this.brandCategory.imageFileDescriptor;
        } else {
            return '/assets/images/default_brand_category_image.jpg';
        }
    }

    private showCertificate() {
        for (let certificate of this.app.data.certificates.values()) {
            if (certificate.courseId == this.course.id) {
                this.router.navigate(['main', 'certificate_detail', certificate.id]);
                return;
            }
        }
    }

    private showQuiz() {
        if (!this.course.allTopicsPassed())
            return;
        this.router.navigate(['course_quiz', this.course.id]);
    }

    ngOnDestroy() {
        this.app.statistic.courseClosed(this.course.id, ContentStatisticType.course);
        this.sub.unsubscribe();
    }

    get TextOverImageColor() {
        if (this.brandCategory) {
            return ThemeWrapper.ConvertIntToHex(this.brandCategory.textOverImageColor);
        } else {
            return 'white';
        }
    }

    get TopicHeaderColor(){
        if (this.brandCategory) {
            return ThemeWrapper.ConvertIntToHex(this.brandCategory.tileTextColor);
        } else {
            return this.app.theme.TopicHeaderColor;
        }
    }
}

enum CDActionButtonState {
    None,
    Quiz,
    Certificate
}

