import {MessageTypes} from "../message_types";
import {ResponseMessage} from "../response_message";
import {SurveyQuestionMessageData} from "../message_data/survey_question_message_data";
import {SurveyQuestionAnswerMessageData} from "../message_data/survey_question_answer_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class TryStartSurveyResponseMessage extends ResponseMessage {

    success: boolean;
    surveyId: number;
    employeeSurveyId: number;
    questions: Array<SurveyQuestionMessageData>;
    answers: Array<SurveyQuestionAnswerMessageData>;

    constructor() {
        super();
        this.type = MessageTypes.TryStartSurveyResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.success = jsonObject.success;
        this.surveyId = jsonObject.surveyId;
        this.employeeSurveyId = jsonObject.employeeSurveyId;
        this.questions = deserializeArrayOfObject(jsonObject.questions, SurveyQuestionMessageData);
        this.answers = deserializeArrayOfObject(jsonObject.answers, SurveyQuestionAnswerMessageData);
    }
}