import {ItemMessageData} from "./item_message_data";

export class RetailerCategoryMessageData extends ItemMessageData {
    retailerCategoryMessageDataVersion: number = 0;

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.retailerCategoryMessageDataVersion = jsonObject.retailerCategoryMessageDataVersion;
    }
}
