import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {App} from "../../app";
import {CoursesListComponent} from "./courses_list.component";
import {EmployeeCourseState} from "../../data/employee_course_state";
import {CoursesFilterComponent} from "../common/courses_filter.component";
import {CourseFilterData} from "../common/course_filter_data";
import {BrandExtendedMessageData} from "../../communication/message_data/brand_extended_message_data";
import {CategoryMessageData} from "../../communication/message_data/category_message_data";

@Component({
    selector: 'sp-courses',
    templateUrl: '../../../templates/courses/courses.component.html'
})
export class CoursesComponent implements OnInit {
    @ViewChild('cfilter', { static: true }) filter: CoursesFilterComponent;
    @ViewChild('crNew', { static: true }) coursesNewComponent: CoursesListComponent;
    @ViewChild('crInProgress', { static: true }) coursesInProgressComponent: CoursesListComponent;
    @ViewChild('crCompleted', { static: true }) coursesCompletedComponent: CoursesListComponent;

    isFilterOpen: boolean = false;
    isFilterSet: boolean = false;

    private sub: any;
    app: App = App.instance;
    courses: Array<CourseMessageData> = [];

    coursesNew: Array<CourseMessageData>;
    coursesInProgress: Array<CourseMessageData>;
    coursesCompleted: Array<CourseMessageData>;

    @Input() selectedInitialTab: number;

    activeTab: HTMLElement;
    activePage: HTMLElement;

    constructor(private route: ActivatedRoute) {
    }


    ngOnInit(): any {
        this.sub = this.route.params.subscribe(params => {
            this.selectedInitialTab = +params['ecs'];
            this.courses = this.app.data.courses.values();
            this.initTabs();
        });
    }

    ngAfterViewInit() {
    }

    private initTabs() {
        this.coursesNew = this.app.data.getNewCourses();
        this.coursesInProgress = this.app.data.getInProgressCourses();
        this.coursesCompleted = this.app.data.getCompletedCourses();

        if (this.selectedInitialTab) {
            switch (this.selectedInitialTab) {
                case EmployeeCourseState.noAnyTopicOpen:
                    this.showNewTabPage();
                    break;
                case EmployeeCourseState.inProgress:
                    this.showInProgressTabPage();
                    break;
                case EmployeeCourseState.quizCompleted:
                    this.showCompletedTabPage();
                    break
            }
        }
        else {
            this.showNewTabPage();
        }
    }

    onTabClick(index: number) {
        switch (index) {
            case 0:
                this.showNewTabPage();
                break;
            case 1:
                this.showInProgressTabPage();
                break;
            case 2:
                this.showCompletedTabPage();
                break;
        }
    }

    private showCompletedTabPage() {
        this.updateActive("tab-completed", "completed");
        this.isFilterSet = this.coursesCompletedComponent.filterData.isSet();
    }

    private showInProgressTabPage() {
        this.updateActive("tab-in-progress", "in-progress");
        this.isFilterSet = this.coursesInProgressComponent.filterData.isSet();
    }

    private showNewTabPage() {
        this.updateActive("tab-new", "new");
        this.isFilterSet = this.coursesNewComponent.filterData.isSet();
    }

    private updateActive(tabId: string, pageId: string) {
        let tab = document.getElementById(tabId);
        let page = document.getElementById(pageId);

        if (this.activeTab)
            this.activeTab.classList.remove("active");

        if (this.activePage)
            this.activePage.classList.remove("active");

        this.activeTab = tab;
        this.activePage = page;

        this.activeTab.classList.add("active");
        this.activePage.classList.add("active");
        this.isFilterOpen = false;
    }

    public onFilterButtonClick() {
        if (this.isFilterOpen) {
            this.isFilterOpen = false;
            return;
        }

        let fd: CourseFilterData;
        let ab: Array<BrandExtendedMessageData>;
        let ac: Array<CategoryMessageData>;

        switch (this.activeTab.id) {
            case "tab-new":
                fd = this.coursesNewComponent.filterData;
                ab = this.coursesNewComponent.getAllBrands();
                ac = this.coursesNewComponent.getAllCategories();
                break;
            case "tab-in-progress":
                fd = this.coursesInProgressComponent.filterData;
                ab = this.coursesInProgressComponent.getAllBrands();
                ac = this.coursesInProgressComponent.getAllCategories();
                break;
            case "tab-completed":
                fd = this.coursesCompletedComponent.filterData;
                ab = this.coursesCompletedComponent.getAllBrands();
                ac = this.coursesCompletedComponent.getAllCategories();
                break;
        }

        this.filter.filterData = fd;
        this.filter.allBrands = ab;
        this.filter.allCategories = ac;

        this.filter.init();
        this.isFilterOpen = true;
    }

    onFilterChanged(fd: CourseFilterData) {
        switch (this.activeTab.id) {
            case "tab-new":
                this.coursesNewComponent.filterList(fd);
                break;
            case "tab-in-progress":
                this.coursesInProgressComponent.filterList(fd);
                break;
            case "tab-completed":
                this.coursesCompletedComponent.filterList(fd);
                break;
        }

        this.isFilterSet = fd.isSet();
    }
}