import {Component, OnInit, ViewChild} from '@angular/core';
import {EventFilterData} from "../../data/event_filter_data";
import {StringExtensions} from "../../common/extensions";
import {App} from "../../app";
import {RetailerEventsFreeSearchResponseMessage} from "../../communication/messages/retailer_events_free_search_response_message";
import {Router} from "@angular/router";
import {UIEventData} from "../../data/ui_event_data";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {EventAttachmentMessageData} from "../../communication/message_data/event_attachment_message_data";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-events-search',
    templateUrl: '../../../templates/events/events_search.component.html',
})
export class SearchEventsComponent implements OnInit {

    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;

    public isSearchingInProcess: boolean = false;
    app: App = App.instance;
    router: Router;
    searchFilterData: EventFilterData;
    eventsData: Array<UIEventData> = [];


    constructor(router: Router, private modal: ModalService) {
        this.router = router;
    }

    ngOnInit(): any {
        this.searchFilterData = new EventFilterData();
    }


    onSubmit() {
        if (this.formIsValid()) {
            this.isSearchingInProcess = true;
            this.spLoader.start();
            this.app.driver.getRetailerFreeSearchEvents(this.app.data.employee.id,
                this.app.data.employee.retailerId, this.searchFilterData.zip,
                +this.searchFilterData.radius, this.searchFilterData.eventCode, this.app.data.store.id).onSuccess((rsp: RetailerEventsFreeSearchResponseMessage) => {
                if (rsp != null && rsp.error == null) {
                    this.eventsData = [];
                    rsp.events.forEach((event) => {
                            let attachments: Array<EventAttachmentMessageData> = [];
                            attachments = rsp.attachments.filter(attachment => attachment.eventId === event.id);
                            event.brand = this.app.data.brands.getValue(event.brandId);
                            this.eventsData.push(new UIEventData(event, attachments));
                        }
                    );
                    this.spLoader.stopAndHide();
                    this.isSearchingInProcess = false;
                }
            }).onError((error: any) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                this.spLoader.stopAndHide();
                this.isSearchingInProcess = false;
            }).send();
        }
    }

    onReset() {
        this.searchFilterData = new EventFilterData();
        this.eventsData = [];
    }

    formIsValid(): boolean {
        if (!StringExtensions.isNullorEmpty(this.searchFilterData.zip) &&
            this.searchFilterData.zip.length > 0 && !StringExtensions.isNullorEmpty(this.searchFilterData.radius) &&
            this.searchFilterData.radius.length > 0 ||
            !StringExtensions.isNullorEmpty(this.searchFilterData.eventCode) &&
            this.searchFilterData.eventCode.length > 0) {
            return true;
        }
        return false;
    }
}

