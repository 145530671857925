import {DriverWithMessages} from "./communication/driver_with_messages";
import {HttpClient} from "@angular/common/http";
import {Data} from "./data/data";
import {Statistic} from "./data/statistics";
import {Router} from "@angular/router";
import { OnInit, Directive } from "@angular/core";
import {ThemeWrapper} from "./ui/common/theme_wrapper";
import {StringExtensions} from "./common/extensions";
import {UUID} from "./common/uuid";
import {Language} from "./language";
import {ICommonEmployeeData} from "./data/common_employee_data";
import {EmployeeMessageData} from "./communication/message_data/employee_message_data";
import {RetailerEventsResponseMessage} from "./communication/messages/retailer_events_response_message";
import {SendQuizStatistic} from "./data/send_quiz_statistic";
import {EmployeePointsReasonMessageData} from "./communication/message_data/employee_points_reason_message_data";
import {EmployeePointsSyncWithCheckResponseMessage} from "./communication/messages/employee_points_sync_with_check_response_message";
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "./common/services/modal.service";
import {UserSessionProviderService} from "./common/services/user-session-provider.service";
import {HelperMethods} from "./common/helper_methods";

@Directive()
export class App implements OnInit, ICommonEmployeeData {

    getId(): number {
        let employee = this.getEmployee();
        return employee != null ? employee.id : 0;
    }

    getEmployeeLanguage(): number {
        let employee = this.getEmployee();
        return (employee != null && employee.languageId != null) ? employee.languageId : 0;
    }

    static instance: App;
    deviceUUID: string;//replace with singleton method
    appVersion: string;
    osName: string;
    appType: number = 4;
    driver: DriverWithMessages;
    data: Data;
    language: Language;
    statistic: Statistic;
    router: Router;
    releaseDate: string;
    translate: TranslateService;
    quizStatisticProcessor: SendQuizStatistic;
    theme: ThemeWrapper;


    ngOnInit(): any {

    }

    constructor(http: HttpClient, router: Router, translate: TranslateService, private modalService: ModalService, private userSessionProvider:UserSessionProviderService) {
        this.appVersion = Window['appVersion'];
        this.releaseDate = Window['releaseDate'];
        this.router = router;
        this.driver = new DriverWithMessages(http, this);
        this.data = null;
        this.statistic = new Statistic(this);
        this.theme = new ThemeWrapper();
        this.translate = translate;
        this.createDeviceId();
        this.language = new Language(this.translate, this, modalService, userSessionProvider);
        this.quizStatisticProcessor = new SendQuizStatistic(this);

        window.addEventListener('popstate', function (event) {
            window.history.forward();
        });

    }

    static init(http: HttpClient, router: Router, translate: TranslateService, modalService: ModalService, userSessionProvider: UserSessionProviderService): void {
        App.instance = new App(http, router, translate, modalService, userSessionProvider);
    }

    getEmployeeId(): number {
        return this.data != null && this.data.employee != null ? this.data.employee.id : 0;
    }

    getEmployee(): EmployeeMessageData {
        return this.data != null && this.data.employee != null ? this.data.employee : null;
    }

    get isRetailer(): boolean {
        if (this.data.employee.retailerId && this.data.employee.retailerId > 0)
            return true;
        return false;
    }

    loadData(employeeId: number, handler: LoadDataSuccessHandler) {
        this.data = new Data();

        this.driver.getCoursesAndRelated(employeeId)
            .onSuccess(rsp => {
                this.data.populateDataByCoursesAndRelated(rsp);
                this.language = new Language(this.translate, this, this.modalService, this.userSessionProvider);
                this.theme = ThemeWrapper.GetActiveThemeOrDefault();
                this.syncEmployeePoints(handler);
                this.statistic.loginAction();
            })
            .onError((error, ec, em, responce) => {
                console.log(error);

                if (handler)
                    handler(false, ec, em);
            }).send();
    }


    tryLoginUser() {
        let employeeId = this.userSessionProvider.getSession();
        if (!employeeId || employeeId == '') {
            this.router.navigate(["login"]);
        } else {
            var that = this;
            this.loadData(+employeeId, (s: boolean, ec: number, em: string) => {
                if (s) {
                    let emp = this.data.employee;
                    if (this.data.employee.status != 1) {
                        //TODO SHOW MESSAGE
                        this.router.navigate(["login"]);
                    } else {
                        if (emp.isRetailer()) {
                            that.driver.getRetailerEvents(emp.id, emp.storeId, emp.retailerId).onSuccess((rsp: RetailerEventsResponseMessage) => {
                                if (rsp.error == null)
                                    that.data.populateDataEvents(rsp.events, rsp.attachments);

                                that.router.navigate(["main"]);
                            }).onError((error, ec, em, responce) => {
                                that.router.navigate(["main"]);
                                `   `
                            }).send();
                        } else {
                            that.router.navigate(["main"]);
                        }
                    }
                }
            });
        }
    }

    syncEmployeePoints(handler: LoadDataSuccessHandler) {
        let a = new EmployeePointsReasonMessageData();
        let ep = this.data.getEmployeePoints();

        this.driver.syncEmployeePointsWithCheck(ep.employeeId, ep.server, ep.local, new Array<EmployeePointsReasonMessageData>()).onSuccess((rsp: EmployeePointsSyncWithCheckResponseMessage) => {
            ep.server = rsp.server;
            ep.local = rsp.local;
            if (handler)
                handler(true);
        }).onError((e, ec, em, responce) => {
            //TODO: communication error
            if (handler)
                handler(false, ec, em);
        }).send();
    }

    getEmployeePoints(): number {
        return this.data.getEmployeePoints().resultPoint();
    }

    createDeviceId() {
        this.deviceUUID = HelperMethods.getCookie('device-id', null);
        if (StringExtensions.isNullorEmpty(this.deviceUUID)) {
            this.deviceUUID = UUID.create();
            HelperMethods.setCookie('device-id', this.deviceUUID, 366);
        }
    }

}

declare type LoadDataSuccessHandler = (success: boolean, errorCode?: number, message?: string) => void;
