import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterComponent} from "./register.component";
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {CountryMessageData} from "../../communication/message_data/country_message_data";
import {CountriesResponseMessage} from "../../communication/messages/countries_response_message";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {HttpClient} from "@angular/common/http";
import {ModalService} from "../../common/services/modal.service";
import {LanguagesResponseMessage} from "../../communication/messages/languages_response_message";

@Component({
    selector: 'sp-countries-list',
    templateUrl: '../../../templates/register/countries_list.component.html'

})

export class CountriesListComponent implements OnInit {


    public registerData: RegisterData;
    public app: App = App.instance;
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;

    constructor(private registerComponent: RegisterComponent, private router: Router, private http: HttpClient, private modal: ModalService) {
        this.registerData = registerComponent.RegisterData;

    }

    ngOnInit() {
        if (this.registerData.countriesList.length > 0)
            return;

        this.spLoader.start();
        this.app.driver.getCountries(false)
            .onSuccess((rsp: CountriesResponseMessage) => {
                if (rsp.countries) {
                    this.spLoader.stopAndHide();
                    this.registerData.countriesList = rsp.countries;
                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                this.spLoader.stopAndHide();
            })
            .send();
    }

    Selected(country: CountryMessageData) {
        this.registerData.Country = country;

        this.app.driver.getLanguages(this.registerData.Country.id)
            .onSuccess((rsp: LanguagesResponseMessage) => {
                if (rsp.languages) {
                    this.registerData.languagesList = rsp.languages;
                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
            })
            .send();

        this.router.navigate(['register', 'country'])
    }
}
