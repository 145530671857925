import {Component, Input} from '@angular/core';
import {EventsMessageData} from "../../communication/message_data/events_message_data";

@Component({
    selector: 'sp-events-upcoming',
    templateUrl: '../../../templates/events/events_upcoming.component.html'
})
export class UpComingEventsComponent {
    @Input() items: Array<EventsMessageData>;

    constructor() {
    }
}

