export interface IJsonDeserializable {
    deserialize(jsonObject: any): void;
}

export function deserializeDateTime<T extends IJsonDeserializable>(jsonObject: any): Date {
    if (jsonObject == null)
        return null;

    let year = Number(jsonObject.substring(0, 4));
    let month = Number(jsonObject.substring(5, 7));
    let day = Number(jsonObject.substring(8, 10));
    let hour = Number(jsonObject.substring(11, 13));
    let min = Number(jsonObject.substring(14, 16));
    let sec = Number(jsonObject.substring(17, 19));

    return new Date(year, month - 1, day, hour, min, sec);
}

export function deserializeDateTimeUtc<T extends IJsonDeserializable>(jsonObject: any): Date {
    if (jsonObject == null)
        return null;

    let year = Number(jsonObject.substring(0, 4));
    let month = Number(jsonObject.substring(5, 7));
    let day = Number(jsonObject.substring(8, 10));
    let hour = Number(jsonObject.substring(11, 13));
    let min = Number(jsonObject.substring(14, 16));
    let sec = Number(jsonObject.substring(17, 19));

    let d = Date.UTC(year, month - 1, day, hour, min, sec);
    return new Date(d);
}

//TODO make method to return date to utc;

export function deserializeObject<T extends IJsonDeserializable>(jsonObject: any, c: { new(): T; }): T {
    if (jsonObject == null)
        return null;

    let obj = new c();
    obj.deserialize(jsonObject);
    return obj;
}

export function deserializeArrayOfObject<T extends IJsonDeserializable>(jsonObject: Array<any>, c: { new(): T; }): T[] {
    if (jsonObject == null)
        return null;

    let arr = new Array<T>();
    (jsonObject || []).forEach(p => arr.push(deserializeObject(p, c)));

    return arr;
}
