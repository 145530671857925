import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {RegisterComponent} from "./register.component";
import {RegisterBrandResponseMessage} from "../../communication/messages/register_brand_response_message";
import {RegisterRetailerResponseMessage} from "../../communication/messages/register_retailer_response_message";
import {HelperMethods} from "../../common/helper_methods";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {HttpClient} from "@angular/common/http";
import { SpModalComponent } from '../common/sp_modal.component';
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-register-terms-and-conditions',
    templateUrl: '../../../templates/register/terms_and_conditions.component.html'

})

export class TermsAndConditionsComponent implements OnInit {
    public registerData: RegisterData;
    public app: App = App.instance;

    url: string = null;
    termsId: number = null;
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;
    isLoadingInProcess: boolean = true;

    constructor(private registerComponent: RegisterComponent, private router: Router, private http: HttpClient, private modal:ModalService) {
        this.registerData = registerComponent.RegisterData;
    }

    ngOnInit() {
        this.spLoader.start();
        this.isLoadingInProcess = true;
        this.app.driver.getTermsAndConditionsId(this.registerData).onSuccess(rsp => {
            if (rsp.termsAndConditionsId) {
                this.termsId = rsp.termsAndConditionsId;
                this.isLoadingInProcess = false;
                this.url = HelperMethods.getUrlForTearmsAndConditions(rsp.termsAndConditionsId);
            }else {
                this.hideLoader();
                this.ActionButtonTapped();
            }
        }).onError(error => {
            this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
        }).send();
    }

    ActionButtonTapped() {
        let that = this;
        that.isLoadingInProcess = true;
        that.spLoader.start();
        this.registerData.TermsAndConditionsId = this.termsId != null ? this.termsId : 0;
            this.app.driver.registerRetailerByUserInfo(this.registerData).onSuccess((rsp: RegisterRetailerResponseMessage) => {
                that.isLoadingInProcess = false;
                that.spLoader.stopAndHide();
                if (rsp.registered) {
                    that.registerData.UserId = rsp.id;
                    that.router.navigate(['register', 'email_verification'])
                } else {
                    this.modal.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                }
            })
                .onError((e, ec, em) => {
                    this.modal.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                })
                .send();


    }

    hideLoader() {
        this.spLoader.stopAndHide();
    }

    BackButtonTapped() {
        this.router.navigate(['register', 'preferences'])
    }

}

