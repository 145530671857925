import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CourseMessageData} from "../../communication/message_data/course_message_data";
import {App} from "../../app";
import {QuizQuestionAnswerMessageData} from "../../communication/message_data/quiz_question_answer_message_data";
import {QuizQuestionMessageData} from "../../communication/message_data/quiz_question_message_data";
import {BrandCategoryMessageData} from "../../communication/message_data/brand_category_message_data";
import {CategoryMessageData} from "../../communication/message_data/category_message_data";
import {BrandExtendedMessageData} from "../../communication/message_data/brand_extended_message_data";
import {TopicMessageData} from "../../communication/message_data/topic_message_data";
import {CourseTopicsComponent} from "./course_topics.component";
import {EmployeeCourseState} from "../../data/employee_course_state";
import {HelperMethods} from "../../common/helper_methods";
import {EmployeeQuizAttemptMessageData} from "../../communication/message_data/employee_quiz_attempt_message_data";
import {UUID} from "../../common/uuid";
import {QuizType} from "../../data/quiz_type";
import {EmployeeQuizAnswerMessageData} from "../../communication/message_data/employee_quiz_answer_message_data";
import {QuizQuestionType} from "../../data/quiz_question_type";

@Component({
    selector: 'sp-topic-quiz',
    templateUrl: '../../../templates/courses/topic_quiz.component.html'
})
export class TopicQuizComponent implements OnInit {
    private sub: any;
    @Input() courseId: number;
    @Input() topicId: number;
    @Input() context: CourseTopicsComponent;

    app: App = App.instance;

    questionTypes = QuizQuestionType;

    topic: TopicMessageData;
    course: CourseMessageData;
    quiz: QuizQuestionMessageData;

    currentIndex: number;
    currentQuestion: QuizQuestionMessageData;

    leftActionButtonText: string;
    brand: BrandExtendedMessageData;


    rightActionButtonState: TQActionButtonState;
    rightActionButtonText: string;

    isFinished: boolean = false;
    brandCategory: BrandCategoryMessageData;
    category: CategoryMessageData;

    resultText: string = "";
    message: string = "";
    private quizAttempt: EmployeeQuizAttemptMessageData;
    private quizAnswers: Array<EmployeeQuizAnswerMessageData>;
    private selectedAnswers: Array<QuizQuestionAnswerMessageData>;

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit(): any {
        this.course = this.app.data.courses.getValue(this.courseId);
        this.topic = this.app.data.topics.getValue(this.topicId);
        this.brand = this.app.data.brands.getValue(this.course.brandId);
        this.loadData();
    }

    private loadData() {
        this.selectedAnswers = new Array<QuizQuestionAnswerMessageData>();
        this.isFinished = false;
        let shuffledQuestions = HelperMethods.shuffle(this.course.quiz.questions.filter(p => p.topicId == this.topicId));
        this.currentQuestion = shuffledQuestions[0];
        this.updateActionButtonState();
        this.app.statistic.topicQuizOpen(this.course.id, this.topicId);
        this.startQuizStatisticDataCollector();
    }

    private updateActionButtonState() {
        if (!this.isFinished) {
            this.rightActionButtonState = TQActionButtonState.Submit;
            this.rightActionButtonText = "COURSE_TOPIC_QUIZ.SUBMIT";
        }
        else {
            if (this.isAnswerCorrect()) {
                this.resultText = "COURSE_TOPIC_QUIZ.SUCCESS";
                this.message = this.currentQuestion.success;
                if (this.context.isLastTopic()) {
                    if (this.course.forEmployeeState == EmployeeCourseState.quizCompleted) {
                        if (this.course.isAllowedRecertification()) {
                            this.rightActionButtonState = TQActionButtonState.Quiz;
                            this.rightActionButtonText = "COURSE_TOPIC_QUIZ.NEXT";
                        } else {
                            this.rightActionButtonState = TQActionButtonState.Certificate;
                            this.rightActionButtonText = "COURSE_TOPIC_QUIZ.NEXT";
                        }
                    }
                    else {
                        if (this.course.allTopicsPassed()) {
                            this.rightActionButtonState = TQActionButtonState.Quiz;
                            this.rightActionButtonText = "COURSE_TOPIC_QUIZ.NEXT";
                        }
                    }
                } else {
                    this.rightActionButtonState = TQActionButtonState.GoToNextTopic;
                    this.rightActionButtonText = "COURSE_TOPIC_QUIZ.NEXT";
                }
            } else {
                this.resultText = "COURSE_TOPIC_QUIZ.FAILURE";
                this.message = this.currentQuestion.failure;
                this.rightActionButtonState = TQActionButtonState.GoBackToTopic;
                this.rightActionButtonText = "COURSE_TOPIC_QUIZ.LEARN";
            }
        }
    }

    onRightActionButtonClick() {
        if (this.rightActionButtonState == TQActionButtonState.Submit)
            this.tryFinishQuiz();
        else if (this.rightActionButtonState == TQActionButtonState.GoToNextTopic)
            this.loadNextTopic();
        else if (this.rightActionButtonState == TQActionButtonState.GoBackToTopic)
            this.GoBackToTopic();
        else if (this.rightActionButtonState == TQActionButtonState.Quiz)
            this.context.tryShowQuiz();
        else if (this.rightActionButtonState == TQActionButtonState.Certificate)
            this.context.tryShowCertificate();
    }

    tryFinishQuiz() {
        this.isFinished = true;
        if (this.isAnswerCorrect()) {
            this.context.currentTopic.updateStateUp(this.context.course);
        }
        let all: number = 1;
        let correct: number = this.isAnswerCorrect() ? 1 : 0;
        let finalScore = this.isAnswerCorrect() ? 100 : 0;
        this.app.statistic.topicQuizClosed(this.course.id, this.topicId, finalScore, correct);
        this.endQuizStatisticDataCollector(this.isAnswerCorrect(), finalScore);
        this.updateActionButtonState();

    }

    onAnswerClick(answer: QuizQuestionAnswerMessageData) {
        answer.answeredAt = new Date();
        if (this.currentQuestion.questionType == QuizQuestionType.SingleSelect) {
            this.selectedAnswers = this.selectedAnswers.filter(a => a.questionId != this.currentQuestion.id);
            this.selectedAnswers.push(answer);
        }

        if (this.currentQuestion.questionType == QuizQuestionType.MultiSelectAny || this.currentQuestion.questionType == QuizQuestionType.MultiSelectAll) {
            let answerAllreadySelected = this.selectedAnswers.some(a => a.id == answer.id);
            if (answerAllreadySelected) {
                this.selectedAnswers = this.selectedAnswers.filter(a => a.id != answer.id);
            } else {
                this.selectedAnswers.push(answer);
            }
        }
    }

    isAnswerSelected(answer: QuizQuestionAnswerMessageData) {
        return this.selectedAnswers.some(item => item.id == answer.id);
    }

    isAnyAnswerSelected() {
        let questionAnswersCount = this.selectedAnswers.filter(ans => ans.questionId == this.currentQuestion.id).length;
        return questionAnswersCount > 0;
    }

    private loadNextTopic() {
        this.context.topicQuizCallback(true);
    }

    isAnswerCorrect(): boolean {

        let selectedQuestionAnswers: Array<QuizQuestionAnswerMessageData> = this.selectedAnswers.filter(item => item.questionId == this.currentQuestion.id);
        let questionCorrectAnswersCount: number = this.currentQuestion.answers.filter(ans => ans.isCorrect).length
        let correctSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => ans.isCorrect).length;
        let incorrectSelectedAnswersCount: number = selectedQuestionAnswers.filter(ans => !ans.isCorrect).length;

        if (this.currentQuestion.questionType == QuizQuestionType.SingleSelect) {
            return selectedQuestionAnswers[0].isCorrect;
        }

        if (this.currentQuestion.questionType == QuizQuestionType.MultiSelectAny) {
            return correctSelectedAnswersCount > 0 && incorrectSelectedAnswersCount == 0;
        }

        if (this.currentQuestion.questionType == QuizQuestionType.MultiSelectAll) {
            return correctSelectedAnswersCount == questionCorrectAnswersCount && incorrectSelectedAnswersCount == 0;
        }
    }

    private GoBackToTopic() {
        this.context.topicQuizCallback(false);
    }

    private startQuizStatisticDataCollector() {
        this.quizAttempt = new EmployeeQuizAttemptMessageData();
        this.quizAttempt.guid = UUID.create();
        this.quizAttempt.employeeId = this.app.getEmployee().id;
        this.quizAttempt.passed = false;
        this.quizAttempt.score = 0;
        this.quizAttempt.quizType = QuizType.Course;
        this.quizAttempt.quizId = this.course.quiz.id;
        this.quizAttempt.courseId = this.course.id;
        this.quizAttempt.topicId = this.topic.id;
        this.quizAttempt.awardId = null;
        this.quizAttempt.startAt = new Date();
    }

    private endQuizStatisticDataCollector(passed: boolean, score: number) {
        this.quizAttempt.passed = passed;
        this.quizAttempt.score = score;
        this.quizAttempt.endAt = new Date();
        this.quizAnswers = this.app.quizStatisticProcessor.quizAnswersToMessageData(this.selectedAnswers, this.quizAttempt.guid, [this.currentQuestion]);//todo
        this.app.quizStatisticProcessor.trySendData(this.quizAttempt, this.quizAnswers);
    }

    get questionHint() {
        let questionHint = "";
        switch (this.currentQuestion.questionType) {
            case QuizQuestionType.SingleSelect:
                questionHint = 'COMMON.QUESTION_HINTS.SINGLE_SELECT';
                break;
            case QuizQuestionType.MultiSelectAny:
            case QuizQuestionType.MultiSelectAll:
                questionHint = 'COMMON.QUESTION_HINTS.MULTI_SELECT_ALL';
                break;
            default:
                questionHint = ''
                break;
        }
        return questionHint;
    }

}

enum TQActionButtonState {
    None,
    Previous,
    Submit,
    GoToNextTopic,
    GoBackToTopic,
    Quiz,
    Certificate,
    Learn
}