import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class RecentlyAwardedRequestMessage extends RequestMessage {
    employeeId: number;
    count: number;
    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.RecentlyAwardedRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.count = jsonObject.count;
        this.preferredLang = jsonObject.preferredLang;
    }
}
