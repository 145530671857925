import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {App} from "../../app";

@Component({
    selector: 'sp-download',
    templateUrl: '../../../templates/common/download.component.html',
})

export class DownloadComponent implements OnInit {

    app: App = App.instance;

    pageTitle: string;

    isEvent: boolean = false;
    isShare: boolean = false;
    sub: any;
    private step: number;


    constructor(private route: ActivatedRoute, private router: Router) {
    }


    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.step = +params['ist'];
            switch (this.step) {
                case 1:
                    this.showNotifications();
                    break;
                case 2:
                    this.showShareSp();
                    break;
                case 3:
                    this.showClipboard();
                    break;
                case 4:
                    this.showPlayzone();
                    break;
                case 5:
                    this.showEvents();
                    break;
                case 6:
                    this.showProShop();
                    break;
                case 7:
                    this.showTheMall();
                    break;
            }
        });
    }


    private showNotifications() {
        this.pageTitle = "DOWNLOAD.FEED";
    }

    private showShareSp() {
        this.pageTitle = "DOWNLOAD.SHARE_SP";
    }

    private showClipboard() {
        this.pageTitle = "DOWNLOAD.CLIPBOARD";
    }

    private showPlayzone() {
        this.pageTitle = "DOWNLOAD.PLAYZONE";
    }

    private showEvents() {
        this.pageTitle = "DOWNLOAD.EVENTS";
    }

    private showProShop() {
        this.pageTitle = "DOWNLOAD.PRO_SHOP";
    }

    private showTheMall() {
        this.pageTitle = "DOWNLOAD.THE_MALL";
    }
}

