import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {SurveyQuestionAnswerMessageData} from "../../../communication/message_data/survey_question_answer_message_data";
import {QuizQuestionType} from "../../../data/quiz_question_type";
import {SurveyQuestionMessageData} from "../../../communication/message_data/survey_question_message_data";
import {BaseAnswer} from "./base_answer";

@Component({
    selector: 'sp-select-answer',
    templateUrl: '../../../../templates/surveys/answer_types/select-answer.component.html'
})
export class SelectAnswerComponent extends BaseAnswer implements OnInit, OnDestroy {

    @Input() question: SurveyQuestionMessageData;
    @Input() surveyId: number;
    @Input() employeeSurveyId: number;
    @Output() answerSelect = new EventEmitter();

    constructor() {
        super();
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }


    onAnswerClick(answer: SurveyQuestionAnswerMessageData) {
        if (this.question.type == QuizQuestionType.SingleSelect) {
            this.question.selectedAnswers = [];
            this.question.selectedAnswers.push(answer);
        }
        if (this.question.type == QuizQuestionType.MultiSelectAny || this.question.type == QuizQuestionType.MultiSelectAll) {
            let answerAllreadySelected = this.question.selectedAnswers.some(a => a.id == answer.id);
            if (answerAllreadySelected) {
                this.question.selectedAnswers = this.question.selectedAnswers.filter(a => a.id != answer.id);
            } else {
                this.question.selectedAnswers.push(answer);
            }
        }
        if (this.anyAnswerSelected()) {
            this.createEmployeeSurveyAnswerMessage(this.question, this.employeeSurveyId, () => {
                this.answerSelect.emit();
            });
        } else {
            this.question.employeeAnswerData = null;
        }
    }

    isAnswerSelected(answer: SurveyQuestionAnswerMessageData) {
        return this.question.selectedAnswers.some(item => item.id == answer.id);
    }

    anyAnswerSelected() {
        return this.question.selectedAnswers.length > 0;
    }

}