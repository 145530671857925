import {ItemMessageData} from "./item_message_data";

export class RetailerMessageData extends ItemMessageData {
    retailerMessageDataVersion: number = 0;

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.retailerMessageDataVersion = jsonObject.retailerMessageDataVersion;
    }
}
