import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";

export class CoursesAndRelatedRequestMessage extends RequestMessage {
    employeeId: number;
    osType: number;
    appBuild: number;
    appVersion: string;

    preferredLang: number;

    constructor() {
        super();
        this.type = MessageTypes.CoursesAndRelatedRequest;
        this.osType = 4;
        this.appBuild = 0;
        this.appVersion = "";

    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);

        this.employeeId = jsonObject.employeeId;
        this.preferredLang = jsonObject.preferredLang;
    }
}
