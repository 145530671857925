import {RequestMessage} from "../request_message";
import {MessageTypes} from "../message_types";
import {EmployeePointsReasonMessageData} from "../message_data/employee_points_reason_message_data";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";

export class EmployeePointsSyncWithCheckRequestMessage extends RequestMessage {
    employeeId: number;
    server: number;
    local: number;
    preferredLang: number;
    pointsReason: Array<EmployeePointsReasonMessageData>;

    constructor() {
        super();
        this.type = MessageTypes.EmployeePointsSyncWithCheckRequest;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.employeeId = jsonObject.employeeId;
        this.server = jsonObject.server;
        this.local = jsonObject.local;
        this.preferredLang = jsonObject.preferredLang;
        this.pointsReason = deserializeArrayOfObject(jsonObject.pointsReason, jsonObject);
    }
}
