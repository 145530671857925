import {Component, OnInit, ViewChild} from '@angular/core';
import {JobCategoryMessageData} from "../../communication/message_data/job_category_message_data";
import {RegisterData} from "./register.data";
import {App} from "../../app";
import {RegisterComponent} from "./register.component";
import {Router} from "@angular/router";
import {JobCategoriesResponseMessage} from "../../communication/messages/job_categories_response_message";
import {SpLoaderComponent} from "../common/sp_loader.component";
import {HttpClient} from "@angular/common/http";
import {ModalService} from "../../common/services/modal.service";

@Component({
    selector: 'sp-job-categories-list',
    templateUrl: '../../../templates/register/job_categories_list.component.html'
})
export class JobCategoriesListComponent implements OnInit {
    @ViewChild('sploader', { static: true }) spLoader: SpLoaderComponent;
    public registerData: RegisterData;
    public app: App = App.instance;

    constructor(private registerComponent: RegisterComponent, private router: Router, private http: HttpClient, private modal: ModalService) {
        this.registerData = registerComponent.RegisterData;
    }

    ngOnInit() {
        if (this.registerData.jobCategoriesList.length > 0)
            return;
        this.spLoader.start();
        this.app.driver.getJobCategories(this.registerData.UserType, 0, this.registerData.Language.id)
            .onSuccess((rsp: JobCategoriesResponseMessage) => {
                if (rsp.jobCategories) {

                    this.spLoader.stopAndHide();
                    this.registerData.jobCategoriesList = rsp.jobCategories;

                }
            })
            .onError((e, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                this.spLoader.stopAndHide();
            })
            .send();
    }

    Selected(record: JobCategoryMessageData) {
        this.registerData.JobCategory = record;
        this.router.navigate(['register', 'retailer_employer'])

    }
}
