import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TopicMessageData} from "../../communication/message_data/topic_message_data";
import {App} from "../../app";

@Component({
    selector: 'sp-common-html',
    templateUrl: '../../../templates/common_topics/common_topic_html.component.html'
})

export class CommonTopicHtmlComponent implements OnInit, OnDestroy {
    @Input() topic: TopicMessageData;

    private sub: any;
    public app: App = App.instance;

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit(): any {
        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // (+) converts string 'id' to a number
            this.topic = this.app.data.topics.getValue(id);

            let cst = this.topic.getStatisticsTypeByCourse(this.app.data.courses.getValue(this.topic.courseId));
            this.app.statistic.topicOpen(this.topic.courseId, this.topic.id, cst);
        });
    }

    ngOnDestroy(): any {
        let cst = this.topic.getStatisticsTypeByCourse(this.app.data.courses.getValue(this.topic.courseId));
        this.app.statistic.topicClosed(this.topic.courseId, this.topic.id, cst)
    }


    onMainToolBoxScreen() {
        this.router.navigate(['main', 'reference_detail', this.topic.courseId]);
    }

}
