import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {RetailerLeaderboardListComponent} from "./internal/retailer_leaderboard_list.component";
import {App} from "../../../app";
import {LeaderBoardType} from "../../../data/leader_board_types";
import {SpLoaderComponent} from "../../common/sp_loader.component";
import {LeaderBoardInfoMessageData} from "../../../communication/message_data/leaderboard_info_message_data";
import {EmployeeLeaderBoardResponseMessage} from "../../../communication/messages/employee_leaderboard_response_message";
import {EmployeeLeaderBoard2ResponseMessage} from "../../../communication/messages/employee_leaderboard_2_response_message";
import {ModalService} from "../../../common/services/modal.service";

@Component({
    selector: 'sp-courses',
    templateUrl: '../../../../templates/leaderboard/retailer/retailer_leaderboard.component.html'
})
export class RetailerLeaderboardComponent implements OnInit {
    app: App = App.instance;
    LeaderBoardTypes = LeaderBoardType;
    selectedLeaderBoardType: LeaderBoardType = LeaderBoardType.TopInStore;
    loading: boolean = false;
    @ViewChild('sploader') spLoader: SpLoaderComponent;
    records: Array<LeaderBoardInfoMessageData> = [];

    constructor(private modal: ModalService, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): any {
        this.fetchData();
    }

    onLeaderBoardTypeChanged(type: LeaderBoardType) {
        this.selectedLeaderBoardType = type;
        this.fetchData();
    }

    showLoading(){
        this.loading = true;
        this.changeDetectorRef.detectChanges();
        this.spLoader.start();
    }

    hideLoading(){
        this.spLoader.stopAndHide();
        this.loading = false;
        this.changeDetectorRef.detectChanges();
    }

    fetchData(){
        this.showLoading();
        this.app.driver.getEmployeeLeaderBoard(this.app.getEmployee(), 10, this.selectedLeaderBoardType)
            .onSuccess((rsp: EmployeeLeaderBoard2ResponseMessage) => {
                this.hideLoading();
                this.records = rsp.data;
            })
            .onError((error, ec, em) => {
                this.modal.show("COMMON.ERROR", "COMMON.ERROR_NO_CONNECTION");
                this.hideLoading();
            })
            .send();
    }


}

