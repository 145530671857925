import {StateMessageData} from "../communication/message_data/state_message_data";
import {StringExtensions} from "../common/extensions";
import {StoreFieldsRequirementValues} from "./store_fields_requirement_values";

export class AddStoraData {
    private _Address: string;
    private _City: string;
    private _RetailerId: number;
    private _StoreNumber: string;
    private _Zip: string;
    private _Description: string;
    private _GooglePlaceId: string;
    private _Lat: number;
    private _Lng: number;
    private _State: StateMessageData = new StateMessageData();

    constructor(
    private storeZipRequirement: StoreFieldsRequirementValues,
    private storeDescriptionRequirement: StoreFieldsRequirementValues,
    private countryName:string
    ) {
    }

    get Address(): string {
        return this._Address;
    }

    set Address(value: string) {
        this._Address = value;
    }

    get City(): string {
        return this._City;
    }

    set City(value: string) {
        this._City = value;
    }

    get RetailerId(): number {
        return this._RetailerId;
    }

    set RetailerId(value: number) {
        this._RetailerId = value;
    }

    get StoreNumber(): string {
        return this._StoreNumber;
    }

    set StoreNumber(value: string) {
        this._StoreNumber = value;
    }

    get Zip(): string {
        return this._Zip;
    }

    set Zip(value: string) {
        this._Zip = value;
    }

    get Description(): string {
        return this._Description;
    }

    set Description(value: string) {
        this._Description = value;
    }

    get State(): StateMessageData {
        return this._State;
    }

    set State(value: StateMessageData) {
        this._State = value;
    }

    get Lat(): number {
        return this._Lat;
    }

    set Lat(value: number) {
        this._Lat = value;
    }

    get Lng(): number {
        return this._Lng;
    }

    set Lng(value: number) {
        this._Lng = value;
    }

    get GooglePlaceId(): string {
        return this._GooglePlaceId;
    }

    set GooglePlaceId(value: string) {
        this._GooglePlaceId = value;
    }

    get IsAddStoreValid() {
        if (!StringExtensions.isNullorEmpty(this.Address) &&
            !StringExtensions.isNullorEmpty(this.StoreNumber) &&
            !StringExtensions.isNullorEmpty(this.City) &&
            (this.storeZipRequirement != StoreFieldsRequirementValues.Required ||
                (!StringExtensions.isNullorEmpty(this.Zip) &&
                    StringExtensions.IsZipValidForCountry(this.Zip, this.countryName))) &&
            (this.storeDescriptionRequirement != StoreFieldsRequirementValues.Required ||
                !StringExtensions.isNullorEmpty(this.Description))
        ) {
            return true;
        }
        return false;
    }

}