import {IJsonDeserializable} from "../json_deserialization/json_deserialization";

export class EmployeePointsReasonMessageData implements IJsonDeserializable {
    version: number = 0;

    guid: string;
    employeeId: number;
    dateTime: Date;
    points: number;
    reason: number;
    reasonArgument: number;
    device: string;
    appVersion: string;

    deserialize(jsonObject: any): void {
        this.version = jsonObject.version;

        this.guid = jsonObject.guid;
        this.employeeId = jsonObject.employeeId;
        this.dateTime = jsonObject.dateTime;
        this.points = jsonObject.points;
        this.reason = jsonObject.reason;
        this.reasonArgument = jsonObject.reasonArgument;
        this.device = jsonObject.device;
        this.appVersion = jsonObject.appVersion;
    }
}
