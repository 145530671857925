import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";
import {deserializeDateTime} from "../json_deserialization/json_deserialization";

export class GetContentStateChangesDatesResponseMessage extends ResponseMessage {
    coursesChangedAt: Date;
    topicChangedAt: Date;
    awardsChangedAt: Date;

    constructor() {
        super();
        this.type = MessageTypes.GetContentStateChangesDatesResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.coursesChangedAt = deserializeDateTime(jsonObject.coursesChangedAt);
        this.topicChangedAt = deserializeDateTime(jsonObject.topicChangedAt);
        this.awardsChangedAt = deserializeDateTime(jsonObject.awardsChangedAt);
    }
}
