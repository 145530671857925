import {ResponseMessage} from "../response_message";
import {StoreForBrandLeaderboardMessageData} from "../message_data/store_for_brand_leaderboard_message_data";
import {MessageTypes} from "../message_types";
import {deserializeArrayOfObject} from "../json_deserialization/json_deserialization";


export class StoreForBrandLeaderboardResponseMessage extends ResponseMessage {
    stores: Array<StoreForBrandLeaderboardMessageData>;

    constructor() {
        super();
        this.type = MessageTypes.StoreForBrandLeaderboardResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.stores = deserializeArrayOfObject(jsonObject.stores, StoreForBrandLeaderboardMessageData);
    }
}
