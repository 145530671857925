import {ResponseMessage} from "../response_message";
import {MessageTypes} from "../message_types";

export class GetTermsAndConditionsIdResponseMessage extends ResponseMessage {
    termsAndConditionsId: number;

    constructor() {
        super();
        this.type = MessageTypes.GetTermsAndConditionsDateResponse;
    }

    deserialize(jsonObject: any): void {
        super.deserialize(jsonObject);
        this.termsAndConditionsId = jsonObject.termsAndConditionsId;
    }
}
